import React from "react";
import { Document, MediaTypes, Presentation, Video } from "../../../api";
import { TrainingResourceSelectorFilter } from "./TrainingResourceSelectorFilter";
import { TrainingResourceSelectorList } from "./TrainingResourceSelectorList";

interface TrainingResourceSelectorProps {
  sidebarOpen: boolean;
  mediaType: string;
  mediaCategory: string;
  mediaCategoryNames: string[];
  mediaSearchFilter: string;
  media: Array<Document | Video>;
  selectedMediaItemId: string;
  onMediaTypeChange: (mediaType: MediaTypes) => void;
  onMediaCategoryChange: (mediaCategory: string) => void;
  onMediaSearchFilterChange: (searchFilter: string) => void;
  onSelectedMediaItemChange: (mediaItem: Document | Presentation | Video) => void;
}

export const TrainingResourceSelectorComponent = (props: TrainingResourceSelectorProps) => {
  const {
    media,
    mediaType,
    mediaCategory,
    mediaCategoryNames,
    mediaSearchFilter,
    selectedMediaItemId,
    onMediaTypeChange,
    onMediaCategoryChange,
    onMediaSearchFilterChange,
    onSelectedMediaItemChange,
  } = props;

  const loadingMedia = !media;

  return (
    <>
      <TrainingResourceSelectorFilter
        mediaType={mediaType}
        mediaCategory={mediaCategory}
        mediaCategoryNames={mediaCategoryNames}
        mediaSearchFilter={mediaSearchFilter}
        onMediaTypeChange={onMediaTypeChange}
        onMediaCategoryChange={onMediaCategoryChange}
        onMediaSearchFilterChange={onMediaSearchFilterChange}
      />
      <TrainingResourceSelectorList
        loading={loadingMedia}
        media={media}
        selectedMediaItemId={selectedMediaItemId}
        onSelectedMediaItemChange={onSelectedMediaItemChange}
      />
    </>
  );
};

export const TrainingResourceSelector = TrainingResourceSelectorComponent;
