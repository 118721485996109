import { FormSchema } from "@interface48/forms";

export const getTrainingProgramSessionRevisionFormSchema = () => {
  const schema: FormSchema = {
    jsonSchema: {
      type: "object",
      properties: {
        number: {
          type: "number",
          title: "Number",
        },
        userName: {
          type: "string",
          title: "Trainee",
        },
        originalDepartment: {
          type: "number",
        },
        originalDepartmentName: {
          type: "string",
          title: "Department (Original)",
        },
        reviseDepartment: {
          type: "boolean",
          title: "Revise Department?",
        },
        originalPosition: {
          type: "number",
        },
        originalPositionName: {
          type: "string",
          title: "Position (Original)",
        },
        revisePosition: {
          type: "boolean",
          title: "Revise Position?",
        },
        lastRevised: {
          title: "Last Revised",
          type: "object",
          properties: {
            at: {
              type: "string",
            },
            userName: {
              type: "string",
            },
            personName: {
              type: "string",
            },
          },
        },
      },
      dependencies: {
        reviseDepartment: {
          oneOf: [
            {
              required: ["revisedDepartment"],
              properties: {
                reviseDepartment: { enum: [true] },
                revisedDepartment: {
                  type: "number",
                  title: "Department (Revised)",
                },
              },
            },

            {
              properties: {
                reviseDepartment: { enum: [false] },
              },
            },
          ],
        },
        revisePosition: {
          oneOf: [
            {
              required: ["revisedPosition"],
              properties: {
                revisePosition: { enum: [true] },
                revisedPosition: {
                  type: "number",
                  title: "Position (Revised)",
                },
              },
            },

            {
              properties: {
                revisePosition: { enum: [false] },
              },
            },
          ],
        },
      },
    },
    uiSchema: {
      "ui:order": [
        "number",
        "userName",
        "originalDepartmentName",
        "reviseDepartment",
        "revisedDepartment",
        "originalPositionName",
        "revisePosition",
        "revisedPosition",
        "lastRevised",
        "*",
      ],
      number: {
        "ui:widget": "ReadOnlyWidget",
      },
      userName: {
        "ui:widget": "ReadOnlyWidget",
      },
      originalDepartment: {
        "ui:widget": "hidden",
      },
      originalDepartmentName: {
        "ui:widget": "ReadOnlyWidget",
        "ui:emptyValue": "(Unspecified)",
      },
      originalPosition: {
        "ui:widget": "hidden",
      },
      originalPositionName: {
        "ui:widget": "ReadOnlyWidget",
        "ui:emptyValue": "(Unspecified)",
      },
      revisedDepartment: {
        "ui:widget": "select",
        "ui:options": {
          remoteOptions: {
            url: "/api/lists/departments",
          },
        },
      },
      revisedPosition: {
        "ui:widget": "select",
        "ui:options": {
          remoteOptions: {
            url: "/api/lists/positions",
          },
        },
      },
      lastRevised: {
        "ui:field": "AuditDetailsField",
      },
    },
  };

  return schema;
};
