/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddOrUpdateTrainingProgramSessionQuizCommand
 */
export interface AddOrUpdateTrainingProgramSessionQuizCommand {
    /**
     * 
     * @type {string}
     * @memberof AddOrUpdateTrainingProgramSessionQuizCommand
     */
    quizId: string;
    /**
     * 
     * @type {number}
     * @memberof AddOrUpdateTrainingProgramSessionQuizCommand
     */
    quizVersion: number;
    /**
     * 
     * @type {{ [key: string]: Array<number>; }}
     * @memberof AddOrUpdateTrainingProgramSessionQuizCommand
     */
    quizQuestionAnswerValues: { [key: string]: Array<number>; };
    /**
     * 
     * @type {string}
     * @memberof AddOrUpdateTrainingProgramSessionQuizCommand
     */
    beganAt: string;
    /**
     * 
     * @type {string}
     * @memberof AddOrUpdateTrainingProgramSessionQuizCommand
     */
    completedAt?: string | null;
}

export function AddOrUpdateTrainingProgramSessionQuizCommandFromJSON(json: any): AddOrUpdateTrainingProgramSessionQuizCommand {
    return {
        'quizId': json['quizId'],
        'quizVersion': json['quizVersion'],
        'quizQuestionAnswerValues': json['quizQuestionAnswerValues'],
        'beganAt': json['beganAt'],
        'completedAt': !exists(json, 'completedAt') ? undefined : json['completedAt'],
    };
}

export function AddOrUpdateTrainingProgramSessionQuizCommandToJSON(value?: AddOrUpdateTrainingProgramSessionQuizCommand | null): any {
    if (value == undefined) {
        return undefined;
    }
    return {
        'quizId': value.quizId,
        'quizVersion': value.quizVersion,
        'quizQuestionAnswerValues': value.quizQuestionAnswerValues,
        'beganAt': value.beganAt,
        'completedAt': value.completedAt,
    };
}


