/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TrainingProgramSessionCourseResultDto,
    TrainingProgramSessionCourseResultDtoFromJSON,
    TrainingProgramSessionCourseResultDtoToJSON,
    TrainingProgramSessionQuizResultDto,
    TrainingProgramSessionQuizResultDtoFromJSON,
    TrainingProgramSessionQuizResultDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface TrainingProgramSessionResultDto
 */
export interface TrainingProgramSessionResultDto {
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramSessionResultDto
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof TrainingProgramSessionResultDto
     */
    number: number;
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramSessionResultDto
     */
    trainingProgramName: string;
    /**
     * 
     * @type {number}
     * @memberof TrainingProgramSessionResultDto
     */
    trainingProgramMajorVersion: number;
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramSessionResultDto
     */
    trainingProgramBeganAt: string;
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramSessionResultDto
     */
    trainingProgramCompletedAt: string;
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramSessionResultDto
     */
    userName: string;
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramSessionResultDto
     */
    companyName: string;
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramSessionResultDto
     */
    userEmailAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramSessionResultDto
     */
    userWorkNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TrainingProgramSessionResultDto
     */
    idBadgeType?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramSessionResultDto
     */
    idBadgeTypeName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramSessionResultDto
     */
    idBadgeNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramSessionResultDto
     */
    supervisorName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TrainingProgramSessionResultDto
     */
    department?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramSessionResultDto
     */
    departmentName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TrainingProgramSessionResultDto
     */
    position?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramSessionResultDto
     */
    positionName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramSessionResultDto
     */
    lastRevisedAt?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramSessionResultDto
     */
    lastRevisedByUserName?: string | null;
    /**
     * 
     * @type {Array<TrainingProgramSessionCourseResultDto>}
     * @memberof TrainingProgramSessionResultDto
     */
    courses: Array<TrainingProgramSessionCourseResultDto>;
    /**
     * 
     * @type {Array<TrainingProgramSessionQuizResultDto>}
     * @memberof TrainingProgramSessionResultDto
     */
    quizzes: Array<TrainingProgramSessionQuizResultDto>;
}

export function TrainingProgramSessionResultDtoFromJSON(json: any): TrainingProgramSessionResultDto {
    return {
        'id': json['id'],
        'number': json['number'],
        'trainingProgramName': json['trainingProgramName'],
        'trainingProgramMajorVersion': json['trainingProgramMajorVersion'],
        'trainingProgramBeganAt': json['trainingProgramBeganAt'],
        'trainingProgramCompletedAt': json['trainingProgramCompletedAt'],
        'userName': json['userName'],
        'companyName': json['companyName'],
        'userEmailAddress': !exists(json, 'userEmailAddress') ? undefined : json['userEmailAddress'],
        'userWorkNumber': !exists(json, 'userWorkNumber') ? undefined : json['userWorkNumber'],
        'idBadgeType': !exists(json, 'idBadgeType') ? undefined : json['idBadgeType'],
        'idBadgeTypeName': !exists(json, 'idBadgeTypeName') ? undefined : json['idBadgeTypeName'],
        'idBadgeNumber': !exists(json, 'idBadgeNumber') ? undefined : json['idBadgeNumber'],
        'supervisorName': !exists(json, 'supervisorName') ? undefined : json['supervisorName'],
        'department': !exists(json, 'department') ? undefined : json['department'],
        'departmentName': !exists(json, 'departmentName') ? undefined : json['departmentName'],
        'position': !exists(json, 'position') ? undefined : json['position'],
        'positionName': !exists(json, 'positionName') ? undefined : json['positionName'],
        'lastRevisedAt': !exists(json, 'lastRevisedAt') ? undefined : json['lastRevisedAt'],
        'lastRevisedByUserName': !exists(json, 'lastRevisedByUserName') ? undefined : json['lastRevisedByUserName'],
        'courses': (json['courses'] as Array<any>).map(TrainingProgramSessionCourseResultDtoFromJSON),
        'quizzes': (json['quizzes'] as Array<any>).map(TrainingProgramSessionQuizResultDtoFromJSON),
    };
}

export function TrainingProgramSessionResultDtoToJSON(value?: TrainingProgramSessionResultDto | null): any {
    if (value == undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'number': value.number,
        'trainingProgramName': value.trainingProgramName,
        'trainingProgramMajorVersion': value.trainingProgramMajorVersion,
        'trainingProgramBeganAt': value.trainingProgramBeganAt,
        'trainingProgramCompletedAt': value.trainingProgramCompletedAt,
        'userName': value.userName,
        'companyName': value.companyName,
        'userEmailAddress': value.userEmailAddress,
        'userWorkNumber': value.userWorkNumber,
        'idBadgeType': value.idBadgeType,
        'idBadgeTypeName': value.idBadgeTypeName,
        'idBadgeNumber': value.idBadgeNumber,
        'supervisorName': value.supervisorName,
        'department': value.department,
        'departmentName': value.departmentName,
        'position': value.position,
        'positionName': value.positionName,
        'lastRevisedAt': value.lastRevisedAt,
        'lastRevisedByUserName': value.lastRevisedByUserName,
        'courses': (value.courses as Array<any>).map(TrainingProgramSessionCourseResultDtoToJSON),
        'quizzes': (value.quizzes as Array<any>).map(TrainingProgramSessionQuizResultDtoToJSON),
    };
}


