/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddTrainingProgramCommand,
    AddTrainingProgramCommandFromJSON,
    AddTrainingProgramCommandToJSON,
    GetTrainingProgramComponentSummariesQuery,
    GetTrainingProgramComponentSummariesQueryFromJSON,
    GetTrainingProgramComponentSummariesQueryToJSON,
    TrainingProgramComponentSummaryDto,
    TrainingProgramComponentSummaryDtoFromJSON,
    TrainingProgramComponentSummaryDtoToJSON,
    TrainingProgramDto,
    TrainingProgramDtoFromJSON,
    TrainingProgramDtoToJSON,
    TrainingProgramSummaryDto,
    TrainingProgramSummaryDtoFromJSON,
    TrainingProgramSummaryDtoToJSON,
    UpdateTrainingProgramCommand,
    UpdateTrainingProgramCommandFromJSON,
    UpdateTrainingProgramCommandToJSON,
} from '../models';

export interface ApiAdministrationTrainingProgramsBySlugSlugGetRequest {
    slug: string;
}

export interface ApiAdministrationTrainingProgramsComponentSummariesPostRequest {
    getTrainingProgramComponentSummariesQuery: GetTrainingProgramComponentSummariesQuery;
}

export interface ApiAdministrationTrainingProgramsIdDeleteRequest {
    id: string;
}

export interface ApiAdministrationTrainingProgramsIdGetRequest {
    id: string;
}

export interface ApiAdministrationTrainingProgramsIdPutRequest {
    id: string;
    updateTrainingProgramCommand: UpdateTrainingProgramCommand;
}

export interface ApiAdministrationTrainingProgramsPostRequest {
    addTrainingProgramCommand: AddTrainingProgramCommand;
}

export interface ApiTrainingProgramsBySlugSlugGetRequest {
    slug: string;
}

export interface ApiTrainingProgramsGetRequest {
    department?: number;
    position?: number;
}

export interface ApiTrainingProgramsIdGetRequest {
    id: string;
}

/**
 * no description
 */
export class TrainingProgramsApi extends runtime.BaseAPI {

    /**
     */
    async apiAdministrationTrainingProgramsBySlugSlugGetRaw(requestParameters: ApiAdministrationTrainingProgramsBySlugSlugGetRequest): Promise<runtime.ApiResponse<TrainingProgramDto>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling apiAdministrationTrainingProgramsBySlugSlugGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["Administrators"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/administration/training-programs/by-slug/{slug}`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TrainingProgramDtoFromJSON(jsonValue));
    }

   /**
    */
    async apiAdministrationTrainingProgramsBySlugSlugGet(requestParameters: ApiAdministrationTrainingProgramsBySlugSlugGetRequest): Promise<TrainingProgramDto> {
        const response = await this.apiAdministrationTrainingProgramsBySlugSlugGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAdministrationTrainingProgramsComponentSummariesPostRaw(requestParameters: ApiAdministrationTrainingProgramsComponentSummariesPostRequest): Promise<runtime.ApiResponse<Array<TrainingProgramComponentSummaryDto>>> {
        if (requestParameters.getTrainingProgramComponentSummariesQuery === null || requestParameters.getTrainingProgramComponentSummariesQuery === undefined) {
            throw new runtime.RequiredError('getTrainingProgramComponentSummariesQuery','Required parameter requestParameters.getTrainingProgramComponentSummariesQuery was null or undefined when calling apiAdministrationTrainingProgramsComponentSummariesPost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["Administrators"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/administration/training-programs/component-summaries`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetTrainingProgramComponentSummariesQueryToJSON(requestParameters.getTrainingProgramComponentSummariesQuery),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TrainingProgramComponentSummaryDtoFromJSON));
    }

   /**
    */
    async apiAdministrationTrainingProgramsComponentSummariesPost(requestParameters: ApiAdministrationTrainingProgramsComponentSummariesPostRequest): Promise<Array<TrainingProgramComponentSummaryDto>> {
        const response = await this.apiAdministrationTrainingProgramsComponentSummariesPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAdministrationTrainingProgramsGetRaw(): Promise<runtime.ApiResponse<Array<TrainingProgramDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["Administrators"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/administration/training-programs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TrainingProgramDtoFromJSON));
    }

   /**
    */
    async apiAdministrationTrainingProgramsGet(): Promise<Array<TrainingProgramDto>> {
        const response = await this.apiAdministrationTrainingProgramsGetRaw();
        return await response.value();
    }

    /**
     */
    async apiAdministrationTrainingProgramsIdDeleteRaw(requestParameters: ApiAdministrationTrainingProgramsIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAdministrationTrainingProgramsIdDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["Administrators"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/administration/training-programs/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

   /**
    */
    async apiAdministrationTrainingProgramsIdDelete(requestParameters: ApiAdministrationTrainingProgramsIdDeleteRequest): Promise<void> {
        await this.apiAdministrationTrainingProgramsIdDeleteRaw(requestParameters);
    }

    /**
     */
    async apiAdministrationTrainingProgramsIdGetRaw(requestParameters: ApiAdministrationTrainingProgramsIdGetRequest): Promise<runtime.ApiResponse<TrainingProgramDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAdministrationTrainingProgramsIdGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["Administrators"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/administration/training-programs/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TrainingProgramDtoFromJSON(jsonValue));
    }

   /**
    */
    async apiAdministrationTrainingProgramsIdGet(requestParameters: ApiAdministrationTrainingProgramsIdGetRequest): Promise<TrainingProgramDto> {
        const response = await this.apiAdministrationTrainingProgramsIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAdministrationTrainingProgramsIdPutRaw(requestParameters: ApiAdministrationTrainingProgramsIdPutRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAdministrationTrainingProgramsIdPut.');
        }

        if (requestParameters.updateTrainingProgramCommand === null || requestParameters.updateTrainingProgramCommand === undefined) {
            throw new runtime.RequiredError('updateTrainingProgramCommand','Required parameter requestParameters.updateTrainingProgramCommand was null or undefined when calling apiAdministrationTrainingProgramsIdPut.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["Administrators"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/administration/training-programs/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateTrainingProgramCommandToJSON(requestParameters.updateTrainingProgramCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

   /**
    */
    async apiAdministrationTrainingProgramsIdPut(requestParameters: ApiAdministrationTrainingProgramsIdPutRequest): Promise<void> {
        await this.apiAdministrationTrainingProgramsIdPutRaw(requestParameters);
    }

    /**
     */
    async apiAdministrationTrainingProgramsPostRaw(requestParameters: ApiAdministrationTrainingProgramsPostRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.addTrainingProgramCommand === null || requestParameters.addTrainingProgramCommand === undefined) {
            throw new runtime.RequiredError('addTrainingProgramCommand','Required parameter requestParameters.addTrainingProgramCommand was null or undefined when calling apiAdministrationTrainingProgramsPost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["Administrators"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/administration/training-programs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddTrainingProgramCommandToJSON(requestParameters.addTrainingProgramCommand),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

   /**
    */
    async apiAdministrationTrainingProgramsPost(requestParameters: ApiAdministrationTrainingProgramsPostRequest): Promise<string> {
        const response = await this.apiAdministrationTrainingProgramsPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiTrainingProgramsBySlugSlugGetRaw(requestParameters: ApiTrainingProgramsBySlugSlugGetRequest): Promise<runtime.ApiResponse<TrainingProgramSummaryDto>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling apiTrainingProgramsBySlugSlugGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/training-programs/by-slug/{slug}`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TrainingProgramSummaryDtoFromJSON(jsonValue));
    }

   /**
    */
    async apiTrainingProgramsBySlugSlugGet(requestParameters: ApiTrainingProgramsBySlugSlugGetRequest): Promise<TrainingProgramSummaryDto> {
        const response = await this.apiTrainingProgramsBySlugSlugGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiTrainingProgramsGetRaw(requestParameters: ApiTrainingProgramsGetRequest): Promise<runtime.ApiResponse<Array<TrainingProgramSummaryDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.department !== undefined) {
            queryParameters['department'] = requestParameters.department;
        }

        if (requestParameters.position !== undefined) {
            queryParameters['position'] = requestParameters.position;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/training-programs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TrainingProgramSummaryDtoFromJSON));
    }

   /**
    */
    async apiTrainingProgramsGet(requestParameters: ApiTrainingProgramsGetRequest): Promise<Array<TrainingProgramSummaryDto>> {
        const response = await this.apiTrainingProgramsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiTrainingProgramsIdGetRaw(requestParameters: ApiTrainingProgramsIdGetRequest): Promise<runtime.ApiResponse<TrainingProgramSummaryDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiTrainingProgramsIdGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/training-programs/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TrainingProgramSummaryDtoFromJSON(jsonValue));
    }

   /**
    */
    async apiTrainingProgramsIdGet(requestParameters: ApiTrainingProgramsIdGetRequest): Promise<TrainingProgramSummaryDto> {
        const response = await this.apiTrainingProgramsIdGetRaw(requestParameters);
        return await response.value();
    }

}
