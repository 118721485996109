export type MediaTypes = "all" | "document" | "video" | "presentation";

export const MEDIA_TYPES = [
  {
    value: "document",
    description: "Documents",
  },
  {
    value: "presentation",
    description: "Presentations",
  },
  {
    value: "video",
    description: "Videos",
  },
];
