/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    QuizQuestionAnswerTemplateDto,
    QuizQuestionAnswerTemplateDtoFromJSON,
    QuizQuestionAnswerTemplateDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface QuizQuestionTemplateDto
 */
export interface QuizQuestionTemplateDto {
    /**
     * 
     * @type {number}
     * @memberof QuizQuestionTemplateDto
     */
    number: number;
    /**
     * 
     * @type {string}
     * @memberof QuizQuestionTemplateDto
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof QuizQuestionTemplateDto
     */
    displayText: string;
    /**
     * 
     * @type {boolean}
     * @memberof QuizQuestionTemplateDto
     */
    hasSingleCorrectAnswer: boolean;
    /**
     * 
     * @type {Array<QuizQuestionAnswerTemplateDto>}
     * @memberof QuizQuestionTemplateDto
     */
    possibleAnswers: Array<QuizQuestionAnswerTemplateDto>;
}

export function QuizQuestionTemplateDtoFromJSON(json: any): QuizQuestionTemplateDto {
    return {
        'number': json['number'],
        'text': json['text'],
        'displayText': json['displayText'],
        'hasSingleCorrectAnswer': json['hasSingleCorrectAnswer'],
        'possibleAnswers': (json['possibleAnswers'] as Array<any>).map(QuizQuestionAnswerTemplateDtoFromJSON),
    };
}

export function QuizQuestionTemplateDtoToJSON(value?: QuizQuestionTemplateDto | null): any {
    if (value == undefined) {
        return undefined;
    }
    return {
        'number': value.number,
        'text': value.text,
        'displayText': value.displayText,
        'hasSingleCorrectAnswer': value.hasSingleCorrectAnswer,
        'possibleAnswers': (value.possibleAnswers as Array<any>).map(QuizQuestionAnswerTemplateDtoToJSON),
    };
}


