/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RemoveCourseCommand
 */
export interface RemoveCourseCommand {
    /**
     * 
     * @type {boolean}
     * @memberof RemoveCourseCommand
     */
    permanentlyDeleteFile: boolean;
}

export function RemoveCourseCommandFromJSON(json: any): RemoveCourseCommand {
    return {
        'permanentlyDeleteFile': json['permanentlyDeleteFile'],
    };
}

export function RemoveCourseCommandToJSON(value?: RemoveCourseCommand | null): any {
    if (value == undefined) {
        return undefined;
    }
    return {
        'permanentlyDeleteFile': value.permanentlyDeleteFile,
    };
}


