import React from "react";
import { Document, Page, pdfjs } from "react-pdf";

// WORKAROUND: https://github.com/wojtekmaj/react-pdf/issues/280
pdfjs.GlobalWorkerOptions.workerSrc = `${document.location.origin}/pdf.worker.min.js`;

interface PdfViewerProps {
  src: string;
  availableWidth: number;
}

interface PdfViewerState {
  totalPages?: number;
}

export const PdfViewerComponent = class extends React.Component<PdfViewerProps, PdfViewerState> {
  constructor(props: PdfViewerProps) {
    super(props);

    this.state = {
      totalPages: undefined,
    };
  }

  public render() {
    const { src, availableWidth } = this.props;
    const { totalPages } = this.state;

    const pages: JSX.Element[] = [];

    if (totalPages) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(<Page className="pdf-viewer__page" key={`page-${i}`} pageNumber={i} width={availableWidth - 32} />);
      }
    }

    return (
      <Document className="pdf-viewer" file={src} onLoadSuccess={this.handlePdfDocumentLoad}>
        {pages}
      </Document>
    );
  }

  public componentDidUpdate(prevProps: PdfViewerProps, prevState: PdfViewerState) {
    // If a new PDF has been loaded...
    if (prevProps.src !== this.props.src) {
      this.setState({ totalPages: undefined });
    }
  }

  private handlePdfDocumentLoad = (event: { numPages: number }) => {
    this.setState({ totalPages: event.numPages });
  };
};

export const PdfViewer = PdfViewerComponent;
