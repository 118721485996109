/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddCourseCommand,
    AddCourseCommandFromJSON,
    AddCourseCommandToJSON,
    CourseDto,
    CourseDtoFromJSON,
    CourseDtoToJSON,
    CourseResultDto,
    CourseResultDtoFromJSON,
    CourseResultDtoToJSON,
    RemoveCourseCommand,
    RemoveCourseCommandFromJSON,
    RemoveCourseCommandToJSON,
    UpdateCourseCommand,
    UpdateCourseCommandFromJSON,
    UpdateCourseCommandToJSON,
} from '../models';

export interface ApiAdministrationCoursesIdDeleteRequest {
    id: string;
    removeCourseCommand: RemoveCourseCommand;
}

export interface ApiAdministrationCoursesIdGetRequest {
    id: string;
}

export interface ApiAdministrationCoursesIdPutRequest {
    id: string;
    updateCourseCommand: UpdateCourseCommand;
}

export interface ApiAdministrationCoursesNameFileUploadIdGetRequest {
    fileUploadId: string;
}

export interface ApiAdministrationCoursesPostRequest {
    addCourseCommand: AddCourseCommand;
}

export interface ApiCoursesIdVersionsVersionResultPostRequest {
    id: string;
    version: number;
}

/**
 * no description
 */
export class CoursesApi extends runtime.BaseAPI {

    /**
     */
    async apiAdministrationCoursesGetRaw(): Promise<runtime.ApiResponse<Array<CourseDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["Administrators"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/administration/courses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CourseDtoFromJSON));
    }

   /**
    */
    async apiAdministrationCoursesGet(): Promise<Array<CourseDto>> {
        const response = await this.apiAdministrationCoursesGetRaw();
        return await response.value();
    }

    /**
     */
    async apiAdministrationCoursesIdDeleteRaw(requestParameters: ApiAdministrationCoursesIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAdministrationCoursesIdDelete.');
        }

        if (requestParameters.removeCourseCommand === null || requestParameters.removeCourseCommand === undefined) {
            throw new runtime.RequiredError('removeCourseCommand','Required parameter requestParameters.removeCourseCommand was null or undefined when calling apiAdministrationCoursesIdDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["Administrators"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/administration/courses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveCourseCommandToJSON(requestParameters.removeCourseCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

   /**
    */
    async apiAdministrationCoursesIdDelete(requestParameters: ApiAdministrationCoursesIdDeleteRequest): Promise<void> {
        await this.apiAdministrationCoursesIdDeleteRaw(requestParameters);
    }

    /**
     */
    async apiAdministrationCoursesIdGetRaw(requestParameters: ApiAdministrationCoursesIdGetRequest): Promise<runtime.ApiResponse<CourseDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAdministrationCoursesIdGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["Administrators"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/administration/courses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CourseDtoFromJSON(jsonValue));
    }

   /**
    */
    async apiAdministrationCoursesIdGet(requestParameters: ApiAdministrationCoursesIdGetRequest): Promise<CourseDto> {
        const response = await this.apiAdministrationCoursesIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAdministrationCoursesIdPutRaw(requestParameters: ApiAdministrationCoursesIdPutRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAdministrationCoursesIdPut.');
        }

        if (requestParameters.updateCourseCommand === null || requestParameters.updateCourseCommand === undefined) {
            throw new runtime.RequiredError('updateCourseCommand','Required parameter requestParameters.updateCourseCommand was null or undefined when calling apiAdministrationCoursesIdPut.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["Administrators"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/administration/courses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCourseCommandToJSON(requestParameters.updateCourseCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

   /**
    */
    async apiAdministrationCoursesIdPut(requestParameters: ApiAdministrationCoursesIdPutRequest): Promise<void> {
        await this.apiAdministrationCoursesIdPutRaw(requestParameters);
    }

    /**
     */
    async apiAdministrationCoursesNameFileUploadIdGetRaw(requestParameters: ApiAdministrationCoursesNameFileUploadIdGetRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.fileUploadId === null || requestParameters.fileUploadId === undefined) {
            throw new runtime.RequiredError('fileUploadId','Required parameter requestParameters.fileUploadId was null or undefined when calling apiAdministrationCoursesNameFileUploadIdGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["Administrators"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/administration/courses/name/{fileUploadId}`.replace(`{${"fileUploadId"}}`, encodeURIComponent(String(requestParameters.fileUploadId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

   /**
    */
    async apiAdministrationCoursesNameFileUploadIdGet(requestParameters: ApiAdministrationCoursesNameFileUploadIdGetRequest): Promise<string> {
        const response = await this.apiAdministrationCoursesNameFileUploadIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAdministrationCoursesPostRaw(requestParameters: ApiAdministrationCoursesPostRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.addCourseCommand === null || requestParameters.addCourseCommand === undefined) {
            throw new runtime.RequiredError('addCourseCommand','Required parameter requestParameters.addCourseCommand was null or undefined when calling apiAdministrationCoursesPost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["Administrators"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/administration/courses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddCourseCommandToJSON(requestParameters.addCourseCommand),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

   /**
    */
    async apiAdministrationCoursesPost(requestParameters: ApiAdministrationCoursesPostRequest): Promise<string> {
        const response = await this.apiAdministrationCoursesPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiCoursesIdVersionsVersionResultPostRaw(requestParameters: ApiCoursesIdVersionsVersionResultPostRequest): Promise<runtime.ApiResponse<CourseResultDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiCoursesIdVersionsVersionResultPost.');
        }

        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError('version','Required parameter requestParameters.version was null or undefined when calling apiCoursesIdVersionsVersionResultPost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/courses/{id}/versions/{version}/result`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CourseResultDtoFromJSON(jsonValue));
    }

   /**
    */
    async apiCoursesIdVersionsVersionResultPost(requestParameters: ApiCoursesIdVersionsVersionResultPostRequest): Promise<CourseResultDto> {
        const response = await this.apiCoursesIdVersionsVersionResultPostRaw(requestParameters);
        return await response.value();
    }

}
