/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserGroupMemberDto
 */
export interface UserGroupMemberDto {
    /**
     * 
     * @type {string}
     * @memberof UserGroupMemberDto
     */
    userPrincipalName: string;
    /**
     * 
     * @type {number}
     * @memberof UserGroupMemberDto
     */
    userGroupType: number;
    /**
     * 
     * @type {string}
     * @memberof UserGroupMemberDto
     */
    userGroupTypeName: string;
}

export function UserGroupMemberDtoFromJSON(json: any): UserGroupMemberDto {
    return {
        'userPrincipalName': json['userPrincipalName'],
        'userGroupType': json['userGroupType'],
        'userGroupTypeName': json['userGroupTypeName'],
    };
}

export function UserGroupMemberDtoToJSON(value?: UserGroupMemberDto | null): any {
    if (value == undefined) {
        return undefined;
    }
    return {
        'userPrincipalName': value.userPrincipalName,
        'userGroupType': value.userGroupType,
        'userGroupTypeName': value.userGroupTypeName,
    };
}


