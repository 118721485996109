/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddOrUpdateTrainingProgramDepartmentCommand,
    AddOrUpdateTrainingProgramDepartmentCommandFromJSON,
    AddOrUpdateTrainingProgramDepartmentCommandToJSON,
} from './';

/**
 * 
 * @export
 * @interface AddTrainingProgramCommand
 */
export interface AddTrainingProgramCommand {
    /**
     * 
     * @type {string}
     * @memberof AddTrainingProgramCommand
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AddTrainingProgramCommand
     */
    description: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AddTrainingProgramCommand
     */
    emailRecipients?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof AddTrainingProgramCommand
     */
    trainingScorecardId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AddTrainingProgramCommand
     */
    sortOrder?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof AddTrainingProgramCommand
     */
    isEnabled: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AddTrainingProgramCommand
     */
    componentIds: Array<string>;
    /**
     * 
     * @type {Array<AddOrUpdateTrainingProgramDepartmentCommand>}
     * @memberof AddTrainingProgramCommand
     */
    departments?: Array<AddOrUpdateTrainingProgramDepartmentCommand> | null;
}

export function AddTrainingProgramCommandFromJSON(json: any): AddTrainingProgramCommand {
    return {
        'name': json['name'],
        'description': json['description'],
        'emailRecipients': !exists(json, 'emailRecipients') ? undefined : json['emailRecipients'],
        'trainingScorecardId': !exists(json, 'trainingScorecardId') ? undefined : json['trainingScorecardId'],
        'sortOrder': !exists(json, 'sortOrder') ? undefined : json['sortOrder'],
        'isEnabled': json['isEnabled'],
        'componentIds': json['componentIds'],
        'departments': !exists(json, 'departments') ? undefined : (json['departments'] as Array<any>).map(AddOrUpdateTrainingProgramDepartmentCommandFromJSON),
    };
}

export function AddTrainingProgramCommandToJSON(value?: AddTrainingProgramCommand | null): any {
    if (value == undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'description': value.description,
        'emailRecipients': value.emailRecipients,
        'trainingScorecardId': value.trainingScorecardId,
        'sortOrder': value.sortOrder,
        'isEnabled': value.isEnabled,
        'componentIds': value.componentIds,
        'departments': value.departments === undefined ? undefined : (value.departments as Array<any>).map(AddOrUpdateTrainingProgramDepartmentCommandToJSON),
    };
}


