import { toAuditDetailsFormData } from "@interface48/forms";
import { AddDocumentCommand, DocumentDto, UpdateDocumentCommand } from "../../../../api";
import { DocumentFormData, DocumentFormDataSchemaVariant } from "../models";

export const toDocumentFormData = (document?: DocumentDto): DocumentFormData => {
  if (document) {
    const isExisting = true;
    const isRemoteFile = !!document.remoteFileUrl;

    return {
      isExisting,
      schemaVariant: getDocumentFormDataSchemaVariant(isExisting, isRemoteFile),
      id: document.id,
      mediaCategoryId: document.mediaCategoryId,
      mediaCategoryName: document.mediaCategoryName,
      name: document.name,
      existingFile: {
        id: document.id,
        fileName: document.fileName,
        fileExtension: document.fileExtension,
        fileSizeBytes: document.fileSizeBytes,
      },
      isRemoteFile,
      remoteFileUrl: document.remoteFileUrl ?? undefined,
      updateFile: false,
      file: {},
      created: toAuditDetailsFormData(document.createdAt, document.createdByUserName),
      lastModified: toAuditDetailsFormData(document.lastModifiedAt, document.lastModifiedByUserName),
    };
  } else {
    const isExisting = false;
    const isRemoteFile = false;

    return {
      isExisting,
      schemaVariant: getDocumentFormDataSchemaVariant(isExisting, isRemoteFile),
      file: {},
      isRemoteFile,
      updateFile: false,
      created: {},
      lastModified: {},
    };
  }
};

export const getDocumentFormDataSchemaVariant = (isExisting: boolean, isRemoteFile: boolean) =>
  isExisting
    ? isRemoteFile
      ? DocumentFormDataSchemaVariant.ExistingRemote
      : DocumentFormDataSchemaVariant.Existing
    : isRemoteFile
    ? DocumentFormDataSchemaVariant.NewRemote
    : DocumentFormDataSchemaVariant.New;

export const toAddDocumentCommand = (formData: DocumentFormData): AddDocumentCommand => {
  const { mediaCategoryId, name, isRemoteFile, remoteFileUrl, file } = formData;

  const addDocumentCommand: AddDocumentCommand = {
    mediaCategoryId: mediaCategoryId!,
    name: name!,
  };

  if (isRemoteFile) {
    addDocumentCommand.remoteFileUrl = remoteFileUrl!;
  } else if (file?.fileUploadId) {
    addDocumentCommand.fileUploadId = file.fileUploadId;
  } else {
    throw Error("No File uploaded.");
  }

  return addDocumentCommand;
};

export const toUpdateDocumentCommand = (formData: DocumentFormData): UpdateDocumentCommand => {
  const { mediaCategoryId, name, isRemoteFile, remoteFileUrl, updateFile, file } = formData;

  const updateDocumentCommand: UpdateDocumentCommand = {
    mediaCategoryId: mediaCategoryId!,
    name: name!,
  };

  if (isRemoteFile) {
    updateDocumentCommand.remoteFileUrl = remoteFileUrl!;
  } else if (updateFile && file?.fileUploadId) {
    updateDocumentCommand.fileUploadId = file.fileUploadId;
  }

  return updateDocumentCommand;
};
