import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { Link } from "react-router-dom";

const styles = (theme: Theme) =>
  createStyles({
    card: {},
    cardContent: {},
    cardTitle: {
      marginBottom: theme.spacing(1),
    },
  });

interface AdminCardProps extends WithStyles<typeof styles> {
  title: string;
  description: string;
  url: string;
}

export const AdminCardComponent = (props: AdminCardProps) => {
  const { title, description, url, classes } = props;

  return (
    <Card className={classes.card}>
      <CardActionArea component={Link} to={url}>
        <CardContent className={classes.cardContent}>
          <Typography variant="h5" component="h2" className={classes.cardTitle}>
            {title}
          </Typography>
          <Typography variant="body2" component="div">
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export const AdminCard = withStyles(styles)(AdminCardComponent);
