import { BaseInput, WidgetProps } from "@interface48/forms";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
    },
    number: {
      marginTop: 4,
      marginRight: theme.spacing(2),
    },
    questionAnswerText: {
      position: "relative",
      width: "100%",
    },
    "& .field-array .field-string label": {
      display: "none",
    },
  });

interface QuizQuestionAnswerTextWidgetProps extends WidgetProps<string | number>, WithStyles<typeof styles> {}

export const QuizQuestionAnswerTextWidgetComponent = (props: QuizQuestionAnswerTextWidgetProps) => {
  const { id, classes, placeholder, ...rest } = props;

  // Extract index position from the index-based id in the format root_questions_0_answers_1_text
  // (Note: additionally add 1 since index is 0-based, but we want the display character from 1-based position)
  const questionAnswerNumberMatch = /_(\d+)_(?!.*_\d+_)/.exec(id); // Negative lookahead because we want the last match
  const questionAnswerNumber = questionAnswerNumberMatch ? parseInt(questionAnswerNumberMatch[1], 10) + 1 : undefined;
  const questionAnswerAlphaChar =
    questionAnswerNumber !== undefined ? String.fromCharCode(97 + questionAnswerNumber - 1) : undefined;

  return (
    <div className={classes.root}>
      <Typography variant="body1" className={classes.number}>
        {questionAnswerAlphaChar ? `${questionAnswerAlphaChar})` : ""}
      </Typography>
      <div className={classes.questionAnswerText}>
        <BaseInput id={id} placeholder="Answer Text" multiline={true} {...rest} />
      </div>
    </div>
  );
};

export const QuizQuestionAnswerTextWidget = withStyles(styles)(QuizQuestionAnswerTextWidgetComponent);
