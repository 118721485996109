import { getApiError } from "@interface48/api";
import { getApiConfiguration } from "@interface48/app";
import {
  Form,
  FormAction,
  FormContext,
  FormSchema,
  getNewFormContext,
  handleFieldError,
  IChangeEvent,
  ISubmitEvent,
} from "@interface48/forms";
import { red } from "@material-ui/core/colors";
import { createStyles, Theme } from "@material-ui/core/styles";
import withStyles, { ClassNameMap, WithStyles } from "@material-ui/core/styles/withStyles";
import cloneDeep from "lodash/cloneDeep";
import React from "react";
import { administrationVideosApi } from "../../../api";
import { MediaFileLinkField } from "../../shared";
import { VideoFileMetadataField } from "./fields";
import { VideoFormData } from "./models";
import { getVideoFormSchema } from "./utils";

const FORM_ID = "videoForm";

const styles = (theme: Theme) =>
  createStyles({
    deleteFormAction: {
      marginLeft: "auto !important",
      color: red[500],
    },
  });

export interface VideoFormContext extends FormContext {
  onError: (errorMessage: string) => void;
}

interface VideoFormProps extends WithStyles<typeof styles> {
  className: string;
  formMode: "read" | "write";
  formData: VideoFormData;
  isFormSubmitting: boolean;
  onEdit: () => void;
  onClose: () => void;
  onChange: (formData: VideoFormData) => void;
  onSubmit: (formData: VideoFormData) => void;
  onCancel: (initialFormData: VideoFormData) => void;
  onDelete: () => void;
  onError: (errorMessage: string) => void;
}

interface VideoFormState {
  formSchema: FormSchema;
  formContext: VideoFormContext;
  formActions: FormAction[];
  initialFormData: VideoFormData;
}

export const VideoFormComponent = class extends React.Component<VideoFormProps, VideoFormState> {
  constructor(props: VideoFormProps) {
    super(props);

    const { formMode, formData, classes, onDelete, onError } = this.props;

    const formSchema = getVideoFormSchema(formMode);
    const formActions = getFormActions(classes, formMode, onDelete);

    this.state = {
      initialFormData: cloneDeep(formData),
      formSchema,
      formContext: {
        ...getNewFormContext(),
        getApiConfiguration,
        onError,
      },
      formActions,
    };
  }

  public render() {
    const { className, formData, isFormSubmitting, onClose, onEdit } = this.props;
    const { formSchema, formContext, formActions } = this.state;

    return (
      <Form<VideoFormData>
        id={FORM_ID}
        className={className}
        schema={formSchema.jsonSchema}
        uiSchema={formSchema.uiSchema}
        fields={{ VideoFileMetadataField, MediaFileLinkField }}
        formData={formData}
        formContext={formContext}
        formActions={formActions}
        isFormSubmitting={isFormSubmitting}
        onEdit={onEdit}
        onChange={this.handleChange}
        onSubmit={this.handleSubmit}
        onError={handleFieldError(FORM_ID)}
        onCancel={this.handleCancel}
        onClose={onClose}
      />
    );
  }

  public async componentDidUpdate(prevProps: VideoFormProps) {
    const { formMode: prevFormMode, formData: prevFormData } = prevProps;
    const { formMode, formData, classes, onChange, onDelete, onError } = this.props;
    // If in read mode, the form data has now been loaded, and the user has permission to edit...
    if (prevFormMode !== formMode || (formMode === "read" && !prevFormData && formData)) {
      const formSchema = getVideoFormSchema(formMode);
      const formActions = getFormActions(classes, formMode, onDelete);

      this.setState({ ...this.state, formSchema, formActions });
    }

    // If a new/updated File has been uploaded...
    if (
      (prevFormData.file !== formData.file && formData.file && formData.file.fileUploadId) ||
      (prevFormData.file && formData.file && prevFormData.file.fileUploadId !== formData.file.fileUploadId)
    ) {
      const fileUploadId = formData.file.fileUploadId;
      // If there exists a File to retrieve Metadata for...
      if (fileUploadId) {
        try {
          const videoFileMetadata = await administrationVideosApi.getUploadedVideoFileMetadata(fileUploadId);

          const nextFormdata: VideoFormData = {
            ...formData,
            fileMetadata: videoFileMetadata,
          };

          onChange(nextFormdata);
        } catch (errorResponse) {
          const error = await getApiError(errorResponse);

          onError(error.message);
        }
      }
      // Otherwise, clear the Metadata...
      else {
        const nextFormdata: VideoFormData = {
          ...formData,
          fileMetadata: undefined,
        };

        onChange(nextFormdata);
      }
    }
  }

  private handleChange = (changeEvent: IChangeEvent<VideoFormData>) => {
    const { onChange } = this.props;

    onChange(changeEvent.formData);
  };

  private handleSubmit = (submitEvent: ISubmitEvent<VideoFormData>) => {
    const { onSubmit } = this.props;

    onSubmit(submitEvent.formData);
  };

  private handleCancel = () => {
    const { onCancel } = this.props;
    const { initialFormData } = this.state;

    onCancel(initialFormData);
  };
};

const getFormActions = (
  classes: ClassNameMap<"deleteFormAction">,
  formMode: "read" | "write",
  onDelete: () => void,
) => {
  if (formMode === "read") {
    const formActions = [
      {
        type: "edit",
        button: {
          variant: "primary",
          label: "Edit",
        },
      },
      {
        type: "close",
        button: {
          variant: "secondary",
          label: "Back",
        },
      },
      {
        type: "other",
        button: {
          className: classes.deleteFormAction,
          variant: "secondary",
          label: "Delete",
          onClick: onDelete,
        },
      },
    ] as FormAction[];

    return formActions;
  } else {
    return [
      {
        type: "submit",
        button: {
          variant: "primary",
          label: "Save",
        },
      },
      {
        type: "cancel",
        button: {
          variant: "secondary",
          label: "Cancel",
        },
      },
    ] as FormAction[];
  }
};

export const VideoForm = withStyles(styles)(VideoFormComponent);
