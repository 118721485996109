import { OptionItem } from "@interface48/api";
import { FormSchema } from "@interface48/forms";
import { IDBadgeType, getIdBadgeTypeName } from "../../../../api";

export const getTrainingProgramSessionCompletionFormSchema = (
  departmentOptionItems: Array<OptionItem<number>>,
  departmentPositionOptionItemsMap: { [department: string]: Array<OptionItem<number>> },
  isUserSignedIn: boolean,
  hasUserWorkNumber: boolean,
  idBadgeType: number | undefined,
): FormSchema => {
  const formSchema: FormSchema = {
    jsonSchema: {
      type: "object",
      required: ["userName", "hasUserWorkNumber", "companyName", "idBadgeType", "supervisorName"],
      properties: {
        userName: {
          type: "string",
          title: "Name",
          maxLength: 75,
        },
        hasUserWorkNumber: {
          type: "boolean",
          title: "Do you have an ILWU Man Number?",
        },
        companyName: {
          type: "string",
          title: hasUserWorkNumber ? "Union Local Name" : "Company Name",
          maxLength: 50,
        },
        idBadgeType: {
          type: "number",
          title: "Which of the following forms of ID do you possess?",
          oneOf: [
            { const: IDBadgeType.PortPass, title: getIdBadgeTypeName(IDBadgeType.PortPass) },
            { const: IDBadgeType.SiteAccessPass, title: getIdBadgeTypeName(IDBadgeType.SiteAccessPass) },
            { const: IDBadgeType.VisitorPass, title: getIdBadgeTypeName(IDBadgeType.VisitorPass) },
            { const: IDBadgeType.None, title: getIdBadgeTypeName(IDBadgeType.None) },
          ],
        },
        supervisorName: {
          type: "string",
          title: hasUserWorkNumber || isUserSignedIn ? "Neptune Supervisor Name" : "Neptune Contact Name",
          maxLength: 75,
        },
        isDepartmentApplicable: {
          type: "boolean",
        },
        informationDisclosureConsent: {
          type: "boolean",
          title:
            "I consent to Neptune collecting the personal information I have provided above, and the use and " +
            "disclosure of such information for the purposes of confirming my completion of this Training Program.",
        },
      },
      dependencies: {
        hasUserWorkNumber: {
          oneOf: [
            {
              required: ["userWorkNumber"],
              properties: {
                hasUserWorkNumber: { enum: [true] },
                userWorkNumber: {
                  type: "string",
                  title: "ILWU Man Number",
                  maxLength: 50,
                  pattern: "^\\d+$",
                },
              },
            },
            {
              required: ["userEmailAddress"],
              properties: {
                hasUserWorkNumber: { enum: [false] },
                userEmailAddress: {
                  type: "string",
                  title: "Email Address",
                  maxLength: 254,
                  format: "email",
                },
              },
            },
          ],
        },
        idBadgeType: {
          oneOf: [
            {
              required: ["idBadgeNumber"],
              properties: {
                idBadgeType: { enum: [IDBadgeType.PortPass, IDBadgeType.SiteAccessPass, IDBadgeType.VisitorPass] },
                idBadgeNumber: {
                  type: "string",
                  title: idBadgeType ? getIdBadgeTypeName(idBadgeType) : undefined,
                  maxLength: 50,
                },
              },
            },
            {
              properties: {
                idBadgeType: { enum: [IDBadgeType.None] },
              },
            },
          ],
        },
        isDepartmentApplicable: {
          oneOf: [
            {
              required: ["department"],
              properties: {
                isDepartmentApplicable: { enum: [true] },
                department: {
                  type: "number",
                  title: "Department",
                  oneOf: departmentOptionItems.map((oi) => ({ const: oi.value, title: oi.label })),
                },
              },
            },
            {
              properties: {
                isDepartmentApplicable: { enum: [false] },
              },
            },
          ],
        },
        department: {
          oneOf: departmentOptionItems.map((department) => {
            const positionsForDepartment = departmentPositionOptionItemsMap[department.value];

            return {
              required: ["position"],
              properties: {
                department: { enum: [department.value] },
                position: {
                  type: "number",
                  title: "Position",
                  oneOf: positionsForDepartment.map((oi) => ({ const: oi.value, title: oi.label })) ?? [],
                },
              },
            };
          }),
        },
      },
    },
    uiSchema: {
      "ui:order": [
        "userName",
        "hasUserWorkNumber",
        "userWorkNumber",
        "userEmailAddress",
        "companyName",
        "idBadgeType",
        "idBadgeNumber",
        "supervisorName",
        "department",
        "position",
        "informationDisclosureConsent",
        "*",
      ],
      userEmailAddress: {
        "ui:help": "e.g. jsmith@neptuneterminals.com",
      },
      userWorkNumber: {
        "ui:help": "e.g. 12345",
      },
      idBadgeType: {
        "ui:widget": "radio",
        "ui:options": {
          inline: true,
        },
      },
      informationDisclosureConsent: {
        "ui:widget": "ConsentWidget",
      },
      isDepartmentApplicable: {
        "ui:widget": "hidden",
      },
      department: {
        "ui:help": "Select the Department you are associated with at Neptune.",
      },
      position: {
        "ui:help": "Select the Position you hold at Neptune.",
      },
    },
  };

  return formSchema;
};
