/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QueryResultsSortingConfiguration
 */
export interface QueryResultsSortingConfiguration {
    /**
     * 
     * @type {string}
     * @memberof QueryResultsSortingConfiguration
     */
    sortPropertyName: string;
    /**
     * 
     * @type {string}
     * @memberof QueryResultsSortingConfiguration
     */
    sortDirection: string;
}

export function QueryResultsSortingConfigurationFromJSON(json: any): QueryResultsSortingConfiguration {
    return {
        'sortPropertyName': json['sortPropertyName'],
        'sortDirection': json['sortDirection'],
    };
}

export function QueryResultsSortingConfigurationToJSON(value?: QueryResultsSortingConfiguration | null): any {
    if (value == undefined) {
        return undefined;
    }
    return {
        'sortPropertyName': value.sortPropertyName,
        'sortDirection': value.sortDirection,
    };
}


