import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OptionItem } from "@interface48/api";
import { ReadOnlyField } from "@interface48/forms";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { TrainingProgramSummaryDto } from "../../../api";
import { SelectField } from "../../shared";
import { TrainingProgramSession } from "../../training-program-sessions";
import { TrainingProgramSyllabusStepper } from "../steppers";
import { sortTrainingProgramsByName } from "../utils";

const styles = (theme: Theme) =>
  createStyles({
    selector: {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      borderBottom: `solid 1px ${theme.palette.divider}`,
    },
    details: {
      height: "calc(100% - 209px)",
      overflowY: "auto",
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
    status: {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
    syllabus: {
      height: "calc(100% - 324px)",
    },
    syllabusHeader: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      fontSize: "0.875rem",
      lineHeight: "48px",
    },
    formControl: {
      margin: `${theme.spacing(1)}px 0`,
    },
    message: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.secondary.main,
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
    },
    messageIcon: {
      paddingRight: theme.spacing(1),
      color: theme.palette.common.white,
    },
    messageBody: {
      color: theme.palette.common.white,
    },
    button: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
  });

interface TrainingProgramSelectorProps extends WithStyles<typeof styles> {
  isLoading: boolean;
  trainingPrograms?: TrainingProgramSummaryDto[];
  trainingProgramSlug?: string;
  trainingProgramSession?: TrainingProgramSession;
  onTrainingProgramChange: (selectedTrainingProgramSlug: string | null | undefined) => void;
  onTrainingProgramStart: () => void;
}

export const TrainingProgramSelectorComponent = (props: TrainingProgramSelectorProps) => {
  const {
    trainingPrograms,
    trainingProgramSlug,
    trainingProgramSession,
    isLoading,
    classes,
    onTrainingProgramChange,
    onTrainingProgramStart,
  } = props;

  const trainingProgram = trainingPrograms?.find((tp) => tp.slug === trainingProgramSlug);

  const trainingProgramComponents = trainingProgramSession?.trainingProgramComponents;
  const currentTrainingProgramComponentIndex = trainingProgramSession?.currentTrainingProgramComponentIndex;
  const remainingTrainingProgramDurationMinutes = trainingProgramSession?.remainingTrainingProgramDurationMinutes;
  const beganAt = trainingProgramSession?.beganAt;
  const trainingProgramSessionNumber = trainingProgramSession?.trainingProgramSessionNumber;

  const trainingProgramOptions: Array<OptionItem<string>> = (trainingPrograms ?? [])
    .sort(sortTrainingProgramsByName)
    .map((tp) => ({
      value: tp.slug,
      label: tp.name,
    }));

  return (
    <>
      <div className={classes.selector}>
        <SelectField<string>
          id="training-programs"
          label="Program"
          value={trainingPrograms && trainingProgramSlug ? trainingProgramSlug : undefined}
          options={trainingProgramOptions}
          fullWidth={true}
          className={classes.formControl}
          onChange={onTrainingProgramChange}
        />
      </div>
      {trainingProgram &&
        (trainingProgramComponents !== undefined &&
        currentTrainingProgramComponentIndex !== undefined &&
        remainingTrainingProgramDurationMinutes !== undefined &&
        beganAt !== undefined ? (
          <>
            <div className={classes.status}>
              <ReadOnlyField label="Approx. Time Remaining" margin="dense">
                <Typography variant="body1">
                  {trainingProgramSessionNumber
                    ? "Done"
                    : `${remainingTrainingProgramDurationMinutes} minute${
                        !remainingTrainingProgramDurationMinutes || remainingTrainingProgramDurationMinutes > 1
                          ? "s"
                          : ""
                      }`}
                </Typography>
              </ReadOnlyField>
            </div>
            <div className={classes.syllabus}>
              <div className={classes.syllabusHeader}>Syllabus</div>
              <TrainingProgramSyllabusStepper
                stepIndex={
                  trainingProgramSessionNumber ? trainingProgramComponents.length : currentTrainingProgramComponentIndex
                }
                stepItems={trainingProgramComponents.map((component) => ({
                  type: component.document
                    ? "document"
                    : component.video
                    ? "video"
                    : component.quiz
                    ? "quiz"
                    : "completion",
                  description: component.name,
                }))}
              />
            </div>
          </>
        ) : isLoading ? (
          <LinearProgress />
        ) : (
          <div className={classes.details}>
            <ReadOnlyField label="Program Description" margin="dense">
              <Typography variant="body1">{trainingProgram.description}</Typography>
            </ReadOnlyField>
            <ReadOnlyField label="Approx. Program Duration" margin="dense">
              <Typography variant="body1">{trainingProgram.durationMinutes} minutes</Typography>
            </ReadOnlyField>
            <Paper className={classes.message}>
              <div className={classes.messageIcon}>
                <FontAwesomeIcon icon={["fas", "info-circle"]} />
              </div>
              <Typography variant="body1" className={classes.messageBody}>
                Please be aware that upon completing this Training Program, you will be asked to provide some personal
                information for Neptune's records, as confirmation of training completion.
              </Typography>
            </Paper>
            <Button variant="contained" color="primary" className={classes.button} onClick={onTrainingProgramStart}>
              Start Training Program
            </Button>
          </div>
        ))}
    </>
  );
};

export const TrainingProgramSelector = withStyles(styles)(TrainingProgramSelectorComponent);
