/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TrainingProgramComponentDto
 */
export interface TrainingProgramComponentDto {
    /**
     * 
     * @type {number}
     * @memberof TrainingProgramComponentDto
     */
    number: number;
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramComponentDto
     */
    courseId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramComponentDto
     */
    documentId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramComponentDto
     */
    videoId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramComponentDto
     */
    quizId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramComponentDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof TrainingProgramComponentDto
     */
    durationMinutes: number;
}

export function TrainingProgramComponentDtoFromJSON(json: any): TrainingProgramComponentDto {
    return {
        'number': json['number'],
        'courseId': !exists(json, 'courseId') ? undefined : json['courseId'],
        'documentId': !exists(json, 'documentId') ? undefined : json['documentId'],
        'videoId': !exists(json, 'videoId') ? undefined : json['videoId'],
        'quizId': !exists(json, 'quizId') ? undefined : json['quizId'],
        'name': json['name'],
        'durationMinutes': json['durationMinutes'],
    };
}

export function TrainingProgramComponentDtoToJSON(value?: TrainingProgramComponentDto | null): any {
    if (value == undefined) {
        return undefined;
    }
    return {
        'number': value.number,
        'courseId': value.courseId,
        'documentId': value.documentId,
        'videoId': value.videoId,
        'quizId': value.quizId,
        'name': value.name,
        'durationMinutes': value.durationMinutes,
    };
}


