export const getDurationDescription = (durationSeconds: number): string | undefined => {
  if (!durationSeconds) {
    return undefined;
  }

  const minutes = Math.floor(durationSeconds / 60);
  const seconds = durationSeconds - minutes * 60;

  return `${minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
};
