import { Media } from "../../../api";
import { compareName } from "../../shared";

export const compareMediaItemName = (m1: Media, m2: Media) => compareName(m1.name, m2.name);

export const compareMediaItemType = (mediaItem1: Media, mediaItem2: Media) => {
  if (mediaItem1.type > mediaItem2.type) {
    return 1;
  } else if (mediaItem1.type < mediaItem2.type) {
    return -1;
  }
  return 0;
};
