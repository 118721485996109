import { getApiConfiguration } from "@interface48/app";
import {
  Form,
  FormAction,
  FormContext,
  FormSchema,
  getNewFormContext,
  handleFieldError,
  IChangeEvent,
  ISubmitEvent,
} from "@interface48/forms";
import { red } from "@material-ui/core/colors";
import { createStyles, Theme, WithStyles } from "@material-ui/core/styles";
import withStyles, { ClassNameMap } from "@material-ui/core/styles/withStyles";
import cloneDeep from "lodash/cloneDeep";
import React from "react";
import { getDocumentFormDataSchemaVariant } from ".";
import { MediaFileLinkField } from "../../shared";
import { DocumentFormData } from "./models";
import { getDocumentFormSchema } from "./utils";

const FORM_ID = "documentForm";

const styles = (theme: Theme) =>
  createStyles({
    deleteFormAction: {
      marginLeft: "auto !important",
      color: red[500],
    },
  });

export interface DocumentFormContext extends FormContext {
  onError: (errorMessage: string) => void;
}

interface DocumentFormProps extends WithStyles<typeof styles> {
  className: string;
  formMode: "read" | "write";
  formData: DocumentFormData;
  isFormSubmitting: boolean;
  onEdit: () => void;
  onClose: () => void;
  onChange: (formData: DocumentFormData) => void;
  onSubmit: (formData: DocumentFormData) => void;
  onCancel: (initialFormData: DocumentFormData) => void;
  onDelete: () => void;
  onError: (errorMessage: string) => void;
}

interface DocumentFormState {
  formSchema: FormSchema;
  formContext: DocumentFormContext;
  formActions: FormAction[];
  initialFormData: DocumentFormData;
}

export const DocumentFormComponent = class extends React.Component<DocumentFormProps, DocumentFormState> {
  constructor(props: DocumentFormProps) {
    super(props);

    const { formMode, formData, classes, onDelete, onError } = this.props;

    const formSchema = getDocumentFormSchema(formMode);
    const formActions = getFormActions(classes, formMode, onDelete);

    this.state = {
      initialFormData: cloneDeep(formData),
      formSchema,
      formContext: {
        ...getNewFormContext(),
        getApiConfiguration,
        onError,
      },
      formActions,
    };
  }

  public render() {
    const { className, formData, isFormSubmitting, onClose, onEdit } = this.props;
    const { formSchema, formContext, formActions } = this.state;

    return (
      <Form<DocumentFormData>
        id={FORM_ID}
        className={className}
        schema={formSchema.jsonSchema}
        uiSchema={formSchema.uiSchema}
        formData={formData}
        formContext={formContext}
        formActions={formActions}
        isFormSubmitting={isFormSubmitting}
        fields={{ MediaFileLinkField }}
        onEdit={onEdit}
        onChange={this.handleChange}
        onSubmit={this.handleSubmit}
        onError={handleFieldError(FORM_ID)}
        onCancel={this.handleCancel}
        onClose={onClose}
      />
    );
  }

  public componentDidUpdate(prevProps: DocumentFormProps) {
    const { formMode: prevFormMode, formData: prevFormData } = prevProps;
    const { formMode, formData, onDelete, classes } = this.props;
    // If in read mode, the form data has now been loaded, and the user has permission to edit...
    if (prevFormMode !== formMode || (formMode === "read" && !prevFormData && formData)) {
      const formSchema = getDocumentFormSchema(formMode);
      const formActions = getFormActions(classes, formMode, onDelete);

      this.setState({ ...this.state, formSchema, formActions });
    }
  }

  private handleChange = (changeEvent: IChangeEvent<DocumentFormData>) => {
    const { formData: prevFormData, onChange } = this.props;

    let { formData } = changeEvent;
    const { isExisting, isRemoteFile } = formData;

    const prevSchemaVariant = prevFormData.schemaVariant;
    const schemaVariant = getDocumentFormDataSchemaVariant(isExisting, isRemoteFile);
    // If the schema variant has changed...
    if (prevSchemaVariant !== schemaVariant) {
      formData = {
        ...changeEvent.formData,
        schemaVariant,
      };
    }

    onChange(formData);
  };

  private handleSubmit = (submitEvent: ISubmitEvent<DocumentFormData>) => {
    const { onSubmit } = this.props;

    const { formData } = submitEvent;

    onSubmit(formData);
  };

  private handleCancel = () => {
    const { onCancel } = this.props;
    const { initialFormData } = this.state;

    onCancel(initialFormData);
  };
};

const getFormActions = (
  classes: ClassNameMap<"deleteFormAction">,
  formMode: "read" | "write",
  onDelete: () => void,
) => {
  if (formMode === "read") {
    const formActions = [
      {
        type: "edit",
        button: {
          variant: "primary",
          label: "Edit",
        },
      },
      {
        type: "close",
        button: {
          variant: "secondary",
          label: "Back",
        },
      },
      {
        type: "other",
        button: {
          className: classes.deleteFormAction,
          variant: "secondary",
          label: "Delete",
          onClick: onDelete,
        },
      },
    ] as FormAction[];

    return formActions;
  } else {
    return [
      {
        type: "submit",
        button: {
          variant: "primary",
          label: "Save",
        },
      },
      {
        type: "cancel",
        button: {
          variant: "secondary",
          label: "Cancel",
        },
      },
    ] as FormAction[];
  }
};

export const DocumentForm = withStyles(styles)(DocumentFormComponent);
