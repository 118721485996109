/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TrainingScorecardDepartmentPositionDto,
    TrainingScorecardDepartmentPositionDtoFromJSON,
    TrainingScorecardDepartmentPositionDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface TrainingScorecardDepartmentDto
 */
export interface TrainingScorecardDepartmentDto {
    /**
     * 
     * @type {number}
     * @memberof TrainingScorecardDepartmentDto
     */
    department: number;
    /**
     * 
     * @type {string}
     * @memberof TrainingScorecardDepartmentDto
     */
    departmentName: string;
    /**
     * 
     * @type {Array<TrainingScorecardDepartmentPositionDto>}
     * @memberof TrainingScorecardDepartmentDto
     */
    positions: Array<TrainingScorecardDepartmentPositionDto>;
}

export function TrainingScorecardDepartmentDtoFromJSON(json: any): TrainingScorecardDepartmentDto {
    return {
        'department': json['department'],
        'departmentName': json['departmentName'],
        'positions': (json['positions'] as Array<any>).map(TrainingScorecardDepartmentPositionDtoFromJSON),
    };
}

export function TrainingScorecardDepartmentDtoToJSON(value?: TrainingScorecardDepartmentDto | null): any {
    if (value == undefined) {
        return undefined;
    }
    return {
        'department': value.department,
        'departmentName': value.departmentName,
        'positions': (value.positions as Array<any>).map(TrainingScorecardDepartmentPositionDtoToJSON),
    };
}


