import Bowser, { OS_MAP } from "bowser";
import React from "react";
import { Document } from "../../../api";
import { PdfViewer } from "./PdfViewer";

interface DocumentViewerProps {
  document: Document;
  availableWidth: number;
  availableHeight: number;
}

export const DocumentViewerComponent = class extends React.Component<DocumentViewerProps> {
  public componentDidMount() {
    window.addEventListener("resize", this.forceReRender);
  }

  public render() {
    const { document, availableWidth, availableHeight } = this.props;

    let src = `/api/documents/${document.id}/file`;
    // HACK: This is (hopefully) temporary to allow a locally maintained copy of a PDF for Visitor Orientation...
    if (document.remoteFileUrl) {
      src = document.remoteFileUrl;
    }

    // TODO: set devicePlatform on DocumentViewerState?
    const devicePlatform = Bowser.parse(window.navigator.userAgent).os.name;

    return (
      <div
        id={"document-container"}
        className={"document-viewer-container"}
        style={{ height: availableHeight - 2, overflowY: devicePlatform === OS_MAP.iOS ? "scroll" : undefined }}
      >
        {devicePlatform === OS_MAP.iOS ? (
          <PdfViewer src={src} availableWidth={availableWidth} />
        ) : (
          <iframe title="Document Preview" src={src} />
        )}
      </div>
    );
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.forceReRender);
  }

  private forceReRender = () => {
    this.forceUpdate();
  };
};

export const DocumentViewer = DocumentViewerComponent;
