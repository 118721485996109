import { getApiError, OptionItem } from "@interface48/api";
import { AppActions, AppPageFrame, baseActionCreators, Loader, OidcState } from "@interface48/app";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";
import cloneDeep from "lodash/cloneDeep";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  administrationCoursesApi,
  administrationDocumentsApi,
  administrationQuizzesApi,
  administrationTrainingProgramsApi,
  administrationVideosApi,
  CompleteTrainingProgramSessionCommand,
  TrainingProgramDepartmentDto,
} from "../../../api";
import { CourseSessionState } from "../../../components/courses";
import { QuizSessionState } from "../../../components/quizzes";
import { EntityDeleteDialog } from "../../../components/shared";
import {
  toTrainingProgramSession,
  TrainingProgramSession,
  TrainingProgramSessionCompletionFormData,
} from "../../../components/training-program-sessions";
import {
  toAddTrainingProgramCommand,
  toTrainingProgramFormData,
  toUpdateTrainingProgramCommand,
  TrainingProgramForm,
  TrainingProgramFormData,
  TrainingProgramPreviewDialog,
} from "../../../components/training-programs";
import { actionCreators, AdministrationActions, AdministrationState, ApplicationState } from "../../../store";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
    },
    form: {
      backgroundColor: theme.palette.background.default,
    },
  });

interface TrainingProgramPageMatchParamsProps {
  trainingProgramId: string;
}

interface TrainingProgramPageStateProps {
  oidcState: OidcState;
  administrationState: AdministrationState;
}

interface TrainingProgramPageDispatchProps {
  appActions: AppActions;
  administrationActions: AdministrationActions;
}

interface TrainingProgramPageOwnProps
  extends RouteComponentProps<TrainingProgramPageMatchParamsProps>,
    WithStyles<typeof styles> {}

type TrainingProgramProps = TrainingProgramPageStateProps &
  TrainingProgramPageDispatchProps &
  TrainingProgramPageOwnProps;

interface TrainingProgramPageState {
  formMode: "read" | "write";
  formData: TrainingProgramFormData;
  initialFormData: TrainingProgramFormData;
  trainingProgramComponentOptions?: Array<OptionItem<string>>;
  trainingProgramPreviewDialog: {
    trainingProgramSession?: TrainingProgramSession;
    isOpen: boolean;
  };
  trainingProgramDeleteConfirmationDialog: { isOpen: boolean };
}

export const TrainingProgramPageComponent = class extends React.Component<
  TrainingProgramProps,
  TrainingProgramPageState
> {
  constructor(props: TrainingProgramProps) {
    super(props);

    const formMode = this.getTrainingProgramId() ? "read" : "write";
    const formData = toTrainingProgramFormData();

    this.state = {
      formMode,
      formData,
      initialFormData: cloneDeep(formData),
      trainingProgramPreviewDialog: { isOpen: false },
      trainingProgramDeleteConfirmationDialog: { isOpen: false },
    };
  }

  public async componentDidMount() {
    const { administrationActions } = this.props;

    const trainingProgramId = this.getTrainingProgramId();

    if (trainingProgramId) {
      administrationActions.requestTrainingProgram(trainingProgramId);
    }

    await this.loadTrainingProgramComponentOptions();
  }

  public render() {
    const { administrationState, classes } = this.props;
    const { trainingProgram, actionStatus } = administrationState;
    const {
      formMode,
      formData,
      trainingProgramComponentOptions,
      trainingProgramDeleteConfirmationDialog,
      trainingProgramPreviewDialog,
    } = this.state;

    const formKey = `form_${formMode}`;
    const trainingProgramId = this.getTrainingProgramId();
    const isTrainingProgramLoading =
      !trainingProgram && actionStatus.type === "ADMINISTRATION_TRAINING_PROGRAM_REQUEST" && actionStatus.pending;
    const isTrainingProgramSaving =
      (actionStatus.type === "ADMINISTRATION_TRAINING_PROGRAM_ADD_REQUEST" ||
        actionStatus.type === "ADMINISTRATION_TRAINING_PROGRAM_UPDATE_REQUEST") &&
      actionStatus.pending;
    const isTrainingProgramFormLoading = trainingProgramId && (isTrainingProgramLoading || !formData.id);

    const viewerAvailableWidth = window.innerWidth;
    const viewerAvailableHeight = window.innerHeight - 400;

    const pageTitle = !trainingProgramId
      ? "Add Training Program"
      : isTrainingProgramFormLoading
      ? "Loading Training Program..."
      : `${formMode === "write" ? "Edit " : ""}Training Program - ${trainingProgram!.name}`;

    return (
      <AppPageFrame pageTitle={pageTitle} onBackClick={this.handleBackClick}>
        <div className={classes.container}>
          {isTrainingProgramFormLoading || !trainingProgramComponentOptions ? (
            <Loader loadingText={"Loading Training Program"} />
          ) : (
            <>
              <TrainingProgramForm
                key={formKey}
                className={classes.form}
                formMode={formMode}
                formData={formData}
                trainingProgramComponentOptions={trainingProgramComponentOptions}
                onEdit={this.handleEdit}
                onChange={this.handleChange}
                onSubmit={this.handleSubmit}
                onCancel={this.handleCancel}
                onClose={this.handleClose}
                onDelete={this.handleDelete}
                onPreview={this.handlePreviewClick}
                isFormSubmitting={isTrainingProgramSaving}
              />
              {trainingProgramId && formData.name && (
                <EntityDeleteDialog
                  entityId={trainingProgramId}
                  entityName={formData.name}
                  isOpen={trainingProgramDeleteConfirmationDialog.isOpen}
                  isActionPending={actionStatus.pending}
                  onCancel={this.handleDeleteCancel}
                  onDelete={this.handleDeleteConfirm}
                />
              )}
              <TrainingProgramPreviewDialog
                trainingProgramSession={trainingProgramPreviewDialog.trainingProgramSession}
                title={`${pageTitle} (Preview)`}
                availableWidth={viewerAvailableWidth}
                availableHeight={viewerAvailableHeight}
                isOpen={trainingProgramPreviewDialog.isOpen}
                onStepChange={this.handlePreviewStepChange}
                onProgressChange={this.handlePreviewProgressChange}
                onCourseOpen={this.handlePreviewCourseOpen}
                onCourseSessionStateChange={this.handlePreviewCourseSessionStateChange}
                onQuizSessionStateChange={this.handlePreviewQuizSessionStateChange}
                onCompletionFormChange={this.handlePreviewCompletionFormChange}
                onSubmit={this.handlePreviewSubmit}
                onError={this.handleError}
                onClose={this.handlePreviewClose}
              />
            </>
          )}
        </div>
      </AppPageFrame>
    );
  }

  public componentDidUpdate(prevProps: TrainingProgramProps, prevState: TrainingProgramPageState) {
    const { administrationState: prevAdministrationState } = prevProps;
    const { formMode: prevFormMode } = prevState;
    const { administrationState, appActions, administrationActions, history } = this.props;
    const { trainingProgram } = administrationState;
    const { formMode } = this.state;

    const trainingProgramId = this.getTrainingProgramId();

    const prevActionStatus = prevAdministrationState.actionStatus;
    const actionStatus = administrationState.actionStatus;

    // If some training program action just completed...
    if (prevActionStatus.pending && !actionStatus.pending) {
      // If an error occurred, display the message...
      if (actionStatus.error) {
        const errorMessage =
          actionStatus.error.message || "An unexpected error occurred upon attempting to process the last operation.";

        appActions.postSnackbarMessage(errorMessage, { variant: "error" });
      } else if (trainingProgram) {
        // If we fetched the Details for this Training Program...
        if (actionStatus.type === "ADMINISTRATION_TRAINING_PROGRAM_REQUEST") {
          const formData = toTrainingProgramFormData(trainingProgram);

          this.setState({ ...this.state, formMode: "read", formData });
          // If we loaded the details for a Training Program that was just added via /add, replace the URL with it's
          // ID-specific one...
          if (!trainingProgramId) {
            history.replace(`/administration/training-programs/${formData.id}`);
          }
        } else if (
          (actionStatus.type === "ADMINISTRATION_TRAINING_PROGRAM_ADD_REQUEST" ||
            actionStatus.type === "ADMINISTRATION_TRAINING_PROGRAM_UPDATE_REQUEST") &&
          trainingProgram
        ) {
          administrationActions.requestTrainingProgram(trainingProgram.id);
        } else if (actionStatus.type === "ADMINISTRATION_TRAINING_PROGRAM_DELETE_REQUEST") {
          history.push("/administration/training-programs");
        }
      }
    }

    // If the Form Mode just changed, scroll to the top of the page...
    if (prevFormMode !== formMode) {
      const appFrame = document.getElementById("app-frame");
      if (appFrame) {
        appFrame.scrollTop = 0;
      }
    }
  }

  private handleBackClick = () => {
    this.props.history.goBack();
  };

  private handleClose = () => {
    this.props.history.goBack();
  };

  private handleEdit = () => {
    this.setState({ ...this.state, formMode: "write" });
  };

  private handleCancel = (initialFormData: TrainingProgramFormData) => {
    const { history } = this.props;

    if (!this.getTrainingProgramId()) {
      history.push("/administration/training-programs");
    } else {
      this.setState({ ...this.state, formMode: "read", formData: initialFormData });
    }
  };

  private handleChange = (formData: TrainingProgramFormData) => {
    this.setState({ ...this.state, formData });
  };

  private handleSubmit = (formData: TrainingProgramFormData) => {
    const { administrationActions } = this.props;

    // If adding a new Training Program...
    if (!this.getTrainingProgramId()) {
      const addTrainingProgramCommand = toAddTrainingProgramCommand(formData);

      administrationActions.requestTrainingProgramAdd(addTrainingProgramCommand);
    } else {
      if (!formData.id) {
        throw Error("Training Program Id is undefined upon request to update.");
      }

      const updateTrainingProgramCommand = toUpdateTrainingProgramCommand(formData);

      administrationActions.requestTrainingProgramUpdate(formData.id, updateTrainingProgramCommand);
    }
  };

  private handleDelete = () => {
    this.setState({ ...this.state, trainingProgramDeleteConfirmationDialog: { isOpen: true } });
  };

  private handleDeleteCancel = () => {
    this.setState({ ...this.state, trainingProgramDeleteConfirmationDialog: { isOpen: false } });
  };

  private handleDeleteConfirm = (trainingProgramId: string) => {
    const { administrationActions } = this.props;

    administrationActions.requestTrainingProgramDelete(trainingProgramId);
  };

  private handlePreviewClick = async () => {
    const { appActions } = this.props;
    const { formData, trainingProgramPreviewDialog } = this.state;

    if (formData.componentIds) {
      this.setState({
        ...this.state,
        trainingProgramPreviewDialog: { ...trainingProgramPreviewDialog, isOpen: false },
      });

      try {
        const componentIds = formData.componentIds.filter((componentId) => componentId !== null) as string[];

        const trainingProgramComponents = await administrationTrainingProgramsApi.getTrainingProgramComponents(
          componentIds,
        );

        trainingProgramComponents.push({
          name: "Completion",
          number: componentIds.length,
          durationMinutes: 1,
        });

        const trainingProgramDepartments: TrainingProgramDepartmentDto[] = formData.departments
          ? formData.departments.map((department) => ({
              department: department.department!,
              departmentName: department.departmentName!,
              positions: department.positions!,
              positionNames: department.positionNames!,
            }))
          : [];

        this.setState({
          ...this.state,
          trainingProgramPreviewDialog: {
            isOpen: true,
            trainingProgramSession: toTrainingProgramSession(
              formData,
              trainingProgramComponents,
              trainingProgramDepartments,
            ),
          },
        });
      } catch (errorResponse) {
        const error = await getApiError(errorResponse);

        this.setState({ ...this.state, trainingProgramPreviewDialog: { isOpen: true } });

        appActions.postSnackbarMessage(error.message, { variant: "error" });
      }
    }
  };

  private handlePreviewStepChange = (stepNumber: number) => {
    const { trainingProgramPreviewDialog } = this.state;

    if (!trainingProgramPreviewDialog.trainingProgramSession) {
      throw Error("No Training Program Session in progress.");
    }

    this.setState({
      ...this.state,
      trainingProgramPreviewDialog: {
        ...trainingProgramPreviewDialog,
        trainingProgramSession: {
          ...trainingProgramPreviewDialog.trainingProgramSession,
          currentTrainingProgramComponentIndex: stepNumber,
        },
      },
    });
  };

  private handlePreviewProgressChange = (stepNumber: number, stepProgress: number) => {
    console.info(`Progress at ${stepProgress} for Step ${stepNumber}.`);
  };

  private handlePreviewCourseOpen = () => {
    console.info("Course Opened.");
  };

  private handlePreviewCourseSessionStateChange = (courseSessionState: CourseSessionState) => {
    const { trainingProgramPreviewDialog } = this.state;

    if (!trainingProgramPreviewDialog.trainingProgramSession) {
      throw Error("No Training Program Session in progress.");
    }

    let courses: CourseSessionState[] | undefined;

    const courseIndex = trainingProgramPreviewDialog.trainingProgramSession.courses.findIndex(
      (c) => c.courseId === courseSessionState.courseId,
    );

    if (courseIndex !== -1) {
      courses = [
        ...trainingProgramPreviewDialog.trainingProgramSession.courses.slice(0, courseIndex),
        courseSessionState,
        ...trainingProgramPreviewDialog.trainingProgramSession.courses.slice(courseIndex + 1),
      ];
    } else {
      courses = [...trainingProgramPreviewDialog.trainingProgramSession.courses, courseSessionState];
    }

    this.setState({
      ...this.state,
      trainingProgramPreviewDialog: {
        ...trainingProgramPreviewDialog,
        trainingProgramSession: {
          ...trainingProgramPreviewDialog.trainingProgramSession,
          courses,
        },
      },
    });
  };

  private handlePreviewQuizSessionStateChange = (quizSessionState: QuizSessionState, isQuizComplete?: boolean) => {
    const { trainingProgramPreviewDialog } = this.state;

    if (!trainingProgramPreviewDialog.trainingProgramSession) {
      throw Error("No Training Program Session in progress.");
    }

    let quizzes: QuizSessionState[] | undefined;

    const quizIndex = trainingProgramPreviewDialog.trainingProgramSession.quizzes.findIndex(
      (q) => q.quizId === quizSessionState.quizId,
    );

    if (quizIndex !== -1) {
      quizzes = [
        ...trainingProgramPreviewDialog.trainingProgramSession.quizzes.slice(0, quizIndex),
        quizSessionState,
        ...trainingProgramPreviewDialog.trainingProgramSession.quizzes.slice(quizIndex + 1),
      ];
    } else {
      quizzes = [...trainingProgramPreviewDialog.trainingProgramSession.quizzes, quizSessionState];
    }

    this.setState({
      ...this.state,
      trainingProgramPreviewDialog: {
        ...trainingProgramPreviewDialog,
        trainingProgramSession: {
          ...trainingProgramPreviewDialog.trainingProgramSession,
          quizzes,
        },
      },
    });
  };

  private handlePreviewCompletionFormChange = (completionFormData: TrainingProgramSessionCompletionFormData) => {
    const { trainingProgramPreviewDialog } = this.state;

    if (!trainingProgramPreviewDialog.trainingProgramSession) {
      throw Error("No Training Program Session in progress.");
    }

    this.setState({
      ...this.state,
      trainingProgramPreviewDialog: {
        ...trainingProgramPreviewDialog,
        trainingProgramSession: {
          ...trainingProgramPreviewDialog.trainingProgramSession,
          completionFormData,
        },
      },
    });
  };

  private handlePreviewSubmit = (completeTrainingProgramSessionCommand: CompleteTrainingProgramSessionCommand) => {
    console.info("Submit with CompleteTrainingProgramSessionCommand:\n", completeTrainingProgramSessionCommand);

    this.setState({
      ...this.state,
      trainingProgramPreviewDialog: { ...this.state.trainingProgramPreviewDialog, isOpen: false },
    });
  };

  private handlePreviewClose = () => {
    this.setState({
      ...this.state,
      trainingProgramPreviewDialog: { ...this.state.trainingProgramPreviewDialog, isOpen: false },
    });
  };

  private handleError = (errorMessage: string) => {
    const { appActions } = this.props;

    appActions.postSnackbarMessage(errorMessage, { variant: "error" });
  };

  private loadTrainingProgramComponentOptions = async () => {
    const courses = await administrationCoursesApi.getCourses();
    const documents = await administrationDocumentsApi.getDocuments();
    const quizzes = await administrationQuizzesApi.getQuizzes();
    const videos = await administrationVideosApi.getVideos();

    const courseOptions = courses.map((c) => ({ value: c.id, label: `Course: ${c.name}` }));
    const documentOptions = documents.map((d) => ({ value: d.id, label: `Document: ${d.name}` }));
    const videoOptions = videos.map((v) => ({ value: v.id, label: `Video: ${v.name}` }));
    const quizOptions = quizzes.map((q) => ({ value: q.id, label: `Quiz: ${q.name}` }));

    const trainingProgramComponentOptions: Array<OptionItem<string>> = [
      ...courseOptions,
      ...documentOptions,
      ...videoOptions,
      ...quizOptions,
    ];

    this.setState({ ...this.state, trainingProgramComponentOptions });
  };

  private getTrainingProgramId = (): string | undefined => {
    const { match } = this.props;
    const { params } = match;
    const { trainingProgramId } = params;

    if (trainingProgramId.toLowerCase() === "add") {
      return undefined;
    }

    return trainingProgramId;
  };
};

export const TrainingProgramPage = withStyles(styles)(
  connect<
    TrainingProgramPageStateProps,
    TrainingProgramPageDispatchProps,
    TrainingProgramPageOwnProps,
    ApplicationState
  >(
    (state) => ({
      oidcState: state.oidc,
      administrationState: state.administration,
    }),
    (dispatch) => ({
      appActions: bindActionCreators(baseActionCreators.app, dispatch),
      administrationActions: bindActionCreators(actionCreators.administration, dispatch),
    }),
  )(TrainingProgramPageComponent),
);
