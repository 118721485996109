import { OptionItem } from "@interface48/api";
import { FormSchema } from "@interface48/forms";

export const getTrainingProgramFormSchema = (
  formMode: "read" | "write",
  trainingProgramComponentOptions: Array<OptionItem<string>>,
): FormSchema => {
  const formSchema: FormSchema = {
    jsonSchema: {
      type: "object",
      required: ["name", "description", "componentIds", "isEnabled"],
      properties: {
        isExisting: {
          type: "boolean",
        },
        id: {
          type: "string",
          title: "Training Program ID",
        },
        name: {
          type: "string",
          title: "Name",
          maxLength: 75,
        },
        description: {
          type: "string",
          title: "Description",
          maxLength: 500,
        },
        componentIds: {
          type: "array",
          title: "Components",
          minItems: 1,
          items: {
            type: "string",
            title: "Component",
            oneOf: trainingProgramComponentOptions.length
              ? trainingProgramComponentOptions.map((option) => {
                  return { const: option.value, title: option.label };
                })
              : undefined,
          },
        },
        departments: {
          type: "array",
          title: "Departments/Positions",
          items: {
            type: "object",
            required: ["department", "positions"],
            properties: {
              department: {
                type: "number",
                title: "Department",
              },
              departmentName: {
                type: "string",
                title: "Department",
              },
              positions: {
                type: "array",
                title: "Position(s)",
                items: {
                  type: "number",
                  enum: [""],
                },
                uniqueItems: true,
                minItems: 1,
              },
              positionNames: {
                type: "string",
                title: "Position(s)",
              },
            },
          },
        },
        hasDepartments: {
          type: "boolean",
        },
        durationMinutes: {
          type: "number",
          title: "Estimated Duration (Minutes)",
        },
        sortOrder: {
          type: "number",
          title: "Sort Order",
          minimum: 1,
          maximum: 127,
        },
        emailRecipients: {
          type: "string",
          title: "Email Recipients",
          pattern:
            "(^$)|(^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)* *( *, *[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*)*$)",
        },
        isEnabled: {
          type: "boolean",
          title: "Enabled?",
        },
      },
      dependencies: {
        hasDepartments: {
          oneOf: [
            {
              properties: {
                hasDepartments: { enum: [true] },
                trainingScorecardId: {
                  type: "string",
                  title: "Scorecard",
                },
                trainingScorecardName: {
                  type: "string",
                  title: "Scorecard",
                },
              },
            },
            {
              properties: {
                hasDepartments: { enum: [false] },
              },
            },
          ],
        },
        isExisting: {
          oneOf: [
            {
              properties: {
                isExisting: { enum: [true] },
                incrementMajorVersion: {
                  type: "boolean",
                  title: "Increment Major Version?",
                },
                majorVersion: {
                  type: "number",
                  title: "Major Version",
                },
                created: {
                  title: "Created",
                  type: "object",
                  properties: {
                    at: {
                      type: "string",
                    },
                    personName: {
                      type: "string",
                    },
                    userName: {
                      type: "string",
                    },
                  },
                },
                lastModified: {
                  title: "Last Modified",
                  type: "object",
                  properties: {
                    at: {
                      type: "string",
                    },
                    personName: {
                      type: "string",
                    },
                    userName: {
                      type: "string",
                    },
                  },
                },
                version: {
                  type: "number",
                  title: "Version",
                },
              },
            },
            {
              properties: {
                isExisting: { enum: [false] },
              },
            },
          ],
        },
      },
    },
    uiSchema: {
      "ui:order": [
        "name",
        "description",
        "componentIds",
        "departments",
        "trainingScorecardId",
        "trainingScorecardName",
        "durationMinutes",
        "sortOrder",
        "emailRecipients",
        "incrementMajorVersion",
        "majorVersion",
        "isEnabled",
        "created",
        "lastModified",
        "version",
        "*",
      ],
      isExisting: {
        "ui:widget": "hidden",
      },
      id: {
        "ui:widget": "hidden",
      },
      name: {
        "ui:widget": formMode === "read" ? "ReadOnlyWidget" : undefined,
      },
      description: {
        "ui:widget": formMode === "read" ? "ReadOnlyWidget" : "textarea",
      },
      componentIds:
        formMode === "read"
          ? {
              "ui:field": "TrainingProgramComponentsReadOnlyField",
            }
          : {
              "ui:options": {
                title: {
                  variant: "label",
                },
              },
              items: {
                "ui:widget": "TrainingProgramComponentSelectWidget",
              },
            },
      durationMinutes: {
        "ui:widget": formMode === "read" ? "ReadOnlyWidget" : "hidden",
      },
      sortOrder: {
        "ui:widget": formMode === "read" ? "ReadOnlyWidget" : undefined,
        "ui:help": "Training Program having a lesser 'Sort Order' value will appear earlier on the Home Page.",
        "ui:options": {
          emptyValue: formMode === "read" ? "(None)" : undefined,
        },
      },
      emailRecipients: {
        "ui:widget": formMode === "read" ? "ReadOnlyWidget" : undefined,
        "ui:help": "Separate multiple Email Addresses with a comma.",
        "ui:options": {
          emptyValue: "(None)",
        },
      },
      departments:
        formMode === "read"
          ? {
              "ui:field": "TrainingProgramDepartmentsReadOnlyField",
              "ui:options": {
                emptyValue: "(None)",
              },
            }
          : {
              "ui:options": {
                orderable: false,
                addable: formMode === "write",
                removable: formMode === "write",
                title: {
                  variant: "label",
                },
                layout: {
                  enumerated: true,
                },
              },
              items: {
                "ui:options": {
                  layout: { size: { xs: 12, sm: 12, md: 6 } },
                },
                department: {
                  "ui:widget": "select",
                  "ui:options": {
                    renderType: "react-select",
                    remoteOptions: {
                      url: "/api/lists/departments",
                    },
                  },
                },
                departmentName: {
                  "ui:widget": "hidden",
                },
                positions: {
                  "ui:widget": "select",
                  "ui:options": {
                    renderType: "react-select",
                    remoteOptions: {
                      url: "/api/lists/positions",
                    },
                    multipleOptions: {
                      selectAll: true,
                    },
                  },
                },
                positionNames: {
                  "ui:widget": "hidden",
                },
              },
            },
      hasDepartments: {
        "ui:widget": "hidden",
      },
      trainingScorecardId:
        formMode === "read"
          ? {
              "ui:widget": "hidden",
            }
          : {
              "ui:widget": "select",
              "ui:options": {
                remoteOptions: {
                  url: "/api/administration/lists/training-scorecards",
                },
              },
            },
      trainingScorecardName:
        formMode === "read"
          ? {
              "ui:widget": "ReadOnlyWidget",
              "ui:emptyValue": "(Unspecified)",
            }
          : {
              "ui:widget": "hidden",
            },
      incrementMajorVersion: {
        "ui:widget": formMode === "read" ? "hidden" : undefined,
      },
      majorVersion: {
        "ui:widget": "ReadOnlyWidget",
      },
      isEnabled: {
        "ui:widget": formMode === "read" ? "ReadOnlyWidget" : undefined,
      },
      created: {
        "ui:field": "AuditDetailsField",
      },
      lastModified: {
        "ui:field": "AuditDetailsField",
      },
      version: {
        "ui:widget": "ReadOnlyWidget",
      },
    },
  };

  return formSchema;
};
