/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddOrUpdateTrainingProgramSessionCourseCommand
 */
export interface AddOrUpdateTrainingProgramSessionCourseCommand {
    /**
     * 
     * @type {string}
     * @memberof AddOrUpdateTrainingProgramSessionCourseCommand
     */
    courseId: string;
    /**
     * 
     * @type {number}
     * @memberof AddOrUpdateTrainingProgramSessionCourseCommand
     */
    courseVersion: number;
    /**
     * 
     * @type {string}
     * @memberof AddOrUpdateTrainingProgramSessionCourseCommand
     */
    courseStateJson?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddOrUpdateTrainingProgramSessionCourseCommand
     */
    beganAt: string;
    /**
     * 
     * @type {string}
     * @memberof AddOrUpdateTrainingProgramSessionCourseCommand
     */
    completedAt?: string | null;
}

export function AddOrUpdateTrainingProgramSessionCourseCommandFromJSON(json: any): AddOrUpdateTrainingProgramSessionCourseCommand {
    return {
        'courseId': json['courseId'],
        'courseVersion': json['courseVersion'],
        'courseStateJson': !exists(json, 'courseStateJson') ? undefined : json['courseStateJson'],
        'beganAt': json['beganAt'],
        'completedAt': !exists(json, 'completedAt') ? undefined : json['completedAt'],
    };
}

export function AddOrUpdateTrainingProgramSessionCourseCommandToJSON(value?: AddOrUpdateTrainingProgramSessionCourseCommand | null): any {
    if (value == undefined) {
        return undefined;
    }
    return {
        'courseId': value.courseId,
        'courseVersion': value.courseVersion,
        'courseStateJson': value.courseStateJson,
        'beganAt': value.beganAt,
        'completedAt': value.completedAt,
    };
}


