import { DialogContent } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Theme, WithStyles, createStyles, withStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { TrainingScorecardDto } from "../../../api";
import { TrainingScorecardForm, TrainingScorecardFormData, toTrainingScorecardFormData } from "../forms";

const styles = (theme: Theme) =>
  createStyles({
    form: {
      "& > div:last-child": {
        paddingBottom: theme.spacing(2),
      },
    },
  });

interface TrainingScorecardDialogProps extends WithStyles<typeof styles> {
  trainingScorecard?: TrainingScorecardDto;
  isOpen: boolean;
  isActionPending: boolean;
  onCancel: () => void;
  onUpdate: (formData: TrainingScorecardFormData) => void;
}

export const TrainingScorecardDialogComponent = (props: TrainingScorecardDialogProps) => {
  const { trainingScorecard, isOpen, isActionPending, classes, onCancel, onUpdate } = props;

  const [formData, setFormData] = useState<TrainingScorecardFormData | null>(
    trainingScorecard ? toTrainingScorecardFormData(trainingScorecard) : null,
  );

  useEffect(() => {
    if (trainingScorecard) {
      setFormData(toTrainingScorecardFormData(trainingScorecard));
    }
  }, [trainingScorecard]);

  return (
    <Dialog open={isOpen} maxWidth="md" fullWidth={true} onEscapeKeyDown={onCancel}>
      <DialogTitle>{`Edit Scorecard '${formData?.name}'`}</DialogTitle>
      <DialogContent>
        {formData && (
          <TrainingScorecardForm
            className={classes.form}
            formData={formData}
            isFormSubmitting={isActionPending}
            onCancel={onCancel}
            onChange={setFormData}
            onSubmit={onUpdate}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export const TrainingScorecardDialog = withStyles(styles)(TrainingScorecardDialogComponent);
