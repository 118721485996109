import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatIso8601String } from "@interface48/formatting";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Chip from "@material-ui/core/Chip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import Grow from "@material-ui/core/Grow";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { Theme, WithStyles, createStyles, withStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useRef, useState } from "react";
import { TrainingProgramSessionStateDto, TrainingProgramSummaryDto } from "../../../api";
import { TrainingProgramSyllabusStepper } from "../steppers";

const styles = (theme: Theme) =>
  createStyles({
    cardContent: {
      paddingTop: 0,
    },
    cardActions: {
      justifyContent: "space-between",
      padding: theme.spacing(2),
      paddingTop: 0,
    },
    cardActionsLeft: {
      display: "flex",
    },
    message: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.secondary.main,
      marginTop: theme.spacing(2),
      padding: theme.spacing(2),
    },
    messageIcon: {
      paddingRight: theme.spacing(1),
      color: theme.palette.common.white,
    },
    messageBody: {
      color: theme.palette.common.white,
    },
    completedChip: {
      marginLeft: theme.spacing(2),
    },
    expandIcon: {
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    componentsDivider: {
      marginTop: -theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    components: {
      "& > div": {
        padding: 0,
      },
    },
  });

interface TrainingProgramCardProps extends WithStyles<typeof styles> {
  className?: string;
  trainingProgram: TrainingProgramSummaryDto;
  trainingProgramSessionState?: TrainingProgramSessionStateDto;
  infoMessage?: string;
  onClick: (restart?: boolean) => void;
}

export const TrainingProgramCardComponent = (props: TrainingProgramCardProps) => {
  const { className, trainingProgram, trainingProgramSessionState, infoMessage, onClick, classes } = props;

  const menuAnchorRef = useRef<HTMLDivElement>(null);

  const [isExpanded, setIsExpanded] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const isTrainingProgramInProgress =
    trainingProgramSessionState?.trainingProgramMajorVersion === trainingProgram.majorVersion &&
    !trainingProgramSessionState.trainingProgramCompletedAt;

  const currentTrainingProgramComponentIndex = trainingProgramSessionState?.trainingProgramStepNumber
    ? trainingProgramSessionState.trainingProgramStepNumber - 1
    : undefined;

  const handleMenuItemClick = (restart?: boolean) => () => {
    onClick(restart);
    setIsMenuOpen(false);
  };

  const handleToggle = () => {
    setIsMenuOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document>) => {
    if (menuAnchorRef.current && menuAnchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setIsMenuOpen(false);
  };

  return (
    <Card className={className}>
      <CardHeader
        title={trainingProgram.name}
        subheader={
          <span>
            <FontAwesomeIcon icon={["fas", "clock"]} />
            &nbsp;&nbsp;{trainingProgram.durationMinutes} minutes
          </span>
        }
      />
      <CardContent className={classes.cardContent}>
        <Typography variant="body1">{trainingProgram.description}</Typography>
        {infoMessage && (
          <Paper className={classes.message}>
            <div className={classes.messageIcon}>
              <FontAwesomeIcon icon={["fas", "info-circle"]} />
            </div>
            <Typography variant="body1" className={classes.messageBody}>
              {infoMessage}
            </Typography>
          </Paper>
        )}
      </CardContent>
      <CardActions className={classes.cardActions}>
        <div className={classes.cardActionsLeft}>
          {isTrainingProgramInProgress ? (
            <>
              <ButtonGroup variant="contained" ref={menuAnchorRef} aria-label="split button">
                <Button variant="contained" color="primary" onClick={handleMenuItemClick()}>
                  Resume
                </Button>
                <Button
                  size="small"
                  color="primary"
                  aria-controls={isMenuOpen ? "split-button-menu" : undefined}
                  aria-expanded={isMenuOpen ? "true" : undefined}
                  aria-label="resume or restart"
                  aria-haspopup="menu"
                  onClick={handleToggle}
                >
                  <ArrowDropDownIcon />
                </Button>
              </ButtonGroup>
              <Popper open={isMenuOpen} anchorEl={menuAnchorRef.current} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id="split-button-menu">
                          <MenuItem onClick={handleMenuItemClick(true)}>Restart</MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </>
          ) : (
            <Button variant="contained" color="primary" onClick={handleMenuItemClick()}>
              Start
            </Button>
          )}
          {trainingProgramSessionState?.trainingProgramCompletedAt && (
            <Chip
              label={`Last Completed: ${formatIso8601String(
                trainingProgramSessionState.trainingProgramCompletedAt,
                "date",
                {
                  template: "MMM D, YYYY",
                },
              )}`}
              className={classes.completedChip}
            />
          )}
        </div>
        <Tooltip title={isExpanded ? "Hide Syllabus" : "Show Syllabus"}>
          <IconButton
            onClick={() => setIsExpanded(!isExpanded)}
            className={classes.expandIcon}
            style={{ transform: !isExpanded ? "rotate(0deg)" : "rotate(180deg)" }}
          >
            <ExpandMoreIcon />
          </IconButton>
        </Tooltip>
      </CardActions>
      <Collapse in={isExpanded} unmountOnExit>
        <CardContent>
          <Divider className={classes.componentsDivider} />
          {trainingProgram.components && (
            <TrainingProgramSyllabusStepper
              className={classes.components}
              stepIndex={currentTrainingProgramComponentIndex}
              stepItems={trainingProgram.components.map((component) => ({
                type: component.document
                  ? "document"
                  : component.video
                  ? "video"
                  : component.quiz
                  ? "quiz"
                  : "completion",
                description: component.name,
              }))}
            />
          )}
        </CardContent>
      </Collapse>
    </Card>
  );
};

export const TrainingProgramCard = withStyles(styles)(TrainingProgramCardComponent);
