import { ApiError, getApiError } from "@interface48/api";
import { ActionStatus, AppThunkAction } from "@interface48/app";
import { TablePagingStateProps, TableSortingStateProps } from "@interface48/tables";
import moment from "moment";
import { Reducer } from "react";
import {
  QueryResultsPagingConfiguration,
  QueryResultsSortingConfiguration,
  ReviseTrainingProgramSessionCommand,
  TrainingProgramSessionResultDto,
  TrainingProgramSessionsQueryFilters,
  TrainingScorecardDto,
  UpdateTrainingScorecardCommand,
  reportingTrainingProgramSessionsApi,
  reportingTrainingScorecardApi,
} from "../api";
import { ApplicationState } from "./ApplicationState";

type ReportingActionType =
  | "REPORTING_TRAINING_PROGRAM_SESSIONS_QUERY_REQUEST"
  | "REPORTING_TRAINING_PROGRAM_SESSION_REVISION_REQUEST"
  | "REPORTING_TRAINING_SCORECARDS_REQUEST"
  | "REPORTING_TRAINING_SCORECARD_UPDATE_REQUEST";

export interface ReportingState {
  trainingProgramSessionsQuery: {
    filters: TrainingProgramSessionsQueryFilters;
    results: TrainingProgramSessionResultDto[];
    sorting: TableSortingStateProps;
    paging: TablePagingStateProps;
    isFiltersOpen: boolean;
  };
  trainingScorecards?: TrainingScorecardDto[];
  actionStatus: ActionStatus<ReportingActionType>;
}

interface TrainingProgramSessionQueryRequestAction {
  type: "REPORTING_TRAINING_PROGRAM_SESSIONS_QUERY_REQUEST";
}

interface TrainingProgramSessionQueryRequestSuccessAction {
  type: "REPORTING_TRAINING_PROGRAM_SESSIONS_QUERY_REQUEST_SUCCESS";
  filters: TrainingProgramSessionsQueryFilters;
  results: TrainingProgramSessionResultDto[];
  sorting: TableSortingStateProps;
  paging: TablePagingStateProps;
}

interface TrainingProgramSessionQueryRequestFailureAction {
  type: "REPORTING_TRAINING_PROGRAM_SESSIONS_QUERY_REQUEST_FAILURE";
  error: ApiError;
}

interface TrainingProgramSessionQueryFiltersDisplayUpdateAction {
  type: "REPORTING_TRAINING_PROGRAM_SESSIONS_QUERY_FILTERS_DISPLAY_UPDATE";
  isFiltersOpen: boolean;
}

interface TrainingProgramSessionRevisionAction {
  type: "REPORTING_TRAINING_PROGRAM_SESSION_REVISION_REQUEST";
}

interface TrainingProgramSessionRevisionSuccessAction {
  type: "REPORTING_TRAINING_PROGRAM_SESSION_REVISION_REQUEST_SUCCESS";
}

interface TrainingProgramSessionRevisionFailureAction {
  type: "REPORTING_TRAINING_PROGRAM_SESSION_REVISION_REQUEST_FAILURE";
  error: ApiError;
}

interface TrainingScorecardsRequestAction {
  type: "REPORTING_TRAINING_SCORECARDS_REQUEST";
}

interface TrainingScorecardsRequestSuccessAction {
  type: "REPORTING_TRAINING_SCORECARDS_REQUEST_SUCCESS";
  trainingScorecards: TrainingScorecardDto[];
}

interface TrainingScorecardsRequestFailureAction {
  type: "REPORTING_TRAINING_SCORECARDS_REQUEST_FAILURE";
  error: ApiError;
}

interface TrainingScorecardUpdateAction {
  type: "REPORTING_TRAINING_SCORECARD_UPDATE_REQUEST";
}

interface TrainingScorecardUpdateSuccessAction {
  type: "REPORTING_TRAINING_SCORECARD_UPDATE_REQUEST_SUCCESS";
}

interface TrainingScorecardUpdateFailureAction {
  type: "REPORTING_TRAINING_SCORECARD_UPDATE_REQUEST_FAILURE";
  error: ApiError;
}

type KnownAction =
  | TrainingProgramSessionQueryRequestAction
  | TrainingProgramSessionQueryRequestSuccessAction
  | TrainingProgramSessionQueryRequestFailureAction
  | TrainingProgramSessionQueryFiltersDisplayUpdateAction
  | TrainingProgramSessionRevisionAction
  | TrainingProgramSessionRevisionSuccessAction
  | TrainingProgramSessionRevisionFailureAction
  | TrainingScorecardsRequestAction
  | TrainingScorecardsRequestSuccessAction
  | TrainingScorecardsRequestFailureAction
  | TrainingScorecardUpdateAction
  | TrainingScorecardUpdateSuccessAction
  | TrainingScorecardUpdateFailureAction;

const processTrainingProgramSessionsQuery = async (
  dispatch: (action: KnownAction) => void,
  filters: TrainingProgramSessionsQueryFilters,
  sorting: QueryResultsSortingConfiguration,
  paging: QueryResultsPagingConfiguration,
) => {
  dispatch({ type: "REPORTING_TRAINING_PROGRAM_SESSIONS_QUERY_REQUEST" });

  try {
    const trainingProgramSessionsQueryResult = await reportingTrainingProgramSessionsApi.getTrainingProgramSessions(
      filters,
      sorting,
      paging,
    );

    dispatch({
      type: "REPORTING_TRAINING_PROGRAM_SESSIONS_QUERY_REQUEST_SUCCESS",
      filters: trainingProgramSessionsQueryResult.filters,
      results: trainingProgramSessionsQueryResult.results,
      sorting: {
        sortPropertyName: trainingProgramSessionsQueryResult.sorting.sortPropertyName,
        sortDirection: trainingProgramSessionsQueryResult.sorting.sortDirection as "asc" | "desc",
      },
      paging: {
        pageIndex: trainingProgramSessionsQueryResult.paging.pageIndex,
        pageSize: trainingProgramSessionsQueryResult.paging.pageSize,
        totalCount: trainingProgramSessionsQueryResult.totalResultsCount,
      },
    });
  } catch (errorResponse) {
    const error = await getApiError(errorResponse);

    dispatch({ type: "REPORTING_TRAINING_PROGRAM_SESSIONS_QUERY_REQUEST_FAILURE", error });
  }
};

export const actionCreators = {
  requestTrainingProgramSessionsQuery:
    (): AppThunkAction<KnownAction, ApplicationState> => async (dispatch, getState) => {
      const { filters, sorting, paging } = getState().reporting.trainingProgramSessionsQuery;

      await processTrainingProgramSessionsQuery(dispatch, filters, sorting, paging);
    },

  updateTrainingProgramSessionsQueryFiltersDisplay: (isFiltersOpen: boolean): KnownAction => ({
    type: "REPORTING_TRAINING_PROGRAM_SESSIONS_QUERY_FILTERS_DISPLAY_UPDATE",
    isFiltersOpen,
  }),

  updateTrainingProgramSessionsQueryFilters:
    (
      filters: TrainingProgramSessionsQueryFilters,
      resetPaging?: boolean,
    ): AppThunkAction<KnownAction, ApplicationState> =>
    async (dispatch, getState) => {
      const { sorting, paging } = getState().reporting.trainingProgramSessionsQuery;

      await processTrainingProgramSessionsQuery(
        dispatch,
        filters,
        sorting,
        resetPaging ? { ...paging, pageIndex: 0 } : paging,
      );
    },

  updateTrainingProgramSessionsQueryResultsSorting:
    (sorting: QueryResultsSortingConfiguration): AppThunkAction<KnownAction, ApplicationState> =>
    async (dispatch, getState) => {
      const { filters, paging } = getState().reporting.trainingProgramSessionsQuery;

      await processTrainingProgramSessionsQuery(dispatch, filters, sorting, paging);
    },

  updateTrainingProgramSessionsQueryResultsPaging:
    (paging: QueryResultsPagingConfiguration): AppThunkAction<KnownAction, ApplicationState> =>
    async (dispatch, getState) => {
      const { filters, sorting } = getState().reporting.trainingProgramSessionsQuery;

      await processTrainingProgramSessionsQuery(dispatch, filters, sorting, paging);
    },

  requestTrainingProgramSessionRevision:
    (
      id: string,
      reviseTrainingProgramSessionCommand: ReviseTrainingProgramSessionCommand,
    ): AppThunkAction<KnownAction, ApplicationState> =>
    async (dispatch, getState) => {
      dispatch({ type: "REPORTING_TRAINING_PROGRAM_SESSION_REVISION_REQUEST" });

      try {
        await reportingTrainingProgramSessionsApi.reviseTrainingProgramSession(id, reviseTrainingProgramSessionCommand);

        dispatch({ type: "REPORTING_TRAINING_PROGRAM_SESSION_REVISION_REQUEST_SUCCESS" });
      } catch (errorResponse) {
        const error = await getApiError(errorResponse);

        dispatch({ type: "REPORTING_TRAINING_PROGRAM_SESSION_REVISION_REQUEST_FAILURE", error });
      }
    },

  requestTrainingScorecardsQuery: (): AppThunkAction<KnownAction, ApplicationState> => async (dispatch, getState) => {
    dispatch({ type: "REPORTING_TRAINING_SCORECARDS_REQUEST" });

    try {
      const trainingScorecards = await reportingTrainingScorecardApi.getTrainingScorecards();

      dispatch({
        type: "REPORTING_TRAINING_SCORECARDS_REQUEST_SUCCESS",
        trainingScorecards,
      });
    } catch (errorResponse) {
      const error = await getApiError(errorResponse);

      dispatch({ type: "REPORTING_TRAINING_SCORECARDS_REQUEST_FAILURE", error });
    }
  },

  requestTrainingScorecardUpdate:
    (id: string, command: UpdateTrainingScorecardCommand): AppThunkAction<KnownAction, ApplicationState> =>
    async (dispatch, getState) => {
      dispatch({ type: "REPORTING_TRAINING_SCORECARD_UPDATE_REQUEST" });

      try {
        await reportingTrainingScorecardApi.updateTrainingScorecard(id, command);

        dispatch({ type: "REPORTING_TRAINING_SCORECARD_UPDATE_REQUEST_SUCCESS" });
      } catch (errorResponse) {
        const error = await getApiError(errorResponse);

        dispatch({ type: "REPORTING_TRAINING_SCORECARD_UPDATE_REQUEST_FAILURE", error });
      }
    },
};

export const initialState: ReportingState = {
  trainingProgramSessionsQuery: {
    filters: {
      completedAnytime: true,
      completedAtBegin: moment().startOf("day").subtract(1, "months").format("YYYY-MM-DD"),
      completedAtEnd: moment().startOf("day").add(1, "day").format("YYYY-MM-DD"),
    },
    results: [],
    sorting: {
      sortPropertyName: "trainingProgramCompletedAt",
      sortDirection: "desc",
    },
    paging: {
      pageIndex: 0,
      pageSize: 25,
      totalCount: 0,
    },
    isFiltersOpen: false,
  },
  actionStatus: {
    pending: false,
  },
};

export const reducer: Reducer<ReportingState, KnownAction> = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case "REPORTING_TRAINING_PROGRAM_SESSIONS_QUERY_REQUEST":
    case "REPORTING_TRAINING_SCORECARDS_REQUEST":
      state = {
        ...state,
        actionStatus: {
          type: action.type,
          pending: true,
        },
      };
      break;

    case "REPORTING_TRAINING_PROGRAM_SESSIONS_QUERY_FILTERS_DISPLAY_UPDATE":
      state = {
        ...state,
        trainingProgramSessionsQuery: {
          ...state.trainingProgramSessionsQuery,
          isFiltersOpen: action.isFiltersOpen,
        },
      };
      break;

    case "REPORTING_TRAINING_PROGRAM_SESSIONS_QUERY_REQUEST_SUCCESS":
      state = {
        ...state,
        trainingProgramSessionsQuery: {
          ...state.trainingProgramSessionsQuery,
          filters: action.filters,
          results: action.results,
          sorting: action.sorting,
          paging: action.paging,
        },
        actionStatus: {
          ...state.actionStatus,
          pending: false,
        },
      };
      break;

    case "REPORTING_TRAINING_SCORECARDS_REQUEST_SUCCESS":
      state = {
        ...state,
        trainingScorecards: action.trainingScorecards,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
        },
      };
      break;

    case "REPORTING_TRAINING_SCORECARDS_REQUEST_FAILURE":
    case "REPORTING_TRAINING_PROGRAM_SESSIONS_QUERY_REQUEST_FAILURE":
      state = {
        ...state,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
          error: action.error,
        },
      };
      break;

    case "REPORTING_TRAINING_SCORECARD_UPDATE_REQUEST":
    case "REPORTING_TRAINING_PROGRAM_SESSION_REVISION_REQUEST":
      state = {
        ...state,
        actionStatus: {
          type: action.type,
          pending: true,
        },
      };
      break;

    case "REPORTING_TRAINING_SCORECARD_UPDATE_REQUEST_SUCCESS":
    case "REPORTING_TRAINING_PROGRAM_SESSION_REVISION_REQUEST_SUCCESS":
      state = {
        ...state,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
        },
      };
      break;

    case "REPORTING_TRAINING_SCORECARD_UPDATE_REQUEST_FAILURE":
    case "REPORTING_TRAINING_PROGRAM_SESSION_REVISION_REQUEST_FAILURE":
      state = {
        ...state,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
          error: action.error,
        },
      };
      break;
  }

  return state;
};
