import { AppActions, AppState, baseActionCreators, isUserSignedIn, OidcState } from "@interface48/app";
import queryString from "query-string";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { bindActionCreators } from "redux";
import {
  CompleteTrainingProgramSessionCommand,
  TrainingProgramSessionQuizResultDto,
  TrainingProgramSessionStateDto,
  TrainingProgramSummaryDto,
} from "../../api";
import { CourseSessionState } from "../../components/courses";
import { QuizSessionState } from "../../components/quizzes";
import { Sidebar } from "../../components/sidebar";
import {
  TrainingProgramSession,
  TrainingProgramSessionCompletionFormData,
  TrainingProgramSessionViewer,
} from "../../components/training-program-sessions";
import { TrainingProgramSelector } from "../../components/training-programs";
import {
  actionCreators,
  ApplicationState,
  TrainingProgramActions,
  TrainingProgramState,
  UIActions,
  UIState,
} from "../../store";

interface TrainingProgramSessionPageMatchParamsProps {
  trainingProgramSlug?: string;
}

interface TrainingProgramSessionPageStateProps {
  appState: AppState;
  oidcState: OidcState;
  trainingProgramState: TrainingProgramState;
  uiState: UIState;
}

interface TrainingProgramSessionPageDispatchProps {
  appActions: AppActions;
  trainingProgramActions: TrainingProgramActions;
  uiActions: UIActions;
}

interface TrainingProgramSessionPageOwnProps extends RouteComponentProps<TrainingProgramSessionPageMatchParamsProps> {}

type TrainingProgramSessionPageProps = TrainingProgramSessionPageStateProps &
  TrainingProgramSessionPageDispatchProps &
  TrainingProgramSessionPageOwnProps;

interface TrainingProgramSessionPageState {
  selectedTrainingProgram?: TrainingProgramSummaryDto;
  quizResults?: TrainingProgramSessionQuizResultDto[];
  viewerAvailableHeight: number;
}

export const TrainingProgramSessionPageComponent = class extends React.Component<
  TrainingProgramSessionPageProps,
  TrainingProgramSessionPageState
> {
  constructor(props: TrainingProgramSessionPageProps) {
    super(props);

    const viewerAvailableHeight = this.getViewerAvailableHeight();

    this.state = {
      viewerAvailableHeight,
    };
  }

  public componentDidMount() {
    const { trainingProgramActions, uiActions } = this.props;

    trainingProgramActions.requestTrainingPrograms();

    uiActions.updateSidebarDisplay(true);
  }

  public render() {
    const { uiState, trainingProgramState, history } = this.props;
    const { sidebar } = uiState;
    const { trainingProgramSession, trainingPrograms, trainingProgramSessionStates } = trainingProgramState;
    const { selectedTrainingProgram, viewerAvailableHeight } = this.state;

    const sidebarOpen = sidebar.open;
    const viewerAvailableWidth = window.innerWidth - (sidebar.open ? 375 : 0);

    const trainingProgramSessionCompletionResultPending =
      trainingProgramState.actionStatus.type === "TRAINING_PROGRAM_SESSION_COMPLETION_REQUEST"
        ? trainingProgramState.actionStatus.pending
        : false;

    const trainingProgramSlug = this.getTrainingProgramSlug();
    const trainingProgramLoading = !trainingPrograms && trainingProgramSlug != null && !selectedTrainingProgram;

    const trainingProgramSessionStateLoading = this.isTrainingProgramSessionLoading(
      trainingProgramSlug,
      selectedTrainingProgram,
      trainingProgramSession,
      trainingProgramSessionStates,
    );

    const validTrainingProgramSession =
      trainingProgramSession &&
      selectedTrainingProgram &&
      trainingProgramSession.trainingProgramMajorVersion === selectedTrainingProgram.majorVersion
        ? trainingProgramSession
        : undefined;

    return (
      <>
        <Sidebar history={history}>
          <TrainingProgramSelector
            trainingPrograms={trainingPrograms}
            trainingProgramSlug={selectedTrainingProgram && selectedTrainingProgram.slug}
            trainingProgramSession={validTrainingProgramSession}
            isLoading={trainingProgramLoading || trainingProgramSessionStateLoading}
            onTrainingProgramChange={this.handleTrainingProgramChange}
            onTrainingProgramStart={this.handleTrainingProgramStart}
          />
        </Sidebar>
        <TrainingProgramSessionViewer
          availableWidth={viewerAvailableWidth}
          availableHeight={viewerAvailableHeight}
          sidebarOpen={sidebarOpen}
          trainingProgramSession={validTrainingProgramSession}
          isUserSignedIn={isUserSignedIn()}
          isLoading={trainingProgramLoading || trainingProgramSessionStateLoading}
          isTrainingProgramSessionSubmitting={trainingProgramSessionCompletionResultPending}
          onReload={this.handleTrainingProgramSessionReload}
          onStepChange={this.handleTrainingProgramSessionStepChange}
          onProgressChange={this.handleTrainingProgramSessionProgressChange}
          onCourseSessionStart={this.handleTrainingProgramSessionCourseStart}
          onCourseSessionStateChange={this.handleTrainingProgramSessionCourseStateChange}
          onQuizSessionStateChange={this.handleTrainingProgramSessionQuizStateChange}
          onCompletionFormChange={this.handleTrainingProgramSessionCompletionFormChange}
          onSubmit={this.handleTrainingProgramSessionSubmit}
          onError={this.handleTrainingProgramSessionError}
        />
      </>
    );
  }

  public componentDidUpdate(prevProps: TrainingProgramSessionPageProps, prevState: TrainingProgramSessionPageState) {
    const { appState: prevAppState, trainingProgramState: prevTrainingProgramState } = prevProps;
    const { trainingProgramSession: prevTrainingProgramSession, trainingPrograms: prevTrainingPrograms } =
      prevTrainingProgramState;
    const { appState, trainingProgramState, trainingProgramActions, appActions } = this.props;
    const { trainingProgramSession, trainingPrograms } = trainingProgramState;
    const { selectedTrainingProgram: prevSelectedTrainingProgram } = prevState;
    const { selectedTrainingProgram } = this.state;

    const prevTrainingProgramActionStatus = prevTrainingProgramState.actionStatus;
    const trainingProgramActionStatus = trainingProgramState.actionStatus;

    if (
      prevTrainingProgramActionStatus.error !== trainingProgramActionStatus.error &&
      trainingProgramActionStatus.error
    ) {
      const errorMessage =
        trainingProgramActionStatus.error.message ||
        "An unexpected error occurred upon attempting to process the last operation.";

      appActions.postSnackbarMessage(errorMessage, { variant: "error" });
    } else {
      if (prevTrainingPrograms !== trainingPrograms && trainingPrograms) {
        const trainingProgramSlugFromQueryString = this.getTrainingProgramSlug();

        if (trainingProgramSlugFromQueryString) {
          const trainingProgram = trainingPrograms?.find((tp) => tp.slug === trainingProgramSlugFromQueryString);

          if (trainingProgram) {
            this.setState({ ...this.state, selectedTrainingProgram: trainingProgram });
          }
        }
      }
    }

    // If the selected Training Program has changed...
    if (prevSelectedTrainingProgram !== selectedTrainingProgram) {
      if (selectedTrainingProgram) {
        trainingProgramActions.requestTrainingProgramSessionState(selectedTrainingProgram.slug);
      }
    }

    const prevAppFrameContentHeight = prevAppState.appFrame.contentHeight;
    const appFrameContentHeight = appState.appFrame.contentHeight;
    const prevTrainingProgramSessionBeganAt = prevTrainingProgramSession && prevTrainingProgramSession.beganAt;
    const trainingProgramSessionBeganAt = trainingProgramSession && trainingProgramSession.beganAt;
    // If the window height has changed, or display of the Trainining Program stepper has been toggled (i.e. a session
    // has begun or ended)...
    if (
      prevAppFrameContentHeight !== appFrameContentHeight ||
      prevTrainingProgramSessionBeganAt !== trainingProgramSessionBeganAt
    ) {
      const viewerAvailableHeight = this.getViewerAvailableHeight();

      this.setState({ ...this.state, viewerAvailableHeight });
    }
  }

  public componentWillUnmount() {
    const { trainingProgramActions } = this.props;

    trainingProgramActions.removeTrainingProgramSession();
  }

  private handleTrainingProgramChange = (value: string | number | null | undefined) => {
    const { trainingProgramState, history } = this.props;
    const { trainingPrograms } = trainingProgramState;

    if (value) {
      history.replace(`/training-program?name=${value}`);
    } else {
      history.replace(`/training-program`);
    }

    const selectedTrainingProgram = trainingPrograms?.find((tp) => tp.slug === value);

    this.setState({ ...this.state, selectedTrainingProgram });
  };

  private handleTrainingProgramStart = () => {
    const { trainingProgramActions } = this.props;
    const { selectedTrainingProgram } = this.state;

    if (!selectedTrainingProgram) {
      throw Error();
    }

    trainingProgramActions.startTrainingProgramSession(selectedTrainingProgram.version);
  };

  private handleTrainingProgramSessionReload = () => {
    const { trainingProgramActions } = this.props;
    const { selectedTrainingProgram } = this.state;

    if (selectedTrainingProgram) {
      trainingProgramActions.requestTrainingProgramSessionState(selectedTrainingProgram.slug);
    }
  };

  private handleTrainingProgramSessionStepChange = (stepIndex: number) => {
    const { trainingProgramActions } = this.props;

    trainingProgramActions.updateTrainingProgramSessionStep(stepIndex);
  };

  private handleTrainingProgramSessionProgressChange = (stepIndex: number, stepProgress: number) => {
    const { trainingProgramActions } = this.props;

    trainingProgramActions.updateTrainingProgramSessionStep(stepIndex, stepProgress);
  };

  private handleTrainingProgramSessionCourseStart = () => {
    const { uiActions } = this.props;

    uiActions.updateSidebarDisplay(false);
  };

  private handleTrainingProgramSessionCourseStateChange = (courseSessionState: CourseSessionState) => {
    const { trainingProgramActions } = this.props;

    trainingProgramActions.addOrUpdateTrainingProgramSessionCourse(courseSessionState);
  };

  private handleTrainingProgramSessionQuizStateChange = (quizSessionState: QuizSessionState) => {
    const { trainingProgramActions } = this.props;

    trainingProgramActions.addOrUpdateTrainingProgramSessionQuiz(quizSessionState);
  };

  private handleTrainingProgramSessionCompletionFormChange = (
    trainingProgramSessionCompletionFormData: TrainingProgramSessionCompletionFormData,
  ) => {
    const { trainingProgramActions } = this.props;

    trainingProgramActions.updateTrainingProgramSessionCompletionForm(trainingProgramSessionCompletionFormData);
  };

  private handleTrainingProgramSessionSubmit = (
    completeTrainingProgramSessionCommand: CompleteTrainingProgramSessionCommand,
  ) => {
    const { trainingProgramActions } = this.props;

    trainingProgramActions.requestTrainingProgramSessionCompletion(completeTrainingProgramSessionCommand);
  };

  private handleTrainingProgramSessionError = (errorMessage: string) => {
    const { appActions } = this.props;

    appActions.postSnackbarMessage(errorMessage, { variant: "error" });
  };

  private isTrainingProgramSessionLoading = (
    trainingProgramSlug: string | undefined,
    selectedTrainingProgram: TrainingProgramSummaryDto | undefined,
    trainingProgramSession: TrainingProgramSession | undefined,
    trainingProgramSessionStates: TrainingProgramSessionStateDto[] | undefined,
  ) => {
    const { oidcState } = this.props;

    const isUserSignedIn = oidcState.isLoadingUser || !!oidcState.user;

    // If no Training Program is selected, then there's no session to load...
    if (!trainingProgramSlug) {
      return false;
    }
    // Otherwise, if there is a session to load, but we have not yet loaded the underlying selected Training Program
    // and Session States for signed-in user, we are still loading...
    else if (!selectedTrainingProgram || (isUserSignedIn && !trainingProgramSessionStates)) {
      return true;
    }

    return selectedTrainingProgram.id !== trainingProgramSession?.trainingProgramId;
  };

  private getTrainingProgramSlug = () => {
    const { location } = this.props;

    const queryStringParameters = queryString.parse(location.search);

    if (queryStringParameters && queryStringParameters.name) {
      if (!Array.isArray(queryStringParameters.name)) {
        const trainingProgramSlug = queryStringParameters.name;

        return trainingProgramSlug;
      } else {
        throw Error("'name' query string parameter unexpectedly has multiple values.");
      }
    }
  };

  private getViewerAvailableHeight = () => {
    const { appState } = this.props;

    let viewerAvailableHeight = appState.appFrame.contentHeight;

    const trainingProgramStepper = document.getElementById("training-program-stepper");
    if (trainingProgramStepper) {
      viewerAvailableHeight -= trainingProgramStepper.clientHeight;
    }

    return viewerAvailableHeight;
  };
};

export const TrainingProgramSessionPage = connect<
  TrainingProgramSessionPageStateProps,
  TrainingProgramSessionPageDispatchProps,
  TrainingProgramSessionPageOwnProps,
  ApplicationState
>(
  (state) => ({
    appState: state.app,
    oidcState: state.oidc,
    trainingProgramState: state.trainingProgram,
    uiState: state.ui,
  }),
  (dispatch) => ({
    appActions: bindActionCreators(baseActionCreators.app, dispatch),
    trainingProgramActions: bindActionCreators(actionCreators.trainingProgram, dispatch),
    uiActions: bindActionCreators(actionCreators.ui, dispatch),
  }),
)(TrainingProgramSessionPageComponent);
