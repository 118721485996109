import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Theme, WithStyles, createStyles, withStyles } from "@material-ui/core/styles";
import React from "react";
import { QuizResultDto, QuizTemplateDto, quizzesApi } from "../../../api";
import { QuizSessionForm, toGetQuizResultQuery, toQuizSessionState } from "../forms";
import { QuizSessionState } from "../models";
import { QuizResultSummary } from "../results";

const styles = (theme: Theme) =>
  createStyles({
    messageContainer: {
      maxWidth: 900,
      margin: `${theme.spacing(2)}px auto`,
      padding: `0 ${theme.spacing(2)}px`,
    },
    message: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.secondary.main,
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
    },
    messageIcon: {
      paddingRight: theme.spacing(1),
      color: theme.palette.common.white,
    },
    messageBody: {
      color: theme.palette.common.white,
    },
    formContainer: {
      position: "relative",
      overflowY: "scroll",
    },
    form: {
      maxWidth: 900,
      margin: "0 auto",
      padding: theme.spacing(2),
      "& label": {
        lineHeight: theme.typography.body1.lineHeight,
        fontWeight: 600,
      },
      "& .field-object h5": {
        marginTop: 0,
      },
      "& .field-object img": {
        display: "block",
        maxWidth: "100%",
        marginTop: theme.spacing(1),
      },
    },
    resultSummary: {
      borderRadius: theme.shape.borderRadius,
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
    },
  });

interface QuizViewerProps extends WithStyles<typeof styles> {
  availableHeight: number;
  quiz: QuizTemplateDto;
  quizSessionState?: QuizSessionState;
  isPreviewMode?: boolean;
  onChange: (sessionState: QuizSessionState, isCompleted: boolean) => void;
  onResultChange: (resultOutcome?: "obsolete" | "passed") => void;
  onSubmit: (sessionState: QuizSessionState) => void;
}

interface QuizViewerState {
  result?: QuizResultDto;
  resultOutcome?: "obsolete" | "passed";
}

export const QuizViewerComponent = class extends React.Component<QuizViewerProps, QuizViewerState> {
  constructor(props: QuizViewerProps) {
    super(props);
    const { quiz, quizSessionState } = props;

    this.state = {
      resultOutcome: !!quizSessionState && quiz.version !== quizSessionState.quizVersion ? "obsolete" : undefined,
    };
  }

  public componentDidMount() {
    this.validateQuizVersion();
  }

  public render() {
    const { availableHeight, quiz, quizSessionState, classes, isPreviewMode, onSubmit } = this.props;
    const { result, resultOutcome } = this.state;

    return quiz && quizSessionState ? (
      <div id="form-container" className={classes.formContainer} style={{ height: availableHeight }}>
        {resultOutcome === "obsolete" && (
          <div className={classes.messageContainer}>
            <Paper className={classes.message}>
              <div className={classes.messageIcon}>
                <FontAwesomeIcon icon={["fas", "info-circle"]} />
              </div>
              <Typography variant="body1" className={classes.messageBody}>
                You must re-take this Quiz, as it has changed since you completed it.
              </Typography>
            </Paper>
          </div>
        )}
        <QuizSessionForm
          key={`${quiz.id}-${quiz.version}`}
          scrollContainerElementId={"form-container"}
          className={classes.form}
          quiz={quiz}
          quizSessionState={quizSessionState}
          isPassed={resultOutcome === "passed"}
          isPreviewMode={isPreviewMode}
          onChange={this.handleChange}
          onCompletionChange={this.handleCompletionChange}
          onSubmit={onSubmit}
        >
          {result ? (
            <Paper className={classes.resultSummary}>
              <QuizResultSummary quizResult={result} showIncorrectQuestionNumbersMessage={true} />
            </Paper>
          ) : undefined}
        </QuizSessionForm>
      </div>
    ) : (
      <Typography variant={"body2"}>Loading Quiz...</Typography>
    );
  }

  public componentDidUpdate(prevProps: QuizViewerProps, prevState: QuizViewerState) {
    const { quiz: prevQuiz, quizSessionState: prevFormData } = prevProps;
    const { quiz, quizSessionState, onResultChange } = this.props;
    const { resultOutcome: prevResultOutcome } = prevState;
    const { resultOutcome } = this.state;

    if (!(prevQuiz === quiz && prevFormData === quizSessionState)) {
      this.validateQuizVersion();
    }

    if (prevResultOutcome !== resultOutcome) {
      onResultChange(resultOutcome);
    }
  }

  private handleChange = async (formData: QuizSessionState, isCompleted: boolean) => {
    const { onChange } = this.props;
    const { resultOutcome } = this.state;

    if (isCompleted && formData.completedAt) {
      this.getResult(formData);
    } else {
      if (this.state.result) {
        this.setState({ ...this.state, result: undefined });
      }
    }

    if (resultOutcome === "obsolete") {
      this.setState({ ...this.state, resultOutcome: undefined });
    }

    onChange(formData, isCompleted);
  };

  private handleCompletionChange = (isCompleted: boolean) => {
    const { quizSessionState } = this.props;
    const { resultOutcome } = this.state;

    // Do not fetch quiz results if obsolete
    // formData and isCompleted may not yet be reset because this handler is called
    // prior to validateQuizCersion in componentDidMount...
    if (isCompleted && quizSessionState && quizSessionState.completedAt && resultOutcome !== "obsolete") {
      this.getResult(quizSessionState);
    }
  };

  private getResult = async (quizSessionState: QuizSessionState) => {
    const resultQuery = toGetQuizResultQuery(quizSessionState);

    const result = await quizzesApi.getQuizResult(quizSessionState.quizId, quizSessionState.quizVersion, resultQuery);

    const resultOutcome = result.isPassResult ? "passed" : result.isObsoleteResult ? "obsolete" : undefined;

    this.setState({ ...this.state, result, resultOutcome });
  };

  private validateQuizVersion = () => {
    const { quizSessionState, quiz: quizTemplate, onChange } = this.props;

    if (quizSessionState && quizTemplate.version !== quizSessionState.quizVersion) {
      this.setState({ ...this.state, resultOutcome: "obsolete" });

      const resetFormData = toQuizSessionState(quizTemplate);

      onChange(resetFormData, false);
    }
  };
};

export const QuizViewer = withStyles(styles)(QuizViewerComponent);
