import { ApiError, getApiError } from "@interface48/api";
import { ActionStatus, AppThunkAction } from "@interface48/app";
import { Reducer } from "redux";
import {
  AddCourseCommand,
  AddDocumentCommand,
  AddQuizCommand,
  AddTrainingProgramCommand,
  AddVideoCommand,
  CourseDto,
  DocumentDto,
  QuizDto,
  TrainingProgramDto,
  UpdateCourseCommand,
  UpdateDocumentCommand,
  UpdateQuizCommand,
  UpdateTrainingProgramCommand,
  UpdateUserGroupCommand,
  UpdateVideoCommand,
  UserGroupDto,
  VideoDto,
  administrationCoursesApi,
  administrationDocumentsApi,
  administrationQuizzesApi,
  administrationTrainingProgramsApi,
  administrationUserGroupsApi,
  administrationVideosApi,
} from "../api";
import { ApplicationState } from "./ApplicationState";

type AdministrationActionType =
  | "ADMINISTRATION_COURSES_REQUEST"
  | "ADMINISTRATION_COURSE_REQUEST"
  | "ADMINISTRATION_COURSE_ADD_REQUEST"
  | "ADMINISTRATION_COURSE_UPDATE_REQUEST"
  | "ADMINISTRATION_COURSE_DELETE_REQUEST"
  | "ADMINISTRATION_DOCUMENTS_REQUEST"
  | "ADMINISTRATION_DOCUMENT_REQUEST"
  | "ADMINISTRATION_DOCUMENT_ADD_REQUEST"
  | "ADMINISTRATION_DOCUMENT_UPDATE_REQUEST"
  | "ADMINISTRATION_DOCUMENT_DELETE_REQUEST"
  | "ADMINISTRATION_QUIZZES_REQUEST"
  | "ADMINISTRATION_QUIZ_REQUEST"
  | "ADMINISTRATION_QUIZ_ADD_REQUEST"
  | "ADMINISTRATION_QUIZ_UPDATE_REQUEST"
  | "ADMINISTRATION_QUIZ_DELETE_REQUEST"
  | "ADMINISTRATION_TRAINING_PROGRAMS_REQUEST"
  | "ADMINISTRATION_TRAINING_PROGRAM_REQUEST"
  | "ADMINISTRATION_TRAINING_PROGRAM_ADD_REQUEST"
  | "ADMINISTRATION_TRAINING_PROGRAM_UPDATE_REQUEST"
  | "ADMINISTRATION_TRAINING_PROGRAM_DELETE_REQUEST"
  | "ADMINISTRATION_USER_GROUPS_REQUEST"
  | "ADMINISTRATION_USER_GROUP_REQUEST"
  | "ADMINISTRATION_USER_GROUP_UPDATE_REQUEST"
  | "ADMINISTRATION_VIDEOS_REQUEST"
  | "ADMINISTRATION_VIDEO_REQUEST"
  | "ADMINISTRATION_VIDEO_ADD_REQUEST"
  | "ADMINISTRATION_VIDEO_UPDATE_REQUEST"
  | "ADMINISTRATION_VIDEO_DELETE_REQUEST";

export interface AdministrationState {
  course?: CourseDto;
  courses?: CourseDto[];
  document?: DocumentDto;
  documents?: DocumentDto[];
  quiz?: QuizDto;
  quizzes?: QuizDto[];
  trainingProgram?: TrainingProgramDto;
  trainingPrograms?: TrainingProgramDto[];
  userGroup?: UserGroupDto;
  userGroups?: UserGroupDto[];
  video?: VideoDto;
  videos?: VideoDto[];
  actionStatus: ActionStatus<AdministrationActionType>;
}

interface CoursesRequestAction {
  type: "ADMINISTRATION_COURSES_REQUEST";
}

interface CoursesRequestSuccessAction {
  type: "ADMINISTRATION_COURSES_REQUEST_SUCCESS";
  courses: CourseDto[];
}

interface CoursesRequestFailureAction {
  type: "ADMINISTRATION_COURSES_REQUEST_FAILURE";
  error: ApiError;
}

interface CourseRequestAction {
  type: "ADMINISTRATION_COURSE_REQUEST";
}

interface CourseRequestSuccessAction {
  type: "ADMINISTRATION_COURSE_REQUEST_SUCCESS";
  course: CourseDto;
}

interface CourseRequestFailureAction {
  type: "ADMINISTRATION_COURSE_REQUEST_FAILURE";
  error: ApiError;
}

interface CourseAddRequestAction {
  type: "ADMINISTRATION_COURSE_ADD_REQUEST";
}

interface CourseAddRequestSuccessAction {
  type: "ADMINISTRATION_COURSE_ADD_REQUEST_SUCCESS";
  courseId: string;
}

interface CourseAddRequestFailureAction {
  type: "ADMINISTRATION_COURSE_ADD_REQUEST_FAILURE";
  error: ApiError;
}

interface CourseUpdateRequestAction {
  type: "ADMINISTRATION_COURSE_UPDATE_REQUEST";
}

interface CourseUpdateRequestSuccessAction {
  type: "ADMINISTRATION_COURSE_UPDATE_REQUEST_SUCCESS";
}

interface CourseUpdateRequestFailureAction {
  type: "ADMINISTRATION_COURSE_UPDATE_REQUEST_FAILURE";
  error: ApiError;
}

interface CourseDeleteRequestAction {
  type: "ADMINISTRATION_COURSE_DELETE_REQUEST";
}

interface CourseDeleteRequestSuccessAction {
  type: "ADMINISTRATION_COURSE_DELETE_REQUEST_SUCCESS";
}

interface CourseDeleteRequestFailureAction {
  type: "ADMINISTRATION_COURSE_DELETE_REQUEST_FAILURE";
  error: ApiError;
}

interface DocumentsRequestAction {
  type: "ADMINISTRATION_DOCUMENTS_REQUEST";
}

interface DocumentsRequestSuccessAction {
  type: "ADMINISTRATION_DOCUMENTS_REQUEST_SUCCESS";
  documents: DocumentDto[];
}

interface DocumentsRequestFailureAction {
  type: "ADMINISTRATION_DOCUMENTS_REQUEST_FAILURE";
  error: ApiError;
}

interface DocumentRequestAction {
  type: "ADMINISTRATION_DOCUMENT_REQUEST";
}

interface DocumentRequestSuccessAction {
  type: "ADMINISTRATION_DOCUMENT_REQUEST_SUCCESS";
  document: DocumentDto;
}

interface DocumentRequestFailureAction {
  type: "ADMINISTRATION_DOCUMENT_REQUEST_FAILURE";
  error: ApiError;
}

interface DocumentAddRequestAction {
  type: "ADMINISTRATION_DOCUMENT_ADD_REQUEST";
}

interface DocumentAddRequestSuccessAction {
  type: "ADMINISTRATION_DOCUMENT_ADD_REQUEST_SUCCESS";
  documentId: string;
}

interface DocumentAddRequestFailureAction {
  type: "ADMINISTRATION_DOCUMENT_ADD_REQUEST_FAILURE";
  error: ApiError;
}

interface DocumentUpdateRequestAction {
  type: "ADMINISTRATION_DOCUMENT_UPDATE_REQUEST";
}

interface DocumentUpdateRequestSuccessAction {
  type: "ADMINISTRATION_DOCUMENT_UPDATE_REQUEST_SUCCESS";
}

interface DocumentUpdateRequestFailureAction {
  type: "ADMINISTRATION_DOCUMENT_UPDATE_REQUEST_FAILURE";
  error: ApiError;
}

interface DocumentDeleteRequestAction {
  type: "ADMINISTRATION_DOCUMENT_DELETE_REQUEST";
}

interface DocumentDeleteRequestSuccessAction {
  type: "ADMINISTRATION_DOCUMENT_DELETE_REQUEST_SUCCESS";
}

interface DocumentDeleteRequestFailureAction {
  type: "ADMINISTRATION_DOCUMENT_DELETE_REQUEST_FAILURE";
  error: ApiError;
}

interface QuizzesRequestAction {
  type: "ADMINISTRATION_QUIZZES_REQUEST";
}

interface QuizzesRequestSuccessAction {
  type: "ADMINISTRATION_QUIZZES_REQUEST_SUCCESS";
  quizzes: QuizDto[];
}

interface QuizzesRequestFailureAction {
  type: "ADMINISTRATION_QUIZZES_REQUEST_FAILURE";
  error: ApiError;
}

interface QuizRequestAction {
  type: "ADMINISTRATION_QUIZ_REQUEST";
}

interface QuizRequestSuccessAction {
  type: "ADMINISTRATION_QUIZ_REQUEST_SUCCESS";
  quiz: QuizDto;
}

interface QuizRequestFailureAction {
  type: "ADMINISTRATION_QUIZ_REQUEST_FAILURE";
  error: ApiError;
}

interface QuizAddRequestAction {
  type: "ADMINISTRATION_QUIZ_ADD_REQUEST";
}

interface QuizAddRequestSuccessAction {
  type: "ADMINISTRATION_QUIZ_ADD_REQUEST_SUCCESS";
  quizId: string;
}

interface QuizAddRequestFailureAction {
  type: "ADMINISTRATION_QUIZ_ADD_REQUEST_FAILURE";
  error: ApiError;
}

interface QuizUpdateRequestAction {
  type: "ADMINISTRATION_QUIZ_UPDATE_REQUEST";
}

interface QuizUpdateRequestSuccessAction {
  type: "ADMINISTRATION_QUIZ_UPDATE_REQUEST_SUCCESS";
}

interface QuizUpdateRequestFailureAction {
  type: "ADMINISTRATION_QUIZ_UPDATE_REQUEST_FAILURE";
  error: ApiError;
}

interface QuizDeleteRequestAction {
  type: "ADMINISTRATION_QUIZ_DELETE_REQUEST";
}

interface QuizDeleteRequestSuccessAction {
  type: "ADMINISTRATION_QUIZ_DELETE_REQUEST_SUCCESS";
}

interface QuizDeleteRequestFailureAction {
  type: "ADMINISTRATION_QUIZ_DELETE_REQUEST_FAILURE";
  error: ApiError;
}

interface TrainingProgramsRequestAction {
  type: "ADMINISTRATION_TRAINING_PROGRAMS_REQUEST";
}

interface TrainingProgramsRequestSuccessAction {
  type: "ADMINISTRATION_TRAINING_PROGRAMS_REQUEST_SUCCESS";
  trainingPrograms: TrainingProgramDto[];
}

interface TrainingProgramsRequestFailureAction {
  type: "ADMINISTRATION_TRAINING_PROGRAMS_REQUEST_FAILURE";
  error: ApiError;
}

interface TrainingProgramRequestAction {
  type: "ADMINISTRATION_TRAINING_PROGRAM_REQUEST";
}

interface TrainingProgramRequestSuccessAction {
  type: "ADMINISTRATION_TRAINING_PROGRAM_REQUEST_SUCCESS";
  trainingProgram: TrainingProgramDto;
}

interface TrainingProgramRequestFailureAction {
  type: "ADMINISTRATION_TRAINING_PROGRAM_REQUEST_FAILURE";
  error: ApiError;
}

interface TrainingProgramAddRequestAction {
  type: "ADMINISTRATION_TRAINING_PROGRAM_ADD_REQUEST";
}

interface TrainingProgramAddRequestSuccessAction {
  type: "ADMINISTRATION_TRAINING_PROGRAM_ADD_REQUEST_SUCCESS";
  trainingProgramId: string;
}

interface TrainingProgramAddRequestFailureAction {
  type: "ADMINISTRATION_TRAINING_PROGRAM_ADD_REQUEST_FAILURE";
  error: ApiError;
}

interface TrainingProgramUpdateRequestAction {
  type: "ADMINISTRATION_TRAINING_PROGRAM_UPDATE_REQUEST";
}

interface TrainingProgramUpdateRequestSuccessAction {
  type: "ADMINISTRATION_TRAINING_PROGRAM_UPDATE_REQUEST_SUCCESS";
}

interface TrainingProgramUpdateRequestFailureAction {
  type: "ADMINISTRATION_TRAINING_PROGRAM_UPDATE_REQUEST_FAILURE";
  error: ApiError;
}

interface TrainingProgramDeleteRequestAction {
  type: "ADMINISTRATION_TRAINING_PROGRAM_DELETE_REQUEST";
}

interface TrainingProgramDeleteRequestSuccessAction {
  type: "ADMINISTRATION_TRAINING_PROGRAM_DELETE_REQUEST_SUCCESS";
}

interface TrainingProgramDeleteRequestFailureAction {
  type: "ADMINISTRATION_TRAINING_PROGRAM_DELETE_REQUEST_FAILURE";
  error: ApiError;
}

interface UserGroupsRequestAction {
  type: "ADMINISTRATION_USER_GROUPS_REQUEST";
}

interface UserGroupsRequestSuccessAction {
  type: "ADMINISTRATION_USER_GROUPS_REQUEST_SUCCESS";
  userGroups: UserGroupDto[];
}

interface UserGroupsRequestFailureAction {
  type: "ADMINISTRATION_USER_GROUPS_REQUEST_FAILURE";
  error: ApiError;
}

interface UserGroupRequestAction {
  type: "ADMINISTRATION_USER_GROUP_REQUEST";
}

interface UserGroupRequestSuccessAction {
  type: "ADMINISTRATION_USER_GROUP_REQUEST_SUCCESS";
  userGroup: UserGroupDto;
}

interface UserGroupRequestFailureAction {
  type: "ADMINISTRATION_USER_GROUP_REQUEST_FAILURE";
  error: ApiError;
}

interface UserGroupUpdateRequestAction {
  type: "ADMINISTRATION_USER_GROUP_UPDATE_REQUEST";
}

interface UserGroupUpdateRequestSuccessAction {
  type: "ADMINISTRATION_USER_GROUP_UPDATE_REQUEST_SUCCESS";
}

interface UserGroupUpdateRequestFailureAction {
  type: "ADMINISTRATION_USER_GROUP_UPDATE_REQUEST_FAILURE";
  error: ApiError;
}

interface VideosRequestAction {
  type: "ADMINISTRATION_VIDEOS_REQUEST";
}

interface VideosRequestSuccessAction {
  type: "ADMINISTRATION_VIDEOS_REQUEST_SUCCESS";
  videos: VideoDto[];
}

interface VideosRequestFailureAction {
  type: "ADMINISTRATION_VIDEOS_REQUEST_FAILURE";
  error: ApiError;
}

interface VideoRequestAction {
  type: "ADMINISTRATION_VIDEO_REQUEST";
}

interface VideoRequestSuccessAction {
  type: "ADMINISTRATION_VIDEO_REQUEST_SUCCESS";
  video: VideoDto;
}

interface VideoRequestFailureAction {
  type: "ADMINISTRATION_VIDEO_REQUEST_FAILURE";
  error: ApiError;
}

interface VideoAddRequestAction {
  type: "ADMINISTRATION_VIDEO_ADD_REQUEST";
}

interface VideoAddRequestSuccessAction {
  type: "ADMINISTRATION_VIDEO_ADD_REQUEST_SUCCESS";
  videoId: string;
}

interface VideoAddRequestFailureAction {
  type: "ADMINISTRATION_VIDEO_ADD_REQUEST_FAILURE";
  error: ApiError;
}

interface VideoUpdateRequestAction {
  type: "ADMINISTRATION_VIDEO_UPDATE_REQUEST";
}

interface VideoUpdateRequestSuccessAction {
  type: "ADMINISTRATION_VIDEO_UPDATE_REQUEST_SUCCESS";
}

interface VideoUpdateRequestFailureAction {
  type: "ADMINISTRATION_VIDEO_UPDATE_REQUEST_FAILURE";
  error: ApiError;
}

interface VideoDeleteRequestAction {
  type: "ADMINISTRATION_VIDEO_DELETE_REQUEST";
}

interface VideoDeleteRequestSuccessAction {
  type: "ADMINISTRATION_VIDEO_DELETE_REQUEST_SUCCESS";
}

interface VideoDeleteRequestFailureAction {
  type: "ADMINISTRATION_VIDEO_DELETE_REQUEST_FAILURE";
  error: ApiError;
}

type KnownAction =
  | CoursesRequestAction
  | CoursesRequestSuccessAction
  | CoursesRequestFailureAction
  | CourseRequestAction
  | CourseRequestSuccessAction
  | CourseRequestFailureAction
  | CourseAddRequestAction
  | CourseAddRequestSuccessAction
  | CourseAddRequestFailureAction
  | CourseUpdateRequestAction
  | CourseUpdateRequestSuccessAction
  | CourseUpdateRequestFailureAction
  | CourseDeleteRequestAction
  | CourseDeleteRequestSuccessAction
  | CourseDeleteRequestFailureAction
  | DocumentsRequestAction
  | DocumentsRequestSuccessAction
  | DocumentsRequestFailureAction
  | DocumentRequestAction
  | DocumentRequestSuccessAction
  | DocumentRequestFailureAction
  | DocumentAddRequestAction
  | DocumentAddRequestSuccessAction
  | DocumentAddRequestFailureAction
  | DocumentUpdateRequestAction
  | DocumentUpdateRequestSuccessAction
  | DocumentUpdateRequestFailureAction
  | DocumentDeleteRequestAction
  | DocumentDeleteRequestSuccessAction
  | DocumentDeleteRequestFailureAction
  | QuizzesRequestAction
  | QuizzesRequestSuccessAction
  | QuizzesRequestFailureAction
  | QuizRequestAction
  | QuizRequestSuccessAction
  | QuizRequestFailureAction
  | QuizAddRequestAction
  | QuizAddRequestSuccessAction
  | QuizAddRequestFailureAction
  | QuizUpdateRequestAction
  | QuizUpdateRequestSuccessAction
  | QuizUpdateRequestFailureAction
  | QuizDeleteRequestAction
  | QuizDeleteRequestSuccessAction
  | QuizDeleteRequestFailureAction
  | TrainingProgramsRequestAction
  | TrainingProgramsRequestSuccessAction
  | TrainingProgramsRequestFailureAction
  | TrainingProgramRequestAction
  | TrainingProgramRequestSuccessAction
  | TrainingProgramRequestFailureAction
  | TrainingProgramAddRequestAction
  | TrainingProgramAddRequestSuccessAction
  | TrainingProgramAddRequestFailureAction
  | TrainingProgramUpdateRequestAction
  | TrainingProgramUpdateRequestSuccessAction
  | TrainingProgramUpdateRequestFailureAction
  | TrainingProgramDeleteRequestAction
  | TrainingProgramDeleteRequestSuccessAction
  | TrainingProgramDeleteRequestFailureAction
  | UserGroupRequestAction
  | UserGroupsRequestSuccessAction
  | UserGroupsRequestFailureAction
  | UserGroupsRequestAction
  | UserGroupRequestSuccessAction
  | UserGroupRequestFailureAction
  | UserGroupUpdateRequestAction
  | UserGroupUpdateRequestSuccessAction
  | UserGroupUpdateRequestFailureAction
  | VideosRequestAction
  | VideosRequestSuccessAction
  | VideosRequestFailureAction
  | VideoRequestAction
  | VideoRequestSuccessAction
  | VideoRequestFailureAction
  | VideoAddRequestAction
  | VideoAddRequestSuccessAction
  | VideoAddRequestFailureAction
  | VideoUpdateRequestAction
  | VideoUpdateRequestSuccessAction
  | VideoUpdateRequestFailureAction
  | VideoDeleteRequestAction
  | VideoDeleteRequestSuccessAction
  | VideoDeleteRequestFailureAction;

export const actionCreators = {
  requestCourses: (): AppThunkAction<KnownAction, ApplicationState> => async (dispatch, getState) => {
    dispatch({ type: "ADMINISTRATION_COURSES_REQUEST" });

    try {
      const courses = await administrationCoursesApi.getCourses();

      dispatch({ type: "ADMINISTRATION_COURSES_REQUEST_SUCCESS", courses });
    } catch (errorResponse) {
      const error = await getApiError(errorResponse);

      dispatch({ type: "ADMINISTRATION_COURSES_REQUEST_FAILURE", error });
    }
  },

  requestCourse:
    (courseId: string): AppThunkAction<KnownAction, ApplicationState> =>
    async (dispatch, getState) => {
      dispatch({ type: "ADMINISTRATION_COURSE_REQUEST" });

      try {
        const course = await administrationCoursesApi.getCourse(courseId);

        dispatch({ type: "ADMINISTRATION_COURSE_REQUEST_SUCCESS", course });
      } catch (errorResponse) {
        const error = await getApiError(errorResponse);

        dispatch({ type: "ADMINISTRATION_COURSE_REQUEST_FAILURE", error });
      }
    },

  requestCourseAdd:
    (addCourseCommand: AddCourseCommand): AppThunkAction<KnownAction, ApplicationState> =>
    async (dispatch, getState) => {
      dispatch({ type: "ADMINISTRATION_COURSE_ADD_REQUEST" });

      try {
        const courseId = await administrationCoursesApi.addCourse(addCourseCommand);

        dispatch({ type: "ADMINISTRATION_COURSE_ADD_REQUEST_SUCCESS", courseId });
      } catch (errorResponse) {
        const error = await getApiError(errorResponse);

        dispatch({ type: "ADMINISTRATION_COURSE_ADD_REQUEST_FAILURE", error });
      }
    },

  requestCourseUpdate:
    (courseId: string, updateCourseCommand: UpdateCourseCommand): AppThunkAction<KnownAction, ApplicationState> =>
    async (dispatch, getState) => {
      dispatch({ type: "ADMINISTRATION_COURSE_UPDATE_REQUEST" });

      try {
        await administrationCoursesApi.updateCourse(courseId, updateCourseCommand);

        dispatch({ type: "ADMINISTRATION_COURSE_UPDATE_REQUEST_SUCCESS" });
      } catch (errorResponse) {
        const error = await getApiError(errorResponse);

        dispatch({ type: "ADMINISTRATION_COURSE_UPDATE_REQUEST_FAILURE", error });
      }
    },

  requestCourseDelete:
    (courseId: string, permanentlyDeleteFile: boolean): AppThunkAction<KnownAction, ApplicationState> =>
    async (dispatch, getState) => {
      dispatch({ type: "ADMINISTRATION_COURSE_DELETE_REQUEST" });

      try {
        await administrationCoursesApi.removeCourse(courseId, { permanentlyDeleteFile });

        dispatch({ type: "ADMINISTRATION_COURSE_DELETE_REQUEST_SUCCESS" });
      } catch (errorResponse) {
        const error = await getApiError(errorResponse);

        dispatch({ type: "ADMINISTRATION_COURSE_DELETE_REQUEST_FAILURE", error });
      }
    },

  requestDocuments: (): AppThunkAction<KnownAction, ApplicationState> => async (dispatch, getState) => {
    dispatch({ type: "ADMINISTRATION_DOCUMENTS_REQUEST" });

    try {
      const documents = await administrationDocumentsApi.getDocuments();

      dispatch({
        type: "ADMINISTRATION_DOCUMENTS_REQUEST_SUCCESS",
        documents,
      });
    } catch (errorResponse) {
      const error = await getApiError(errorResponse);

      dispatch({ type: "ADMINISTRATION_DOCUMENTS_REQUEST_FAILURE", error });
    }
  },

  requestDocument:
    (id: string): AppThunkAction<KnownAction, ApplicationState> =>
    async (dispatch, getState) => {
      dispatch({ type: "ADMINISTRATION_DOCUMENT_REQUEST" });

      try {
        const document = await administrationDocumentsApi.getDocument(id);

        dispatch({ type: "ADMINISTRATION_DOCUMENT_REQUEST_SUCCESS", document });
      } catch (errorResponse) {
        const error = await getApiError(errorResponse);

        dispatch({ type: "ADMINISTRATION_DOCUMENT_REQUEST_FAILURE", error });
      }
    },

  requestDocumentAdd:
    (addDocumentCommand: AddDocumentCommand): AppThunkAction<KnownAction, ApplicationState> =>
    async (dispatch, getState) => {
      dispatch({ type: "ADMINISTRATION_DOCUMENT_ADD_REQUEST" });

      try {
        const documentId = await administrationDocumentsApi.addDocument(addDocumentCommand);

        dispatch({ type: "ADMINISTRATION_DOCUMENT_ADD_REQUEST_SUCCESS", documentId });
      } catch (errorResponse) {
        const error = await getApiError(errorResponse);

        dispatch({ type: "ADMINISTRATION_DOCUMENT_ADD_REQUEST_FAILURE", error });
      }
    },

  requestDocumentUpdate:
    (documentId: string, updateDocumentCommand: UpdateDocumentCommand): AppThunkAction<KnownAction, ApplicationState> =>
    async (dispatch, getState) => {
      dispatch({ type: "ADMINISTRATION_DOCUMENT_UPDATE_REQUEST" });

      try {
        await administrationDocumentsApi.updateDocument(documentId, updateDocumentCommand);

        dispatch({ type: "ADMINISTRATION_DOCUMENT_UPDATE_REQUEST_SUCCESS" });
      } catch (errorResponse) {
        const error = await getApiError(errorResponse);

        dispatch({ type: "ADMINISTRATION_DOCUMENT_UPDATE_REQUEST_FAILURE", error });
      }
    },

  requestDocumentDelete:
    (documentId: string, permanentlyDeleteFile: boolean): AppThunkAction<KnownAction, ApplicationState> =>
    async (dispatch, getState) => {
      dispatch({ type: "ADMINISTRATION_DOCUMENT_DELETE_REQUEST" });

      try {
        await administrationDocumentsApi.removeDocument(documentId, { permanentlyDeleteFile });

        dispatch({ type: "ADMINISTRATION_DOCUMENT_DELETE_REQUEST_SUCCESS" });
      } catch (errorResponse) {
        const error = await getApiError(errorResponse);

        dispatch({ type: "ADMINISTRATION_DOCUMENT_DELETE_REQUEST_FAILURE", error });
      }
    },

  requestQuizzes: (): AppThunkAction<KnownAction, ApplicationState> => async (dispatch, getState) => {
    dispatch({ type: "ADMINISTRATION_QUIZZES_REQUEST" });

    try {
      const quizzes = await administrationQuizzesApi.getQuizzes();

      dispatch({
        type: "ADMINISTRATION_QUIZZES_REQUEST_SUCCESS",
        quizzes,
      });
    } catch (errorResponse) {
      const error = await getApiError(errorResponse);

      dispatch({ type: "ADMINISTRATION_QUIZZES_REQUEST_FAILURE", error });
    }
  },

  requestQuiz:
    (quizId: string): AppThunkAction<KnownAction, ApplicationState> =>
    async (dispatch, getState) => {
      dispatch({ type: "ADMINISTRATION_QUIZ_REQUEST" });

      try {
        const quiz = await administrationQuizzesApi.getQuiz(quizId);

        dispatch({ type: "ADMINISTRATION_QUIZ_REQUEST_SUCCESS", quiz });
      } catch (errorResponse) {
        const error = await getApiError(errorResponse);

        dispatch({ type: "ADMINISTRATION_QUIZ_REQUEST_FAILURE", error });
      }
    },

  requestQuizAdd:
    (addQuizCommand: AddQuizCommand): AppThunkAction<KnownAction, ApplicationState> =>
    async (dispatch, getState) => {
      dispatch({ type: "ADMINISTRATION_QUIZ_ADD_REQUEST" });

      try {
        const quizId = await administrationQuizzesApi.addQuiz(addQuizCommand);

        dispatch({ type: "ADMINISTRATION_QUIZ_ADD_REQUEST_SUCCESS", quizId });
      } catch (errorResponse) {
        const error = await getApiError(errorResponse);

        dispatch({ type: "ADMINISTRATION_QUIZ_ADD_REQUEST_FAILURE", error });
      }
    },

  requestQuizUpdate:
    (quizId: string, updateQuizCommand: UpdateQuizCommand): AppThunkAction<KnownAction, ApplicationState> =>
    async (dispatch, getState) => {
      dispatch({ type: "ADMINISTRATION_QUIZ_UPDATE_REQUEST" });

      try {
        await administrationQuizzesApi.updateQuiz(quizId, updateQuizCommand);

        dispatch({ type: "ADMINISTRATION_QUIZ_UPDATE_REQUEST_SUCCESS" });
      } catch (errorResponse) {
        const error = await getApiError(errorResponse);

        dispatch({ type: "ADMINISTRATION_QUIZ_UPDATE_REQUEST_FAILURE", error });
      }
    },

  requestQuizDelete:
    (quizId: string): AppThunkAction<KnownAction, ApplicationState> =>
    async (dispatch, getState) => {
      dispatch({ type: "ADMINISTRATION_QUIZ_DELETE_REQUEST" });
      try {
        await administrationQuizzesApi.removeQuiz(quizId);

        dispatch({ type: "ADMINISTRATION_QUIZ_DELETE_REQUEST_SUCCESS" });
      } catch (errorResponse) {
        const error = await getApiError(errorResponse);

        dispatch({ type: "ADMINISTRATION_QUIZ_DELETE_REQUEST_FAILURE", error });
      }
    },

  requestTrainingPrograms: (): AppThunkAction<KnownAction, ApplicationState> => async (dispatch, getState) => {
    dispatch({ type: "ADMINISTRATION_TRAINING_PROGRAMS_REQUEST" });
    try {
      const trainingPrograms = await administrationTrainingProgramsApi.getTrainingPrograms();

      dispatch({
        type: "ADMINISTRATION_TRAINING_PROGRAMS_REQUEST_SUCCESS",
        trainingPrograms,
      });
    } catch (errorResponse) {
      const error = await getApiError(errorResponse);

      dispatch({ type: "ADMINISTRATION_TRAINING_PROGRAMS_REQUEST_FAILURE", error });
    }
  },

  requestTrainingProgram:
    (trainingProgramId: string): AppThunkAction<KnownAction, ApplicationState> =>
    async (dispatch, getState) => {
      dispatch({ type: "ADMINISTRATION_TRAINING_PROGRAM_REQUEST" });

      try {
        const trainingProgram = await administrationTrainingProgramsApi.getTrainingProgram(trainingProgramId);

        dispatch({ type: "ADMINISTRATION_TRAINING_PROGRAM_REQUEST_SUCCESS", trainingProgram });
      } catch (errorResponse) {
        const error = await getApiError(errorResponse);

        dispatch({ type: "ADMINISTRATION_TRAINING_PROGRAM_REQUEST_FAILURE", error });
      }
    },

  requestTrainingProgramAdd:
    (addTrainingProgramCommand: AddTrainingProgramCommand): AppThunkAction<KnownAction, ApplicationState> =>
    async (dispatch, getState) => {
      dispatch({ type: "ADMINISTRATION_TRAINING_PROGRAM_ADD_REQUEST" });

      try {
        const trainingProgramId = await administrationTrainingProgramsApi.addTrainingProgram(addTrainingProgramCommand);

        dispatch({ type: "ADMINISTRATION_TRAINING_PROGRAM_ADD_REQUEST_SUCCESS", trainingProgramId });
      } catch (errorResponse) {
        const error = await getApiError(errorResponse);

        dispatch({ type: "ADMINISTRATION_TRAINING_PROGRAM_ADD_REQUEST_FAILURE", error });
      }
    },

  requestTrainingProgramUpdate:
    (
      trainingProgramId: string,
      updateTrainingProgramCommand: UpdateTrainingProgramCommand,
    ): AppThunkAction<KnownAction, ApplicationState> =>
    async (dispatch, getState) => {
      dispatch({ type: "ADMINISTRATION_TRAINING_PROGRAM_UPDATE_REQUEST" });

      try {
        await administrationTrainingProgramsApi.updateTrainingProgram(trainingProgramId, updateTrainingProgramCommand);

        dispatch({ type: "ADMINISTRATION_TRAINING_PROGRAM_UPDATE_REQUEST_SUCCESS" });
      } catch (errorResponse) {
        const error = await getApiError(errorResponse);

        dispatch({ type: "ADMINISTRATION_TRAINING_PROGRAM_UPDATE_REQUEST_FAILURE", error });
      }
    },

  requestTrainingProgramDelete:
    (trainingProgramId: string): AppThunkAction<KnownAction, ApplicationState> =>
    async (dispatch, getState) => {
      dispatch({ type: "ADMINISTRATION_TRAINING_PROGRAM_DELETE_REQUEST" });

      try {
        await administrationTrainingProgramsApi.removeTrainingProgram(trainingProgramId);

        dispatch({ type: "ADMINISTRATION_TRAINING_PROGRAM_DELETE_REQUEST_SUCCESS" });
      } catch (errorResponse) {
        const error = await getApiError(errorResponse);

        dispatch({ type: "ADMINISTRATION_TRAINING_PROGRAM_DELETE_REQUEST_FAILURE", error });
      }
    },

  requestUserGroups: (): AppThunkAction<KnownAction, ApplicationState> => async (dispatch, getState) => {
    dispatch({ type: "ADMINISTRATION_USER_GROUPS_REQUEST" });
    try {
      const userGroups = await administrationUserGroupsApi.getUserGroups();

      dispatch({
        type: "ADMINISTRATION_USER_GROUPS_REQUEST_SUCCESS",
        userGroups,
      });
    } catch (errorResponse) {
      const error = await getApiError(errorResponse);

      dispatch({ type: "ADMINISTRATION_USER_GROUPS_REQUEST_FAILURE", error });
    }
  },

  requestUserGroup:
    (userGroupType: number): AppThunkAction<KnownAction, ApplicationState> =>
    async (dispatch, getState) => {
      dispatch({ type: "ADMINISTRATION_USER_GROUP_REQUEST" });

      try {
        const userGroup = await administrationUserGroupsApi.getUserGroup(userGroupType);

        dispatch({ type: "ADMINISTRATION_USER_GROUP_REQUEST_SUCCESS", userGroup });
      } catch (errorResponse) {
        const error = await getApiError(errorResponse);

        dispatch({ type: "ADMINISTRATION_USER_GROUP_REQUEST_FAILURE", error });
      }
    },

  requestUserGroupUpdate:
    (
      userGroupType: number,
      addOrUpdateUserGroupCommand: UpdateUserGroupCommand,
    ): AppThunkAction<KnownAction, ApplicationState> =>
    async (dispatch, getState) => {
      dispatch({ type: "ADMINISTRATION_USER_GROUP_UPDATE_REQUEST" });

      try {
        await administrationUserGroupsApi.updateUserGroup(userGroupType, addOrUpdateUserGroupCommand);

        dispatch({ type: "ADMINISTRATION_USER_GROUP_UPDATE_REQUEST_SUCCESS" });
      } catch (errorResponse) {
        const error = await getApiError(errorResponse);

        dispatch({ type: "ADMINISTRATION_USER_GROUP_UPDATE_REQUEST_FAILURE", error });
      }
    },

  requestVideos: (): AppThunkAction<KnownAction, ApplicationState> => async (dispatch, getState) => {
    dispatch({ type: "ADMINISTRATION_VIDEOS_REQUEST" });

    try {
      const videos = await administrationVideosApi.getVideos();

      dispatch({
        type: "ADMINISTRATION_VIDEOS_REQUEST_SUCCESS",
        videos,
      });
    } catch (errorResponse) {
      const error = await getApiError(errorResponse);

      dispatch({ type: "ADMINISTRATION_VIDEOS_REQUEST_FAILURE", error });
    }
  },

  requestVideo:
    (id: string): AppThunkAction<KnownAction, ApplicationState> =>
    async (dispatch, getState) => {
      dispatch({ type: "ADMINISTRATION_VIDEO_REQUEST" });

      try {
        const video = await administrationVideosApi.getVideo(id);

        dispatch({ type: "ADMINISTRATION_VIDEO_REQUEST_SUCCESS", video });
      } catch (errorResponse) {
        const error = await getApiError(errorResponse);

        dispatch({ type: "ADMINISTRATION_VIDEO_REQUEST_FAILURE", error });
      }
    },

  requestVideoAdd:
    (addVideoCommand: AddVideoCommand): AppThunkAction<KnownAction, ApplicationState> =>
    async (dispatch, getState) => {
      dispatch({ type: "ADMINISTRATION_VIDEO_ADD_REQUEST" });

      try {
        const videoId = await administrationVideosApi.addVideo(addVideoCommand);

        dispatch({ type: "ADMINISTRATION_VIDEO_ADD_REQUEST_SUCCESS", videoId });
      } catch (errorResponse) {
        const error = await getApiError(errorResponse);

        dispatch({ type: "ADMINISTRATION_VIDEO_ADD_REQUEST_FAILURE", error });
      }
    },

  requestVideoUpdate:
    (videoId: string, updateVideoCommand: UpdateVideoCommand): AppThunkAction<KnownAction, ApplicationState> =>
    async (dispatch, getState) => {
      dispatch({ type: "ADMINISTRATION_VIDEO_UPDATE_REQUEST" });

      try {
        await administrationVideosApi.updateVideo(videoId, updateVideoCommand);

        dispatch({ type: "ADMINISTRATION_VIDEO_UPDATE_REQUEST_SUCCESS" });
      } catch (errorResponse) {
        const error = await getApiError(errorResponse);

        dispatch({ type: "ADMINISTRATION_VIDEO_UPDATE_REQUEST_FAILURE", error });
      }
    },

  requestVideoDelete:
    (videoId: string, permanentlyDeleteFile: boolean): AppThunkAction<KnownAction, ApplicationState> =>
    async (dispatch, getState) => {
      dispatch({ type: "ADMINISTRATION_VIDEO_DELETE_REQUEST" });

      try {
        await administrationVideosApi.removeVideo(videoId, { permanentlyDeleteFile });

        dispatch({ type: "ADMINISTRATION_VIDEO_DELETE_REQUEST_SUCCESS" });
      } catch (errorResponse) {
        const error = await getApiError(errorResponse);

        dispatch({ type: "ADMINISTRATION_VIDEO_DELETE_REQUEST_FAILURE", error });
      }
    },
};

export const initialState: AdministrationState = {
  actionStatus: {
    pending: false,
  },
};

export const reducer: Reducer<AdministrationState, KnownAction> = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case "ADMINISTRATION_COURSES_REQUEST":
    case "ADMINISTRATION_COURSE_REQUEST":
    case "ADMINISTRATION_COURSE_ADD_REQUEST":
    case "ADMINISTRATION_COURSE_UPDATE_REQUEST":
    case "ADMINISTRATION_COURSE_DELETE_REQUEST":
      state = {
        ...state,
        actionStatus: {
          type: action.type,
          pending: true,
        },
      };
      break;

    case "ADMINISTRATION_COURSES_REQUEST_SUCCESS":
      state = {
        ...state,
        courses: action.courses,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
        },
      };
      break;

    case "ADMINISTRATION_COURSE_REQUEST_SUCCESS":
      state = {
        ...state,
        course: action.course,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
        },
      };
      break;

    case "ADMINISTRATION_COURSE_ADD_REQUEST_SUCCESS":
      state = {
        ...state,
        course: {
          id: action.courseId,
        } as CourseDto,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
        },
      };
      break;

    case "ADMINISTRATION_COURSE_UPDATE_REQUEST_SUCCESS":
    case "ADMINISTRATION_COURSE_DELETE_REQUEST_SUCCESS":
      state = {
        ...state,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
        },
      };
      break;

    case "ADMINISTRATION_COURSES_REQUEST_FAILURE":
    case "ADMINISTRATION_COURSE_REQUEST_FAILURE":
    case "ADMINISTRATION_COURSE_ADD_REQUEST_FAILURE":
    case "ADMINISTRATION_COURSE_UPDATE_REQUEST_FAILURE":
    case "ADMINISTRATION_COURSE_DELETE_REQUEST_FAILURE":
      state = {
        ...state,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
          error: action.error,
        },
      };
      break;

    case "ADMINISTRATION_DOCUMENTS_REQUEST":
      state = {
        ...state,
        actionStatus: {
          type: action.type,
          pending: true,
        },
      };
      break;

    case "ADMINISTRATION_DOCUMENTS_REQUEST_SUCCESS":
      state = {
        ...state,
        documents: action.documents,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
        },
      };
      break;

    case "ADMINISTRATION_DOCUMENTS_REQUEST_FAILURE":
      state = {
        ...state,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
          error: action.error,
        },
      };
      break;

    case "ADMINISTRATION_DOCUMENT_REQUEST":
      state = {
        ...state,
        actionStatus: {
          type: action.type,
          pending: true,
        },
      };
      break;

    case "ADMINISTRATION_DOCUMENT_REQUEST_SUCCESS":
      state = {
        ...state,
        document: action.document,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
        },
      };
      break;

    case "ADMINISTRATION_DOCUMENT_REQUEST_FAILURE":
      state = {
        ...state,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
          error: action.error,
        },
      };
      break;

    case "ADMINISTRATION_DOCUMENT_ADD_REQUEST":
      state = {
        ...state,
        actionStatus: {
          type: action.type,
          pending: true,
        },
      };
      break;

    case "ADMINISTRATION_DOCUMENT_ADD_REQUEST_SUCCESS":
      state = {
        ...state,
        document: {
          id: action.documentId,
        } as DocumentDto,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
        },
      };
      break;

    case "ADMINISTRATION_DOCUMENT_ADD_REQUEST_FAILURE":
      state = {
        ...state,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
          error: action.error,
        },
      };
      break;

    case "ADMINISTRATION_DOCUMENT_UPDATE_REQUEST":
      state = {
        ...state,
        actionStatus: {
          type: action.type,
          pending: true,
        },
      };
      break;

    case "ADMINISTRATION_DOCUMENT_UPDATE_REQUEST_SUCCESS":
      state = {
        ...state,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
        },
      };
      break;

    case "ADMINISTRATION_DOCUMENT_UPDATE_REQUEST_FAILURE":
      state = {
        ...state,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
          error: action.error,
        },
      };
      break;

    case "ADMINISTRATION_DOCUMENT_DELETE_REQUEST":
      state = {
        ...state,
        actionStatus: {
          type: action.type,
          pending: true,
        },
      };
      break;

    case "ADMINISTRATION_DOCUMENT_DELETE_REQUEST_SUCCESS":
      state = {
        ...state,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
        },
      };
      break;

    case "ADMINISTRATION_DOCUMENT_DELETE_REQUEST_FAILURE":
      state = {
        ...state,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
          error: action.error,
        },
      };
      break;

    case "ADMINISTRATION_QUIZZES_REQUEST":
      state = {
        ...state,
        actionStatus: {
          type: action.type,
          pending: true,
        },
      };
      break;

    case "ADMINISTRATION_QUIZZES_REQUEST_SUCCESS":
      state = {
        ...state,
        quizzes: action.quizzes,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
        },
      };
      break;

    case "ADMINISTRATION_QUIZZES_REQUEST_FAILURE":
      state = {
        ...state,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
          error: action.error,
        },
      };
      break;
    case "ADMINISTRATION_QUIZ_REQUEST":
      state = {
        ...state,
        actionStatus: {
          type: action.type,
          pending: true,
        },
      };
      break;

    case "ADMINISTRATION_QUIZ_REQUEST_SUCCESS":
      state = {
        ...state,
        quiz: action.quiz,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
        },
      };
      break;

    case "ADMINISTRATION_QUIZ_REQUEST_FAILURE":
      state = {
        ...state,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
          error: action.error,
        },
      };
      break;

    case "ADMINISTRATION_QUIZ_ADD_REQUEST":
      state = {
        ...state,
        actionStatus: {
          type: action.type,
          pending: true,
        },
      };
      break;

    case "ADMINISTRATION_QUIZ_ADD_REQUEST_SUCCESS":
      state = {
        ...state,
        quiz: {
          id: action.quizId,
        } as QuizDto,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
        },
      };
      break;

    case "ADMINISTRATION_QUIZ_ADD_REQUEST_FAILURE":
      state = {
        ...state,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
          error: action.error,
        },
      };
      break;

    case "ADMINISTRATION_QUIZ_UPDATE_REQUEST":
      state = {
        ...state,
        actionStatus: {
          type: action.type,
          pending: true,
        },
      };
      break;

    case "ADMINISTRATION_QUIZ_UPDATE_REQUEST_SUCCESS":
      state = {
        ...state,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
        },
      };
      break;

    case "ADMINISTRATION_QUIZ_UPDATE_REQUEST_FAILURE":
      state = {
        ...state,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
          error: action.error,
        },
      };
      break;

    case "ADMINISTRATION_QUIZ_DELETE_REQUEST":
      state = {
        ...state,
        actionStatus: {
          type: action.type,
          pending: true,
        },
      };
      break;

    case "ADMINISTRATION_QUIZ_DELETE_REQUEST_SUCCESS":
      state = {
        ...state,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
        },
      };
      break;

    case "ADMINISTRATION_QUIZ_DELETE_REQUEST_FAILURE":
      state = {
        ...state,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
          error: action.error,
        },
      };
      break;

    case "ADMINISTRATION_TRAINING_PROGRAMS_REQUEST":
      state = {
        ...state,
        actionStatus: {
          type: action.type,
          pending: true,
        },
      };
      break;

    case "ADMINISTRATION_TRAINING_PROGRAMS_REQUEST_SUCCESS":
      state = {
        ...state,
        trainingPrograms: action.trainingPrograms,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
        },
      };
      break;

    case "ADMINISTRATION_TRAINING_PROGRAMS_REQUEST_FAILURE":
      state = {
        ...state,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
          error: action.error,
        },
      };
      break;

    case "ADMINISTRATION_TRAINING_PROGRAM_REQUEST":
      state = {
        ...state,
        actionStatus: {
          type: action.type,
          pending: true,
        },
      };
      break;

    case "ADMINISTRATION_TRAINING_PROGRAM_REQUEST_SUCCESS":
      state = {
        ...state,
        trainingProgram: action.trainingProgram,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
        },
      };
      break;

    case "ADMINISTRATION_TRAINING_PROGRAM_REQUEST_FAILURE":
      state = {
        ...state,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
          error: action.error,
        },
      };
      break;

    case "ADMINISTRATION_TRAINING_PROGRAM_ADD_REQUEST":
      state = {
        ...state,
        actionStatus: {
          type: action.type,
          pending: true,
        },
      };
      break;

    case "ADMINISTRATION_TRAINING_PROGRAM_ADD_REQUEST_SUCCESS":
      state = {
        ...state,
        trainingProgram: {
          id: action.trainingProgramId,
        } as TrainingProgramDto,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
        },
      };
      break;

    case "ADMINISTRATION_TRAINING_PROGRAM_ADD_REQUEST_FAILURE":
      state = {
        ...state,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
          error: action.error,
        },
      };
      break;

    case "ADMINISTRATION_TRAINING_PROGRAM_UPDATE_REQUEST":
      state = {
        ...state,
        actionStatus: {
          type: action.type,
          pending: true,
        },
      };
      break;

    case "ADMINISTRATION_TRAINING_PROGRAM_UPDATE_REQUEST_SUCCESS":
      state = {
        ...state,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
        },
      };
      break;

    case "ADMINISTRATION_TRAINING_PROGRAM_UPDATE_REQUEST_FAILURE":
      state = {
        ...state,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
          error: action.error,
        },
      };
      break;

    case "ADMINISTRATION_TRAINING_PROGRAM_DELETE_REQUEST":
      state = {
        ...state,
        actionStatus: {
          type: action.type,
          pending: true,
        },
      };
      break;

    case "ADMINISTRATION_TRAINING_PROGRAM_DELETE_REQUEST_SUCCESS":
      state = {
        ...state,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
        },
      };
      break;

    case "ADMINISTRATION_TRAINING_PROGRAM_DELETE_REQUEST_FAILURE":
      state = {
        ...state,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
          error: action.error,
        },
      };
      break;

    case "ADMINISTRATION_USER_GROUPS_REQUEST":
      state = {
        ...state,
        actionStatus: {
          type: action.type,
          pending: true,
        },
      };
      break;

    case "ADMINISTRATION_USER_GROUPS_REQUEST_SUCCESS":
      state = {
        ...state,
        userGroups: action.userGroups,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
        },
      };
      break;

    case "ADMINISTRATION_USER_GROUPS_REQUEST_FAILURE":
      state = {
        ...state,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
          error: action.error,
        },
      };
      break;

    case "ADMINISTRATION_USER_GROUP_REQUEST":
      state = {
        ...state,
        actionStatus: {
          type: action.type,
          pending: true,
        },
      };
      break;

    case "ADMINISTRATION_USER_GROUP_REQUEST_SUCCESS":
      state = {
        ...state,
        userGroup: action.userGroup,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
        },
      };
      break;

    case "ADMINISTRATION_USER_GROUP_REQUEST_FAILURE":
      state = {
        ...state,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
          error: action.error,
        },
      };
      break;

    case "ADMINISTRATION_USER_GROUP_UPDATE_REQUEST":
      state = {
        ...state,
        actionStatus: {
          type: action.type,
          pending: true,
        },
      };
      break;

    case "ADMINISTRATION_USER_GROUP_UPDATE_REQUEST_SUCCESS":
      state = {
        ...state,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
        },
      };
      break;

    case "ADMINISTRATION_USER_GROUP_UPDATE_REQUEST_FAILURE":
      state = {
        ...state,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
          error: action.error,
        },
      };
      break;

    case "ADMINISTRATION_VIDEOS_REQUEST":
      state = {
        ...state,
        actionStatus: {
          type: action.type,
          pending: true,
        },
      };
      break;

    case "ADMINISTRATION_VIDEOS_REQUEST_SUCCESS":
      state = {
        ...state,
        videos: action.videos,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
        },
      };
      break;

    case "ADMINISTRATION_VIDEOS_REQUEST_FAILURE":
      state = {
        ...state,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
          error: action.error,
        },
      };
      break;

    case "ADMINISTRATION_VIDEO_REQUEST":
      state = {
        ...state,
        actionStatus: {
          type: action.type,
          pending: true,
        },
      };
      break;

    case "ADMINISTRATION_VIDEO_REQUEST_SUCCESS":
      state = {
        ...state,
        video: action.video,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
        },
      };
      break;

    case "ADMINISTRATION_VIDEO_REQUEST_FAILURE":
      state = {
        ...state,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
          error: action.error,
        },
      };
      break;

    case "ADMINISTRATION_VIDEO_ADD_REQUEST":
      state = {
        ...state,
        actionStatus: {
          type: action.type,
          pending: true,
        },
      };
      break;

    case "ADMINISTRATION_VIDEO_ADD_REQUEST_SUCCESS":
      state = {
        ...state,
        video: {
          id: action.videoId,
        } as VideoDto,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
        },
      };
      break;

    case "ADMINISTRATION_VIDEO_ADD_REQUEST_FAILURE":
      state = {
        ...state,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
          error: action.error,
        },
      };
      break;

    case "ADMINISTRATION_VIDEO_UPDATE_REQUEST":
      state = {
        ...state,
        actionStatus: {
          type: action.type,
          pending: true,
        },
      };
      break;

    case "ADMINISTRATION_VIDEO_UPDATE_REQUEST_SUCCESS":
      state = {
        ...state,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
        },
      };
      break;

    case "ADMINISTRATION_VIDEO_UPDATE_REQUEST_FAILURE":
      state = {
        ...state,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
          error: action.error,
        },
      };
      break;

    case "ADMINISTRATION_VIDEO_DELETE_REQUEST":
      state = {
        ...state,
        actionStatus: {
          type: action.type,
          pending: true,
        },
      };
      break;

    case "ADMINISTRATION_VIDEO_DELETE_REQUEST_SUCCESS":
      state = {
        ...state,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
        },
      };
      break;

    case "ADMINISTRATION_VIDEO_DELETE_REQUEST_FAILURE":
      state = {
        ...state,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
          error: action.error,
        },
      };
      break;

    default:
  }

  return state;
};
