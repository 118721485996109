import { toAuditDetailsFormData } from "@interface48/forms";
import { AddTrainingProgramCommand, TrainingProgramDto, UpdateTrainingProgramCommand } from "../../../../api";
import { sortTrainingProgramComponentsByNumber } from "../../utils";
import { TrainingProgramFormData } from "../models";

export const toTrainingProgramFormData = (trainingProgram?: TrainingProgramDto): TrainingProgramFormData => {
  if (trainingProgram) {
    const isExisting = true;

    return {
      isExisting,
      id: trainingProgram.id,
      name: trainingProgram.name,
      description: trainingProgram.description,
      componentIds: trainingProgram.components
        .sort(sortTrainingProgramComponentsByNumber)
        .map(
          (component) => component.courseId ?? component.documentId ?? component.videoId ?? component.quizId,
        ) as string[],
      durationMinutes: trainingProgram.durationMinutes,
      emailRecipients: trainingProgram.emailRecipients?.join(", "),
      sortOrder: trainingProgram.sortOrder,
      departments: trainingProgram.departments.map((department) => ({
        department: department.department,
        departmentName: department.departmentName,
        positions: department.positions,
        positionNames: department.positionNames,
      })),
      hasDepartments: !!trainingProgram.departments.length,
      trainingScorecardId: trainingProgram.trainingScorecardId,
      trainingScorecardName: trainingProgram.trainingScorecardName,
      isEnabled: trainingProgram.isEnabled,
      incrementMajorVersion: false,
      majorVersion: trainingProgram.majorVersion,
      version: trainingProgram.version,
      created: toAuditDetailsFormData(trainingProgram.createdAt, trainingProgram.createdByUserName),
      lastModified: toAuditDetailsFormData(trainingProgram.lastModifiedAt, trainingProgram.lastModifiedByUserName),
    };
  } else {
    const isExisting = false;

    return {
      isExisting,
      isEnabled: false,
      incrementMajorVersion: false,
      componentIds: [undefined],
      hasDepartments: false,
      created: {},
      lastModified: {},
    };
  }
};

const getEmailRecipientsList = (emailRecipients: string | undefined) => {
  const emailRecipientsList = emailRecipients ? emailRecipients.split(",").map((r) => r.trim()) : undefined;
  return emailRecipientsList;
};

export const toAddTrainingProgramCommand = (formData: TrainingProgramFormData) => {
  const { name, description, emailRecipients, departments, trainingScorecardId, isEnabled, sortOrder, componentIds } =
    formData;

  const addTrainingProgramCommand: AddTrainingProgramCommand = {
    name: name!,
    description: description!,
    emailRecipients: getEmailRecipientsList(emailRecipients),
    departments: departments?.map((department) => ({
      department: department.department!,
      positions: department.positions!,
    })),
    trainingScorecardId,
    isEnabled: !!isEnabled,
    sortOrder: sortOrder,
    componentIds: componentIds as string[],
  };

  return addTrainingProgramCommand;
};

export const toUpdateTrainingProgramCommand = (formData: TrainingProgramFormData) => {
  const {
    name,
    description,
    emailRecipients,
    departments,
    trainingScorecardId,
    isEnabled,
    sortOrder,
    incrementMajorVersion,
    componentIds,
  } = formData;

  const updateTrainingProgramCommand: UpdateTrainingProgramCommand = {
    name: name!,
    description: description!,
    emailRecipients: getEmailRecipientsList(emailRecipients),
    departments: departments?.map((department) => ({
      department: department.department!,
      positions: department.positions!,
    })),
    trainingScorecardId,
    isEnabled: !!isEnabled,
    sortOrder: sortOrder,
    incrementMajorVersion: !!incrementMajorVersion,
    componentIds: componentIds as string[],
  };

  return updateTrainingProgramCommand;
};
