import { toAuditDetailsFormData } from "@interface48/forms";
import { AddVideoCommand, UpdateVideoCommand, VideoDto } from "../../../../api";
import { VideoFormData } from "../models";

export const toVideoFormData = (video?: VideoDto): VideoFormData => {
  if (video) {
    const isExisting = true;

    return {
      isExisting,
      id: video.id,
      mediaCategoryId: video.mediaCategoryId,
      mediaCategoryName: video.mediaCategoryName,
      name: video.name,
      existingFile: {
        id: video.id,
        fileName: video.fileName,
        fileExtension: video.fileExtension,
        fileSizeBytes: video.fileSizeBytes,
      },
      existingFileMetadata: {
        durationSeconds: video.durationSeconds,
        resolutionWidth: video.resolutionWidth,
        resolutionHeight: video.resolutionHeight,
      },
      updateFile: false,
      file: {},
      created: toAuditDetailsFormData(video.createdAt, video.createdByUserName),
      lastModified: toAuditDetailsFormData(video.lastModifiedAt, video.lastModifiedByUserName),
    };
  } else {
    const isExisting = false;

    return {
      isExisting,
      updateFile: false,
      created: {},
      lastModified: {},
    };
  }
};

export const toAddVideoCommand = (formData: VideoFormData) => {
  const { mediaCategoryId, name, file } = formData;

  if (!file) {
    throw Error("No File uploaded.");
  }

  const addVideoCommand: AddVideoCommand = {
    mediaCategoryId: mediaCategoryId!,
    name: name!,
    fileUploadId: file.fileUploadId!,
  };

  return addVideoCommand;
};

export const toUpdateVideoCommand = (formData: VideoFormData) => {
  const { mediaCategoryId, name, file } = formData;

  const updateVideoCommand: UpdateVideoCommand = {
    mediaCategoryId: mediaCategoryId!,
    name: name!,
    fileUploadId: file ? file.fileUploadId! : undefined,
  };

  return updateVideoCommand;
};
