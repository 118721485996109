/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VideoDto
 */
export interface VideoDto {
    /**
     * 
     * @type {string}
     * @memberof VideoDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof VideoDto
     */
    mediaCategoryId: string;
    /**
     * 
     * @type {string}
     * @memberof VideoDto
     */
    mediaCategoryName: string;
    /**
     * 
     * @type {string}
     * @memberof VideoDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof VideoDto
     */
    fileName: string;
    /**
     * 
     * @type {string}
     * @memberof VideoDto
     */
    fileExtension: string;
    /**
     * 
     * @type {number}
     * @memberof VideoDto
     */
    fileSizeBytes: number;
    /**
     * 
     * @type {number}
     * @memberof VideoDto
     */
    durationSeconds: number;
    /**
     * 
     * @type {number}
     * @memberof VideoDto
     */
    resolutionWidth: number;
    /**
     * 
     * @type {number}
     * @memberof VideoDto
     */
    resolutionHeight: number;
    /**
     * 
     * @type {string}
     * @memberof VideoDto
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof VideoDto
     */
    createdByUserName: string;
    /**
     * 
     * @type {string}
     * @memberof VideoDto
     */
    lastModifiedAt: string;
    /**
     * 
     * @type {string}
     * @memberof VideoDto
     */
    lastModifiedByUserName: string;
}

export function VideoDtoFromJSON(json: any): VideoDto {
    return {
        'id': json['id'],
        'mediaCategoryId': json['mediaCategoryId'],
        'mediaCategoryName': json['mediaCategoryName'],
        'name': json['name'],
        'fileName': json['fileName'],
        'fileExtension': json['fileExtension'],
        'fileSizeBytes': json['fileSizeBytes'],
        'durationSeconds': json['durationSeconds'],
        'resolutionWidth': json['resolutionWidth'],
        'resolutionHeight': json['resolutionHeight'],
        'createdAt': json['createdAt'],
        'createdByUserName': json['createdByUserName'],
        'lastModifiedAt': json['lastModifiedAt'],
        'lastModifiedByUserName': json['lastModifiedByUserName'],
    };
}

export function VideoDtoToJSON(value?: VideoDto | null): any {
    if (value == undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'mediaCategoryId': value.mediaCategoryId,
        'mediaCategoryName': value.mediaCategoryName,
        'name': value.name,
        'fileName': value.fileName,
        'fileExtension': value.fileExtension,
        'fileSizeBytes': value.fileSizeBytes,
        'durationSeconds': value.durationSeconds,
        'resolutionWidth': value.resolutionWidth,
        'resolutionHeight': value.resolutionHeight,
        'createdAt': value.createdAt,
        'createdByUserName': value.createdByUserName,
        'lastModifiedAt': value.lastModifiedAt,
        'lastModifiedByUserName': value.lastModifiedByUserName,
    };
}


