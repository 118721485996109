/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TrainingScorecardDepartmentPositionDto
 */
export interface TrainingScorecardDepartmentPositionDto {
    /**
     * 
     * @type {number}
     * @memberof TrainingScorecardDepartmentPositionDto
     */
    position?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TrainingScorecardDepartmentPositionDto
     */
    positionName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TrainingScorecardDepartmentPositionDto
     */
    targetCompletionCount: number;
    /**
     * 
     * @type {number}
     * @memberof TrainingScorecardDepartmentPositionDto
     */
    completionCount: number;
    /**
     * 
     * @type {number}
     * @memberof TrainingScorecardDepartmentPositionDto
     */
    completionPercentage: number;
    /**
     * 
     * @type {string}
     * @memberof TrainingScorecardDepartmentPositionDto
     */
    readonly completionPercentageDescription: string;
}

export function TrainingScorecardDepartmentPositionDtoFromJSON(json: any): TrainingScorecardDepartmentPositionDto {
    return {
        'position': !exists(json, 'position') ? undefined : json['position'],
        'positionName': !exists(json, 'positionName') ? undefined : json['positionName'],
        'targetCompletionCount': json['targetCompletionCount'],
        'completionCount': json['completionCount'],
        'completionPercentage': json['completionPercentage'],
        'completionPercentageDescription': json['completionPercentageDescription'],
    };
}

export function TrainingScorecardDepartmentPositionDtoToJSON(value?: TrainingScorecardDepartmentPositionDto | null): any {
    if (value == undefined) {
        return undefined;
    }
    return {
        'position': value.position,
        'positionName': value.positionName,
        'targetCompletionCount': value.targetCompletionCount,
        'completionCount': value.completionCount,
        'completionPercentage': value.completionPercentage,
    };
}


