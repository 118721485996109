/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddOrUpdateQuizQuestionAnswerCommand
 */
export interface AddOrUpdateQuizQuestionAnswerCommand {
    /**
     * 
     * @type {string}
     * @memberof AddOrUpdateQuizQuestionAnswerCommand
     */
    text: string;
    /**
     * 
     * @type {boolean}
     * @memberof AddOrUpdateQuizQuestionAnswerCommand
     */
    isCorrect: boolean;
}

export function AddOrUpdateQuizQuestionAnswerCommandFromJSON(json: any): AddOrUpdateQuizQuestionAnswerCommand {
    return {
        'text': json['text'],
        'isCorrect': json['isCorrect'],
    };
}

export function AddOrUpdateQuizQuestionAnswerCommandToJSON(value?: AddOrUpdateQuizQuestionAnswerCommand | null): any {
    if (value == undefined) {
        return undefined;
    }
    return {
        'text': value.text,
        'isCorrect': value.isCorrect,
    };
}


