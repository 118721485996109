/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateCourseCommand
 */
export interface UpdateCourseCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateCourseCommand
     */
    fileUploadId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateCourseCommand
     */
    durationMinutes: number;
}

export function UpdateCourseCommandFromJSON(json: any): UpdateCourseCommand {
    return {
        'fileUploadId': !exists(json, 'fileUploadId') ? undefined : json['fileUploadId'],
        'durationMinutes': json['durationMinutes'],
    };
}

export function UpdateCourseCommandToJSON(value?: UpdateCourseCommand | null): any {
    if (value == undefined) {
        return undefined;
    }
    return {
        'fileUploadId': value.fileUploadId,
        'durationMinutes': value.durationMinutes,
    };
}


