import { Media } from "../../../api";

export const transformToMediaItemsByCategory = (media: Media[]) => {
  const mediaByCategory: { [key: string]: Media[] } = {};

  if (media) {
    for (const medium of media) {
      const mediaItem = medium;
      if (!mediaByCategory.hasOwnProperty(mediaItem.mediaCategoryName)) {
        mediaByCategory[mediaItem.mediaCategoryName] = [];
      }
      mediaByCategory[mediaItem.mediaCategoryName].push(mediaItem);
    }
  }

  return mediaByCategory;
};
