/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddVideoCommand,
    AddVideoCommandFromJSON,
    AddVideoCommandToJSON,
    RemoveVideoCommand,
    RemoveVideoCommandFromJSON,
    RemoveVideoCommandToJSON,
    UpdateVideoCommand,
    UpdateVideoCommandFromJSON,
    UpdateVideoCommandToJSON,
    VideoDto,
    VideoDtoFromJSON,
    VideoDtoToJSON,
    VideoFileMetadataDto,
    VideoFileMetadataDtoFromJSON,
    VideoFileMetadataDtoToJSON,
    VideoSummaryDto,
    VideoSummaryDtoFromJSON,
    VideoSummaryDtoToJSON,
} from '../models';

export interface ApiAdministrationVideosFileUploadFileUploadIdMetadataGetRequest {
    fileUploadId: string;
}

export interface ApiAdministrationVideosIdDeleteRequest {
    id: string;
    removeVideoCommand: RemoveVideoCommand;
}

export interface ApiAdministrationVideosIdGetRequest {
    id: string;
}

export interface ApiAdministrationVideosIdPutRequest {
    id: string;
    updateVideoCommand: UpdateVideoCommand;
}

export interface ApiAdministrationVideosPostRequest {
    addVideoCommand: AddVideoCommand;
}

export interface ApiVideosIdFileGetRequest {
    id: string;
    download?: boolean;
}

export interface ApiVideosIdGetRequest {
    id: string;
}

export interface ApiVideosIdPosterFileGetRequest {
    id: string;
    download?: boolean;
}

/**
 * no description
 */
export class VideosApi extends runtime.BaseAPI {

    /**
     */
    async apiAdministrationVideosFileUploadFileUploadIdMetadataGetRaw(requestParameters: ApiAdministrationVideosFileUploadFileUploadIdMetadataGetRequest): Promise<runtime.ApiResponse<VideoFileMetadataDto>> {
        if (requestParameters.fileUploadId === null || requestParameters.fileUploadId === undefined) {
            throw new runtime.RequiredError('fileUploadId','Required parameter requestParameters.fileUploadId was null or undefined when calling apiAdministrationVideosFileUploadFileUploadIdMetadataGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["Administrators"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/administration/videos/file-upload/{fileUploadId}/metadata`.replace(`{${"fileUploadId"}}`, encodeURIComponent(String(requestParameters.fileUploadId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VideoFileMetadataDtoFromJSON(jsonValue));
    }

   /**
    */
    async apiAdministrationVideosFileUploadFileUploadIdMetadataGet(requestParameters: ApiAdministrationVideosFileUploadFileUploadIdMetadataGetRequest): Promise<VideoFileMetadataDto> {
        const response = await this.apiAdministrationVideosFileUploadFileUploadIdMetadataGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAdministrationVideosGetRaw(): Promise<runtime.ApiResponse<Array<VideoDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["Administrators"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/administration/videos`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VideoDtoFromJSON));
    }

   /**
    */
    async apiAdministrationVideosGet(): Promise<Array<VideoDto>> {
        const response = await this.apiAdministrationVideosGetRaw();
        return await response.value();
    }

    /**
     */
    async apiAdministrationVideosIdDeleteRaw(requestParameters: ApiAdministrationVideosIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAdministrationVideosIdDelete.');
        }

        if (requestParameters.removeVideoCommand === null || requestParameters.removeVideoCommand === undefined) {
            throw new runtime.RequiredError('removeVideoCommand','Required parameter requestParameters.removeVideoCommand was null or undefined when calling apiAdministrationVideosIdDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["Administrators"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/administration/videos/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveVideoCommandToJSON(requestParameters.removeVideoCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

   /**
    */
    async apiAdministrationVideosIdDelete(requestParameters: ApiAdministrationVideosIdDeleteRequest): Promise<void> {
        await this.apiAdministrationVideosIdDeleteRaw(requestParameters);
    }

    /**
     */
    async apiAdministrationVideosIdGetRaw(requestParameters: ApiAdministrationVideosIdGetRequest): Promise<runtime.ApiResponse<VideoDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAdministrationVideosIdGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["Administrators"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/administration/videos/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VideoDtoFromJSON(jsonValue));
    }

   /**
    */
    async apiAdministrationVideosIdGet(requestParameters: ApiAdministrationVideosIdGetRequest): Promise<VideoDto> {
        const response = await this.apiAdministrationVideosIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAdministrationVideosIdPutRaw(requestParameters: ApiAdministrationVideosIdPutRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAdministrationVideosIdPut.');
        }

        if (requestParameters.updateVideoCommand === null || requestParameters.updateVideoCommand === undefined) {
            throw new runtime.RequiredError('updateVideoCommand','Required parameter requestParameters.updateVideoCommand was null or undefined when calling apiAdministrationVideosIdPut.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["Administrators"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/administration/videos/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateVideoCommandToJSON(requestParameters.updateVideoCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

   /**
    */
    async apiAdministrationVideosIdPut(requestParameters: ApiAdministrationVideosIdPutRequest): Promise<void> {
        await this.apiAdministrationVideosIdPutRaw(requestParameters);
    }

    /**
     */
    async apiAdministrationVideosPostRaw(requestParameters: ApiAdministrationVideosPostRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.addVideoCommand === null || requestParameters.addVideoCommand === undefined) {
            throw new runtime.RequiredError('addVideoCommand','Required parameter requestParameters.addVideoCommand was null or undefined when calling apiAdministrationVideosPost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["Administrators"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/administration/videos`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddVideoCommandToJSON(requestParameters.addVideoCommand),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

   /**
    */
    async apiAdministrationVideosPost(requestParameters: ApiAdministrationVideosPostRequest): Promise<string> {
        const response = await this.apiAdministrationVideosPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiVideosGetRaw(): Promise<runtime.ApiResponse<Array<VideoSummaryDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/videos`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VideoSummaryDtoFromJSON));
    }

   /**
    */
    async apiVideosGet(): Promise<Array<VideoSummaryDto>> {
        const response = await this.apiVideosGetRaw();
        return await response.value();
    }

    /**
     */
    async apiVideosIdFileGetRaw(requestParameters: ApiVideosIdFileGetRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiVideosIdFileGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.download !== undefined) {
            queryParameters['download'] = requestParameters.download;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/videos/{id}/file`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

   /**
    */
    async apiVideosIdFileGet(requestParameters: ApiVideosIdFileGetRequest): Promise<void> {
        await this.apiVideosIdFileGetRaw(requestParameters);
    }

    /**
     */
    async apiVideosIdGetRaw(requestParameters: ApiVideosIdGetRequest): Promise<runtime.ApiResponse<VideoSummaryDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiVideosIdGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/videos/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VideoSummaryDtoFromJSON(jsonValue));
    }

   /**
    */
    async apiVideosIdGet(requestParameters: ApiVideosIdGetRequest): Promise<VideoSummaryDto> {
        const response = await this.apiVideosIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiVideosIdPosterFileGetRaw(requestParameters: ApiVideosIdPosterFileGetRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiVideosIdPosterFileGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.download !== undefined) {
            queryParameters['download'] = requestParameters.download;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/videos/{id}/poster-file`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

   /**
    */
    async apiVideosIdPosterFileGet(requestParameters: ApiVideosIdPosterFileGetRequest): Promise<void> {
        await this.apiVideosIdPosterFileGetRaw(requestParameters);
    }

}
