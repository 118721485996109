import { IconName } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import deepOrange from "@material-ui/core/colors/deepOrange";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import React from "react";
import { getIdBadgeTypeName } from "../../../api";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
    row: {
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
    },
    circleIcon: {
      // Same colour as Chip default:
      //  https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/Chip/Chip.js
      color: theme.palette.grey[300],
    },
    unionCircleIcon: {
      color: deepOrange[500],
    },
    unionUserIcon: {
      color: theme.palette.common.white,
    },
    userContactIconRoot: {
      marginTop: 2,
      cursor: "help",
    },
    userContactCircleIcon: {
      color: "transparent",
    },
    link: {
      color: theme.palette.secondary.main,
    },
  });

interface TrainingProgramSessionUserDetailsProps extends WithStyles<typeof styles> {
  userName: string;
  companyName: string;
  userEmailAddress?: string | null;
  userWorkNumber?: string | null;
  idBadgeType?: number | null;
  idBadgeNumber?: string | null;
}

export const TrainingProgramSessionUserDetailsComponent = (props: TrainingProgramSessionUserDetailsProps) => {
  const { userName, companyName, userEmailAddress, userWorkNumber, idBadgeType, idBadgeNumber, classes } = props;

  const userIconName: IconName = userEmailAddress ? "user" : userWorkNumber ? "user-hard-hat" : "question";
  const userContactIconName: IconName = userEmailAddress ? "envelope" : userWorkNumber ? "id-card" : "question";
  const userContactIconTitle = userEmailAddress ? "Email" : userWorkNumber ? "ILWU Man Number" : undefined;
  const companyIconTitle = userEmailAddress ? "Company" : "Union Local";

  return (
    <div className={classes.root}>
      <Typography className={classes.row}>
        <span className="fa-stack">
          <FontAwesomeIcon
            icon={["fas", "circle"]}
            className={classNames(
              "fa-stack-2x",
              classes.circleIcon,
              userIconName === "user-hard-hat" ? classes.unionCircleIcon : undefined,
            )}
          />
          <FontAwesomeIcon
            icon={["fas", userIconName]}
            className={classNames("fa-stack-1x", userIconName === "user-hard-hat" ? classes.unionUserIcon : undefined)}
          />
        </span>
        &nbsp;&nbsp;{userName}
      </Typography>
      {/* Note: It should be the case companyName is always populated, but undefined check
      is for older sessions that did not require a companyName */}
      {companyName ? (
        <Typography className={classes.row}>
          <span className={classNames("fa-stack", classes.userContactIconRoot)} title={companyIconTitle}>
            <FontAwesomeIcon
              icon={["fas", "circle"]}
              className={classNames("fa-stack-2x", classes.userContactCircleIcon)}
            />
            <FontAwesomeIcon icon={["fas", "building"]} className="fa-stack-1x" />
          </span>
          &nbsp;&nbsp;{companyName}
        </Typography>
      ) : (
        companyName
      )}
      <Typography className={classes.row}>
        <span className={classNames("fa-stack", classes.userContactIconRoot)} title={userContactIconTitle}>
          <FontAwesomeIcon
            icon={["fas", "circle"]}
            className={classNames("fa-stack-2x", classes.userContactCircleIcon)}
          />
          <FontAwesomeIcon icon={["fas", userContactIconName]} className="fa-stack-1x" />
        </span>
        &nbsp;&nbsp;
        {userEmailAddress ? (
          <a className={classes.link} href={`mailto:${userEmailAddress}`}>
            {userEmailAddress}
          </a>
        ) : (
          userWorkNumber
        )}
      </Typography>{" "}
      {idBadgeType && idBadgeNumber ? (
        <Typography className={classes.row}>
          <span className={classNames("fa-stack", classes.userContactIconRoot)} title={getIdBadgeTypeName(idBadgeType)}>
            <FontAwesomeIcon
              icon={["fas", "circle"]}
              className={classNames("fa-stack-2x", classes.userContactCircleIcon)}
            />
            <FontAwesomeIcon icon={["fas", "id-badge"]} className="fa-stack-1x" />
          </span>
          &nbsp;&nbsp;{idBadgeNumber}
        </Typography>
      ) : undefined}
    </div>
  );
};

export const TrainingProgramSessionUserDetails = withStyles(styles)(TrainingProgramSessionUserDetailsComponent);
