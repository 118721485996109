import { toAuditDetailsFormData } from "@interface48/forms";
import { UpdateUserGroupCommand, UserGroupDto } from "../../../../api";
import { UserGroupFormData } from "../models";

export const toUserGroupFormData = (userGroup?: UserGroupDto): UserGroupFormData => {
  if (userGroup) {
    const userPrincipalNames = userGroup.userGroupMembers.map((ugm) => ugm.userPrincipalName);

    return {
      userGroupType: userGroup.userGroupType,
      userGroupTypeName: userGroup.userGroupTypeName,
      userPrincipalNames: userPrincipalNames,
      lastModified: toAuditDetailsFormData(userGroup.lastModifiedAt, userGroup.lastModifiedByUserName),
    };
  } else {
    return {
      lastModified: toAuditDetailsFormData(),
    };
  }
};

export const toUpdateUserGroupCommand = (userGroupFormData: UserGroupFormData): UpdateUserGroupCommand => {
  const { userGroupType, userPrincipalNames } = userGroupFormData;

  const updateUserGroupCommand: UpdateUserGroupCommand = {
    userGroupType: userGroupType!,
    userPrincipalNames: userPrincipalNames!,
  };

  return updateUserGroupCommand;
};
