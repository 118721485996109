/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddOrUpdateQuizQuestionCommand,
    AddOrUpdateQuizQuestionCommandFromJSON,
    AddOrUpdateQuizQuestionCommandToJSON,
} from './';

/**
 * 
 * @export
 * @interface UpdateQuizCommand
 */
export interface UpdateQuizCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateQuizCommand
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateQuizCommand
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateQuizCommand
     */
    durationMinutes: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateQuizCommand
     */
    minimumPassScorePercentage: number;
    /**
     * 
     * @type {Array<AddOrUpdateQuizQuestionCommand>}
     * @memberof UpdateQuizCommand
     */
    questions: Array<AddOrUpdateQuizQuestionCommand>;
}

export function UpdateQuizCommandFromJSON(json: any): UpdateQuizCommand {
    return {
        'name': json['name'],
        'description': json['description'],
        'durationMinutes': json['durationMinutes'],
        'minimumPassScorePercentage': json['minimumPassScorePercentage'],
        'questions': (json['questions'] as Array<any>).map(AddOrUpdateQuizQuestionCommandFromJSON),
    };
}

export function UpdateQuizCommandToJSON(value?: UpdateQuizCommand | null): any {
    if (value == undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'description': value.description,
        'durationMinutes': value.durationMinutes,
        'minimumPassScorePercentage': value.minimumPassScorePercentage,
        'questions': (value.questions as Array<any>).map(AddOrUpdateQuizQuestionCommandToJSON),
    };
}


