/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddOrUpdateTrainingProgramSessionCourseCommand,
    AddOrUpdateTrainingProgramSessionCourseCommandFromJSON,
    AddOrUpdateTrainingProgramSessionCourseCommandToJSON,
    AddOrUpdateTrainingProgramSessionQuizCommand,
    AddOrUpdateTrainingProgramSessionQuizCommandFromJSON,
    AddOrUpdateTrainingProgramSessionQuizCommandToJSON,
} from './';

/**
 * 
 * @export
 * @interface CompleteTrainingProgramSessionCommand
 */
export interface CompleteTrainingProgramSessionCommand {
    /**
     * 
     * @type {number}
     * @memberof CompleteTrainingProgramSessionCommand
     */
    trainingProgramVersion: number;
    /**
     * 
     * @type {string}
     * @memberof CompleteTrainingProgramSessionCommand
     */
    trainingProgramBeganAt: string;
    /**
     * 
     * @type {string}
     * @memberof CompleteTrainingProgramSessionCommand
     */
    userName: string;
    /**
     * 
     * @type {string}
     * @memberof CompleteTrainingProgramSessionCommand
     */
    companyName: string;
    /**
     * 
     * @type {string}
     * @memberof CompleteTrainingProgramSessionCommand
     */
    userEmailAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompleteTrainingProgramSessionCommand
     */
    userWorkNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CompleteTrainingProgramSessionCommand
     */
    idBadgeType?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CompleteTrainingProgramSessionCommand
     */
    idBadgeNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompleteTrainingProgramSessionCommand
     */
    supervisorName: string;
    /**
     * 
     * @type {number}
     * @memberof CompleteTrainingProgramSessionCommand
     */
    department?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompleteTrainingProgramSessionCommand
     */
    position?: number | null;
    /**
     * 
     * @type {Array<AddOrUpdateTrainingProgramSessionCourseCommand>}
     * @memberof CompleteTrainingProgramSessionCommand
     */
    courses: Array<AddOrUpdateTrainingProgramSessionCourseCommand>;
    /**
     * 
     * @type {Array<AddOrUpdateTrainingProgramSessionQuizCommand>}
     * @memberof CompleteTrainingProgramSessionCommand
     */
    quizzes: Array<AddOrUpdateTrainingProgramSessionQuizCommand>;
}

export function CompleteTrainingProgramSessionCommandFromJSON(json: any): CompleteTrainingProgramSessionCommand {
    return {
        'trainingProgramVersion': json['trainingProgramVersion'],
        'trainingProgramBeganAt': json['trainingProgramBeganAt'],
        'userName': json['userName'],
        'companyName': json['companyName'],
        'userEmailAddress': !exists(json, 'userEmailAddress') ? undefined : json['userEmailAddress'],
        'userWorkNumber': !exists(json, 'userWorkNumber') ? undefined : json['userWorkNumber'],
        'idBadgeType': !exists(json, 'idBadgeType') ? undefined : json['idBadgeType'],
        'idBadgeNumber': !exists(json, 'idBadgeNumber') ? undefined : json['idBadgeNumber'],
        'supervisorName': json['supervisorName'],
        'department': !exists(json, 'department') ? undefined : json['department'],
        'position': !exists(json, 'position') ? undefined : json['position'],
        'courses': (json['courses'] as Array<any>).map(AddOrUpdateTrainingProgramSessionCourseCommandFromJSON),
        'quizzes': (json['quizzes'] as Array<any>).map(AddOrUpdateTrainingProgramSessionQuizCommandFromJSON),
    };
}

export function CompleteTrainingProgramSessionCommandToJSON(value?: CompleteTrainingProgramSessionCommand | null): any {
    if (value == undefined) {
        return undefined;
    }
    return {
        'trainingProgramVersion': value.trainingProgramVersion,
        'trainingProgramBeganAt': value.trainingProgramBeganAt,
        'userName': value.userName,
        'companyName': value.companyName,
        'userEmailAddress': value.userEmailAddress,
        'userWorkNumber': value.userWorkNumber,
        'idBadgeType': value.idBadgeType,
        'idBadgeNumber': value.idBadgeNumber,
        'supervisorName': value.supervisorName,
        'department': value.department,
        'position': value.position,
        'courses': (value.courses as Array<any>).map(AddOrUpdateTrainingProgramSessionCourseCommandToJSON),
        'quizzes': (value.quizzes as Array<any>).map(AddOrUpdateTrainingProgramSessionQuizCommandToJSON),
    };
}


