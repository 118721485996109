import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";
import { duration as transitionDuration } from "@material-ui/core/styles/transitions";
import classNames from "classnames";
import React from "react";
import { Document, Presentation, Video } from "../../../api";
import { DocumentViewer } from "../../documents";
import { PresentationViewer } from "../../presentations";
import { VideoViewer } from "../../videos";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: 0,
      transition: theme.transitions.create(["padding"], {
        easing: theme.transitions.easing.sharp,
        duration: transitionDuration.enteringScreen,
      }),
    },
    rootWithMediaSelectorOpen: {
      paddingLeft: Math.min(375, window.innerWidth),
      transition: theme.transitions.create(["padding"], {
        easing: theme.transitions.easing.sharp,
        duration: transitionDuration.leavingScreen,
      }),
      [theme.breakpoints.up("sm")]: {
        paddingLeft: 375,
      },
    },
  });

interface TrainingResourceViewerProps extends WithStyles<typeof styles> {
  mediaItem: Document | Presentation | Video;
  availableWidth: number;
  availableHeight: number;
  sidebarOpen: boolean;
}

export const TrainingResourceViewerComponent = (props: TrainingResourceViewerProps) => {
  const { mediaItem, availableWidth, availableHeight, sidebarOpen, classes } = props;

  let viewerComponent: JSX.Element | null = null;
  if (mediaItem) {
    if (mediaItem.type === "document") {
      viewerComponent = (
        <DocumentViewer
          document={mediaItem as Document}
          availableWidth={availableWidth}
          availableHeight={availableHeight}
        />
      );
    } else if (mediaItem.type === "presentation") {
      viewerComponent = <PresentationViewer presentation={mediaItem as Presentation} availableWidth={availableWidth} />;
    } else if (mediaItem.type === "video") {
      viewerComponent = (
        <VideoViewer video={mediaItem as Video} availableWidth={availableWidth} availableHeight={availableHeight} />
      );
    }
  }
  return (
    <div className={classNames(classes.root, sidebarOpen && classes.rootWithMediaSelectorOpen)}>{viewerComponent}</div>
  );
};

export const TrainingResourceViewer = withStyles(styles)(TrainingResourceViewerComponent);
