import { AppThunkAction } from "@interface48/app";
import { Reducer } from "redux";
import { ApplicationState } from ".";

export interface UIState {
  sidebar: {
    open: boolean;
  };
}

export interface SidebarDisplayUpdateAction {
  type: "SIDEBAR_DISPLAY_UPDATE";
  open: boolean;
}

type KnownAction = SidebarDisplayUpdateAction;

export const actionCreators = {
  updateSidebarDisplay: (open: boolean) => ({ type: "SIDEBAR_DISPLAY_UPDATE", open }),

  toggleSidebarDisplay: (): AppThunkAction<KnownAction, ApplicationState> => (dispatch, getState) => {
    const currentOpen = getState().ui.sidebar.open;

    dispatch({ type: "SIDEBAR_DISPLAY_UPDATE", open: !currentOpen });
  },
};

export const initialState: UIState = {
  sidebar: {
    open: true,
  },
};

export const reducer: Reducer<UIState, KnownAction> = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case "SIDEBAR_DISPLAY_UPDATE":
      state = {
        ...state,
        sidebar: {
          ...state.sidebar,
          open: action.open,
        },
      };
      break;

    default:
  }

  return state;
};
