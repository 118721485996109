/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddOrUpdateUpdateTrainingScorecardDepartmentPositionCommand
 */
export interface AddOrUpdateUpdateTrainingScorecardDepartmentPositionCommand {
    /**
     * 
     * @type {number}
     * @memberof AddOrUpdateUpdateTrainingScorecardDepartmentPositionCommand
     */
    position?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AddOrUpdateUpdateTrainingScorecardDepartmentPositionCommand
     */
    targetCompletionCount: number;
}

export function AddOrUpdateUpdateTrainingScorecardDepartmentPositionCommandFromJSON(json: any): AddOrUpdateUpdateTrainingScorecardDepartmentPositionCommand {
    return {
        'position': !exists(json, 'position') ? undefined : json['position'],
        'targetCompletionCount': json['targetCompletionCount'],
    };
}

export function AddOrUpdateUpdateTrainingScorecardDepartmentPositionCommandToJSON(value?: AddOrUpdateUpdateTrainingScorecardDepartmentPositionCommand | null): any {
    if (value == undefined) {
        return undefined;
    }
    return {
        'position': value.position,
        'targetCompletionCount': value.targetCompletionCount,
    };
}


