/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    QuizQuestionDto,
    QuizQuestionDtoFromJSON,
    QuizQuestionDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface QuizDto
 */
export interface QuizDto {
    /**
     * 
     * @type {string}
     * @memberof QuizDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof QuizDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof QuizDto
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof QuizDto
     */
    durationMinutes: number;
    /**
     * 
     * @type {number}
     * @memberof QuizDto
     */
    minimumPassScorePercentage: number;
    /**
     * 
     * @type {number}
     * @memberof QuizDto
     */
    version: number;
    /**
     * 
     * @type {string}
     * @memberof QuizDto
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof QuizDto
     */
    createdByUserName: string;
    /**
     * 
     * @type {string}
     * @memberof QuizDto
     */
    lastModifiedAt: string;
    /**
     * 
     * @type {string}
     * @memberof QuizDto
     */
    lastModifiedByUserName: string;
    /**
     * 
     * @type {Array<QuizQuestionDto>}
     * @memberof QuizDto
     */
    questions?: Array<QuizQuestionDto> | null;
}

export function QuizDtoFromJSON(json: any): QuizDto {
    return {
        'id': json['id'],
        'name': json['name'],
        'description': json['description'],
        'durationMinutes': json['durationMinutes'],
        'minimumPassScorePercentage': json['minimumPassScorePercentage'],
        'version': json['version'],
        'createdAt': json['createdAt'],
        'createdByUserName': json['createdByUserName'],
        'lastModifiedAt': json['lastModifiedAt'],
        'lastModifiedByUserName': json['lastModifiedByUserName'],
        'questions': !exists(json, 'questions') ? undefined : (json['questions'] as Array<any>).map(QuizQuestionDtoFromJSON),
    };
}

export function QuizDtoToJSON(value?: QuizDto | null): any {
    if (value == undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'durationMinutes': value.durationMinutes,
        'minimumPassScorePercentage': value.minimumPassScorePercentage,
        'version': value.version,
        'createdAt': value.createdAt,
        'createdByUserName': value.createdByUserName,
        'lastModifiedAt': value.lastModifiedAt,
        'lastModifiedByUserName': value.lastModifiedByUserName,
        'questions': value.questions === undefined ? undefined : (value.questions as Array<any>).map(QuizQuestionDtoToJSON),
    };
}


