import { Table, TableBodyRowButton } from "@interface48/tables";
import React, { useState } from "react";
import { QuizDto } from "../../../api";

interface QuizzesTableProps {
  results?: QuizDto[];
  width: number;
  height?: number;
  isLoading: boolean;
}

interface QuizzesTableState {
  sortDirection: "asc" | "desc";
  sortPropertyName: string;
}

export const QuizzesTableComponent = (props: QuizzesTableProps) => {
  const { results = [], width, height, isLoading } = props;
  const [tableState, setTableState] = useState<QuizzesTableState>({ sortDirection: "asc", sortPropertyName: "name" });

  const viewDetailsButtonRenderer = (id: string, row: QuizDto) => (
    <TableBodyRowButton icon={["fas", "info"]} title="View Details" linkUrl={`/administration/quizzes/${row.id}`} />
  );

  const handleSortChange = (sortPropertyName: string, sortDirection: "asc" | "desc") => {
    setTableState({ sortDirection, sortPropertyName });
  };

  return (
    <Table
      columnSchemas={[
        {
          id: "id",
          width: 40,
          cellRenderer: viewDetailsButtonRenderer,
        },
        {
          id: "name",
          label: "Name",
        },
        { id: "description", label: "Description", hidden: width < 600 },
        { id: "durationMinutes", label: "Duration Minutes", hidden: width < 1000 },
        { id: "minimumPassScorePercentage", label: "Pass Score Percentage", hidden: width < 1100 },
        {
          id: "lastModifiedAt",
          label: "Last Modified",
          width: 140,
          format: { type: "date-time", options: { reference: "relative" } },
        },
      ]}
      data={results}
      dataSourceType="local"
      sorting={{
        sortDirection: tableState.sortDirection,
        sortPropertyName: tableState.sortPropertyName,
        onSortChange: handleSortChange,
      }}
      height={height}
      isLoading={isLoading}
    />
  );
};

export const QuizzesTable = QuizzesTableComponent;
