import { getApiError } from "@interface48/api";
import { AppActions, AppPageFrame, Loader, baseActionCreators, isUserAuthorized } from "@interface48/app";
import { formatIso8601String } from "@interface48/formatting";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import { Theme, WithStyles, WithTheme, createStyles, withStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import SaveAlt from "@material-ui/icons/SaveAlt";
import { saveAs } from "file-saver";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { bindActionCreators } from "redux";
import { TrainingScorecardDto, reportingTrainingScorecardApi } from "../../../api";
import { AuthorizationPolicy } from "../../../auth";
import {
  TrainingScorecardDialog,
  TrainingScorecardFormData,
  TrainingScorecardTable,
  toUpdateTrainingScorecardCommand,
} from "../../../components/training-scorecards";
import { ApplicationState, ReportingActions, ReportingState, actionCreators } from "../../../store";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
    },
    scorecard: {
      marginBottom: theme.spacing(3),
    },
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: theme.spacing(2),
    },
    title: {
      display: "flex",
      textAlign: "left",
      "& .MuiChip-root": {
        marginLeft: theme.spacing(2),
      },
    },
    sinceLabel: {
      display: "flex",
      alignItems: "center",
      "& p:first-child": {
        fontWeight: 600,
        marginRight: theme.spacing(1),
      },
    },
    actions: {
      display: "flex",
      "& > :first-child": {
        marginRight: theme.spacing(1),
      },
    },
    exportButtonWrapper: {
      position: "relative",
      display: "flex",
      alignItems: "center",
    },
    exportButtonProgress: {
      color: theme.palette.primary.main,
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  });

interface TrainingScorecardsPageStateProps {
  reportingState: ReportingState;
}

interface TrainingScorecardsPageDispatchProps {
  appActions: AppActions;
  reportingActions: ReportingActions;
}

interface TrainingScorecardsPageOwnProps extends RouteComponentProps<any>, WithStyles<typeof styles>, WithTheme {}

type TrainingScorecardsPageProps = TrainingScorecardsPageStateProps &
  TrainingScorecardsPageDispatchProps &
  TrainingScorecardsPageOwnProps;

export const TrainingScorecardsPageComponent = (props: TrainingScorecardsPageProps) => {
  const { history, appActions, reportingActions, reportingState, classes } = props;
  const { actionStatus, trainingScorecards } = reportingState;

  const [dialog, setDialog] = React.useState<{
    open: boolean;
    trainingScorecard?: TrainingScorecardDto;
  }>({ open: false });
  const [idsPendingExport, setIdsPendingExport] = React.useState<string[]>([]);

  useEffect(() => {
    reportingActions.requestTrainingScorecardsQuery();
  }, [reportingActions]);

  useEffect(() => {
    if (!actionStatus.pending) {
      // If an error occurred, display the message...
      if (actionStatus.error) {
        const errorMessage =
          actionStatus.error.message || "An unexpected error occurred upon attempting to process the last operation.";

        appActions.postSnackbarMessage(errorMessage, { variant: "error" });
      }
      // Otherwise, the operation completed successfully...
      else if (actionStatus.type === "REPORTING_TRAINING_SCORECARD_UPDATE_REQUEST") {
        reportingActions.requestTrainingScorecardsQuery();

        setDialog({ open: false });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionStatus.pending]);

  const handleBackClick = () => {
    history.push("/reporting");
  };

  const handleTrainingScorecardEdit = (trainingScorecard: TrainingScorecardDto) => () => {
    setDialog({
      open: true,
      trainingScorecard,
    });
  };

  const handleTrainingScorecardDialogCancel = () => {
    setDialog({ open: false });
  };

  const handleTrainingScorecardDialogUpdate = (formData: TrainingScorecardFormData) => {
    if (dialog.trainingScorecard) {
      reportingActions.requestTrainingScorecardUpdate(
        dialog.trainingScorecard.id,
        toUpdateTrainingScorecardCommand(formData),
      );
    }
  };

  const handleExportClick = (id: string) => async () => {
    setIdsPendingExport((ids) => [...(ids || []), id]);

    try {
      const file = await reportingTrainingScorecardApi.getTrainingScorecardReport(id);

      saveAs(file);
    } catch (errorResponse) {
      const error = await getApiError(errorResponse);

      appActions.postSnackbarMessage(error.message, { variant: "error" });
    }

    setIdsPendingExport((ids) => ids.filter((i) => i !== id));
  };

  const isAdminUser = isUserAuthorized([AuthorizationPolicy.Administrators]);

  const isTableLoading = actionStatus.type === "REPORTING_TRAINING_SCORECARDS_REQUEST" && actionStatus.pending;

  const isConfigurationDialogSubmitting =
    actionStatus.type === "REPORTING_TRAINING_SCORECARD_UPDATE_REQUEST" && actionStatus.pending;

  return (
    <AppPageFrame pageTitle={"Training Scorecards"} onBackClick={handleBackClick}>
      <div className={classes.container}>
        {!trainingScorecards ? (
          <Loader loadingText="Loading Training Scorecards" />
        ) : (
          trainingScorecards.map((trainingScorecard) => {
            const isExportPending = idsPendingExport.includes(trainingScorecard.id);

            return (
              <div key={trainingScorecard.name} className={classes.scorecard}>
                <div className={classes.header}>
                  <div className={classes.title}>
                    <Typography variant="h5">{trainingScorecard.name}</Typography>
                    <Chip
                      label={
                        <div className={classes.sinceLabel}>
                          <Typography variant="body2">Since</Typography>
                          <Typography variant="body2">
                            {formatIso8601String(trainingScorecard.currentPeriodBeginAt, "date", {
                              template: "MMM D, YYYY",
                            })}
                          </Typography>
                        </div>
                      }
                    />
                  </div>
                  {isAdminUser && (
                    <div className={classes.actions}>
                      <div className={classes.exportButtonWrapper}>
                        <Button
                          color="primary"
                          variant="contained"
                          startIcon={<SaveAlt />}
                          disabled={trainingScorecard.departments.length === 0 || isExportPending}
                          onClick={handleExportClick(trainingScorecard.id)}
                        >
                          Export
                        </Button>
                        {isExportPending && <CircularProgress size={24} className={classes.exportButtonProgress} />}
                      </div>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<EditIcon />}
                        onClick={handleTrainingScorecardEdit(trainingScorecard)}
                      >
                        Edit...
                      </Button>
                    </div>
                  )}
                </div>
                <TrainingScorecardTable trainingScorecard={trainingScorecard} isLoading={isTableLoading} />
              </div>
            );
          })
        )}
        <TrainingScorecardDialog
          trainingScorecard={dialog.trainingScorecard}
          isOpen={dialog.open}
          isActionPending={isConfigurationDialogSubmitting}
          onCancel={handleTrainingScorecardDialogCancel}
          onUpdate={handleTrainingScorecardDialogUpdate}
        />
      </div>
    </AppPageFrame>
  );
};

export const TrainingScorecardsPage = withStyles(styles)(
  connect<
    TrainingScorecardsPageStateProps,
    TrainingScorecardsPageDispatchProps,
    TrainingScorecardsPageOwnProps,
    ApplicationState
  >(
    (state) => ({
      reportingState: state.reporting,
    }),
    (dispatch) => ({
      appActions: bindActionCreators(baseActionCreators.app, dispatch),
      reportingActions: bindActionCreators(actionCreators.reporting, dispatch),
    }),
  )(TrainingScorecardsPageComponent),
);
