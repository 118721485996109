import Bowser, { OS_MAP } from "bowser";
import moment from "moment";
import React from "react";
import { CourseSummaryDto } from "../../../api";
import { CourseSessionState } from "../models";

interface CourseViewerProps {
  course: CourseSummaryDto;
  courseSessionState: CourseSessionState;
  availableHeight: number;
  onChange: (courseState: CourseSessionState, isCourseComplete?: boolean) => void;
}

interface CourseViewerState {
  courseState: { [key: string]: string };
  devicePlatform?: string;
}

export const CourseViewerComponent = class extends React.Component<CourseViewerProps, CourseViewerState> {
  private readonly _courseDataKey = "cmi.suspend_data";
  private readonly _courseStatusKey = "cmi.core.lesson_status";

  private constructor(props: CourseViewerProps) {
    super(props);

    const { courseSessionState } = this.props;
    const { courseStateJson } = courseSessionState;

    this.state = {
      courseState: courseStateJson ? (JSON.parse(courseStateJson) as { [key: string]: string }) : {},
      devicePlatform: Bowser.parse(window.navigator.userAgent).os.name,
    };
  }

  public componentDidMount() {
    const { course } = this.props;
    const { courseState } = this.state;

    window.addEventListener("resize", this.forceReRender);

    (window as any).API = {
      LMSInitialize: () => {
        console.info(`Initialize Course '${course.name}'.`);

        return true;
      },
      LMSFinish: () => {
        console.info(`Finish Course '${course.name}'.`);

        return true;
      },
      LMSGetValue: (name: string) => {
        return courseState[name] ?? "";
      },
      LMSSetValue: (name: string, value: string) => {
        courseState[name] = value;

        if (name === this._courseDataKey) {
          this.onCourseStateChange(JSON.stringify(courseState));
        } else if (name === this._courseStatusKey && value === "passed") {
          this.onCourseCompleted();
        }

        return true;
      },
      LMSCommit: (name: string) => {},
      LMSGetLastError: () => {},
      LMSGetErrorString: (name: string) => {},
      LMSGetDiagnostic: (name: string) => {},
    };

    // If the Course has previously been passed, ensure it is set as completed...
    // -  Note: This is a workaround for the SCORM API not emitting an LMSSetValue event for the course status key when
    //    the Course has been published from Articulate with "Track using quiz result" selected as the 'Tracking'
    //    option, despite the Quiz selected for "Track using quiz result" already having been passed at some point.
    // -  Note: This workaround is not required when the Course has been published from Articulate with
    //    "Track using course completion" selected as the 'Tracking' option.
    if (courseState[this._courseStatusKey] === "passed") {
      this.onCourseCompleted();
    }
  }

  public render() {
    const { course, availableHeight } = this.props;
    const { devicePlatform } = this.state;

    return (
      <div
        id={"course-container"}
        className={"course-viewer-container"}
        style={{ height: availableHeight - 2, overflowY: devicePlatform === OS_MAP.iOS ? "scroll" : undefined }}
      >
        <iframe title="Course Viewer" src={`/courses/${course.id}/scormdriver/indexAPI.html`} />
      </div>
    );
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.forceReRender);
  }

  private onCourseStateChange = (courseStateJson: string) => {
    const { courseSessionState, onChange } = this.props;

    onChange({
      ...courseSessionState,
      courseStateJson,
    });
  };

  private onCourseCompleted = () => {
    const { courseSessionState, onChange } = this.props;

    // Preserve the previous completion time, if applicable
    const completedAt = courseSessionState.completedAt ?? moment().toISOString();

    onChange({ ...courseSessionState, completedAt }, true);
  };

  private forceReRender = () => {
    this.forceUpdate();
  };
};

export const CourseViewer = CourseViewerComponent;
