import { formatFileSize } from "@interface48/formatting";
import { Table, TableBodyRowButton } from "@interface48/tables";
import React, { useState } from "react";
import { CourseDto } from "../../../api";

interface CoursesTableProps {
  results?: CourseDto[];
  width: number;
  height?: number;
  isLoading: boolean;
}

interface CoursesTableState {
  sortDirection: "asc" | "desc";
  sortPropertyName: string;
}

export const CoursesTableComponent = (props: CoursesTableProps) => {
  const { results = [], width, height, isLoading } = props;
  const [tableState, setTableState] = useState<CoursesTableState>({ sortDirection: "asc", sortPropertyName: "name" });

  const viewDetailsButtonRenderer = (id: string, row: CourseDto) => (
    <TableBodyRowButton icon={["fas", "info"]} title="View Details" linkUrl={`/administration/courses/${row.id}`} />
  );

  const fileSizeRenderer = (fileSize: number, row: CourseDto) => formatFileSize(fileSize);

  const handleSortChange = (sortPropertyName: string, sortDirection: "asc" | "desc") => {
    setTableState({ sortDirection, sortPropertyName });
  };

  return (
    <Table
      columnSchemas={[
        {
          id: "id",
          width: 40,
          cellRenderer: viewDetailsButtonRenderer,
        },
        {
          id: "name",
          label: "Name",
        },
        { id: "durationMinutes", label: "Duration (Minutes)", hidden: width < 600 },
        { id: "fileSizeBytes", label: "File Size", cellRenderer: fileSizeRenderer, hidden: width < 1300 },
        {
          id: "lastModifiedAt",
          label: "Last Modified",
          width: 140,
          format: { type: "date-time", options: { reference: "relative" } },
        },
      ]}
      data={results}
      dataSourceType="local"
      sorting={{
        sortDirection: tableState.sortDirection,
        sortPropertyName: tableState.sortPropertyName,
        onSortChange: handleSortChange,
      }}
      height={height}
      isLoading={isLoading}
    />
  );
};

export const CoursesTable = CoursesTableComponent;
