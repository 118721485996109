/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CourseResultDto
 */
export interface CourseResultDto {
    /**
     * 
     * @type {string}
     * @memberof CourseResultDto
     */
    courseName: string;
    /**
     * 
     * @type {number}
     * @memberof CourseResultDto
     */
    courseVersion: number;
    /**
     * 
     * @type {boolean}
     * @memberof CourseResultDto
     */
    isPassResult: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseResultDto
     */
    isObsoleteResult: boolean;
}

export function CourseResultDtoFromJSON(json: any): CourseResultDto {
    return {
        'courseName': json['courseName'],
        'courseVersion': json['courseVersion'],
        'isPassResult': json['isPassResult'],
        'isObsoleteResult': json['isObsoleteResult'],
    };
}

export function CourseResultDtoToJSON(value?: CourseResultDto | null): any {
    if (value == undefined) {
        return undefined;
    }
    return {
        'courseName': value.courseName,
        'courseVersion': value.courseVersion,
        'isPassResult': value.isPassResult,
        'isObsoleteResult': value.isObsoleteResult,
    };
}


