import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";
import React, { ChangeEvent } from "react";
import { MEDIA_TYPES, MediaTypes } from "../../../api";

const styles = (theme: Theme) =>
  createStyles({
    mediaSelectorFilter: {
      display: "flex",
      flexWrap: "wrap",
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      borderBottom: `solid 1px ${theme.palette.divider}`,
    },
    formControl: {
      margin: `${theme.spacing(1)}px 0`,
    },
  });

interface TrainingResourceSelectorFilterProps extends WithStyles<typeof styles> {
  mediaType: string;
  mediaCategory: string;
  mediaCategoryNames: string[];
  mediaSearchFilter: string;
  onMediaTypeChange: (mediaType: MediaTypes) => void;
  onMediaCategoryChange: (mediaCategory: string) => void;
  onMediaSearchFilterChange: (searchFilter: string) => void;
}

export const TrainingResourceSelectorFilterComponent = (props: TrainingResourceSelectorFilterProps) => {
  const {
    mediaType,
    mediaCategory,
    mediaCategoryNames,
    mediaSearchFilter,
    onMediaTypeChange,
    onMediaCategoryChange,
    onMediaSearchFilterChange,
    classes,
  } = props;

  const mediaTypeMenuItems = [];
  mediaTypeMenuItems.push(
    <MenuItem key={"all"} value={"all"}>
      (All)
    </MenuItem>,
  );
  mediaTypeMenuItems.push(
    MEDIA_TYPES.map((mt) => {
      return (
        <MenuItem key={mt.value} value={mt.value}>
          {mt.description}
        </MenuItem>
      );
    }),
  );

  const mediaCategoryMenuItems = [];
  mediaCategoryMenuItems.push(
    <MenuItem key={"all"} value={"all"}>
      (All)
    </MenuItem>,
  );
  mediaCategoryMenuItems.push(
    mediaCategoryNames.map((mc) => {
      return (
        <MenuItem key={mc} value={mc}>
          {mc}
        </MenuItem>
      );
    }),
  );

  const handleMediaTypeChange = (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    const type = event.target.value as MediaTypes;

    onMediaTypeChange(type);
  };

  const handleMediaCategoryChange = (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    const category = event.target.value as string;

    onMediaCategoryChange(category);
  };

  const handleMediaSearchFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchFilter = event.target.value;

    onMediaSearchFilterChange(searchFilter);
  };

  return (
    <div className={classes.mediaSelectorFilter}>
      <FormControl className={classes.formControl} fullWidth={true}>
        <InputLabel htmlFor="resource-type">Resource Type</InputLabel>
        <Select
          input={<Input name="resource-type" id="resource-type" />}
          fullWidth={true}
          value={mediaType}
          onChange={handleMediaTypeChange}
        >
          {mediaTypeMenuItems}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl} fullWidth={true}>
        <InputLabel htmlFor="resource-category">Resource Category</InputLabel>
        <Select
          input={<Input name="resource-category" id="resource-category" />}
          fullWidth={true}
          value={mediaCategory}
          onChange={handleMediaCategoryChange}
        >
          {mediaCategoryMenuItems}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl} fullWidth={true}>
        <Input placeholder="Search..." value={mediaSearchFilter} onChange={handleMediaSearchFilterChange} />
      </FormControl>
    </div>
  );
};

export const TrainingResourceSelectorFilter = withStyles(styles)(TrainingResourceSelectorFilterComponent);
