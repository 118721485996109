import { OptionItem } from "@interface48/api";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Theme, WithStyles, createStyles, withStyles } from "@material-ui/core/styles";
import sortBy from "lodash/sortBy";
import React, { useEffect, useMemo } from "react";
import { Department, Position } from "../../../api";
import { SelectField } from "../../shared";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
    },
    content: {
      padding: theme.spacing(2),
    },
    loader: {
      position: "absolute",
      bottom: 0,
      width: "100%",
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
    },
  });

const ANY_OPTION_VALUE = -1;

interface TrainingProgramFiltersPanelProps extends WithStyles<typeof styles> {
  departmentOptionItems: OptionItem<number>[];
  departmentPositionOptionItemsMap: { [department: number]: OptionItem<number>[] };
  filters: { department?: Department; position?: Position };
  message?: JSX.Element;
  isLoading: boolean;
  onFiltersChange: (filters: { department?: Department; position?: Position }) => void;
}

const TrainingProgramFiltersPanelComponent = (props: TrainingProgramFiltersPanelProps) => {
  const { filters, message, isLoading, classes, onFiltersChange } = props;

  const departmentOptionItems = useMemo(
    () => [{ value: ANY_OPTION_VALUE, label: "(Any)" }, ...sortBy(props.departmentOptionItems, (oi) => oi.label)],
    [props.departmentOptionItems],
  );

  const departmentPositionOptionItems = useMemo(
    () => [
      { value: ANY_OPTION_VALUE, label: "(Any)" },
      ...(filters.department
        ? sortBy(props.departmentPositionOptionItemsMap[filters.department], (oi) => oi.label)
        : []),
    ],
    [props.departmentPositionOptionItemsMap, filters.department],
  );

  useEffect(() => {
    if ((filters.position ?? 0) > 0 && !departmentPositionOptionItems.some((oi) => oi.value === filters.position)) {
      onFiltersChange({ ...filters, position: undefined });
    }
  }, [filters, departmentPositionOptionItems, onFiltersChange]);

  const handleChange = (propertyName: "department" | "position") => (value: number | null | undefined) => {
    // If the filter value has actually changed (i.e., suppress onFiltersChange if the field was just focused then
    // immediately blurred without changing value)...
    if (filters[propertyName] !== (value !== ANY_OPTION_VALUE ? value : undefined)) {
      onFiltersChange({ ...filters, [propertyName]: value !== ANY_OPTION_VALUE ? value : undefined });
    }
  };

  return (
    <Paper variant="outlined" className={classes.root}>
      <div className={classes.content}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <Typography variant="h6">Filters</Typography>
            {message}
          </Grid>
          <Grid item={true} xs={12} sm={6}>
            <SelectField<number>
              id="departments"
              label="Department"
              fullWidth={true}
              value={filters.department ?? ANY_OPTION_VALUE}
              options={departmentOptionItems}
              onChange={handleChange("department")}
            />
          </Grid>
          <Grid item={true} xs={12} sm={6}>
            <SelectField<number>
              id="position"
              label="Position"
              fullWidth={true}
              disabled={!filters.department}
              value={filters.position ?? ANY_OPTION_VALUE}
              options={departmentPositionOptionItems}
              onChange={handleChange("position")}
            />
          </Grid>
        </Grid>
      </div>
      {isLoading ? <LinearProgress className={classes.loader} /> : null}
    </Paper>
  );
};

export const TrainingProgramFiltersPanel = withStyles(styles)(TrainingProgramFiltersPanelComponent);
