import { AppActions, AppState, baseActionCreators } from "@interface48/app";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { bindActionCreators } from "redux";
import { Document, Media, Video } from "../../api";
import { Sidebar } from "../../components/sidebar";
import { TrainingResourceSelector, TrainingResourceViewer } from "../../components/training-resources";
import { actionCreators, ApplicationState, MediaActions, MediaState, UIState } from "../../store";

interface TrainingResourceBrowserPageMatchParamsProps {
  documentId?: string;
  presentationId?: string;
  videoId?: string;
}

interface TrainingResourceBrowserPageStateProps {
  appState: AppState;
  uiState: UIState;
  mediaState: MediaState;
}

interface TrainingResourceBrowserPageDispatchProps {
  appActions: AppActions;
  mediaActions: MediaActions;
}

interface TrainingResourceBrowserPageOwnProps
  extends RouteComponentProps<TrainingResourceBrowserPageMatchParamsProps> {}

type TrainingResourceBrowserPageProps = TrainingResourceBrowserPageStateProps &
  TrainingResourceBrowserPageDispatchProps &
  TrainingResourceBrowserPageOwnProps;

interface TrainingResourceBrowserPageState {
  media?: Array<Document | Video>;
  selectedMediaItemId?: string;
  mediaItem?: Document | Video;
  viewerAvailableHeight: number;
}

export const TrainingResourceBrowserPageComponent = class extends React.Component<
  TrainingResourceBrowserPageProps,
  TrainingResourceBrowserPageState
> {
  constructor(props: TrainingResourceBrowserPageProps) {
    super(props);

    const { mediaState, match } = this.props;
    const { media } = mediaState;
    const { params } = match;
    const { documentId, presentationId, videoId } = params;

    const selectedMediaItemId = documentId || presentationId || videoId || this.getDefaultMediaItemId(media);
    const viewerAvailableHeight = this.getViewerAvailableHeight();

    this.state = {
      selectedMediaItemId,
      viewerAvailableHeight,
    };
  }

  public componentDidMount() {
    const { mediaState, mediaActions } = this.props;

    if (mediaState.mediaCategoryNames) {
      mediaActions.loadMedia();
    } else {
      mediaActions.requestMediaCategoryNames();
    }
  }

  public render() {
    const { mediaState, mediaActions, uiState, history } = this.props;
    const { mediaCategoryNames, mediaSelector } = mediaState;
    const { mediaType, mediaCategory, mediaSearchFilter } = mediaSelector;
    const { onMediaTypeChange, onMediaCategoryChange, onMediaSearchFilterChange, onSelectedMediaItemChange } =
      mediaActions;
    const { sidebar } = uiState;
    const { media, mediaItem, selectedMediaItemId, viewerAvailableHeight } = this.state;

    const sidebarOpen = sidebar.open;
    const viewerAvailableWidth = window.innerWidth - (sidebar.open ? 375 : 0);

    return (
      <>
        <Sidebar history={history}>
          <TrainingResourceSelector
            sidebarOpen={sidebar.open}
            mediaType={mediaType}
            mediaCategory={mediaCategory}
            mediaCategoryNames={mediaCategoryNames ?? []}
            mediaSearchFilter={mediaSearchFilter}
            media={media!}
            selectedMediaItemId={selectedMediaItemId!}
            onMediaTypeChange={onMediaTypeChange}
            onMediaCategoryChange={onMediaCategoryChange}
            onMediaSearchFilterChange={onMediaSearchFilterChange}
            onSelectedMediaItemChange={onSelectedMediaItemChange}
          />
        </Sidebar>
        <TrainingResourceViewer
          mediaItem={mediaItem!}
          availableWidth={viewerAvailableWidth}
          availableHeight={viewerAvailableHeight}
          sidebarOpen={sidebarOpen}
        />
      </>
    );
  }

  public componentDidUpdate(prevProps: TrainingResourceBrowserPageProps) {
    const { appState: prevAppState, mediaState: prevMediaState } = prevProps;
    const { mediaSelector: prevTrainingResourceSelector } = prevMediaState;
    const {
      mediaType: prevMediaType,
      mediaCategory: prevMediaCategory,
      mediaSearchFilter: prevMediaSearchFilter,
    } = prevTrainingResourceSelector;
    const { appState, appActions, mediaState, mediaActions, match } = this.props;
    const { media, mediaSelector } = mediaState;
    const { mediaType, mediaCategory, mediaSearchFilter } = mediaSelector;
    const { params } = match;
    const { documentId, presentationId, videoId } = params;
    const { media: currMedia, selectedMediaItemId: currSelectedMediaItemId } = this.state;

    const selectedMediaItemId = documentId || presentationId || videoId || this.getDefaultMediaItemId(media);

    const prevActionStatus = prevMediaState.actionStatus;
    const actionStatus = mediaState.actionStatus;
    // If some API action just completed...
    if (prevActionStatus.pending && !actionStatus.pending) {
      if (actionStatus.error) {
        const errorMessage =
          actionStatus.error.message || "An unexpected error occurred upon attempting to process the last operation.";

        appActions.postSnackbarMessage(errorMessage, { variant: "error" });
      }
      // Otherwise, the operation completed successfully...
      else {
        // If we just loaded the Media Categories, then load the Media...
        if (actionStatus.type === "MEDIA_CATEGORIES_REQUEST") {
          mediaActions.loadMedia();
        }
        // Otherwise if we just loaded the Media...
        else if (actionStatus.type === "LOAD_MEDIA_REQUEST" && media) {
          const filteredMedia = this.getFilteredMedia(media, mediaType, mediaCategory, mediaSearchFilter);

          const mediaItem = filteredMedia.find((mi) => {
            return mi.id === selectedMediaItemId;
          });

          this.setState({ ...this.state, mediaItem, media: filteredMedia, selectedMediaItemId });
        }
      }
    }
    // Otherwise, if any of the Media Selector filters have changed, re-filter the Media list...
    else if (
      !(
        prevMediaType === mediaType &&
        prevMediaCategory === mediaCategory &&
        prevMediaSearchFilter === mediaSearchFilter
      ) &&
      media
    ) {
      const filteredMedia = this.getFilteredMedia(media, mediaType, mediaCategory, mediaSearchFilter);

      this.setState({ ...this.state, media: filteredMedia });
    }
    // Otherwise, if Media is loaded and the selected item has changed...
    else if (currMedia && media && currSelectedMediaItemId !== selectedMediaItemId) {
      const mediaItem = currMedia.find((mi) => {
        return mi.id === selectedMediaItemId;
      });

      this.setState({ ...this.state, mediaItem, selectedMediaItemId });
    }

    const prevAppFrameContentHeight = prevAppState.appFrame.contentHeight;
    const appFrameContentHeight = appState.appFrame.contentHeight;
    // If the window height has changed...
    if (prevAppFrameContentHeight !== appFrameContentHeight) {
      const viewerAvailableHeight = this.getViewerAvailableHeight();

      this.setState({ ...this.state, viewerAvailableHeight });
    }
  }

  private getFilteredMedia = (
    media: Media[],
    mediaType: "all" | "document" | "video" | "presentation",
    mediaCategory: string,
    mediaSearchFilter: string,
  ) => {
    const loweredMediaType = mediaType.toLowerCase();
    const loweredMediaCategory = mediaCategory.toLowerCase();
    const trimmedLoweredMediaSearchFilter = mediaSearchFilter.trim().toLowerCase();

    return media.filter((mediaItem: Media) => {
      return (
        (loweredMediaType === "all" || loweredMediaType === mediaItem.type.toLowerCase()) &&
        (loweredMediaCategory === "all" || loweredMediaCategory === mediaItem.mediaCategoryName.toLowerCase()) &&
        (trimmedLoweredMediaSearchFilter === "" ||
          mediaItem.name.toLowerCase().indexOf(trimmedLoweredMediaSearchFilter) > -1)
      );
    });
  };

  private getDefaultMediaItemId = (media?: Media[]): string | undefined => {
    const defaultMediaItem = media && media.find((m) => m.name === "Neptune Employee Orientation Full Version");

    return defaultMediaItem ? defaultMediaItem.id : undefined;
  };

  private getViewerAvailableHeight = () => {
    const { appState } = this.props;

    return appState.appFrame.contentHeight;
  };
};

export const TrainingResourceBrowserPage = connect<
  TrainingResourceBrowserPageStateProps,
  TrainingResourceBrowserPageDispatchProps,
  TrainingResourceBrowserPageOwnProps,
  ApplicationState
>(
  (state) => ({
    appState: state.app,
    uiState: state.ui,
    mediaState: state.media,
  }),
  (dispatch) => ({
    appActions: bindActionCreators(baseActionCreators.app, dispatch),
    mediaActions: bindActionCreators(actionCreators.media, dispatch),
  }),
)(TrainingResourceBrowserPageComponent);
