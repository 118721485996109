import { AppPageFrame } from "@interface48/app";
import Button from "@material-ui/core/Button";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { TrainingProgramsTable } from "../../../components/training-programs";
import { actionCreators, AdministrationActions, AdministrationState, ApplicationState } from "../../../store";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      margin: theme.spacing(2),
    },
    button: {
      marginLeft: "auto",
      marginBottom: theme.spacing(2),
    },
  });

interface TrainingProgramsIndexPageStateProps {
  administrationState: AdministrationState;
}

interface TrainingProgramsIndexPageDispatchProps {
  administrationActions: AdministrationActions;
}

interface TrainingProgramsIndexPageOwnProps extends RouteComponentProps<any>, WithStyles<typeof styles> {}

type TrainingProgramsIndexPageProps = TrainingProgramsIndexPageStateProps &
  TrainingProgramsIndexPageDispatchProps &
  TrainingProgramsIndexPageOwnProps;

const TrainingProgramsIndexPageComponent = class extends React.Component<TrainingProgramsIndexPageProps> {
  public componentDidMount() {
    const { administrationActions } = this.props;

    administrationActions.requestTrainingPrograms();
  }

  public render() {
    const { administrationState, classes } = this.props;
    const { trainingPrograms, actionStatus } = administrationState;

    const windowWidth = window.innerWidth;
    const isTableLoading =
      actionStatus && actionStatus.type === "ADMINISTRATION_TRAINING_PROGRAMS_REQUEST" && actionStatus.pending;

    return (
      <AppPageFrame pageTitle="Training Programs" fullWidth={true} onBackClick={this.handleBackClick}>
        <div className={classes.root}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            component={Link}
            to="/administration/training-programs/add"
          >
            Add Training Program
          </Button>
          <TrainingProgramsTable results={trainingPrograms ?? []} width={windowWidth} isLoading={isTableLoading} />
        </div>
      </AppPageFrame>
    );
  }

  private handleBackClick = () => {
    this.props.history.push("/administration");
  };
};

export const TrainingProgramsIndexPage = withStyles(styles)(
  connect<
    TrainingProgramsIndexPageStateProps,
    TrainingProgramsIndexPageDispatchProps,
    TrainingProgramsIndexPageOwnProps,
    ApplicationState
  >(
    (state) => ({
      administrationState: state.administration,
    }),
    (dispatch) => ({
      administrationActions: bindActionCreators(actionCreators.administration, dispatch),
    }),
  )(TrainingProgramsIndexPageComponent),
);
