import { FieldProps, JsonSchema } from "@interface48/forms";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";

const styles = (theme: Theme) =>
  createStyles({
    label: {
      color: theme.palette.text.secondary,
      fontSize: "75%",
    },
    trainingProgramComponent: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    trainingProgramComponentNumber: {
      marginRight: theme.spacing(2),
    },
    trainingProgramComponentText: {},
  });

interface TrainingProgramComponentsReadOnlyFieldProps extends FieldProps<string[]>, WithStyles<typeof styles> {}

export const TrainingProgramComponentsReadOnlyFieldComponent = (props: TrainingProgramComponentsReadOnlyFieldProps) => {
  const { schema, formData, classes } = props;
  const label = schema.title;

  const trainingProgramComponentOptions = ((schema.items as JsonSchema)?.oneOf ?? []) as Array<{
    const: string;
    title: string;
  }>;

  return (
    <>
      <Typography className={classes.label}>{label}</Typography>
      {formData.map((trainingProgramComponentId, index) => {
        const trainingProgramComponentNumber = index + 1;

        const trainingProgramComponent = trainingProgramComponentOptions.find(
          (o) => o.const === trainingProgramComponentId,
        );

        if (!trainingProgramComponent) {
          throw Error("Training Program Component not found within list of options. Please reload the page.");
        }

        return (
          <div key={`tpc-${index}`} className={classes.trainingProgramComponent}>
            <div className={classes.trainingProgramComponentNumber}>{`${trainingProgramComponentNumber}.`}</div>
            <div className={classes.trainingProgramComponentText}>{trainingProgramComponent.title}</div>
          </div>
        );
      })}
    </>
  );
};

export const TrainingProgramComponentsReadOnlyField = withStyles(styles)(
  TrainingProgramComponentsReadOnlyFieldComponent,
);
