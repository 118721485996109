/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TrainingProgramSessionCourseStateDto,
    TrainingProgramSessionCourseStateDtoFromJSON,
    TrainingProgramSessionCourseStateDtoToJSON,
    TrainingProgramSessionQuizStateDto,
    TrainingProgramSessionQuizStateDtoFromJSON,
    TrainingProgramSessionQuizStateDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface TrainingProgramSessionStateDto
 */
export interface TrainingProgramSessionStateDto {
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramSessionStateDto
     */
    trainingProgramId: string;
    /**
     * 
     * @type {number}
     * @memberof TrainingProgramSessionStateDto
     */
    trainingProgramVersion: number;
    /**
     * 
     * @type {number}
     * @memberof TrainingProgramSessionStateDto
     */
    trainingProgramMajorVersion: number;
    /**
     * 
     * @type {number}
     * @memberof TrainingProgramSessionStateDto
     */
    trainingProgramStepNumber?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TrainingProgramSessionStateDto
     */
    maximumTrainingProgramStepNumber?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TrainingProgramSessionStateDto
     */
    maximumTrainingProgramStepProgress?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramSessionStateDto
     */
    trainingProgramBeganAt: string;
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramSessionStateDto
     */
    trainingProgramCompletedAt?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramSessionStateDto
     */
    trainingProgramLastModifiedAt: string;
    /**
     * 
     * @type {Array<TrainingProgramSessionCourseStateDto>}
     * @memberof TrainingProgramSessionStateDto
     */
    courses: Array<TrainingProgramSessionCourseStateDto>;
    /**
     * 
     * @type {Array<TrainingProgramSessionQuizStateDto>}
     * @memberof TrainingProgramSessionStateDto
     */
    quizzes: Array<TrainingProgramSessionQuizStateDto>;
}

export function TrainingProgramSessionStateDtoFromJSON(json: any): TrainingProgramSessionStateDto {
    return {
        'trainingProgramId': json['trainingProgramId'],
        'trainingProgramVersion': json['trainingProgramVersion'],
        'trainingProgramMajorVersion': json['trainingProgramMajorVersion'],
        'trainingProgramStepNumber': !exists(json, 'trainingProgramStepNumber') ? undefined : json['trainingProgramStepNumber'],
        'maximumTrainingProgramStepNumber': !exists(json, 'maximumTrainingProgramStepNumber') ? undefined : json['maximumTrainingProgramStepNumber'],
        'maximumTrainingProgramStepProgress': !exists(json, 'maximumTrainingProgramStepProgress') ? undefined : json['maximumTrainingProgramStepProgress'],
        'trainingProgramBeganAt': json['trainingProgramBeganAt'],
        'trainingProgramCompletedAt': !exists(json, 'trainingProgramCompletedAt') ? undefined : json['trainingProgramCompletedAt'],
        'trainingProgramLastModifiedAt': json['trainingProgramLastModifiedAt'],
        'courses': (json['courses'] as Array<any>).map(TrainingProgramSessionCourseStateDtoFromJSON),
        'quizzes': (json['quizzes'] as Array<any>).map(TrainingProgramSessionQuizStateDtoFromJSON),
    };
}

export function TrainingProgramSessionStateDtoToJSON(value?: TrainingProgramSessionStateDto | null): any {
    if (value == undefined) {
        return undefined;
    }
    return {
        'trainingProgramId': value.trainingProgramId,
        'trainingProgramVersion': value.trainingProgramVersion,
        'trainingProgramMajorVersion': value.trainingProgramMajorVersion,
        'trainingProgramStepNumber': value.trainingProgramStepNumber,
        'maximumTrainingProgramStepNumber': value.maximumTrainingProgramStepNumber,
        'maximumTrainingProgramStepProgress': value.maximumTrainingProgramStepProgress,
        'trainingProgramBeganAt': value.trainingProgramBeganAt,
        'trainingProgramCompletedAt': value.trainingProgramCompletedAt,
        'trainingProgramLastModifiedAt': value.trainingProgramLastModifiedAt,
        'courses': (value.courses as Array<any>).map(TrainingProgramSessionCourseStateDtoToJSON),
        'quizzes': (value.quizzes as Array<any>).map(TrainingProgramSessionQuizStateDtoToJSON),
    };
}


