import { actionCreators as administrationActionCreators, reducer as administrationReducer } from "./Administration";
import { actionCreators as mediaActionCreators, reducer as mediaReducer } from "./Media";
import { actionCreators as reportingActionCreators, reducer as reportingReducer } from "./Reporting";
import { actionCreators as trainingProgramActionCreators, reducer as trainingProgramReducer } from "./TrainingProgram";
import { actionCreators as uiActionCreators, reducer as uiReducer } from "./UI";
export * from "./ApplicationState";

export type AdministrationActions = typeof administrationActionCreators;
export type MediaActions = typeof mediaActionCreators;
export type ReportingActions = typeof reportingActionCreators;
export type TrainingProgramActions = typeof trainingProgramActionCreators;
export type UIActions = typeof uiActionCreators;

export const actionCreators = {
  administration: administrationActionCreators,
  media: mediaActionCreators,
  reporting: reportingActionCreators,
  trainingProgram: trainingProgramActionCreators,
  ui: uiActionCreators,
};

export const reducers = {
  administration: administrationReducer,
  media: mediaReducer,
  reporting: reportingReducer,
  trainingProgram: trainingProgramReducer,
  ui: uiReducer,
};
