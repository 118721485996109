import { AppPageFrame } from "@interface48/app";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { bindActionCreators } from "redux";
import { UserGroupsTable } from "../../../components/user-groups";
import { actionCreators, AdministrationActions, AdministrationState, ApplicationState } from "../../../store";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(2),
    },
    button: {
      marginBottom: theme.spacing(2),
    },
  });

interface UserGroupsIndexPageStateProps {
  administrationState: AdministrationState;
}

interface UserGroupsIndexPageDispatchProps {
  administrationActions: AdministrationActions;
}

interface UserGroupsIndexPageOwnProps extends RouteComponentProps<any>, WithStyles<typeof styles> {}

type UserGroupsIndexPageProps = UserGroupsIndexPageStateProps &
  UserGroupsIndexPageDispatchProps &
  UserGroupsIndexPageOwnProps;

const UserGroupsIndexPageComponent = class extends React.Component<UserGroupsIndexPageProps> {
  public componentDidMount() {
    const { administrationActions } = this.props;

    administrationActions.requestUserGroups();
  }

  public render() {
    const { administrationState, classes } = this.props;
    const { userGroups, actionStatus } = administrationState;

    const windowWidth = window.innerWidth;
    const isTableLoading =
      actionStatus && actionStatus.type === "ADMINISTRATION_USER_GROUPS_REQUEST" && actionStatus.pending;

    return (
      <AppPageFrame pageTitle="User Groups" fullWidth={true} onBackClick={this.handleBackClick}>
        <div className={classes.root}>
          <UserGroupsTable results={userGroups ?? []} width={windowWidth} isLoading={isTableLoading} />
        </div>
      </AppPageFrame>
    );
  }

  private handleBackClick = () => {
    this.props.history.push("/administration");
  };
};

export const UserGroupsIndexPage = withStyles(styles)(
  connect<
    UserGroupsIndexPageStateProps,
    UserGroupsIndexPageDispatchProps,
    UserGroupsIndexPageOwnProps,
    ApplicationState
  >(
    (state) => ({
      administrationState: state.administration,
    }),
    (dispatch) => ({
      administrationActions: bindActionCreators(actionCreators.administration, dispatch),
    }),
  )(UserGroupsIndexPageComponent),
);
