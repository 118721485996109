/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserSignInResultDto
 */
export interface UserSignInResultDto {
    /**
     * 
     * @type {boolean}
     * @memberof UserSignInResultDto
     */
    signedIn: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserSignInResultDto
     */
    roles: Array<string>;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof UserSignInResultDto
     */
    authorizationPolicies?: { [key: string]: boolean; } | null;
}

export function UserSignInResultDtoFromJSON(json: any): UserSignInResultDto {
    return {
        'signedIn': json['signedIn'],
        'roles': json['roles'],
        'authorizationPolicies': !exists(json, 'authorizationPolicies') ? undefined : json['authorizationPolicies'],
    };
}

export function UserSignInResultDtoToJSON(value?: UserSignInResultDto | null): any {
    if (value == undefined) {
        return undefined;
    }
    return {
        'signedIn': value.signedIn,
        'roles': value.roles,
        'authorizationPolicies': value.authorizationPolicies,
    };
}


