import Button from "@material-ui/core/Button";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { AlertTitle } from "@material-ui/lab";
import Alert from "@material-ui/lab/Alert";
import orderBy from "lodash/orderBy";
import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Department, Position } from "../../api";
import {
  getTrainingProgramsDepartmentPositionOptionItemsMap,
  sortTrainingProgramsBySortNumberThenName,
  TrainingProgramCard,
  TrainingProgramFiltersPanel,
} from "../../components/training-programs";
import { actionCreators, ApplicationState, TrainingProgramActions, TrainingProgramState } from "../../store";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: "0 auto",
      padding: theme.spacing(2),
      maxWidth: theme.breakpoints.values.md,
    },
    alert: {
      marginTop: theme.spacing(2),
    },
    loader: {
      margin: `${theme.spacing(2)}px auto`,
      padding: `0 ${theme.spacing(2)}px`,
      textAlign: "center",
    },
    trainingProgramsTitle: {
      padding: 24,
      textAlign: "center",
    },
    trainingProgramCard: {
      margin: `${theme.spacing(2)}px 0`,
    },
    resourceBrowser: {
      marginTop: 72,
      position: "relative",
      textAlign: "center",
    },
    resourceBrowserTitle: {
      textAlign: "center",
      backgroundColor: theme.palette.background.default,
      position: "absolute",
      width: 70,
      top: "-1.25rem",
      left: "calc(50% - 35px)",
    },
    resourceBrowserButton: {
      margin: `${theme.spacing(6)}px 0`,
    },
    hr: {
      color: theme.palette.divider,
    },
  });

interface HomePageStateProps {
  trainingProgramState: TrainingProgramState;
}

interface HomePageDispatchProps {
  trainingProgramActions: TrainingProgramActions;
}

interface HomePageOwnProps extends RouteComponentProps<any>, WithStyles<typeof styles> {}

type HomePageProps = HomePageStateProps & HomePageDispatchProps & HomePageOwnProps;

export const HomePageComponent = (props: HomePageProps) => {
  const { trainingProgramActions, trainingProgramState, history, classes } = props;
  const { trainingPrograms, trainingProgramFilters } = trainingProgramState;

  const optionItems = useMemo(
    () => getTrainingProgramsDepartmentPositionOptionItemsMap(trainingPrograms ?? []),
    [trainingPrograms],
  );

  const filteredTrainingPrograms = useMemo(() => {
    if (trainingPrograms == null) {
      return undefined;
    }

    return trainingPrograms.filter((trainingProgram) => {
      // If a Department has not been selected, include all Training Programs...
      if (trainingProgramFilters.department == null) {
        return true;
      }

      const trainingProgramDepartment = trainingProgram.departments.find(
        (department) => department.department === trainingProgramFilters.department,
      );

      // If this Training Program is not associated with the selected Department, exclude...
      if (!trainingProgramDepartment) {
        return false;
      }

      // If a Position has not been selected, include all Training Programs associated with the selected Department...
      if (trainingProgramFilters.position == null) {
        return true;
      }

      // Include those Training Programs associated with the selected Department and Position...
      return trainingProgramDepartment.positions.some((position) => position === trainingProgramFilters.position);
    });
  }, [trainingPrograms, trainingProgramFilters]);

  useEffect(() => {
    trainingProgramActions.requestTrainingPrograms();
  }, [trainingProgramActions]);

  const handleFiltersUpdate = (filters: { department?: Department; position?: Position }) => {
    trainingProgramActions.updateTrainingProgramsFilters(filters);
  };

  const handleCardClick = (trainingProgramName: string) => (restart?: boolean) => {
    if (restart) {
      trainingProgramActions.restartTrainingProgramSession(trainingProgramName);
    }

    history.push(`/training-program?name=${trainingProgramName}`);
  };

  const trainingProgramSessionStates = trainingProgramState.trainingProgramSessionStates ?? [];

  return (
    <div className={classes.root}>
      <div>
        <Typography variant="h4" className={classes.trainingProgramsTitle}>
          Choose a Program...
        </Typography>
        <TrainingProgramFiltersPanel
          departmentOptionItems={optionItems.departmentOptionItems}
          departmentPositionOptionItemsMap={optionItems.departmentPositionOptionItemsMap}
          filters={trainingProgramFilters}
          message={
            <Alert severity="info" className={classes.alert}>
              <AlertTitle>Visitors and Contractors</AlertTitle>
              <Typography variant="body2" gutterBottom={true}>
                If you're a <strong>Visitor</strong>, please select "(Not Applicable)" for both the 'Department' and{" "}
                'Position' fields.
              </Typography>
              <Typography variant="body2">
                Otherwise, if you're a <strong>Contractor</strong>, please select "(Not Applicable)" for the{" "}
                'Department' field, and "Contractor" for the 'Position' field.
              </Typography>
            </Alert>
          }
          isLoading={!filteredTrainingPrograms}
          onFiltersChange={handleFiltersUpdate}
        />
        {filteredTrainingPrograms && (
          <div>
            {filteredTrainingPrograms.sort(sortTrainingProgramsBySortNumberThenName).map((trainingProgram, index) => {
              const trainingProgramSessionState = orderBy(
                trainingProgramSessionStates.filter((tpss) => tpss.trainingProgramId === trainingProgram.id),
                (tpss) => tpss.trainingProgramLastModifiedAt,
                "desc",
              )[0];

              return (
                <TrainingProgramCard
                  key={index}
                  className={classes.trainingProgramCard}
                  trainingProgram={trainingProgram}
                  trainingProgramSessionState={trainingProgramSessionState}
                  onClick={handleCardClick(trainingProgram.slug)}
                />
              );
            })}
          </div>
        )}
      </div>
      <div className={classes.resourceBrowser}>
        <Typography variant="h4" className={classes.resourceBrowserTitle}>
          Or
        </Typography>
        <hr className={classes.hr} />
        <Button
          variant="contained"
          color="primary"
          className={classes.resourceBrowserButton}
          component={Link}
          to="/resource-browser"
        >
          Browse Resources
        </Button>
      </div>
    </div>
  );
};

export const HomePage = withStyles(styles)(
  connect<HomePageStateProps, HomePageDispatchProps, HomePageOwnProps, ApplicationState>(
    (state) => ({
      trainingProgramState: state.trainingProgram,
    }),
    (dispatch) => ({
      trainingProgramActions: bindActionCreators(actionCreators.trainingProgram, dispatch),
    }),
  )(HomePageComponent),
);
