/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CourseDto
 */
export interface CourseDto {
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof CourseDto
     */
    fileSizeBytes: number;
    /**
     * 
     * @type {number}
     * @memberof CourseDto
     */
    durationMinutes?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    createdByUserName: string;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    lastModifiedAt: string;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    lastModifiedByUserName: string;
    /**
     * 
     * @type {number}
     * @memberof CourseDto
     */
    version: number;
}

export function CourseDtoFromJSON(json: any): CourseDto {
    return {
        'id': json['id'],
        'name': json['name'],
        'fileSizeBytes': json['fileSizeBytes'],
        'durationMinutes': !exists(json, 'durationMinutes') ? undefined : json['durationMinutes'],
        'createdAt': json['createdAt'],
        'createdByUserName': json['createdByUserName'],
        'lastModifiedAt': json['lastModifiedAt'],
        'lastModifiedByUserName': json['lastModifiedByUserName'],
        'version': json['version'],
    };
}

export function CourseDtoToJSON(value?: CourseDto | null): any {
    if (value == undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'fileSizeBytes': value.fileSizeBytes,
        'durationMinutes': value.durationMinutes,
        'createdAt': value.createdAt,
        'createdByUserName': value.createdByUserName,
        'lastModifiedAt': value.lastModifiedAt,
        'lastModifiedByUserName': value.lastModifiedByUserName,
        'version': value.version,
    };
}


