import { ReactSelect, SelectRemoteOptions, SelectValue } from "@interface48/form-controls";
import { getSchemaOptions, processValue as processValueUtil, WidgetProps } from "@interface48/forms";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    number: {
      marginRight: theme.spacing(2),
    },
    componentSelect: {
      marginTop: -14,
      width: "100%",
    },
  });

export interface TrainingProgramComponentSelectWidgetProps
  extends WidgetProps<string | number>,
    WithStyles<typeof styles> {
  options: {
    enumDisabled?: Array<string | number>;
    emptyValue?: string | number;
    remoteOptions?: SelectRemoteOptions;
  };
  processValue?: (value: any) => string | number | undefined | null;
}

export const TrainingProgramComponentSelectWidgetComponent = (props: TrainingProgramComponentSelectWidgetProps) => {
  const {
    id,
    schema,
    required,
    value,
    autofocus,
    disabled,
    options: uiOptions,
    formContext,
    onBlur,
    onChange,
    classes,
  } = props;

  // Extract index position from the index-based id in the format root_componentIds_0 (Note: additionally add 1 since
  // index is 0-based, but we want the display number to be 1-based)
  const componentNumberMatch = /_(\d+)$/.exec(id);
  const componentNumber = componentNumberMatch ? parseInt(componentNumberMatch[1], 10) + 1 : undefined;

  const processValue = (value: any) => {
    return processValueUtil(value, schema.type, required) as string | number | null | undefined;
  };

  const handleBlur = (value: SelectValue<string | number>, otherData?: any) => {
    if (onBlur) {
      onBlur(id, value);
    }
  };

  return (
    <div className={classes.root}>
      <Typography variant="body1" className={classes.number}>
        {componentNumber ? `${componentNumber}.` : ""}
      </Typography>
      <div className={classes.componentSelect}>
        <ReactSelect
          id={id}
          label={schema.title}
          autofocus={autofocus}
          disabled={disabled}
          value={value}
          options={uiOptions.remoteOptions ? [] : getSchemaOptions(schema)}
          remoteOptions={uiOptions.remoteOptions}
          getApiConfiguration={formContext && formContext.getApiConfiguration}
          processValue={processValue}
          sharedValidation={formContext.sharedValidation}
          onBlur={handleBlur}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export const TrainingProgramComponentSelectWidget = withStyles(styles)(TrainingProgramComponentSelectWidgetComponent);
