/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReviseTrainingProgramSessionCommand
 */
export interface ReviseTrainingProgramSessionCommand {
    /**
     * 
     * @type {number}
     * @memberof ReviseTrainingProgramSessionCommand
     */
    revisedDepartment?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReviseTrainingProgramSessionCommand
     */
    revisedPosition?: number | null;
}

export function ReviseTrainingProgramSessionCommandFromJSON(json: any): ReviseTrainingProgramSessionCommand {
    return {
        'revisedDepartment': !exists(json, 'revisedDepartment') ? undefined : json['revisedDepartment'],
        'revisedPosition': !exists(json, 'revisedPosition') ? undefined : json['revisedPosition'],
    };
}

export function ReviseTrainingProgramSessionCommandToJSON(value?: ReviseTrainingProgramSessionCommand | null): any {
    if (value == undefined) {
        return undefined;
    }
    return {
        'revisedDepartment': value.revisedDepartment,
        'revisedPosition': value.revisedPosition,
    };
}


