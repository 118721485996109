/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    UpdateUserGroupCommand,
    UpdateUserGroupCommandFromJSON,
    UpdateUserGroupCommandToJSON,
    UserGroupDto,
    UserGroupDtoFromJSON,
    UserGroupDtoToJSON,
} from '../models';

export interface ApiAdministrationUserGroupsUserGroupTypeGetRequest {
    userGroupType: number;
}

export interface ApiAdministrationUserGroupsUserGroupTypePutRequest {
    userGroupType: number;
    updateUserGroupCommand: UpdateUserGroupCommand;
}

/**
 * no description
 */
export class UserGroupsApi extends runtime.BaseAPI {

    /**
     */
    async apiAdministrationUserGroupsGetRaw(): Promise<runtime.ApiResponse<Array<UserGroupDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["Administrators"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/administration/user-groups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserGroupDtoFromJSON));
    }

   /**
    */
    async apiAdministrationUserGroupsGet(): Promise<Array<UserGroupDto>> {
        const response = await this.apiAdministrationUserGroupsGetRaw();
        return await response.value();
    }

    /**
     */
    async apiAdministrationUserGroupsUserGroupTypeGetRaw(requestParameters: ApiAdministrationUserGroupsUserGroupTypeGetRequest): Promise<runtime.ApiResponse<UserGroupDto>> {
        if (requestParameters.userGroupType === null || requestParameters.userGroupType === undefined) {
            throw new runtime.RequiredError('userGroupType','Required parameter requestParameters.userGroupType was null or undefined when calling apiAdministrationUserGroupsUserGroupTypeGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["Administrators"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/administration/user-groups/{userGroupType}`.replace(`{${"userGroupType"}}`, encodeURIComponent(String(requestParameters.userGroupType))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserGroupDtoFromJSON(jsonValue));
    }

   /**
    */
    async apiAdministrationUserGroupsUserGroupTypeGet(requestParameters: ApiAdministrationUserGroupsUserGroupTypeGetRequest): Promise<UserGroupDto> {
        const response = await this.apiAdministrationUserGroupsUserGroupTypeGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAdministrationUserGroupsUserGroupTypePutRaw(requestParameters: ApiAdministrationUserGroupsUserGroupTypePutRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userGroupType === null || requestParameters.userGroupType === undefined) {
            throw new runtime.RequiredError('userGroupType','Required parameter requestParameters.userGroupType was null or undefined when calling apiAdministrationUserGroupsUserGroupTypePut.');
        }

        if (requestParameters.updateUserGroupCommand === null || requestParameters.updateUserGroupCommand === undefined) {
            throw new runtime.RequiredError('updateUserGroupCommand','Required parameter requestParameters.updateUserGroupCommand was null or undefined when calling apiAdministrationUserGroupsUserGroupTypePut.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["Administrators"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/administration/user-groups/{userGroupType}`.replace(`{${"userGroupType"}}`, encodeURIComponent(String(requestParameters.userGroupType))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserGroupCommandToJSON(requestParameters.updateUserGroupCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

   /**
    */
    async apiAdministrationUserGroupsUserGroupTypePut(requestParameters: ApiAdministrationUserGroupsUserGroupTypePutRequest): Promise<void> {
        await this.apiAdministrationUserGroupsUserGroupTypePutRaw(requestParameters);
    }

}
