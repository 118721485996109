import { OptionItem } from "@interface48/api";
import { getApiConfiguration } from "@interface48/app";
import {
  Form,
  FormAction,
  FormContext,
  FormSchema,
  getNewFormContext,
  handleFieldError,
  IChangeEvent,
  ISubmitEvent,
} from "@interface48/forms";
import Typography from "@material-ui/core/Typography";
import cloneDeep from "lodash/cloneDeep";
import React from "react";
import { TrainingProgramSessionCompletionFormData } from "./models";
import { getTrainingProgramSessionCompletionFormSchema } from "./utils";

const FORM_ID = "trainingProgramSessionCompletionForm";

interface TrainingProgramSessionCompletionFormProps {
  scrollContainerElementId?: string;
  className?: string;
  trainingProgramName: string;
  formData: TrainingProgramSessionCompletionFormData;
  isUserSignedIn: boolean;
  isFormSubmittable: boolean;
  isFormSubmitting: boolean;
  departmentOptionItems: Array<OptionItem<number>>;
  departmentPositionOptionItemsMap: { [department: string]: Array<OptionItem<number>> };
  onChange: (formData: TrainingProgramSessionCompletionFormData) => void;
  onSubmit: (formData: TrainingProgramSessionCompletionFormData) => void;
}

interface TrainingProgramSessionCompletionFormState {
  formContext: FormContext;
  initialFormData: TrainingProgramSessionCompletionFormData;
  formSchema: FormSchema;
  formActions: FormAction[];
}

export const TrainingProgramSessionCompletionFormComponent = class extends React.Component<
  TrainingProgramSessionCompletionFormProps,
  TrainingProgramSessionCompletionFormState
> {
  constructor(props: TrainingProgramSessionCompletionFormProps) {
    super(props);

    const { formData, isUserSignedIn, isFormSubmittable, departmentOptionItems, departmentPositionOptionItemsMap } =
      this.props;

    // Note: initialFormData should only need to be cloned in the constructor and not in componentDidUpdate,
    // but if canceling out of this form does not restore the initial data, than check if this form
    // is not reconstructing itself on load of a new set of add train form data...
    this.state = {
      // Only restore initialFormData for existing Operations
      initialFormData: cloneDeep(formData),
      formSchema: getTrainingProgramSessionCompletionFormSchema(
        departmentOptionItems,
        departmentPositionOptionItemsMap,
        isUserSignedIn,
        formData.hasUserWorkNumber,
        formData.idBadgeType,
      ),
      formContext: { ...getNewFormContext(), getApiConfiguration },
      formActions: getFormActions(formData.informationDisclosureConsent && isFormSubmittable),
    };
  }

  public render() {
    const { scrollContainerElementId, className, trainingProgramName, formData, isFormSubmitting, children } =
      this.props;
    const { formContext, formSchema, formActions } = this.state;

    return (
      <div className={className}>
        <Typography variant="h5" style={{ marginBottom: "16px" }}>
          Completion
        </Typography>
        <Typography variant="body1">
          As the final step of the {trainingProgramName} Training Program, please enter the following details that are
          required for Neptune to confirm your completion, then select 'Submit'.
        </Typography>
        <Form<TrainingProgramSessionCompletionFormData>
          id={FORM_ID}
          schema={formSchema.jsonSchema}
          uiSchema={formSchema.uiSchema}
          formContext={formContext}
          formData={formData}
          formActions={formActions}
          isFormSubmitting={isFormSubmitting}
          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
          onError={handleFieldError(FORM_ID, scrollContainerElementId)}
          formActionsAlignment={"left"}
        >
          {children}
        </Form>
      </div>
    );
  }

  public componentDidUpdate(
    prevProps: TrainingProgramSessionCompletionFormProps,
    prevState: TrainingProgramSessionCompletionFormState,
  ) {
    const { formData: prevFormData, isFormSubmittable: prevIsFormSubmittable } = prevProps;
    const {
      hasUserWorkNumber: prevHasUserWorkNumber,
      idBadgeType: prevIdBadgeType,
      informationDisclosureConsent: prevInformationDisclosureConsent,
    } = prevFormData;
    const { formData, isFormSubmittable, isUserSignedIn, departmentOptionItems, departmentPositionOptionItemsMap } =
      this.props;
    const { hasUserWorkNumber, idBadgeType, informationDisclosureConsent } = formData;

    if (
      prevHasUserWorkNumber !== hasUserWorkNumber ||
      prevIdBadgeType !== idBadgeType ||
      prevInformationDisclosureConsent !== informationDisclosureConsent ||
      prevIsFormSubmittable !== isFormSubmittable
    ) {
      const formSchema = getTrainingProgramSessionCompletionFormSchema(
        departmentOptionItems,
        departmentPositionOptionItemsMap,
        isUserSignedIn,
        hasUserWorkNumber,
        idBadgeType,
      );

      const formActions = getFormActions(informationDisclosureConsent && isFormSubmittable);

      this.setState({ ...this.state, formSchema, formActions });
    }
  }

  private handleChange = (changeEvent: IChangeEvent<TrainingProgramSessionCompletionFormData>) => {
    const { onChange } = this.props;

    const { formData } = changeEvent;

    onChange(formData);
  };

  private handleSubmit = (submitEvent: ISubmitEvent<TrainingProgramSessionCompletionFormData>) => {
    this.props.onSubmit(submitEvent.formData);
  };
};

const getFormActions = (isSubmitEnabled: boolean = false) => {
  return [
    {
      type: "submit",
      button: {
        disabled: !isSubmitEnabled,
        variant: "primary",
        label: "Submit",
      },
    },
  ] as FormAction[];
};

export const TrainingProgramSessionCompletionForm = TrainingProgramSessionCompletionFormComponent;
