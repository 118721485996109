import {
  AddOrUpdateTrainingProgramSessionCourseCommand,
  AddOrUpdateTrainingProgramSessionQuizCommand,
  CompleteTrainingProgramSessionCommand,
  TrainingProgramDepartmentDto,
} from "../../../../api";
import { toQuestionAnswerValuesForCommand } from "../../../quizzes";
import { TrainingProgramSession } from "../../models";
import { TrainingProgramSessionCompletionFormData } from "../models";

export const toTrainingProgramSessionCompletionFormData = (
  trainingProgramDepartments?: TrainingProgramDepartmentDto[] | null,
): TrainingProgramSessionCompletionFormData => {
  const trainingProgramCompletionFormData: TrainingProgramSessionCompletionFormData = {
    hasUserWorkNumber: false,
    isDepartmentApplicable: !!trainingProgramDepartments?.length,
  };

  return trainingProgramCompletionFormData;
};

export const toCompleteTrainingProgramSessionCommand = (
  trainingProgramSession: TrainingProgramSession,
  trainingCompletionFormData: TrainingProgramSessionCompletionFormData,
): CompleteTrainingProgramSessionCommand => {
  const trainingProgramSessionCourseCommands = trainingProgramSession.courses.map(
    (course): AddOrUpdateTrainingProgramSessionCourseCommand => {
      const { courseId, courseVersion, courseStateJson, beganAt, completedAt } = course;

      if (courseStateJson == null) {
        throw Error("courseStateJson undefined.");
      }

      return {
        courseId,
        courseVersion,
        courseStateJson,
        beganAt,
        completedAt,
      };
    },
  );

  const trainingProgramSessionQuizCommands = trainingProgramSession.quizzes.map(
    (quiz): AddOrUpdateTrainingProgramSessionQuizCommand => {
      const { quizId, quizVersion, quizQuestionAnswerValues, beganAt, completedAt } = quiz;

      return {
        quizId,
        quizVersion,
        quizQuestionAnswerValues: toQuestionAnswerValuesForCommand(quizQuestionAnswerValues),
        beganAt,
        completedAt,
      };
    },
  );

  return {
    trainingProgramVersion: trainingProgramSession.trainingProgramVersion,
    trainingProgramBeganAt: trainingProgramSession.beganAt!,
    userName: trainingCompletionFormData.userName!,
    companyName: trainingCompletionFormData.companyName!,
    userEmailAddress: trainingCompletionFormData.userEmailAddress,
    userWorkNumber: trainingCompletionFormData.userWorkNumber,
    idBadgeType: trainingCompletionFormData.idBadgeType === 0 ? undefined : trainingCompletionFormData.idBadgeType,
    idBadgeNumber: trainingCompletionFormData.idBadgeNumber,
    supervisorName: trainingCompletionFormData.supervisorName!,
    department: trainingCompletionFormData.department,
    position: trainingCompletionFormData.position,
    courses: trainingProgramSessionCourseCommands,
    quizzes: trainingProgramSessionQuizCommands,
  };
};
