import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTimePicker, SelectValue } from "@interface48/form-controls";
import Accordion from "@material-ui/core/Accordion";
import AccordionActions from "@material-ui/core/AccordionActions";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Typography from "@material-ui/core/Typography";
import { Theme, WithStyles, createStyles, withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
import { TrainingProgramSessionsQueryFilters } from "../../../api";
import { RemoteSelectField } from "../../shared";

const styles = (theme: Theme) =>
  createStyles({
    accordionRoot: {
      padding: 0,
    },
    accordionSummaryRoot: {
      padding: `0 ${theme.spacing(2)}px`,
    },
    accordionDetailsRoot: {
      flexGrow: 1,
    },
    accordionActionsRoot: {
      justifyContent: "flex-start",
      padding: theme.spacing(2),
    },
    accordionActionButton: {
      marginLeft: 0,
    },
    compoundFormControl: {
      flexDirection: "column",
      [theme.breakpoints.up("md")]: {
        flexDirection: "row",
      },
    },
    formGroup: {
      marginTop: theme.spacing(1),
      marginBottom: -theme.spacing(1),
      flexDirection: "row",
    },
    formControlDisplay: {
      marginTop: theme.spacing(2),
      padding: "6px 0 7px",
    },
    datePickersContainer: {
      margin: `-${theme.spacing(2)}px -${theme.spacing(1)}px 0 -${theme.spacing(1)}px`,
      [theme.breakpoints.up("md")]: {
        marginTop: 0,
      },
    },
    datePickerFormControl: {
      width: 100,
      margin: `0 ${theme.spacing(1)}px`,
      "&.rdt": {
        width: "100%",
      },
    },
  });

interface TrainingProgramSessionsFiltersPanelProps extends WithStyles<typeof styles> {
  id: string;
  filters: TrainingProgramSessionsQueryFilters;
  isFiltersOpen: boolean;
  onFiltersResized?: () => void;
  onFiltersChange: (filters: TrainingProgramSessionsQueryFilters) => void;
  onFiltersOpenChange: (isFiltersOpen: boolean) => void;
}

interface TrainingProgramSessionsFiltersPanelState {
  filters: TrainingProgramSessionsQueryFilters;
}

export const TrainingProgramSessionsFiltersPanelComponent = class extends React.Component<
  TrainingProgramSessionsFiltersPanelProps,
  TrainingProgramSessionsFiltersPanelState
> {
  constructor(props: TrainingProgramSessionsFiltersPanelProps) {
    super(props);

    const { filters } = this.props;

    this.state = { filters };
  }

  public render() {
    const { id, isFiltersOpen, classes, onFiltersResized } = this.props;
    const { filters } = this.state;

    return (
      <Accordion
        id={id}
        TransitionProps={{
          onEntered: onFiltersResized,
          onExited: onFiltersResized,
        }}
        classes={{ root: classes.accordionRoot, expanded: classes.accordionRoot }}
        expanded={isFiltersOpen}
        onChange={this.handleFiltersOpenChange}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} classes={{ root: classes.accordionSummaryRoot }}>
          <Typography variant={"h6"}>Filters</Typography>
        </AccordionSummary>
        <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={12} md={6} lg={6} xl={3}>
              <RemoteSelectField<string>
                id="companyNames"
                label="Company"
                defaultLabel={"(Any)"}
                fullWidth={true}
                multiple={true}
                limit={100}
                value={filters.companyNames ?? undefined}
                url="/api/administration/lists/company-names"
                valueType="string"
                selectType="react-select"
                onChange={this.handleSelectFieldChange("companyNames")}
              />
              <FormHelperText>Type one or more characters to reveal more options.</FormHelperText>
            </Grid>
            <Grid item={true} xs={12} md={6} lg={6} xl={3}>
              <RemoteSelectField<string>
                id="traineeNames"
                label="Trainee"
                defaultLabel={"(Any)"}
                fullWidth={true}
                multiple={true}
                limit={100}
                value={filters.traineeNames ?? undefined}
                url="/api/administration/lists/trainee-names"
                valueType="string"
                selectType="react-select"
                onChange={this.handleSelectFieldChange("traineeNames")}
              />
              <FormHelperText>Type one or more characters to reveal more options.</FormHelperText>
            </Grid>
            <Grid item={true} xs={12} md={12} lg={12} xl={6}>
              <RemoteSelectField<string>
                id="trainingProgramIds"
                label="Training Program"
                defaultLabel={"(Any)"}
                fullWidth={true}
                multiple={true}
                value={filters.trainingProgramIds ?? undefined}
                url={"/api/administration/lists/training-programs"}
                valueType="string"
                selectType="react-select"
                onChange={this.handleSelectFieldChange("trainingProgramIds")}
              />
            </Grid>
            <Grid item={true} xs={12} md={6} lg={6} xl={3}>
              <RemoteSelectField<number>
                id="departments"
                label="Department"
                defaultLabel={"(Any)"}
                fullWidth={true}
                multiple={true}
                value={filters.departments ?? undefined}
                url={"/api/lists/departments"}
                valueType="number"
                selectType="react-select"
                onChange={this.handleSelectFieldChange("departments")}
              />
            </Grid>
            <Grid item={true} xs={12} md={6} lg={6} xl={3}>
              <RemoteSelectField<number>
                id="positions"
                label="Position"
                defaultLabel={"(Any)"}
                fullWidth={true}
                multiple={true}
                value={filters.positions ?? undefined}
                url={"/api/lists/positions"}
                valueType="number"
                selectType="react-select"
                onChange={this.handleSelectFieldChange("positions")}
              />
            </Grid>
            <Grid item={true} xs={12} lg={12} xl={6}>
              <FormControl className={classes.compoundFormControl}>
                <InputLabel shrink={true}>Completed At</InputLabel>
                <RadioGroup
                  name="timeRangeType"
                  aria-label="Date Range Type"
                  className={classes.formGroup}
                  value={filters.completedAnytime ? "anytime" : "specific"}
                  onChange={this.handleDateRangeTypeChange("completedAnytime")}
                >
                  <FormControlLabel value="anytime" control={<Radio />} label="Anytime" />
                  <FormControlLabel value="specific" control={<Radio />} label="Between" />
                </RadioGroup>
                <div className={classes.datePickersContainer}>
                  <FormControl className={classes.datePickerFormControl}>
                    <DateTimePicker
                      id={"completedBegin"}
                      label={"Completed Begin At"}
                      value={filters.completedAtBegin || ""}
                      valueFormat={{ time: false }}
                      disabled={filters.completedAnytime}
                      onChange={this.handleDateRangeBoundsChange("completedAtBegin")}
                    />
                  </FormControl>
                  <FormControl>
                    <Typography variant="body2" className={classes.formControlDisplay}>
                      &nbsp;&nbsp;and&nbsp;&nbsp;
                    </Typography>
                  </FormControl>
                  <FormControl className={classes.datePickerFormControl}>
                    <DateTimePicker
                      id={"completedAtEnd"}
                      label={"End At"}
                      value={filters.completedAtEnd || ""}
                      valueFormat={{ time: false }}
                      disabled={filters.completedAnytime}
                      onChange={this.handleDateRangeBoundsChange("completedAtEnd")}
                    />
                  </FormControl>
                </div>
              </FormControl>
            </Grid>
          </Grid>
        </AccordionDetails>
        <Divider />
        <AccordionActions classes={{ root: classes.accordionActionsRoot }}>
          <Button
            color="primary"
            variant="contained"
            className={classes.accordionActionButton}
            onClick={this.handleUpdateClick}
          >
            <FontAwesomeIcon icon={["fas", "sync"]} />
            &nbsp;&nbsp;Update
          </Button>
        </AccordionActions>
      </Accordion>
    );
  }

  private handleSelectFieldChange =
    (filterPropertyName: "companyNames" | "traineeNames" | "trainingProgramIds" | "departments" | "positions") =>
    (value: SelectValue<number | string>) => {
      const { onFiltersResized } = this.props;

      this.setState({
        ...this.state,
        filters: {
          ...this.state.filters,
          [filterPropertyName]: Array.isArray(value) && value.length === 0 ? undefined : value,
        },
      });

      if (onFiltersResized) {
        onFiltersResized();
      }
    };

  private handleDateRangeTypeChange = (filterPropertyName: "completedAnytime") => (event: any) => {
    const { filters } = this.state;

    const rangeType = event.target.value;
    const rangeAnytime = rangeType === "anytime";

    this.setState({
      ...this.state,
      filters: { ...filters, [filterPropertyName]: rangeAnytime },
    });
  };

  private handleDateRangeBoundsChange =
    (boundsType: "completedAtBegin" | "completedAtEnd") => (value: string | null | undefined) => {
      const { filters } = this.state;

      this.setState({
        ...this.state,
        filters: { ...filters, [boundsType]: value },
      });
    };

  private handleUpdateClick = (event: any) => {
    const { onFiltersChange } = this.props;
    const { filters } = this.state;

    onFiltersChange(filters);
  };

  private handleFiltersOpenChange = (event: any) => {
    const { isFiltersOpen, onFiltersOpenChange } = this.props;

    onFiltersOpenChange(!isFiltersOpen);
  };
};

export const TrainingProgramSessionsFiltersPanel = withStyles(styles)(TrainingProgramSessionsFiltersPanelComponent);
