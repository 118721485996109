export enum IDBadgeType {
  None = 0,
  PortPass = 1,
  SiteAccessPass,
  VisitorPass,
}

export enum Department {
  NotApplicable = 1,
  Administration = 2,
  HealthSafetyEnvironment = 4,
  ProjectsEngineering = 8,
  Procurement = 16,
  Operations = 32,
  Traffic = 64,
  Maintenance = 128,
}

export enum Position {
  NotApplicable = 1,
  SeniorManagement = 2,
  Manager = 4,
  OfficeStaff = 8,
  TerminalStaff = 16,
  ProjectManager = 32,
  Superintendent = 64,
  Foreman = 128,
  DispatchedWorkforce = 256,
  Contractor = 512,
  RegularWorkforce = 1024,
}
