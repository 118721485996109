import { getApiConfiguration } from "@interface48/app";
import {
  AddCourseCommand,
  AddDocumentCommand,
  AddOrUpdateTrainingProgramSessionCourseCommand,
  AddOrUpdateTrainingProgramSessionQuizCommand,
  AddOrUpdateTrainingProgramSessionStateCommand,
  AddQuizCommand,
  AddTrainingProgramCommand,
  AddVideoCommand,
  ApiReportingTrainingProgramSessionsFileGetRequest,
  CompleteTrainingProgramSessionCommand,
  Configuration,
  CoursesApi,
  DocumentsApi,
  GetQuizResultQuery,
  ListsApi,
  MediaCategoriesApi,
  QueryResultsPagingConfiguration,
  QueryResultsSortingConfiguration,
  QuizzesApi,
  RemoveCourseCommand,
  RemoveDocumentCommand,
  RemoveVideoCommand,
  ReviseTrainingProgramSessionCommand,
  TrainingProgramsApi,
  TrainingProgramSessionsApi,
  TrainingProgramSessionsQueryFilters,
  TrainingScorecardsApi,
  UpdateCourseCommand,
  UpdateDocumentCommand,
  UpdateQuizCommand,
  UpdateTrainingProgramCommand,
  UpdateTrainingScorecardCommand,
  UpdateUserGroupCommand,
  UpdateVideoCommand,
  UserApi,
  UserGroupsApi,
  VideosApi,
} from "./client";
export * from "./client";
export * from "./constants";
export * from "./models";
export * from "./utils";

export const getConfiguration = (includeAccessToken: boolean = false): Promise<Configuration> =>
  getApiConfiguration(includeAccessToken).then(
    (apiConfiguration) =>
      new Configuration({
        basePath: apiConfiguration.basePath,
        apiKey: apiConfiguration.apiKey,
        accessToken: apiConfiguration.accessToken ? `Bearer ${apiConfiguration.accessToken}` : undefined,
      }),
  );

export const coursesApi = {
  getCourseResult: (id: string, version: number) =>
    getConfiguration().then((config) => new CoursesApi(config).apiCoursesIdVersionsVersionResultPost({ id, version })),
};

export const documentsApi = {
  getDocuments: () => getConfiguration().then((config) => new DocumentsApi(config).apiDocumentsGet()),

  getDocumentById: (id: string) =>
    getConfiguration().then((config) => new DocumentsApi(config).apiDocumentsIdGet({ id })),
};

export const mediaCategoriesApi = {
  getMediaCategoryNames: () =>
    getConfiguration().then((config) => new MediaCategoriesApi(config).apiMediaCategoriesNamesGet()),
};

export const videosApi = {
  getVideos: () => getConfiguration().then((config) => new VideosApi(config).apiVideosGet()),

  getVideoById: (id: string) => getConfiguration().then((config) => new VideosApi(config).apiVideosIdGet({ id })),
};

export const quizzesApi = {
  getQuizTemplate: (id: string) => getConfiguration().then((config) => new QuizzesApi(config).apiQuizzesIdGet({ id })),

  getQuizResult: (id: string, version: number, getQuizResultQuery: GetQuizResultQuery) =>
    getConfiguration().then((config) =>
      new QuizzesApi(config).apiQuizzesIdVersionsVersionResultPost({ id, version, getQuizResultQuery }),
    ),
};

export const trainingProgramsApi = {
  getTrainingPrograms: () =>
    getConfiguration().then((config) => new TrainingProgramsApi(config).apiTrainingProgramsGet({})),

  getTrainingProgram: (id: string) =>
    getConfiguration().then((config) => new TrainingProgramsApi(config).apiTrainingProgramsIdGet({ id })),

  getTrainingProgramBySlug: (slug: string) =>
    getConfiguration().then((config) => new TrainingProgramsApi(config).apiTrainingProgramsBySlugSlugGet({ slug })),
};

export const trainingProgramSessionsApi = {
  getTrainingProgramSessionStates: () =>
    getConfiguration(true).then((config) => new TrainingProgramSessionsApi(config).apiTrainingProgramSessionsGet()),

  addOrUpdateTrainingProgramSessionState: (
    trainingProgramId: string,
    addOrUpdateTrainingProgramSessionStateCommand: AddOrUpdateTrainingProgramSessionStateCommand,
  ) =>
    getConfiguration(true).then((config) =>
      new TrainingProgramSessionsApi(config).apiTrainingProgramSessionsTrainingProgramIdPost({
        trainingProgramId,
        addOrUpdateTrainingProgramSessionStateCommand,
      }),
    ),

  addOrUpdateTrainingProgramSessionCourse: (
    trainingProgramId: string,
    addOrUpdateTrainingProgramSessionCourseCommand: AddOrUpdateTrainingProgramSessionCourseCommand,
  ) =>
    getConfiguration(true).then((config) =>
      new TrainingProgramSessionsApi(config).apiTrainingProgramSessionsTrainingProgramIdCoursesPost({
        trainingProgramId,
        addOrUpdateTrainingProgramSessionCourseCommand,
      }),
    ),

  addOrUpdateTrainingProgramSessionQuiz: (
    trainingProgramId: string,
    addOrUpdateTrainingProgramSessionQuizCommand: AddOrUpdateTrainingProgramSessionQuizCommand,
  ) =>
    getConfiguration(true).then((config) =>
      new TrainingProgramSessionsApi(config).apiTrainingProgramSessionsTrainingProgramIdQuizzesPost({
        trainingProgramId,
        addOrUpdateTrainingProgramSessionQuizCommand,
      }),
    ),

  completeTrainingProgramSessionResult: (
    trainingProgramId: string,
    completeTrainingProgramSessionCommand: CompleteTrainingProgramSessionCommand,
  ) =>
    getConfiguration().then((config) =>
      new TrainingProgramSessionsApi(config).apiTrainingProgramSessionsTrainingProgramIdCompletePost({
        trainingProgramId,
        completeTrainingProgramSessionCommand,
      }),
    ),

  completeAuthenticatedTrainingProgramSessionResult: (
    trainingProgramId: string,
    completeTrainingProgramSessionCommand: CompleteTrainingProgramSessionCommand,
  ) =>
    getConfiguration(true).then((config) =>
      new TrainingProgramSessionsApi(config).apiTrainingProgramSessionsTrainingProgramIdCompleteAuthenticatedPost({
        trainingProgramId,
        completeTrainingProgramSessionCommand,
      }),
    ),
};

export const listsApi = {
  getDepartments: (trainingProgramId?: string) =>
    getConfiguration().then((config) => new ListsApi(config).apiListsDepartmentsGet({ trainingProgramId })),

  getPositions: (trainingProgramId?: string) =>
    getConfiguration().then((config) => new ListsApi(config).apiListsPositionsGet({ trainingProgramId })),

  getDepartmentPositionsMap: () =>
    getConfiguration().then((config) => new ListsApi(config).apiListsDepartmentPositionsMapGet()),
};

export const userApi = {
  signIn: (token: string) =>
    getConfiguration(true).then((config) => new UserApi(config).apiUserSignInPost({ body: token })),
};

export const reportingTrainingScorecardApi = {
  getTrainingScorecards: () =>
    getConfiguration(true).then((config) => new TrainingScorecardsApi(config).apiReportingTrainingScorecardsGet()),

  updateTrainingScorecard: (id: string, updateTrainingScorecardCommand: UpdateTrainingScorecardCommand) =>
    getConfiguration(true).then((config) =>
      new TrainingScorecardsApi(config).apiReportingTrainingScorecardsIdPost({
        id,
        updateTrainingScorecardCommand,
      }),
    ),

  getTrainingScorecardReport: (id: string) =>
    getConfiguration(true).then((config) =>
      new TrainingScorecardsApi(config).apiReportingTrainingScorecardsIdFileGet({ id }),
    ),
};

export const reportingTrainingProgramSessionsApi = {
  getTrainingProgramSessions: (
    filters: TrainingProgramSessionsQueryFilters,
    sorting: QueryResultsSortingConfiguration,
    paging: QueryResultsPagingConfiguration,
  ) =>
    getConfiguration(true).then((config) =>
      new TrainingProgramSessionsApi(config).apiReportingTrainingProgramSessionsGet({
        filtersSearchTerm: filters.searchTerm ?? undefined,
        filtersCompanyNames: filters.companyNames ?? undefined,
        filtersTraineeNames: filters.traineeNames ?? undefined,
        filtersTrainingProgramIds: filters.trainingProgramIds ?? undefined,
        filtersDepartments: filters.departments ?? undefined,
        filtersPositions: filters.positions ?? undefined,
        filtersCompletedAnytime: filters.completedAnytime,
        filtersCompletedAtBegin: filters.completedAtBegin ?? undefined,
        filtersCompletedAtEnd: filters.completedAtEnd ?? undefined,
        sortingSortPropertyName: sorting.sortPropertyName,
        sortingSortDirection: sorting.sortDirection,
        pagingPageIndex: paging.pageIndex,
        pagingPageSize: paging.pageSize,
      }),
    ),

  getTrainingProgramSession: (id: string) =>
    getConfiguration(true).then((config) =>
      new TrainingProgramSessionsApi(config).apiReportingTrainingProgramSessionsIdGet({ id }),
    ),

  getTrainingProgramSessionsFile: (request: ApiReportingTrainingProgramSessionsFileGetRequest) =>
    getConfiguration(true).then((config) =>
      new TrainingProgramSessionsApi(config).apiReportingTrainingProgramSessionsFileGet(request),
    ),

  reviseTrainingProgramSession: (
    id: string,
    reviseTrainingProgramSessionCommand: ReviseTrainingProgramSessionCommand,
  ) =>
    getConfiguration(true).then((config) =>
      new TrainingProgramSessionsApi(config).apiReportingTrainingProgramSessionsIdPut({
        id,
        reviseTrainingProgramSessionCommand,
      }),
    ),
};

export const administrationCoursesApi = {
  getCourses: () => getConfiguration(true).then((config) => new CoursesApi(config).apiAdministrationCoursesGet()),

  getCourse: (id: string) =>
    getConfiguration(true).then((config) => new CoursesApi(config).apiAdministrationCoursesIdGet({ id })),

  getCourseName: (fileUploadId: string) =>
    getConfiguration(true).then((config) =>
      new CoursesApi(config).apiAdministrationCoursesNameFileUploadIdGet({ fileUploadId }),
    ),

  addCourse: (addCourseCommand: AddCourseCommand) =>
    getConfiguration(true).then((config) => new CoursesApi(config).apiAdministrationCoursesPost({ addCourseCommand })),

  updateCourse: (id: string, updateCourseCommand: UpdateCourseCommand) =>
    getConfiguration(true).then((config) =>
      new CoursesApi(config).apiAdministrationCoursesIdPut({ id, updateCourseCommand }),
    ),

  removeCourse: (id: string, removeCourseCommand: RemoveCourseCommand) =>
    getConfiguration(true).then((config) =>
      new CoursesApi(config).apiAdministrationCoursesIdDelete({ id, removeCourseCommand }),
    ),
};

export const administrationDocumentsApi = {
  getDocuments: () => getConfiguration(true).then((config) => new DocumentsApi(config).apiAdministrationDocumentsGet()),

  getDocument: (id: string) =>
    getConfiguration(true).then((config) => new DocumentsApi(config).apiAdministrationDocumentsIdGet({ id })),

  addDocument: (addDocumentCommand: AddDocumentCommand) =>
    getConfiguration(true).then((config) =>
      new DocumentsApi(config).apiAdministrationDocumentsPost({ addDocumentCommand }),
    ),

  updateDocument: (id: string, updateDocumentCommand: UpdateDocumentCommand) =>
    getConfiguration(true).then((config) =>
      new DocumentsApi(config).apiAdministrationDocumentsIdPut({ id, updateDocumentCommand }),
    ),

  removeDocument: (id: string, removeDocumentCommand: RemoveDocumentCommand) =>
    getConfiguration(true).then((config) =>
      new DocumentsApi(config).apiAdministrationDocumentsIdDelete({ id, removeDocumentCommand }),
    ),
};

export const administrationVideosApi = {
  getVideos: () => getConfiguration(true).then((config) => new VideosApi(config).apiAdministrationVideosGet()),

  getVideo: (id: string) =>
    getConfiguration(true).then((config) => new VideosApi(config).apiAdministrationVideosIdGet({ id })),

  getUploadedVideoFileMetadata: (fileUploadId: string) =>
    getConfiguration(true).then((config) =>
      new VideosApi(config).apiAdministrationVideosFileUploadFileUploadIdMetadataGet({ fileUploadId }),
    ),

  addVideo: (addVideoCommand: AddVideoCommand) =>
    getConfiguration(true).then((config) => new VideosApi(config).apiAdministrationVideosPost({ addVideoCommand })),

  updateVideo: (id: string, updateVideoCommand: UpdateVideoCommand) =>
    getConfiguration(true).then((config) =>
      new VideosApi(config).apiAdministrationVideosIdPut({ id, updateVideoCommand }),
    ),

  removeVideo: (id: string, removeVideoCommand: RemoveVideoCommand) =>
    getConfiguration(true).then((config) =>
      new VideosApi(config).apiAdministrationVideosIdDelete({ id, removeVideoCommand }),
    ),
};

export const administrationQuizzesApi = {
  getQuizzes: () => getConfiguration(true).then((config) => new QuizzesApi(config).apiAdministrationQuizzesGet()),

  getQuiz: (id: string) =>
    getConfiguration(true).then((config) => new QuizzesApi(config).apiAdministrationQuizzesIdGet({ id })),

  addQuiz: (addQuizCommand: AddQuizCommand) =>
    getConfiguration(true).then((config) => new QuizzesApi(config).apiAdministrationQuizzesPost({ addQuizCommand })),

  updateQuiz: (id: string, updateQuizCommand: UpdateQuizCommand) =>
    getConfiguration(true).then((config) =>
      new QuizzesApi(config).apiAdministrationQuizzesIdPut({ id, updateQuizCommand }),
    ),

  removeQuiz: (id: string) =>
    getConfiguration(true).then((config) => new QuizzesApi(config).apiAdministrationQuizzesIdDelete({ id })),
};

export const administrationTrainingProgramsApi = {
  getTrainingPrograms: () =>
    getConfiguration(true).then((config) => new TrainingProgramsApi(config).apiAdministrationTrainingProgramsGet()),

  getTrainingProgram: (id: string) =>
    getConfiguration(true).then((config) =>
      new TrainingProgramsApi(config).apiAdministrationTrainingProgramsIdGet({ id }),
    ),

  getTrainingProgramBySlug: (slug: string) =>
    getConfiguration(true).then((config) =>
      new TrainingProgramsApi(config).apiAdministrationTrainingProgramsBySlugSlugGet({ slug }),
    ),

  addTrainingProgram: (addTrainingProgramCommand: AddTrainingProgramCommand) =>
    getConfiguration(true).then((config) =>
      new TrainingProgramsApi(config).apiAdministrationTrainingProgramsPost({ addTrainingProgramCommand }),
    ),

  updateTrainingProgram: (id: string, updateTrainingProgramCommand: UpdateTrainingProgramCommand) =>
    getConfiguration(true).then((config) =>
      new TrainingProgramsApi(config).apiAdministrationTrainingProgramsIdPut({
        id,
        updateTrainingProgramCommand,
      }),
    ),

  removeTrainingProgram: (id: string) =>
    getConfiguration(true).then((config) =>
      new TrainingProgramsApi(config).apiAdministrationTrainingProgramsIdDelete({ id }),
    ),

  getTrainingProgramComponents: (componentIds: string[]) =>
    getConfiguration(true).then((config) =>
      new TrainingProgramsApi(config).apiAdministrationTrainingProgramsComponentSummariesPost({
        getTrainingProgramComponentSummariesQuery: { componentIds },
      }),
    ),
};

export const administrationUserGroupsApi = {
  getUserGroups: () =>
    getConfiguration(true).then((config) => new UserGroupsApi(config).apiAdministrationUserGroupsGet()),

  getUserGroup: (userGroupType: number) =>
    getConfiguration(true).then((config) =>
      new UserGroupsApi(config).apiAdministrationUserGroupsUserGroupTypeGet({ userGroupType }),
    ),

  updateUserGroup: (userGroupType: number, updateUserGroupCommand: UpdateUserGroupCommand) =>
    getConfiguration(true).then((config) =>
      new UserGroupsApi(config).apiAdministrationUserGroupsUserGroupTypePut({
        userGroupType: userGroupType,
        updateUserGroupCommand,
      }),
    ),
};
