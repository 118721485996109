import { UserGroupType } from "../../../api";

export const getUserPermissionSlugFromType = (type: UserGroupType): string => {
  switch (type) {
    case UserGroupType.TrainingProgramStakeholders:
      return "training-program-stakeholders";
    default:
      throw Error(`Type ${type} does not resolve to known User Permission Type.`);
  }
};

export const getUserGroupTypeFromSlug = (slug: string): UserGroupType => {
  switch (slug) {
    case "training-program-stakeholders":
      return UserGroupType.TrainingProgramStakeholders;
    default:
      throw Error(`Slug ${slug} does not resolve to known User Permission Type.`);
  }
};
