import { toAuditDetailsFormData } from "@interface48/forms";
import { ReviseTrainingProgramSessionCommand, TrainingProgramSessionResultDto } from "../../../../api";
import { TrainingProgramSessionRevisionFormData } from "../models";

export const toTrainingProgramSessionRevisionFormData = (
  trainingProgramSession: TrainingProgramSessionResultDto,
): TrainingProgramSessionRevisionFormData => {
  return {
    number: trainingProgramSession.number,
    userName: trainingProgramSession.userName,
    originalDepartment: trainingProgramSession.department,
    originalDepartmentName: trainingProgramSession.departmentName,
    reviseDepartment: false,
    originalPosition: trainingProgramSession.position,
    originalPositionName: trainingProgramSession.positionName,
    revisePosition: false,
    lastRevised: toAuditDetailsFormData(
      trainingProgramSession.lastRevisedAt,
      trainingProgramSession.lastRevisedByUserName,
    ),
  };
};

export const toReviseTrainingProgramSessionCommand = (
  formData: TrainingProgramSessionRevisionFormData,
): ReviseTrainingProgramSessionCommand => {
  const { revisedDepartment, revisedPosition } = formData;

  return {
    revisedDepartment,
    revisedPosition,
  };
};
