/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TrainingProgramSessionQuizResultDto
 */
export interface TrainingProgramSessionQuizResultDto {
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramSessionQuizResultDto
     */
    quizName: string;
    /**
     * 
     * @type {number}
     * @memberof TrainingProgramSessionQuizResultDto
     */
    quizVersion: number;
    /**
     * 
     * @type {number}
     * @memberof TrainingProgramSessionQuizResultDto
     */
    minimumPassScorePercentage: number;
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramSessionQuizResultDto
     */
    beganAt: string;
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramSessionQuizResultDto
     */
    completedAt: string;
    /**
     * 
     * @type {number}
     * @memberof TrainingProgramSessionQuizResultDto
     */
    scorePercentage: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof TrainingProgramSessionQuizResultDto
     */
    incorrectQuestionNumbers: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof TrainingProgramSessionQuizResultDto
     */
    isPassResult: boolean;
}

export function TrainingProgramSessionQuizResultDtoFromJSON(json: any): TrainingProgramSessionQuizResultDto {
    return {
        'quizName': json['quizName'],
        'quizVersion': json['quizVersion'],
        'minimumPassScorePercentage': json['minimumPassScorePercentage'],
        'beganAt': json['beganAt'],
        'completedAt': json['completedAt'],
        'scorePercentage': json['scorePercentage'],
        'incorrectQuestionNumbers': json['incorrectQuestionNumbers'],
        'isPassResult': json['isPassResult'],
    };
}

export function TrainingProgramSessionQuizResultDtoToJSON(value?: TrainingProgramSessionQuizResultDto | null): any {
    if (value == undefined) {
        return undefined;
    }
    return {
        'quizName': value.quizName,
        'quizVersion': value.quizVersion,
        'minimumPassScorePercentage': value.minimumPassScorePercentage,
        'beganAt': value.beganAt,
        'completedAt': value.completedAt,
        'scorePercentage': value.scorePercentage,
        'incorrectQuestionNumbers': value.incorrectQuestionNumbers,
        'isPassResult': value.isPassResult,
    };
}


