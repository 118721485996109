import { getApiConfiguration } from "@interface48/app";
import {
  Form,
  FormAction,
  FormValidation,
  getNewFormContext,
  handleFieldError,
  IChangeEvent,
  ISubmitEvent,
} from "@interface48/forms";
import groupBy from "lodash/groupBy";
import React, { useMemo } from "react";
import { TrainingScorecardFormData } from "./models";
import { getTrainingScorecardFormSchema } from "./utils";

const FORM_ID = "trainingScorecardForm";

interface TrainingScorecardFormProps {
  className?: string;
  formData: TrainingScorecardFormData;
  isFormSubmitting: boolean;
  onCancel: () => void;
  onChange: (formData: TrainingScorecardFormData) => void;
  onSubmit: (formData: TrainingScorecardFormData) => void;
}

export const TrainingScorecardFormComponent = (props: TrainingScorecardFormProps) => {
  const { className, formData, isFormSubmitting, onChange, onSubmit, onCancel } = props;

  const formSchema = useMemo(() => getTrainingScorecardFormSchema(), []);
  const formContext = useMemo(() => ({ ...getNewFormContext(), getApiConfiguration }), []);
  const formActions = useMemo(() => getFormActions(), []);

  const handleValidate = (
    formData: TrainingScorecardFormData,
    errors: FormValidation<TrainingScorecardFormData>,
    isOnSubmit?: boolean,
  ) => {
    if (
      formData.targets &&
      Object.values(
        groupBy(
          formData.targets.filter((d) => d.department),
          (d) => `${d.department}-${d.position}`,
        ),
      ).some((d) => d.length > 1)
    ) {
      errors.targets?.addError("One or more duplicate Department/Position Targets have been specified.");
    }
  };

  const handleChange = (changeEvent: IChangeEvent<TrainingScorecardFormData>) => {
    const { formData } = changeEvent;

    onChange(formData);
  };

  const handleSubmit = (submitEvent: ISubmitEvent<TrainingScorecardFormData>) => {
    const { formData } = submitEvent;

    onSubmit(formData);
  };

  return (
    <Form<TrainingScorecardFormData>
      id={FORM_ID}
      className={className}
      schema={formSchema.jsonSchema}
      uiSchema={formSchema.uiSchema}
      formData={formData}
      formContext={formContext}
      formActions={formActions}
      formActionsAlignment="right"
      isFormSubmitting={isFormSubmitting}
      validate={handleValidate}
      onCancel={onCancel}
      onChange={handleChange}
      onSubmit={handleSubmit}
      onError={handleFieldError(FORM_ID)}
    />
  );
};

const getFormActions = () => {
  return [
    {
      type: "submit",
      button: {
        variant: "primary",
        label: "Save",
      },
    },
    {
      type: "cancel",
      button: {
        variant: "secondary",
        label: "Cancel",
      },
    },
  ] as FormAction[];
};

export const TrainingScorecardForm = TrainingScorecardFormComponent;
