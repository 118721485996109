import moment from "moment";
import { CourseSessionState } from "../../components/courses";
import { QuizSessionState, toQuestionAnswerValuesForCommand } from "../../components/quizzes";
import { TrainingProgramSession } from "../../components/training-program-sessions";
import {
  TrainingProgramSessionCourseStateDto,
  TrainingProgramSessionQuizStateDto,
  TrainingProgramSessionStateDto,
} from "../client";
import { Department, IDBadgeType, Position } from "../constants";

export const getIdBadgeTypeName = (idBadgeType: IDBadgeType) => {
  switch (idBadgeType) {
    case IDBadgeType.None:
      return "None";
    case IDBadgeType.PortPass:
      return "VFPA Port Pass";
    case IDBadgeType.SiteAccessPass:
      return "Neptune Site Access Pass";
    case IDBadgeType.VisitorPass:
      return "Neptune Visitor Pass";
    default:
      throw Error(`ID Badge Type ${idBadgeType} not supported.`);
  }
};

export const getDepartmentName = (department: Department) => {
  switch (department) {
    case Department.NotApplicable:
      return "(Not Applicable)";
    case Department.Administration:
      return "Brooksbank";
    case Department.HealthSafetyEnvironment:
      return "Health, Safety & Environment";
    case Department.ProjectsEngineering:
      return "Projects & Engineering";
    case Department.Procurement:
      return "Procurement";
    case Department.Operations:
      return "Operations";
    case Department.Traffic:
      return "Traffic";
    case Department.Maintenance:
      return "Maintenance";
    default:
      throw Error(`Department ${department} not supported.`);
  }
};

export const getPositionName = (position: Position) => {
  switch (position) {
    case Position.NotApplicable:
      return "(Not Applicable)";
    case Position.SeniorManagement:
      return "Senior Management";
    case Position.Manager:
      return "Manager";
    case Position.OfficeStaff:
      return "Office Staff";
    case Position.TerminalStaff:
      return "Terminal/Field Staff";
    case Position.ProjectManager:
      return "Project Manager";
    case Position.Superintendent:
      return "Superintendent";
    case Position.Foreman:
      return "Foreman";
    case Position.DispatchedWorkforce:
      return "Dispatched Workforce/Pool";
    case Position.Contractor:
      return "Contractor";
    case Position.RegularWorkforce:
      return "Regular Workforce (RWF)";
    default:
      throw Error(`Position ${position} not supported.`);
  }
};

export const toTrainingProgramSessionStateDto = (
  trainingProgramSession: TrainingProgramSession,
): TrainingProgramSessionStateDto => {
  const courses = trainingProgramSession.courses.map(toTrainingProgramSessionCourseDtoFromCourseSessionState);

  const quizzes = trainingProgramSession.quizzes.map(toTrainingProgramSessionQuizStateDtoFromQuizSessionState);

  return {
    trainingProgramId: trainingProgramSession.trainingProgramId,
    trainingProgramVersion: trainingProgramSession.trainingProgramVersion,
    trainingProgramMajorVersion: trainingProgramSession.trainingProgramMajorVersion,
    trainingProgramBeganAt: trainingProgramSession.beganAt!,
    trainingProgramLastModifiedAt: moment().toISOString(),
    trainingProgramStepNumber: trainingProgramSession.currentTrainingProgramComponentIndex + 1,
    maximumTrainingProgramStepNumber: trainingProgramSession.maximumTrainingProgramComponentIndex + 1,
    maximumTrainingProgramStepProgress: trainingProgramSession.maximumTrainingProgramComponentProgress,
    courses,
    quizzes,
  };
};

const toTrainingProgramSessionCourseDtoFromCourseSessionState = (
  courseSessionState: CourseSessionState,
): TrainingProgramSessionCourseStateDto => {
  const { courseId, courseVersion, courseStateJson, beganAt, completedAt } = courseSessionState;

  if (beganAt == null) {
    throw Error("Course state undefined.");
  }

  return {
    courseId,
    courseVersion,
    courseStateJson,
    beganAt,
    completedAt,
  };
};

const toTrainingProgramSessionQuizStateDtoFromQuizSessionState = (
  quizSessionState: QuizSessionState,
): TrainingProgramSessionQuizStateDto => {
  const { quizId, quizVersion, quizQuestionAnswerValues, beganAt, completedAt } = quizSessionState;

  if (beganAt == null) {
    throw Error("Quiz state undefined.");
  }

  return {
    quizId,
    quizVersion,
    quizQuestionAnswerValues: toQuestionAnswerValuesForCommand(quizQuestionAnswerValues),
    beganAt,
    completedAt,
  };
};
