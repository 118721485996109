import Chip from "@material-ui/core/Chip";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
    },
    chip: {
      marginLeft: theme.spacing(1),
    },
  });

interface TrainingProgramDetailsProps extends WithStyles<typeof styles> {
  name: string;
  majorVersion: number;
}

export const TrainingProgramDetailsComponent = (props: TrainingProgramDetailsProps) => {
  const { name, majorVersion, classes } = props;

  return (
    <div className={classes.root}>
      <Typography>{name}</Typography>
      <Chip label={`v${majorVersion}`} className={classes.chip} />
    </div>
  );
};

export const TrainingProgramDetails = withStyles(styles)(TrainingProgramDetailsComponent);
