/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserGroupMemberDto,
    UserGroupMemberDtoFromJSON,
    UserGroupMemberDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserGroupDto
 */
export interface UserGroupDto {
    /**
     * 
     * @type {number}
     * @memberof UserGroupDto
     */
    userGroupType: number;
    /**
     * 
     * @type {string}
     * @memberof UserGroupDto
     */
    userGroupTypeName: string;
    /**
     * 
     * @type {Array<UserGroupMemberDto>}
     * @memberof UserGroupDto
     */
    userGroupMembers: Array<UserGroupMemberDto>;
    /**
     * 
     * @type {string}
     * @memberof UserGroupDto
     */
    lastModifiedAt: string;
    /**
     * 
     * @type {string}
     * @memberof UserGroupDto
     */
    lastModifiedByUserName: string;
}

export function UserGroupDtoFromJSON(json: any): UserGroupDto {
    return {
        'userGroupType': json['userGroupType'],
        'userGroupTypeName': json['userGroupTypeName'],
        'userGroupMembers': (json['userGroupMembers'] as Array<any>).map(UserGroupMemberDtoFromJSON),
        'lastModifiedAt': json['lastModifiedAt'],
        'lastModifiedByUserName': json['lastModifiedByUserName'],
    };
}

export function UserGroupDtoToJSON(value?: UserGroupDto | null): any {
    if (value == undefined) {
        return undefined;
    }
    return {
        'userGroupType': value.userGroupType,
        'userGroupTypeName': value.userGroupTypeName,
        'userGroupMembers': (value.userGroupMembers as Array<any>).map(UserGroupMemberDtoToJSON),
        'lastModifiedAt': value.lastModifiedAt,
        'lastModifiedByUserName': value.lastModifiedByUserName,
    };
}


