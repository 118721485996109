/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddOrUpdateTrainingProgramSessionCourseCommand,
    AddOrUpdateTrainingProgramSessionCourseCommandFromJSON,
    AddOrUpdateTrainingProgramSessionCourseCommandToJSON,
    AddOrUpdateTrainingProgramSessionQuizCommand,
    AddOrUpdateTrainingProgramSessionQuizCommandFromJSON,
    AddOrUpdateTrainingProgramSessionQuizCommandToJSON,
    AddOrUpdateTrainingProgramSessionStateCommand,
    AddOrUpdateTrainingProgramSessionStateCommandFromJSON,
    AddOrUpdateTrainingProgramSessionStateCommandToJSON,
    CompleteTrainingProgramSessionCommand,
    CompleteTrainingProgramSessionCommandFromJSON,
    CompleteTrainingProgramSessionCommandToJSON,
    ReviseTrainingProgramSessionCommand,
    ReviseTrainingProgramSessionCommandFromJSON,
    ReviseTrainingProgramSessionCommandToJSON,
    TrainingProgramSessionCompletionResultDto,
    TrainingProgramSessionCompletionResultDtoFromJSON,
    TrainingProgramSessionCompletionResultDtoToJSON,
    TrainingProgramSessionResultDto,
    TrainingProgramSessionResultDtoFromJSON,
    TrainingProgramSessionResultDtoToJSON,
    TrainingProgramSessionStateDto,
    TrainingProgramSessionStateDtoFromJSON,
    TrainingProgramSessionStateDtoToJSON,
    TrainingProgramSessionsQueryFiltersTrainingProgramSessionResultDtoQueryResultsDto,
    TrainingProgramSessionsQueryFiltersTrainingProgramSessionResultDtoQueryResultsDtoFromJSON,
    TrainingProgramSessionsQueryFiltersTrainingProgramSessionResultDtoQueryResultsDtoToJSON,
} from '../models';

export interface ApiReportingTrainingProgramSessionsFileGetRequest {
    sortingSortPropertyName: string;
    sortingSortDirection: string;
    format: string;
    filtersCompanyNames?: Array<string>;
    filtersTraineeNames?: Array<string>;
    filtersTrainingProgramIds?: Array<string>;
    filtersDepartments?: Array<number>;
    filtersPositions?: Array<number>;
    filtersCompletedAnytime?: boolean;
    filtersCompletedAtBegin?: string;
    filtersCompletedAtEnd?: string;
    filtersSearchTerm?: string;
}

export interface ApiReportingTrainingProgramSessionsGetRequest {
    sortingSortPropertyName: string;
    sortingSortDirection: string;
    pagingPageIndex: number;
    pagingPageSize: number;
    filtersCompanyNames?: Array<string>;
    filtersTraineeNames?: Array<string>;
    filtersTrainingProgramIds?: Array<string>;
    filtersDepartments?: Array<number>;
    filtersPositions?: Array<number>;
    filtersCompletedAnytime?: boolean;
    filtersCompletedAtBegin?: string;
    filtersCompletedAtEnd?: string;
    filtersSearchTerm?: string;
}

export interface ApiReportingTrainingProgramSessionsIdGetRequest {
    id: string;
}

export interface ApiReportingTrainingProgramSessionsIdPutRequest {
    id: string;
    reviseTrainingProgramSessionCommand: ReviseTrainingProgramSessionCommand;
}

export interface ApiTrainingProgramSessionsTrainingProgramIdCompleteAuthenticatedPostRequest {
    trainingProgramId: string;
    completeTrainingProgramSessionCommand: CompleteTrainingProgramSessionCommand;
}

export interface ApiTrainingProgramSessionsTrainingProgramIdCompletePostRequest {
    trainingProgramId: string;
    completeTrainingProgramSessionCommand: CompleteTrainingProgramSessionCommand;
}

export interface ApiTrainingProgramSessionsTrainingProgramIdCoursesPostRequest {
    trainingProgramId: string;
    addOrUpdateTrainingProgramSessionCourseCommand: AddOrUpdateTrainingProgramSessionCourseCommand;
}

export interface ApiTrainingProgramSessionsTrainingProgramIdPostRequest {
    trainingProgramId: string;
    addOrUpdateTrainingProgramSessionStateCommand: AddOrUpdateTrainingProgramSessionStateCommand;
}

export interface ApiTrainingProgramSessionsTrainingProgramIdQuizzesPostRequest {
    trainingProgramId: string;
    addOrUpdateTrainingProgramSessionQuizCommand: AddOrUpdateTrainingProgramSessionQuizCommand;
}

/**
 * no description
 */
export class TrainingProgramSessionsApi extends runtime.BaseAPI {

    /**
     */
    async apiReportingTrainingProgramSessionsFileGetRaw(requestParameters: ApiReportingTrainingProgramSessionsFileGetRequest): Promise<runtime.ApiResponse<File>> {
        if (requestParameters.sortingSortPropertyName === null || requestParameters.sortingSortPropertyName === undefined) {
            throw new runtime.RequiredError('sortingSortPropertyName','Required parameter requestParameters.sortingSortPropertyName was null or undefined when calling apiReportingTrainingProgramSessionsFileGet.');
        }

        if (requestParameters.sortingSortDirection === null || requestParameters.sortingSortDirection === undefined) {
            throw new runtime.RequiredError('sortingSortDirection','Required parameter requestParameters.sortingSortDirection was null or undefined when calling apiReportingTrainingProgramSessionsFileGet.');
        }

        if (requestParameters.format === null || requestParameters.format === undefined) {
            throw new runtime.RequiredError('format','Required parameter requestParameters.format was null or undefined when calling apiReportingTrainingProgramSessionsFileGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filtersCompanyNames) {
            queryParameters['filters.companyNames'] = requestParameters.filtersCompanyNames;
        }

        if (requestParameters.filtersTraineeNames) {
            queryParameters['filters.traineeNames'] = requestParameters.filtersTraineeNames;
        }

        if (requestParameters.filtersTrainingProgramIds) {
            queryParameters['filters.trainingProgramIds'] = requestParameters.filtersTrainingProgramIds;
        }

        if (requestParameters.filtersDepartments) {
            queryParameters['filters.departments'] = requestParameters.filtersDepartments;
        }

        if (requestParameters.filtersPositions) {
            queryParameters['filters.positions'] = requestParameters.filtersPositions;
        }

        if (requestParameters.filtersCompletedAnytime !== undefined) {
            queryParameters['filters.completedAnytime'] = requestParameters.filtersCompletedAnytime;
        }

        if (requestParameters.filtersCompletedAtBegin !== undefined) {
            queryParameters['filters.completedAtBegin'] = requestParameters.filtersCompletedAtBegin;
        }

        if (requestParameters.filtersCompletedAtEnd !== undefined) {
            queryParameters['filters.completedAtEnd'] = requestParameters.filtersCompletedAtEnd;
        }

        if (requestParameters.filtersSearchTerm !== undefined) {
            queryParameters['filters.searchTerm'] = requestParameters.filtersSearchTerm;
        }

        if (requestParameters.sortingSortPropertyName !== undefined) {
            queryParameters['sorting.sortPropertyName'] = requestParameters.sortingSortPropertyName;
        }

        if (requestParameters.sortingSortDirection !== undefined) {
            queryParameters['sorting.sortDirection'] = requestParameters.sortingSortDirection;
        }

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["TrainingProgramStakeholders"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/reporting/training-program-sessions/file`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.FileApiResponse(response);
    }

   /**
    */
    async apiReportingTrainingProgramSessionsFileGet(requestParameters: ApiReportingTrainingProgramSessionsFileGetRequest): Promise<File> {
        const response = await this.apiReportingTrainingProgramSessionsFileGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiReportingTrainingProgramSessionsGetRaw(requestParameters: ApiReportingTrainingProgramSessionsGetRequest): Promise<runtime.ApiResponse<TrainingProgramSessionsQueryFiltersTrainingProgramSessionResultDtoQueryResultsDto>> {
        if (requestParameters.sortingSortPropertyName === null || requestParameters.sortingSortPropertyName === undefined) {
            throw new runtime.RequiredError('sortingSortPropertyName','Required parameter requestParameters.sortingSortPropertyName was null or undefined when calling apiReportingTrainingProgramSessionsGet.');
        }

        if (requestParameters.sortingSortDirection === null || requestParameters.sortingSortDirection === undefined) {
            throw new runtime.RequiredError('sortingSortDirection','Required parameter requestParameters.sortingSortDirection was null or undefined when calling apiReportingTrainingProgramSessionsGet.');
        }

        if (requestParameters.pagingPageIndex === null || requestParameters.pagingPageIndex === undefined) {
            throw new runtime.RequiredError('pagingPageIndex','Required parameter requestParameters.pagingPageIndex was null or undefined when calling apiReportingTrainingProgramSessionsGet.');
        }

        if (requestParameters.pagingPageSize === null || requestParameters.pagingPageSize === undefined) {
            throw new runtime.RequiredError('pagingPageSize','Required parameter requestParameters.pagingPageSize was null or undefined when calling apiReportingTrainingProgramSessionsGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filtersCompanyNames) {
            queryParameters['filters.companyNames'] = requestParameters.filtersCompanyNames;
        }

        if (requestParameters.filtersTraineeNames) {
            queryParameters['filters.traineeNames'] = requestParameters.filtersTraineeNames;
        }

        if (requestParameters.filtersTrainingProgramIds) {
            queryParameters['filters.trainingProgramIds'] = requestParameters.filtersTrainingProgramIds;
        }

        if (requestParameters.filtersDepartments) {
            queryParameters['filters.departments'] = requestParameters.filtersDepartments;
        }

        if (requestParameters.filtersPositions) {
            queryParameters['filters.positions'] = requestParameters.filtersPositions;
        }

        if (requestParameters.filtersCompletedAnytime !== undefined) {
            queryParameters['filters.completedAnytime'] = requestParameters.filtersCompletedAnytime;
        }

        if (requestParameters.filtersCompletedAtBegin !== undefined) {
            queryParameters['filters.completedAtBegin'] = requestParameters.filtersCompletedAtBegin;
        }

        if (requestParameters.filtersCompletedAtEnd !== undefined) {
            queryParameters['filters.completedAtEnd'] = requestParameters.filtersCompletedAtEnd;
        }

        if (requestParameters.filtersSearchTerm !== undefined) {
            queryParameters['filters.searchTerm'] = requestParameters.filtersSearchTerm;
        }

        if (requestParameters.sortingSortPropertyName !== undefined) {
            queryParameters['sorting.sortPropertyName'] = requestParameters.sortingSortPropertyName;
        }

        if (requestParameters.sortingSortDirection !== undefined) {
            queryParameters['sorting.sortDirection'] = requestParameters.sortingSortDirection;
        }

        if (requestParameters.pagingPageIndex !== undefined) {
            queryParameters['paging.pageIndex'] = requestParameters.pagingPageIndex;
        }

        if (requestParameters.pagingPageSize !== undefined) {
            queryParameters['paging.pageSize'] = requestParameters.pagingPageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["TrainingProgramStakeholders"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/reporting/training-program-sessions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TrainingProgramSessionsQueryFiltersTrainingProgramSessionResultDtoQueryResultsDtoFromJSON(jsonValue));
    }

   /**
    */
    async apiReportingTrainingProgramSessionsGet(requestParameters: ApiReportingTrainingProgramSessionsGetRequest): Promise<TrainingProgramSessionsQueryFiltersTrainingProgramSessionResultDtoQueryResultsDto> {
        const response = await this.apiReportingTrainingProgramSessionsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiReportingTrainingProgramSessionsIdGetRaw(requestParameters: ApiReportingTrainingProgramSessionsIdGetRequest): Promise<runtime.ApiResponse<TrainingProgramSessionResultDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiReportingTrainingProgramSessionsIdGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["TrainingProgramStakeholders"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/reporting/training-program-sessions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TrainingProgramSessionResultDtoFromJSON(jsonValue));
    }

   /**
    */
    async apiReportingTrainingProgramSessionsIdGet(requestParameters: ApiReportingTrainingProgramSessionsIdGetRequest): Promise<TrainingProgramSessionResultDto> {
        const response = await this.apiReportingTrainingProgramSessionsIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiReportingTrainingProgramSessionsIdPutRaw(requestParameters: ApiReportingTrainingProgramSessionsIdPutRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiReportingTrainingProgramSessionsIdPut.');
        }

        if (requestParameters.reviseTrainingProgramSessionCommand === null || requestParameters.reviseTrainingProgramSessionCommand === undefined) {
            throw new runtime.RequiredError('reviseTrainingProgramSessionCommand','Required parameter requestParameters.reviseTrainingProgramSessionCommand was null or undefined when calling apiReportingTrainingProgramSessionsIdPut.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["TrainingProgramStakeholders"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/reporting/training-program-sessions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ReviseTrainingProgramSessionCommandToJSON(requestParameters.reviseTrainingProgramSessionCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

   /**
    */
    async apiReportingTrainingProgramSessionsIdPut(requestParameters: ApiReportingTrainingProgramSessionsIdPutRequest): Promise<void> {
        await this.apiReportingTrainingProgramSessionsIdPutRaw(requestParameters);
    }

    /**
     */
    async apiTrainingProgramSessionsGetRaw(): Promise<runtime.ApiResponse<Array<TrainingProgramSessionStateDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["null"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/training-program-sessions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TrainingProgramSessionStateDtoFromJSON));
    }

   /**
    */
    async apiTrainingProgramSessionsGet(): Promise<Array<TrainingProgramSessionStateDto>> {
        const response = await this.apiTrainingProgramSessionsGetRaw();
        return await response.value();
    }

    /**
     */
    async apiTrainingProgramSessionsTrainingProgramIdCompleteAuthenticatedPostRaw(requestParameters: ApiTrainingProgramSessionsTrainingProgramIdCompleteAuthenticatedPostRequest): Promise<runtime.ApiResponse<TrainingProgramSessionCompletionResultDto>> {
        if (requestParameters.trainingProgramId === null || requestParameters.trainingProgramId === undefined) {
            throw new runtime.RequiredError('trainingProgramId','Required parameter requestParameters.trainingProgramId was null or undefined when calling apiTrainingProgramSessionsTrainingProgramIdCompleteAuthenticatedPost.');
        }

        if (requestParameters.completeTrainingProgramSessionCommand === null || requestParameters.completeTrainingProgramSessionCommand === undefined) {
            throw new runtime.RequiredError('completeTrainingProgramSessionCommand','Required parameter requestParameters.completeTrainingProgramSessionCommand was null or undefined when calling apiTrainingProgramSessionsTrainingProgramIdCompleteAuthenticatedPost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["null"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/training-program-sessions/{trainingProgramId}/complete-authenticated`.replace(`{${"trainingProgramId"}}`, encodeURIComponent(String(requestParameters.trainingProgramId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompleteTrainingProgramSessionCommandToJSON(requestParameters.completeTrainingProgramSessionCommand),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TrainingProgramSessionCompletionResultDtoFromJSON(jsonValue));
    }

   /**
    */
    async apiTrainingProgramSessionsTrainingProgramIdCompleteAuthenticatedPost(requestParameters: ApiTrainingProgramSessionsTrainingProgramIdCompleteAuthenticatedPostRequest): Promise<TrainingProgramSessionCompletionResultDto> {
        const response = await this.apiTrainingProgramSessionsTrainingProgramIdCompleteAuthenticatedPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiTrainingProgramSessionsTrainingProgramIdCompletePostRaw(requestParameters: ApiTrainingProgramSessionsTrainingProgramIdCompletePostRequest): Promise<runtime.ApiResponse<TrainingProgramSessionCompletionResultDto>> {
        if (requestParameters.trainingProgramId === null || requestParameters.trainingProgramId === undefined) {
            throw new runtime.RequiredError('trainingProgramId','Required parameter requestParameters.trainingProgramId was null or undefined when calling apiTrainingProgramSessionsTrainingProgramIdCompletePost.');
        }

        if (requestParameters.completeTrainingProgramSessionCommand === null || requestParameters.completeTrainingProgramSessionCommand === undefined) {
            throw new runtime.RequiredError('completeTrainingProgramSessionCommand','Required parameter requestParameters.completeTrainingProgramSessionCommand was null or undefined when calling apiTrainingProgramSessionsTrainingProgramIdCompletePost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/training-program-sessions/{trainingProgramId}/complete`.replace(`{${"trainingProgramId"}}`, encodeURIComponent(String(requestParameters.trainingProgramId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompleteTrainingProgramSessionCommandToJSON(requestParameters.completeTrainingProgramSessionCommand),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TrainingProgramSessionCompletionResultDtoFromJSON(jsonValue));
    }

   /**
    */
    async apiTrainingProgramSessionsTrainingProgramIdCompletePost(requestParameters: ApiTrainingProgramSessionsTrainingProgramIdCompletePostRequest): Promise<TrainingProgramSessionCompletionResultDto> {
        const response = await this.apiTrainingProgramSessionsTrainingProgramIdCompletePostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiTrainingProgramSessionsTrainingProgramIdCoursesPostRaw(requestParameters: ApiTrainingProgramSessionsTrainingProgramIdCoursesPostRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.trainingProgramId === null || requestParameters.trainingProgramId === undefined) {
            throw new runtime.RequiredError('trainingProgramId','Required parameter requestParameters.trainingProgramId was null or undefined when calling apiTrainingProgramSessionsTrainingProgramIdCoursesPost.');
        }

        if (requestParameters.addOrUpdateTrainingProgramSessionCourseCommand === null || requestParameters.addOrUpdateTrainingProgramSessionCourseCommand === undefined) {
            throw new runtime.RequiredError('addOrUpdateTrainingProgramSessionCourseCommand','Required parameter requestParameters.addOrUpdateTrainingProgramSessionCourseCommand was null or undefined when calling apiTrainingProgramSessionsTrainingProgramIdCoursesPost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["null"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/training-program-sessions/{trainingProgramId}/courses`.replace(`{${"trainingProgramId"}}`, encodeURIComponent(String(requestParameters.trainingProgramId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddOrUpdateTrainingProgramSessionCourseCommandToJSON(requestParameters.addOrUpdateTrainingProgramSessionCourseCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

   /**
    */
    async apiTrainingProgramSessionsTrainingProgramIdCoursesPost(requestParameters: ApiTrainingProgramSessionsTrainingProgramIdCoursesPostRequest): Promise<void> {
        await this.apiTrainingProgramSessionsTrainingProgramIdCoursesPostRaw(requestParameters);
    }

    /**
     */
    async apiTrainingProgramSessionsTrainingProgramIdPostRaw(requestParameters: ApiTrainingProgramSessionsTrainingProgramIdPostRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.trainingProgramId === null || requestParameters.trainingProgramId === undefined) {
            throw new runtime.RequiredError('trainingProgramId','Required parameter requestParameters.trainingProgramId was null or undefined when calling apiTrainingProgramSessionsTrainingProgramIdPost.');
        }

        if (requestParameters.addOrUpdateTrainingProgramSessionStateCommand === null || requestParameters.addOrUpdateTrainingProgramSessionStateCommand === undefined) {
            throw new runtime.RequiredError('addOrUpdateTrainingProgramSessionStateCommand','Required parameter requestParameters.addOrUpdateTrainingProgramSessionStateCommand was null or undefined when calling apiTrainingProgramSessionsTrainingProgramIdPost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["null"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/training-program-sessions/{trainingProgramId}`.replace(`{${"trainingProgramId"}}`, encodeURIComponent(String(requestParameters.trainingProgramId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddOrUpdateTrainingProgramSessionStateCommandToJSON(requestParameters.addOrUpdateTrainingProgramSessionStateCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

   /**
    */
    async apiTrainingProgramSessionsTrainingProgramIdPost(requestParameters: ApiTrainingProgramSessionsTrainingProgramIdPostRequest): Promise<void> {
        await this.apiTrainingProgramSessionsTrainingProgramIdPostRaw(requestParameters);
    }

    /**
     */
    async apiTrainingProgramSessionsTrainingProgramIdQuizzesPostRaw(requestParameters: ApiTrainingProgramSessionsTrainingProgramIdQuizzesPostRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.trainingProgramId === null || requestParameters.trainingProgramId === undefined) {
            throw new runtime.RequiredError('trainingProgramId','Required parameter requestParameters.trainingProgramId was null or undefined when calling apiTrainingProgramSessionsTrainingProgramIdQuizzesPost.');
        }

        if (requestParameters.addOrUpdateTrainingProgramSessionQuizCommand === null || requestParameters.addOrUpdateTrainingProgramSessionQuizCommand === undefined) {
            throw new runtime.RequiredError('addOrUpdateTrainingProgramSessionQuizCommand','Required parameter requestParameters.addOrUpdateTrainingProgramSessionQuizCommand was null or undefined when calling apiTrainingProgramSessionsTrainingProgramIdQuizzesPost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["null"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/training-program-sessions/{trainingProgramId}/quizzes`.replace(`{${"trainingProgramId"}}`, encodeURIComponent(String(requestParameters.trainingProgramId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddOrUpdateTrainingProgramSessionQuizCommandToJSON(requestParameters.addOrUpdateTrainingProgramSessionQuizCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

   /**
    */
    async apiTrainingProgramSessionsTrainingProgramIdQuizzesPost(requestParameters: ApiTrainingProgramSessionsTrainingProgramIdQuizzesPostRequest): Promise<void> {
        await this.apiTrainingProgramSessionsTrainingProgramIdQuizzesPostRaw(requestParameters);
    }

}
