/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddQuizCommand,
    AddQuizCommandFromJSON,
    AddQuizCommandToJSON,
    GetQuizResultQuery,
    GetQuizResultQueryFromJSON,
    GetQuizResultQueryToJSON,
    QuizDto,
    QuizDtoFromJSON,
    QuizDtoToJSON,
    QuizResultDto,
    QuizResultDtoFromJSON,
    QuizResultDtoToJSON,
    QuizTemplateDto,
    QuizTemplateDtoFromJSON,
    QuizTemplateDtoToJSON,
    UpdateQuizCommand,
    UpdateQuizCommandFromJSON,
    UpdateQuizCommandToJSON,
} from '../models';

export interface ApiAdministrationQuizzesIdDeleteRequest {
    id: string;
}

export interface ApiAdministrationQuizzesIdGetRequest {
    id: string;
}

export interface ApiAdministrationQuizzesIdPutRequest {
    id: string;
    updateQuizCommand: UpdateQuizCommand;
}

export interface ApiAdministrationQuizzesPostRequest {
    addQuizCommand: AddQuizCommand;
}

export interface ApiQuizzesIdGetRequest {
    id: string;
}

export interface ApiQuizzesIdVersionsVersionResultPostRequest {
    id: string;
    version: number;
    getQuizResultQuery: GetQuizResultQuery;
}

/**
 * no description
 */
export class QuizzesApi extends runtime.BaseAPI {

    /**
     */
    async apiAdministrationQuizzesGetRaw(): Promise<runtime.ApiResponse<Array<QuizDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["Administrators"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/administration/quizzes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(QuizDtoFromJSON));
    }

   /**
    */
    async apiAdministrationQuizzesGet(): Promise<Array<QuizDto>> {
        const response = await this.apiAdministrationQuizzesGetRaw();
        return await response.value();
    }

    /**
     */
    async apiAdministrationQuizzesIdDeleteRaw(requestParameters: ApiAdministrationQuizzesIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAdministrationQuizzesIdDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["Administrators"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/administration/quizzes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

   /**
    */
    async apiAdministrationQuizzesIdDelete(requestParameters: ApiAdministrationQuizzesIdDeleteRequest): Promise<void> {
        await this.apiAdministrationQuizzesIdDeleteRaw(requestParameters);
    }

    /**
     */
    async apiAdministrationQuizzesIdGetRaw(requestParameters: ApiAdministrationQuizzesIdGetRequest): Promise<runtime.ApiResponse<QuizDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAdministrationQuizzesIdGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["Administrators"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/administration/quizzes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => QuizDtoFromJSON(jsonValue));
    }

   /**
    */
    async apiAdministrationQuizzesIdGet(requestParameters: ApiAdministrationQuizzesIdGetRequest): Promise<QuizDto> {
        const response = await this.apiAdministrationQuizzesIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAdministrationQuizzesIdPutRaw(requestParameters: ApiAdministrationQuizzesIdPutRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAdministrationQuizzesIdPut.');
        }

        if (requestParameters.updateQuizCommand === null || requestParameters.updateQuizCommand === undefined) {
            throw new runtime.RequiredError('updateQuizCommand','Required parameter requestParameters.updateQuizCommand was null or undefined when calling apiAdministrationQuizzesIdPut.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["Administrators"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/administration/quizzes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateQuizCommandToJSON(requestParameters.updateQuizCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

   /**
    */
    async apiAdministrationQuizzesIdPut(requestParameters: ApiAdministrationQuizzesIdPutRequest): Promise<void> {
        await this.apiAdministrationQuizzesIdPutRaw(requestParameters);
    }

    /**
     */
    async apiAdministrationQuizzesPostRaw(requestParameters: ApiAdministrationQuizzesPostRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.addQuizCommand === null || requestParameters.addQuizCommand === undefined) {
            throw new runtime.RequiredError('addQuizCommand','Required parameter requestParameters.addQuizCommand was null or undefined when calling apiAdministrationQuizzesPost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["Administrators"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/administration/quizzes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddQuizCommandToJSON(requestParameters.addQuizCommand),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

   /**
    */
    async apiAdministrationQuizzesPost(requestParameters: ApiAdministrationQuizzesPostRequest): Promise<string> {
        const response = await this.apiAdministrationQuizzesPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiQuizzesIdGetRaw(requestParameters: ApiQuizzesIdGetRequest): Promise<runtime.ApiResponse<QuizTemplateDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiQuizzesIdGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/quizzes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => QuizTemplateDtoFromJSON(jsonValue));
    }

   /**
    */
    async apiQuizzesIdGet(requestParameters: ApiQuizzesIdGetRequest): Promise<QuizTemplateDto> {
        const response = await this.apiQuizzesIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiQuizzesIdVersionsVersionResultPostRaw(requestParameters: ApiQuizzesIdVersionsVersionResultPostRequest): Promise<runtime.ApiResponse<QuizResultDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiQuizzesIdVersionsVersionResultPost.');
        }

        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError('version','Required parameter requestParameters.version was null or undefined when calling apiQuizzesIdVersionsVersionResultPost.');
        }

        if (requestParameters.getQuizResultQuery === null || requestParameters.getQuizResultQuery === undefined) {
            throw new runtime.RequiredError('getQuizResultQuery','Required parameter requestParameters.getQuizResultQuery was null or undefined when calling apiQuizzesIdVersionsVersionResultPost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/quizzes/{id}/versions/{version}/result`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetQuizResultQueryToJSON(requestParameters.getQuizResultQuery),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => QuizResultDtoFromJSON(jsonValue));
    }

   /**
    */
    async apiQuizzesIdVersionsVersionResultPost(requestParameters: ApiQuizzesIdVersionsVersionResultPostRequest): Promise<QuizResultDto> {
        const response = await this.apiQuizzesIdVersionsVersionResultPostRaw(requestParameters);
        return await response.value();
    }

}
