import {
  QuizQuestionAnswerDto,
  QuizQuestionAnswerTemplateDto,
  QuizQuestionDto,
  QuizQuestionTemplateDto,
} from "../../../api";
import { compareNumber } from "../../shared";

export const sortQuizQuestionsByNumber = (
  qq1: QuizQuestionDto | QuizQuestionTemplateDto,
  qq2: QuizQuestionDto | QuizQuestionTemplateDto,
) => compareNumber(qq1.number, qq2.number);

export const sortQuizQuestionAnswersByNumber = (
  qqa1: QuizQuestionAnswerDto | QuizQuestionAnswerTemplateDto,
  qqa2: QuizQuestionAnswerDto | QuizQuestionAnswerTemplateDto,
) => compareNumber(qqa1.number, qqa2.number);
