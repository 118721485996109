import { FormSchema } from "@interface48/forms";
import { UserGroupFormData } from "../models";

export const getUserGroupFormSchema = (formMode: "read" | "write", formData: UserGroupFormData): FormSchema => {
  const hasUserGroupMembers = (formData.userPrincipalNames ?? []).length > 0;

  let formSchema: FormSchema = {
    jsonSchema: {
      type: "object",
      required: ["userPrincipalNames"],
      properties: {
        userGroupType: {
          type: "number",
        },
        userPrincipalNames: {
          type: "array",
          title: "Members",
          items: {
            type: "string",
            format: "email",
            title: "Email Address",
            maxLength: 254,
          },
          uniqueItems: true,
        },
        lastModified: {
          title: "Last Modified",
          type: "object",
          properties: {
            at: {
              type: "string",
            },
            userName: {
              type: "string",
            },
            personName: {
              type: "string",
            },
          },
        },
      },
    },
    uiSchema: {
      userGroupType: {
        "ui:widget": "hidden",
      },
      userPrincipalNames: {
        "ui:options": {
          orderable: true,
        },
      },
      lastModified: {
        "ui:field": "AuditDetailsField",
      },
    },
  };

  if (formMode === "read") {
    formSchema.uiSchema = hasUserGroupMembers
      ? {
          userGroupType: {
            "ui:widget": "hidden",
          },
          userPrincipalNames: {
            "ui:options": {
              addable: false,
              removable: false,
              orderable: false,
            },
            items: { "ui:widget": "ReadOnlyWidget" },
          },
          lastModified: {
            "ui:field": "AuditDetailsField",
          },
        }
      : {
          userGroupType: {
            "ui:widget": "hidden",
          },
          userPrincipalNames: {
            "ui:field": "HiddenField",
          },
          lastModified: {
            "ui:field": "HiddenField",
          },
        };
  }

  return formSchema;
};
