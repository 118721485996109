/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TrainingProgramSessionsQueryFilters
 */
export interface TrainingProgramSessionsQueryFilters {
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramSessionsQueryFilters
     */
    searchTerm?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TrainingProgramSessionsQueryFilters
     */
    companyNames?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TrainingProgramSessionsQueryFilters
     */
    traineeNames?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TrainingProgramSessionsQueryFilters
     */
    trainingProgramIds?: Array<string> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof TrainingProgramSessionsQueryFilters
     */
    departments?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof TrainingProgramSessionsQueryFilters
     */
    positions?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof TrainingProgramSessionsQueryFilters
     */
    completedAnytime?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramSessionsQueryFilters
     */
    completedAtBegin?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramSessionsQueryFilters
     */
    completedAtEnd?: string | null;
}

export function TrainingProgramSessionsQueryFiltersFromJSON(json: any): TrainingProgramSessionsQueryFilters {
    return {
        'searchTerm': !exists(json, 'searchTerm') ? undefined : json['searchTerm'],
        'companyNames': !exists(json, 'companyNames') ? undefined : json['companyNames'],
        'traineeNames': !exists(json, 'traineeNames') ? undefined : json['traineeNames'],
        'trainingProgramIds': !exists(json, 'trainingProgramIds') ? undefined : json['trainingProgramIds'],
        'departments': !exists(json, 'departments') ? undefined : json['departments'],
        'positions': !exists(json, 'positions') ? undefined : json['positions'],
        'completedAnytime': !exists(json, 'completedAnytime') ? undefined : json['completedAnytime'],
        'completedAtBegin': !exists(json, 'completedAtBegin') ? undefined : json['completedAtBegin'],
        'completedAtEnd': !exists(json, 'completedAtEnd') ? undefined : json['completedAtEnd'],
    };
}

export function TrainingProgramSessionsQueryFiltersToJSON(value?: TrainingProgramSessionsQueryFilters | null): any {
    if (value == undefined) {
        return undefined;
    }
    return {
        'searchTerm': value.searchTerm,
        'companyNames': value.companyNames,
        'traineeNames': value.traineeNames,
        'trainingProgramIds': value.trainingProgramIds,
        'departments': value.departments,
        'positions': value.positions,
        'completedAnytime': value.completedAnytime,
        'completedAtBegin': value.completedAtBegin,
        'completedAtEnd': value.completedAtEnd,
    };
}


