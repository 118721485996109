/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TrainingProgramSessionCourseResultDto
 */
export interface TrainingProgramSessionCourseResultDto {
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramSessionCourseResultDto
     */
    courseName: string;
    /**
     * 
     * @type {number}
     * @memberof TrainingProgramSessionCourseResultDto
     */
    courseVersion: number;
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramSessionCourseResultDto
     */
    beganAt: string;
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramSessionCourseResultDto
     */
    completedAt?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TrainingProgramSessionCourseResultDto
     */
    isPassResult: boolean;
}

export function TrainingProgramSessionCourseResultDtoFromJSON(json: any): TrainingProgramSessionCourseResultDto {
    return {
        'courseName': json['courseName'],
        'courseVersion': json['courseVersion'],
        'beganAt': json['beganAt'],
        'completedAt': !exists(json, 'completedAt') ? undefined : json['completedAt'],
        'isPassResult': json['isPassResult'],
    };
}

export function TrainingProgramSessionCourseResultDtoToJSON(value?: TrainingProgramSessionCourseResultDto | null): any {
    if (value == undefined) {
        return undefined;
    }
    return {
        'courseName': value.courseName,
        'courseVersion': value.courseVersion,
        'beganAt': value.beganAt,
        'completedAt': value.completedAt,
        'isPassResult': value.isPassResult,
    };
}


