import { AppPageFrame } from "@interface48/app";
import Button from "@material-ui/core/Button";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { CoursesTable } from "../../../components/courses";
import { actionCreators, AdministrationActions, AdministrationState, ApplicationState } from "../../../store";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      margin: theme.spacing(2),
    },
    button: {
      marginLeft: "auto",
      marginBottom: theme.spacing(2),
    },
  });

interface CoursesIndexPageStateProps {
  administrationState: AdministrationState;
}

interface CoursesIndexPageDispatchProps {
  administrationActions: AdministrationActions;
}

interface CoursesIndexPageOwnProps extends RouteComponentProps<any>, WithStyles<typeof styles> {}

type CoursesIndexPageProps = CoursesIndexPageStateProps & CoursesIndexPageDispatchProps & CoursesIndexPageOwnProps;

const CoursesIndexPageComponent = class extends React.Component<CoursesIndexPageProps> {
  public componentDidMount() {
    const { administrationActions } = this.props;

    administrationActions.requestCourses();
  }

  public render() {
    const { administrationState, classes } = this.props;
    const { courses, actionStatus } = administrationState;

    const windowWidth = window.innerWidth;
    const isTableLoading =
      actionStatus && actionStatus.type === "ADMINISTRATION_COURSES_REQUEST" && actionStatus.pending;

    return (
      <AppPageFrame pageTitle="Courses" fullWidth={true} onBackClick={this.handleBackClick}>
        <div className={classes.root}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            component={Link}
            to="/administration/courses/add"
          >
            Add Course
          </Button>
          <CoursesTable results={courses ?? []} width={windowWidth} isLoading={isTableLoading} />
        </div>
      </AppPageFrame>
    );
  }

  private handleBackClick = () => {
    this.props.history.push("/administration");
  };
};

export const CoursesIndexPage = withStyles(styles)(
  connect<CoursesIndexPageStateProps, CoursesIndexPageDispatchProps, CoursesIndexPageOwnProps, ApplicationState>(
    (state) => ({
      administrationState: state.administration,
    }),
    (dispatch) => ({
      administrationActions: bindActionCreators(actionCreators.administration, dispatch),
    }),
  )(CoursesIndexPageComponent),
);
