import { FormSchema } from "@interface48/forms";

export const getTrainingScorecardFormSchema = () => {
  const schema: FormSchema = {
    jsonSchema: {
      type: "object",
      required: ["name", "currentPeriodBeginAt", "targets"],
      properties: {
        id: {
          type: "string",
        },
        name: {
          type: "string",
          title: "Name",
        },
        currentPeriodBeginAt: {
          type: "string",
          title: "Since",
          format: "date",
        },
        targets: {
          type: "array",
          title: "Targets",
          items: {
            type: "object",
            required: ["department", "targetCompletionCount"],
            properties: {
              department: {
                type: "number",
                title: "Department",
              },
              position: {
                type: "number",
                title: "Position",
              },
              targetCompletionCount: {
                type: "number",
                title: "Completion Count",
                minimum: 0,
              },
            },
          },
        },
      },
    },
    uiSchema: {
      id: {
        "ui:widget": "hidden",
      },
      targets: {
        "ui:options": {
          orderable: false,
        },
        items: {
          "ui:options": {
            layout: { size: { sm: 4 } },
          },
          department: {
            "ui:widget": "select",
            "ui:options": {
              remoteOptions: {
                url: "/api/lists/departments",
              },
            },
          },
          position: {
            "ui:widget": "select",
            "ui:options": {
              remoteOptions: {
                url: "/api/lists/positions",
              },
            },
          },
        },
      },
    },
  };

  return schema;
};
