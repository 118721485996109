import { FormSchema } from "@interface48/forms";
import { DocumentFormDataSchemaVariant } from "../models";

export const getDocumentFormSchema = (formMode: "read" | "write"): FormSchema => {
  const formSchema: FormSchema = {
    jsonSchema: {
      type: "object",
      required: ["mediaCategoryId", "name", "isRemoteFile"],
      properties: {
        isExisting: {
          type: "boolean",
        },
        schemaVariant: {
          type: "string",
        },
        id: {
          type: "string",
          title: "Document ID",
        },
        mediaCategoryId: {
          type: "string",
          title: "Media Category",
        },
        mediaCategoryName: {
          type: "string",
          title: "Media Category",
        },
        name: {
          type: "string",
          title: "Name",
          maxLength: 75,
        },
        isRemoteFile: {
          type: "boolean",
          title: "Enablon File?",
        },
      },
      dependencies: {
        isExisting: {
          oneOf: [
            {
              properties: {
                isExisting: { enum: [true] },
                existingFile: {
                  type: "object",
                  title: "File",
                  properties: {
                    id: {
                      type: "string",
                    },
                    fileName: {
                      type: "string",
                    },
                    fileExtension: {
                      type: "string",
                    },
                    fileSizeBytes: {
                      type: "number",
                    },
                  },
                },
                created: {
                  title: "Created",
                  type: "object",
                  properties: {
                    at: {
                      type: "string",
                    },
                    personName: {
                      type: "string",
                    },
                    userName: {
                      type: "string",
                    },
                  },
                },
                lastModified: {
                  title: "Last Modified",
                  type: "object",
                  properties: {
                    at: {
                      type: "string",
                    },
                    personName: {
                      type: "string",
                    },
                    userName: {
                      type: "string",
                    },
                  },
                },
              },
            },
            {
              properties: {
                isExisting: { enum: [false] },
              },
            },
          ],
        },
        schemaVariant: {
          oneOf: [
            {
              required: ["file"],
              properties: {
                schemaVariant: { enum: [DocumentFormDataSchemaVariant.New] },
                file: {
                  type: "object",
                  title: "File",
                  required: formMode === "write" ? ["fileUploadId", "fileName", "fileSizeBytes"] : undefined,
                  properties: {
                    fileUploadId: {
                      type: "string",
                      title: "File Upload ID",
                    },
                    fileName: {
                      type: "string",
                      title: "File Name",
                      maxLength: 256,
                    },
                    fileSizeBytes: {
                      type: "number",
                      title: "File Size",
                    },
                  },
                },
              },
            },
            {
              required: ["remoteFileUrl"],
              properties: {
                schemaVariant: { enum: [DocumentFormDataSchemaVariant.NewRemote] },
                remoteFileUrl: {
                  type: "string",
                  title: "Enablon File URL",
                  maxLength: 2048,
                },
              },
            },
            {
              required: ["updateFile"],
              properties: {
                schemaVariant: { enum: [DocumentFormDataSchemaVariant.Existing] },
                updateFile: {
                  type: "boolean",
                  title: "Update File?",
                },
              },
              dependencies: {
                updateFile: {
                  oneOf: [
                    {
                      required: ["updateFile"],
                      properties: {
                        updateFile: { enum: [true] },
                        file: {
                          type: "object",
                          title: "File",
                          required: formMode === "write" ? ["fileUploadId", "fileName", "fileSizeBytes"] : undefined,
                          properties: {
                            fileUploadId: {
                              type: "string",
                              title: "File Upload ID",
                            },
                            fileName: {
                              type: "string",
                              title: "File Name",
                              maxLength: 256,
                            },
                            fileSizeBytes: {
                              type: "number",
                              title: "File Size",
                            },
                          },
                        },
                      },
                    },
                    {
                      properties: {
                        updateFile: { enum: [false] },
                      },
                    },
                  ],
                },
              },
            },
            {
              required: ["remoteFileUrl"],
              properties: {
                schemaVariant: { enum: [DocumentFormDataSchemaVariant.ExistingRemote] },
                remoteFileUrl: {
                  type: "string",
                  title: "Enablon File URL",
                  maxLength: 2048,
                },
              },
            },
          ],
        },
      },
    },
    uiSchema: {
      "ui:order": [
        "mediaCategoryId",
        "mediaCategoryName",
        "name",
        "existingFile",
        "isRemoteFile",
        "remoteFileUrl",
        "updateFile",
        "file",
        "created",
        "lastModified",
        "*",
      ],
      isExisting: {
        "ui:widget": "hidden",
      },
      schemaVariant: {
        "ui:widget": "hidden",
      },
      id: {
        "ui:widget": "hidden",
      },
      mediaCategoryId: {
        "ui:widget": formMode === "read" ? "hidden" : "select",
        "ui:options": {
          remoteOptions: {
            url: `/api/administration/lists/media-categories`,
          },
        },
      },
      mediaCategoryName: {
        "ui:widget": formMode === "read" ? "ReadOnlyWidget" : "hidden",
      },
      name: {
        "ui:widget": formMode === "read" ? "ReadOnlyWidget" : undefined,
      },
      existingFile: {
        "ui:field": "MediaFileLinkField",
        "ui:options": {
          mediaCategory: "documents",
        },
      },
      isRemoteFile: {
        "ui:widget": formMode === "read" ? "hidden" : undefined,
      },
      remoteFileUrl: {
        "ui:widget": formMode === "read" ? "ReadOnlyWidget" : undefined,
      },
      updateFile: {
        "ui:widget": formMode === "read" ? "hidden" : undefined,
      },
      file: {
        "ui:field": formMode === "read" ? "HiddenField" : "FileField",
        "ui:options": {
          uploadUrl: "/api/administration/documents/file-upload",
          acceptedMimeTypes: ["application/pdf"],
          maxSizeBytes: 20000000, // 20 MB
        },
        "ui:help": "You may upload a PDF file that is no more than 20 MB in size.",
      },
      created: {
        "ui:field": "AuditDetailsField",
      },
      lastModified: {
        "ui:field": "AuditDetailsField",
      },
    },
  };

  return formSchema;
};
