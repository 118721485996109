/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddOrUpdateTrainingProgramSessionStateCommand
 */
export interface AddOrUpdateTrainingProgramSessionStateCommand {
    /**
     * 
     * @type {number}
     * @memberof AddOrUpdateTrainingProgramSessionStateCommand
     */
    trainingProgramVersion: number;
    /**
     * 
     * @type {string}
     * @memberof AddOrUpdateTrainingProgramSessionStateCommand
     */
    trainingProgramBeganAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AddOrUpdateTrainingProgramSessionStateCommand
     */
    stepNumber: number;
    /**
     * 
     * @type {number}
     * @memberof AddOrUpdateTrainingProgramSessionStateCommand
     */
    stepProgress?: number | null;
}

export function AddOrUpdateTrainingProgramSessionStateCommandFromJSON(json: any): AddOrUpdateTrainingProgramSessionStateCommand {
    return {
        'trainingProgramVersion': json['trainingProgramVersion'],
        'trainingProgramBeganAt': !exists(json, 'trainingProgramBeganAt') ? undefined : json['trainingProgramBeganAt'],
        'stepNumber': json['stepNumber'],
        'stepProgress': !exists(json, 'stepProgress') ? undefined : json['stepProgress'],
    };
}

export function AddOrUpdateTrainingProgramSessionStateCommandToJSON(value?: AddOrUpdateTrainingProgramSessionStateCommand | null): any {
    if (value == undefined) {
        return undefined;
    }
    return {
        'trainingProgramVersion': value.trainingProgramVersion,
        'trainingProgramBeganAt': value.trainingProgramBeganAt,
        'stepNumber': value.stepNumber,
        'stepProgress': value.stepProgress,
    };
}


