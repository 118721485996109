import { getApiError } from "@interface48/api";
import { getApiConfiguration } from "@interface48/app";
import {
  Form,
  FormAction,
  FormContext,
  FormSchema,
  FormValidation,
  getNewFormContext,
  handleFieldError,
  IChangeEvent,
  ISubmitEvent,
} from "@interface48/forms";
import { red } from "@material-ui/core/colors";
import { createStyles, Theme, WithStyles } from "@material-ui/core/styles";
import withStyles, { ClassNameMap } from "@material-ui/core/styles/withStyles";
import Alert from "@material-ui/lab/Alert";
import cloneDeep from "lodash/cloneDeep";
import React from "react";
import { administrationCoursesApi } from "../../../api";
import { CourseFormData } from "./models";
import { getCourseFormSchema } from "./utils";

const FORM_ID = "courseForm";

const styles = (theme: Theme) =>
  createStyles({
    deleteFormAction: {
      marginLeft: "auto !important",
      color: red[500],
    },
  });

interface CourseFormProps extends WithStyles<typeof styles> {
  className: string;
  formMode: "read" | "write";
  formData: CourseFormData;
  isFormSubmitting: boolean;
  onEdit: () => void;
  onClose: () => void;
  onChange: (formData: CourseFormData) => void;
  onSubmit: (formData: CourseFormData) => void;
  onCancel: (initialFormData: CourseFormData) => void;
  onDelete: () => void;
}

interface CourseFormState {
  formSchema: FormSchema;
  formContext: FormContext;
  formActions: FormAction[];
  initialFormData: CourseFormData;
  showRevisionWarning: boolean;
}

export const CourseFormComponent = class extends React.Component<CourseFormProps, CourseFormState> {
  constructor(props: CourseFormProps) {
    super(props);

    const { formMode, formData, onDelete, classes } = this.props;

    const formSchema = getCourseFormSchema(formMode);
    const formActions = getFormActions(classes, formMode, false, onDelete);

    this.state = {
      formSchema,
      formContext: {
        ...getNewFormContext(),
        getApiConfiguration,
      },
      formActions,
      initialFormData: cloneDeep(formData),
      showRevisionWarning: false,
    };
  }

  public render() {
    const { className, formData, isFormSubmitting, onClose, onEdit } = this.props;
    const { formSchema, formContext, formActions, showRevisionWarning } = this.state;

    return (
      <Form<CourseFormData>
        id={FORM_ID}
        className={className}
        schema={formSchema.jsonSchema}
        uiSchema={formSchema.uiSchema}
        formData={formData}
        formContext={formContext}
        formActions={formActions}
        isFormSubmitting={isFormSubmitting}
        validate={this.handleValidate}
        onEdit={onEdit}
        onChange={this.handleChange}
        onSubmit={this.handleSubmit}
        onError={handleFieldError(FORM_ID)}
        onCancel={this.handleCancel}
        onClose={onClose}
      >
        {showRevisionWarning && (
          <Alert severity="warning">
            If you update the Course Content, the Course Version will be incremented. As a result, any Trainees
            currently in the process of completing the existing Course will be forced to retake the Course based on the
            new Content.
          </Alert>
        )}
      </Form>
    );
  }

  public componentDidUpdate(prevProps: CourseFormProps) {
    const { formMode: prevFormMode, formData: prevFormData } = prevProps;
    const { formMode, formData, onDelete, classes } = this.props;

    const isPreviousSubmitDisabled = prevFormMode === "write" && prevFormData.isFileValid === undefined;
    const isSubmitDisabled = formMode === "write" && formData.isFileValid === undefined;

    // If in read mode, the form data has now been loaded, and the user has permission to edit...
    if (
      prevFormMode !== formMode ||
      (formMode === "read" && !prevFormData && formData) ||
      isPreviousSubmitDisabled !== isSubmitDisabled
    ) {
      const formSchema = getCourseFormSchema(formMode);
      const formActions = getFormActions(classes, formMode, isSubmitDisabled, onDelete);

      this.setState({ ...this.state, formSchema, formActions });
    }
  }

  private handleValidate = (formData: CourseFormData, errors: FormValidation<CourseFormData>, isOnSubmit?: boolean) => {
    if (
      (!formData.isExisting && formData.file?.fileUploadId && formData.isFileValid === false) ||
      (formData.isExisting && formData.updateFile && formData.file?.fileUploadId && formData.isFileValid === false)
    ) {
      errors.addError(
        "Course ZIP file is not in a supported format. Please try uploading the Course ZIP file, exactly as it was " +
          "exported from the application used to author it.",
      );
    }
  };

  private handleChange = async (changeEvent: IChangeEvent<CourseFormData>) => {
    const { onChange, formData: prevFormData } = this.props;
    const { showRevisionWarning } = this.state;

    const { formData } = changeEvent;

    if (formData.isExisting) {
      const nextShowRevisionWarning = formData.updateFile;

      if (showRevisionWarning !== nextShowRevisionWarning) {
        this.setState({ ...this.state, showRevisionWarning: nextShowRevisionWarning });
      }
    }

    if (formData.file?.fileUploadId && formData.file?.fileUploadId !== prevFormData.file?.fileUploadId) {
      // Set isFileValid to undefined to indicate the file is being validated, which in turn disables the Submit button
      onChange({ ...formData, isFileValid: undefined });

      try {
        const courseName = await administrationCoursesApi.getCourseName(formData.file?.fileUploadId);

        onChange({ ...formData, name: courseName, isFileValid: true });
      } catch (errorResponse) {
        const error = await getApiError(errorResponse);

        console.error(error);

        onChange({ ...formData, isFileValid: false });
      }
    } else {
      onChange(formData);
    }
  };

  private handleSubmit = (submitEvent: ISubmitEvent<CourseFormData>) => {
    const { onSubmit } = this.props;

    const { formData } = submitEvent;

    onSubmit(formData);
  };

  private handleCancel = () => {
    const { onCancel } = this.props;
    const { initialFormData } = this.state;

    onCancel(initialFormData);
  };
};

const getFormActions = (
  classes: ClassNameMap<"deleteFormAction">,
  formMode: "read" | "write",
  isSubmitDisabled: boolean,
  onDelete: () => void,
) => {
  if (formMode === "read") {
    const formActions = [
      {
        type: "edit",
        button: {
          variant: "primary",
          label: "Edit",
        },
      },
      {
        type: "close",
        button: {
          variant: "secondary",
          label: "Back",
        },
      },
      {
        type: "other",
        button: {
          className: classes.deleteFormAction,
          variant: "secondary",
          label: "Delete",
          onClick: onDelete,
        },
      },
    ] as FormAction[];

    return formActions;
  } else {
    return [
      {
        type: "submit",
        button: {
          variant: "primary",
          label: "Save",
          disabled: isSubmitDisabled,
        },
      },
      {
        type: "cancel",
        button: {
          variant: "secondary",
          label: "Cancel",
        },
      },
    ] as FormAction[];
  }
};

export const CourseForm = withStyles(styles)(CourseFormComponent);
