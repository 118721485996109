/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DocumentDto
 */
export interface DocumentDto {
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    mediaCategoryId: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    mediaCategoryName: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    remoteFileUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    fileName: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    fileExtension: string;
    /**
     * 
     * @type {number}
     * @memberof DocumentDto
     */
    fileSizeBytes: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    createdByUserName: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    lastModifiedAt: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    lastModifiedByUserName: string;
}

export function DocumentDtoFromJSON(json: any): DocumentDto {
    return {
        'id': json['id'],
        'mediaCategoryId': json['mediaCategoryId'],
        'mediaCategoryName': json['mediaCategoryName'],
        'name': json['name'],
        'remoteFileUrl': !exists(json, 'remoteFileUrl') ? undefined : json['remoteFileUrl'],
        'fileName': json['fileName'],
        'fileExtension': json['fileExtension'],
        'fileSizeBytes': json['fileSizeBytes'],
        'createdAt': json['createdAt'],
        'createdByUserName': json['createdByUserName'],
        'lastModifiedAt': json['lastModifiedAt'],
        'lastModifiedByUserName': json['lastModifiedByUserName'],
    };
}

export function DocumentDtoToJSON(value?: DocumentDto | null): any {
    if (value == undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'mediaCategoryId': value.mediaCategoryId,
        'mediaCategoryName': value.mediaCategoryName,
        'name': value.name,
        'remoteFileUrl': value.remoteFileUrl,
        'fileName': value.fileName,
        'fileExtension': value.fileExtension,
        'fileSizeBytes': value.fileSizeBytes,
        'createdAt': value.createdAt,
        'createdByUserName': value.createdByUserName,
        'lastModifiedAt': value.lastModifiedAt,
        'lastModifiedByUserName': value.lastModifiedByUserName,
    };
}


