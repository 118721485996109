import { AppActions, AppPageFrame, baseActionCreators, Loader, OidcState } from "@interface48/app";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";
import cloneDeep from "lodash/cloneDeep";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  DocumentForm,
  DocumentFormData,
  toAddDocumentCommand,
  toDocumentFormData,
  toUpdateDocumentCommand,
} from "../../../components/documents";
import { EntityDeleteDialog } from "../../../components/shared";
import { actionCreators, AdministrationActions, AdministrationState, ApplicationState } from "../../../store";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
    },
    form: {
      backgroundColor: theme.palette.background.default,
    },
  });

interface DocumentPageMatchParamsProps {
  documentId: string;
}

interface DocumentPageStateProps {
  oidcState: OidcState;
  administrationState: AdministrationState;
}

interface DocumentPageDispatchProps {
  appActions: AppActions;
  administrationActions: AdministrationActions;
}

interface DocumentPageOwnProps extends RouteComponentProps<DocumentPageMatchParamsProps>, WithStyles<typeof styles> {}

type DocumentPageProps = DocumentPageStateProps & DocumentPageDispatchProps & DocumentPageOwnProps;

interface DocumentPageState {
  formMode: "read" | "write";
  formData: DocumentFormData;
  initialFormData: DocumentFormData;
  isDeleteConfirmationModalOpen: boolean;
}

export const DocumentPageComponent = class extends React.Component<DocumentPageProps, DocumentPageState> {
  constructor(props: DocumentPageProps) {
    super(props);

    const formMode = this.getDocumentId() ? "read" : "write";
    const formData = toDocumentFormData();

    this.state = {
      formMode,
      formData,
      initialFormData: cloneDeep(formData),
      isDeleteConfirmationModalOpen: false,
    };
  }

  public componentDidMount() {
    const { administrationActions } = this.props;

    const documentId = this.getDocumentId();

    if (documentId) {
      administrationActions.requestDocument(documentId);
    }
  }

  public render() {
    const { administrationState, classes } = this.props;
    const { document, actionStatus } = administrationState;
    const { formMode, formData, isDeleteConfirmationModalOpen } = this.state;

    const formKey = `form_${formMode}`;
    const documentId = this.getDocumentId();
    const isDocumentLoading =
      !document && actionStatus.type === "ADMINISTRATION_DOCUMENT_REQUEST" && actionStatus.pending;
    const isDocumentSaving =
      (actionStatus.type === "ADMINISTRATION_DOCUMENT_ADD_REQUEST" ||
        actionStatus.type === "ADMINISTRATION_DOCUMENT_UPDATE_REQUEST") &&
      actionStatus.pending;
    const isDocumentFormLoading = documentId && (isDocumentLoading || !formData.id);

    return (
      <AppPageFrame
        pageTitle={
          !documentId
            ? "Add Document"
            : isDocumentFormLoading
            ? "Loading Document..."
            : `${formMode === "write" ? "Edit " : ""}Document - ${document!.name}`
        }
        onBackClick={this.handleBackClick}
      >
        <div className={classes.container}>
          {isDocumentFormLoading ? (
            <Loader loadingText={"Loading Document"} />
          ) : (
            <>
              <DocumentForm
                key={formKey}
                className={classes.form}
                formMode={formMode}
                formData={formData}
                isFormSubmitting={isDocumentSaving}
                onEdit={this.handleEdit}
                onChange={this.handleChange}
                onSubmit={this.handleSubmit}
                onCancel={this.handleCancel}
                onClose={this.handleClose}
                onDelete={this.handleDelete}
                onError={this.handleError}
              />
              {documentId && formData.name && (
                <EntityDeleteDialog
                  entityId={documentId}
                  entityName={formData.name}
                  entityDeleteOption={"Delete the Document permanently?"}
                  isOpen={isDeleteConfirmationModalOpen}
                  isActionPending={actionStatus.pending}
                  onCancel={this.handleDeleteCancel}
                  onDelete={this.handleDeleteConfirm}
                />
              )}
            </>
          )}
        </div>
      </AppPageFrame>
    );
  }

  public componentDidUpdate(prevProps: DocumentPageProps, prevState: DocumentPageState) {
    const { administrationState: prevAdministrationState } = prevProps;
    const { formMode: prevFormMode } = prevState;
    const { administrationState, appActions, administrationActions, history } = this.props;
    const { document } = administrationState;
    const { formMode } = this.state;

    const documentId = this.getDocumentId();

    const prevActionStatus = prevAdministrationState.actionStatus;
    const actionStatus = administrationState.actionStatus;

    // If some Document action just completed...
    if (prevActionStatus.pending && !actionStatus.pending) {
      // If an error occurred, display the message...
      if (actionStatus.error) {
        const errorMessage =
          actionStatus.error.message || "An unexpected error occurred upon attempting to process the last operation.";

        appActions.postSnackbarMessage(errorMessage, { variant: "error" });
      }
      // Otherwise, the operation completed successfully...
      else if (document) {
        // If we fetched the Details for this Document...
        if (actionStatus.type === "ADMINISTRATION_DOCUMENT_REQUEST") {
          const formData = toDocumentFormData(document);

          this.setState({ ...this.state, formMode: "read", formData });
          // If we loaded the details for a Document that was just added via /add, replace the URL with it's
          // ID-specific one...
          if (!documentId) {
            history.replace(`/administration/documents/${formData.id}`);
          }
        }
        // Otherwise if we added or updated a Document, fetch the latest Details...
        else if (
          actionStatus.type === "ADMINISTRATION_DOCUMENT_ADD_REQUEST" ||
          actionStatus.type === "ADMINISTRATION_DOCUMENT_UPDATE_REQUEST"
        ) {
          administrationActions.requestDocument(document.id);
        }
        // Otherwise, if we removed a Document...
        else if (actionStatus.type === "ADMINISTRATION_DOCUMENT_DELETE_REQUEST") {
          history.push("/administration/documents");
        }
      }
    }

    // If the Form Mode just changed, scroll to the top of the page...
    if (prevFormMode !== formMode) {
      const appFrame = window.document.getElementById("app-frame");
      if (appFrame) {
        appFrame.scrollTop = 0;
      }
    }
  }

  private handleBackClick = () => {
    this.props.history.goBack();
  };

  private handleClose = () => {
    this.props.history.goBack();
  };

  private handleEdit = () => {
    this.setState({ ...this.state, formMode: "write" });
  };

  private handleCancel = (initialFormData: DocumentFormData) => {
    const { history } = this.props;

    if (!this.getDocumentId()) {
      history.push("/administration/documents");
    } else {
      this.setState({ ...this.state, formMode: "read", formData: initialFormData });
    }
  };

  private handleChange = (formData: DocumentFormData) => {
    this.setState({ ...this.state, formData });
  };

  private handleSubmit = (formData: DocumentFormData) => {
    const { administrationActions } = this.props;

    // If adding a new Document...
    if (!this.getDocumentId()) {
      const addDocumentCommand = toAddDocumentCommand(formData);

      administrationActions.requestDocumentAdd(addDocumentCommand);
    }
    // Otherwise, if updating a Document...
    else {
      if (!formData.id) {
        throw Error("Document Id is undefined upon request to update.");
      }

      const updateDocumentCommand = toUpdateDocumentCommand(formData);

      administrationActions.requestDocumentUpdate(formData.id, updateDocumentCommand);
    }
  };

  private handleDelete = () => {
    this.setState({ ...this.state, isDeleteConfirmationModalOpen: true });
  };

  private handleDeleteCancel = () => {
    this.setState({ ...this.state, isDeleteConfirmationModalOpen: false });
  };

  private handleDeleteConfirm = (documentId: string, permanentlyDeleteFile?: boolean) => {
    const { administrationActions } = this.props;

    administrationActions.requestDocumentDelete(documentId, permanentlyDeleteFile ?? false);
  };

  private handleError = (errorMessage: string) => {
    const { appActions } = this.props;

    appActions.postSnackbarMessage(errorMessage, { variant: "error" });
  };

  private getDocumentId = (): string | undefined => {
    const { match } = this.props;
    const { params } = match;
    const { documentId } = params;

    if (documentId.toLowerCase() === "add") {
      return undefined;
    }

    return documentId;
  };
};

export const DocumentPage = withStyles(styles)(
  connect<DocumentPageStateProps, DocumentPageDispatchProps, DocumentPageOwnProps, ApplicationState>(
    (state) => ({
      oidcState: state.oidc,
      administrationState: state.administration,
    }),
    (dispatch) => ({
      appActions: bindActionCreators(baseActionCreators.app, dispatch),
      administrationActions: bindActionCreators(actionCreators.administration, dispatch),
    }),
  )(DocumentPageComponent),
);
