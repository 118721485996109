import { getApiConfiguration } from "@interface48/app";
import { Form, FormAction, getNewFormContext, handleFieldError, IChangeEvent, ISubmitEvent } from "@interface48/forms";
import React, { useMemo } from "react";
import { TrainingProgramSessionRevisionFormData } from "./models";
import { getTrainingProgramSessionRevisionFormSchema } from "./utils";

const FORM_ID = "trainingProgramSessionRevisionForm";

interface TrainingProgramSessionRevisionFormProps {
  scrollContainerElementId?: string;
  className?: string;
  formData: TrainingProgramSessionRevisionFormData;
  isFormSubmitting?: boolean;
  onCancel: () => void;
  onChange: (formData: TrainingProgramSessionRevisionFormData) => void;
  onSubmit: (formData: TrainingProgramSessionRevisionFormData) => void;
}

export const TrainingProgramSessionRevisionFormComponent = (props: TrainingProgramSessionRevisionFormProps) => {
  const { scrollContainerElementId, className, formData, isFormSubmitting, onChange, onSubmit, onCancel } = props;

  const formSchema = useMemo(() => getTrainingProgramSessionRevisionFormSchema(), []);
  const formContext = useMemo(() => ({ ...getNewFormContext(), getApiConfiguration }), []);
  const formActions = useMemo(() => {
    const isSubmitDisabled =
      (formData.revisedDepartment == null || formData.originalDepartment === formData.revisedDepartment) &&
      (formData.revisedPosition == null || formData.originalPosition === formData.revisedPosition);

    return getFormActions(isSubmitDisabled);
  }, [formData]);

  const handleChange = (changeEvent: IChangeEvent<TrainingProgramSessionRevisionFormData>) => {
    const { formData } = changeEvent;

    onChange(formData);
  };

  const handleSubmit = (submitEvent: ISubmitEvent<TrainingProgramSessionRevisionFormData>) => {
    const { formData } = submitEvent;

    onSubmit(formData);
  };

  return (
    <Form<TrainingProgramSessionRevisionFormData>
      id={FORM_ID}
      className={className}
      schema={formSchema.jsonSchema}
      uiSchema={formSchema.uiSchema}
      formContext={formContext}
      formData={formData}
      formActions={formActions}
      formActionsAlignment={"right"}
      isFormSubmitting={isFormSubmitting}
      onChange={handleChange}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      onError={handleFieldError(FORM_ID, scrollContainerElementId)}
    />
  );
};

const getFormActions = (isSubmitDisabled: boolean) => {
  return [
    {
      type: "submit",
      button: {
        variant: "primary",
        label: "Save",
        disabled: isSubmitDisabled,
      },
    },
    {
      type: "cancel",
      button: {
        variant: "secondary",
        label: "Cancel",
      },
    },
  ] as FormAction[];
};

export const TrainingProgramSessionRevisionForm = TrainingProgramSessionRevisionFormComponent;
