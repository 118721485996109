/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VideoFileMetadataDto
 */
export interface VideoFileMetadataDto {
    /**
     * 
     * @type {string}
     * @memberof VideoFileMetadataDto
     */
    fileName: string;
    /**
     * 
     * @type {number}
     * @memberof VideoFileMetadataDto
     */
    fileSizeBytes: number;
    /**
     * 
     * @type {number}
     * @memberof VideoFileMetadataDto
     */
    durationSeconds: number;
    /**
     * 
     * @type {number}
     * @memberof VideoFileMetadataDto
     */
    resolutionWidth: number;
    /**
     * 
     * @type {number}
     * @memberof VideoFileMetadataDto
     */
    resolutionHeight: number;
}

export function VideoFileMetadataDtoFromJSON(json: any): VideoFileMetadataDto {
    return {
        'fileName': json['fileName'],
        'fileSizeBytes': json['fileSizeBytes'],
        'durationSeconds': json['durationSeconds'],
        'resolutionWidth': json['resolutionWidth'],
        'resolutionHeight': json['resolutionHeight'],
    };
}

export function VideoFileMetadataDtoToJSON(value?: VideoFileMetadataDto | null): any {
    if (value == undefined) {
        return undefined;
    }
    return {
        'fileName': value.fileName,
        'fileSizeBytes': value.fileSizeBytes,
        'durationSeconds': value.durationSeconds,
        'resolutionWidth': value.resolutionWidth,
        'resolutionHeight': value.resolutionHeight,
    };
}


