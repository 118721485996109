import { getApiConfiguration } from "@interface48/app";
import { ReactSelect, Select, SelectValue } from "@interface48/form-controls";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import React from "react";

interface RemoteSelectFieldProps<T = string | number> {
  id: string;
  className?: string;
  label?: string;
  defaultLabel?: string;
  value?: SelectValue<T>;
  valueType: "string" | "number";
  fullWidth?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  selectType?: "native" | "react-select";
  url: string;
  limit?: number;
  onChange?: (value: SelectValue<T>) => void;
}

export const RemoteSelectField = <T extends string | number>(props: RemoteSelectFieldProps<T>) => {
  const {
    id,
    className,
    label,
    defaultLabel,
    value,
    valueType,
    fullWidth = true,
    disabled = false,
    multiple = false,
    selectType = "native",
    url,
    limit,
    onChange,
  } = props;

  const handleChange = (value: ReadonlyArray<string | number> | string | number | null | undefined) => {
    if (onChange) {
      onChange(value as T | null | undefined);
    }
  };

  return (
    <FormControl fullWidth={fullWidth} className={className}>
      {label != null ? <InputLabel htmlFor={id}>{label}</InputLabel> : null}
      {selectType === "native" ? (
        <Select
          id={id}
          disabled={disabled}
          value={value}
          multiple={multiple}
          remoteOptions={{ url, valueType, sort: true, defaultLabel }}
          getApiConfiguration={getApiConfiguration}
          onChange={handleChange}
        />
      ) : (
        <ReactSelect
          id={id}
          disabled={disabled}
          value={value}
          multiple={multiple}
          remoteOptions={{ url, valueType, sort: true, limit }}
          getApiConfiguration={getApiConfiguration}
          onChange={handleChange}
        />
      )}
    </FormControl>
  );
};
