import Divider from "@material-ui/core/Divider";
import LinearProgress from "@material-ui/core/LinearProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import DvrIcon from "@material-ui/icons/Dvr";
import HelpIcon from "@material-ui/icons/Help";
import MovieIcon from "@material-ui/icons/Movie";
import NoteIcon from "@material-ui/icons/Note";
import React from "react";
import { Document, Media, Presentation, Video } from "../../../api";
import {
  compareMediaItemName,
  compareMediaItemType,
  getDurationDescription,
  transformToMediaItemsByCategory,
} from "../../media";
import { compareName } from "../../shared";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flex: "none",
      height: "calc(100% - 305px)",
      width: "100%",
      background: theme.palette.background.paper,
      position: "relative",
      overflow: "auto",
      padding: 0,
    },
    listLoaderMessage: {
      marginTop: theme.spacing(2),
      textAlign: "center",
      fontStyle: "italic",
    },
    listSubheader: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    listSection: {
      background: "inherit",
    },
    selectedListItem: {
      backgroundColor: "rgba(0, 0, 0, 0.2)",
    },
  });

interface TrainingResourceSelectorListProps extends WithStyles<typeof styles> {
  loading: boolean;
  media: Array<Document | Video>;
  selectedMediaItemId: string;
  onSelectedMediaItemChange: (mediaItem: Document | Presentation | Video) => void;
}

export const TrainingResourceSelectorListComponent = (props: TrainingResourceSelectorListProps) => {
  const { loading, media, selectedMediaItemId, onSelectedMediaItemChange, classes } = props;

  const mediaByCategory = transformToMediaItemsByCategory(media);

  const mediaCategoryListElement: JSX.Element[] = [];
  Object.keys(mediaByCategory)
    .sort(compareName)
    .forEach((category) => {
      if (mediaByCategory.hasOwnProperty(category)) {
        mediaCategoryListElement.push(
          <ListSubheader className={classes.listSubheader} key={"subheader_" + category}>
            {category}
          </ListSubheader>,
        );
        mediaCategoryListElement.push(
          ...mediaByCategory[category]
            .sort(compareMediaItemName)
            .sort(compareMediaItemType)
            .map((m: Media) => {
              const onClick = () => {
                onSelectedMediaItemChange(m);
              };
              let mediaIcon: JSX.Element = <HelpIcon />;
              if (m.type === "document") {
                mediaIcon = <NoteIcon />;
              } else if (m.type === "presentation") {
                mediaIcon = <DvrIcon />;
              } else if (m.type === "video") {
                mediaIcon = <MovieIcon />;
              }
              return (
                <ListItem
                  button={true}
                  key={m.type + m.id}
                  className={m.id === selectedMediaItemId ? classes.selectedListItem : undefined}
                  onClick={onClick}
                >
                  <ListItemIcon>{mediaIcon}</ListItemIcon>
                  <ListItemText
                    primary={m.name}
                    secondary={m.type === "video" ? getDurationDescription((m as Video).durationSeconds) : null}
                  />
                </ListItem>
              );
            }),
        );
        mediaCategoryListElement.push(<Divider key={"divider_" + category} />);
      }
    });

  return (
    <>
      {loading && (
        <>
          <LinearProgress />
          <Typography variant="body1" className={classes.listLoaderMessage}>
            Loading Resources...
          </Typography>
        </>
      )}
      <List className={classes.root}>{mediaCategoryListElement}</List>
    </>
  );
};

export const TrainingResourceSelectorList = withStyles(styles)(TrainingResourceSelectorListComponent);
