import { FieldProps } from "@interface48/forms";
import Chip from "@material-ui/core/Chip";
import green from "@material-ui/core/colors/green";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { QuizQuestionFormData } from "../models";

const styles = (theme: Theme) =>
  createStyles({
    question: {
      display: "flex",
      flexDirection: "row",
      alignItems: "start",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    questionTitle: {
      marginTop: theme.spacing(2),
      fontSize: theme.typography.body1.fontSize,
    },
    questionNumber: {
      marginRight: theme.spacing(2),
    },
    questionText: {},
    questionAnswers: {
      marginTop: 8,
      padding: theme.spacing(2),
      width: `calc(100% - ${theme.spacing(4)}px)`,
      border: `solid 1px ${theme.palette.divider}`,
      borderRadius: theme.shape.borderRadius,
    },
    questionAnswerTitle: {
      marginTop: "0 !important",
      fontSize: theme.typography.body1.fontSize,
    },
    questionAnswer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "start",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    questionAnswerNumber: {
      marginRight: theme.spacing(2),
    },
    questionAnswerText: {
      display: "flex",
      alignItems: "start",
    },
    questionAnswerIsCorrect: {
      color: theme.palette.common.white,
      backgroundColor: green[500],
      marginLeft: theme.spacing(2),
      marginTop: -5,
    },
  });

interface QuizQuestionsReadOnlyFieldProps extends FieldProps<QuizQuestionFormData[]>, WithStyles<typeof styles> {}

export const QuizQuestionsReadOnlyFieldComponent = (props: QuizQuestionsReadOnlyFieldProps) => {
  const { schema, formData, classes } = props;
  const label = schema.title;

  return (
    <>
      <Typography variant="h5" className={classes.questionTitle}>
        {label}
      </Typography>
      {formData.map((questionFormData, questionIndex) => {
        const questionNumber = questionIndex + 1;

        return (
          <div key={`q${questionNumber}`}>
            <div className={classes.question}>
              <div className={classes.questionNumber}>{`${questionNumber}.`}</div>
              <div className={classes.questionText} dangerouslySetInnerHTML={{ __html: questionFormData.text || "" }} />
            </div>
            <div className={classes.questionAnswers}>
              <Typography variant="h5" className={classes.questionAnswerTitle}>
                Answers
              </Typography>
              {questionFormData.answers!.map((questionAnswerFormData, answerIndex) => {
                const questionAnswerNumber = answerIndex + 1;
                const questionAnswerAlphaChar =
                  questionAnswerNumber !== undefined ? String.fromCharCode(97 + questionAnswerNumber - 1) : undefined;

                return (
                  <div key={`q${questionNumber}a${questionAnswerNumber}`} className={classes.questionAnswer}>
                    <div className={classes.questionAnswerNumber}>{`${questionAnswerAlphaChar})`}</div>
                    <div className={classes.questionAnswerText}>
                      {questionAnswerFormData.text}{" "}
                      {questionAnswerFormData.isCorrect && (
                        <Chip className={classes.questionAnswerIsCorrect} label="Correct" />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
};

export const QuizQuestionsReadOnlyField = withStyles(styles)(QuizQuestionsReadOnlyFieldComponent);
