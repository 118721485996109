import { OptionItem } from "@interface48/api";
import { getApiConfiguration } from "@interface48/app";
import {
  Form,
  FormAction,
  FormContext,
  FormSchema,
  FormValidation,
  getNewFormContext,
  handleFieldError,
  IChangeEvent,
  ISubmitEvent,
} from "@interface48/forms";
import { red } from "@material-ui/core/colors";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import classNames from "classnames";
import cloneDeep from "lodash/cloneDeep";
import groupBy from "lodash/groupBy";
import React from "react";
import { TrainingProgramComponentsReadOnlyField, TrainingProgramDepartmentsReadOnlyField } from "./fields";
import { TrainingProgramFormData } from "./models";
import { getTrainingProgramFormSchema } from "./utils";
import { TrainingProgramComponentSelectWidget } from "./widgets";

const FORM_ID = "trainingProgramForm";

const styles = (theme: Theme) =>
  createStyles({
    form: {
      "& .field-array .field-string label": {
        display: "none",
      },
    },
    deleteFormAction: {
      marginLeft: "auto !important",
      color: red[500],
    },
  });

interface TrainingProgramFormProps extends WithStyles<typeof styles> {
  className: string;
  formMode: "read" | "write";
  formData: TrainingProgramFormData;
  isFormSubmitting: boolean;
  trainingProgramComponentOptions: Array<OptionItem<string>>;
  onEdit: () => void;
  onClose: () => void;
  onChange: (formData: TrainingProgramFormData) => void;
  onSubmit: (formData: TrainingProgramFormData) => void;
  onCancel: (initialFormData: TrainingProgramFormData) => void;
  onDelete: () => void;
  onPreview: () => void;
}

interface TrainingProgramFormState {
  formSchema: FormSchema;
  formContext: FormContext;
  formActions: FormAction[];
  initialFormData: TrainingProgramFormData;
}

export const TrainingProgramFormComponent = class extends React.Component<
  TrainingProgramFormProps,
  TrainingProgramFormState
> {
  constructor(props: TrainingProgramFormProps) {
    super(props);

    const { formMode, formData, onDelete, onPreview, trainingProgramComponentOptions, classes } = this.props;

    const formSchema = getTrainingProgramFormSchema(formMode, trainingProgramComponentOptions);
    const formActions = getFormActions(classes, formMode, onDelete, onPreview);

    this.state = {
      initialFormData: cloneDeep(formData),
      formSchema,
      formContext: {
        ...getNewFormContext(),
        getApiConfiguration,
      },
      formActions,
    };
  }

  public render() {
    const { className, formData, isFormSubmitting, classes, onClose, onEdit } = this.props;
    const { formSchema, formContext, formActions } = this.state;

    return (
      <Form<TrainingProgramFormData>
        id={FORM_ID}
        className={classNames(classes.form, className)}
        schema={formSchema.jsonSchema}
        uiSchema={formSchema.uiSchema}
        formData={formData}
        formContext={formContext}
        formActions={formActions}
        widgets={{ TrainingProgramComponentSelectWidget }}
        fields={{ TrainingProgramComponentsReadOnlyField, TrainingProgramDepartmentsReadOnlyField }}
        isFormSubmitting={isFormSubmitting}
        validate={this.handleValidate}
        onEdit={onEdit}
        onChange={this.handleChange}
        onSubmit={this.handleSubmit}
        onError={handleFieldError(FORM_ID)}
        onCancel={this.handleCancel}
        onClose={onClose}
      />
    );
  }

  public componentDidUpdate(prevProps: TrainingProgramFormProps) {
    const {
      formMode: prevFormMode,
      formData: prevFormData,
      trainingProgramComponentOptions: prevTrainingProgramComponentOptions,
    } = prevProps;
    const { formMode, formData, trainingProgramComponentOptions, onDelete, onPreview, classes } = this.props;
    // If properties upon which the Form Schema and Form Actions have changed...
    if (
      !(prevFormMode === formMode && prevTrainingProgramComponentOptions === trainingProgramComponentOptions) ||
      (formMode === "read" && !prevFormData && formData)
    ) {
      const formSchema = getTrainingProgramFormSchema(formMode, trainingProgramComponentOptions);
      const formActions = getFormActions(classes, formMode, onDelete, onPreview);

      this.setState({ ...this.state, formSchema, formActions });
    }
  }

  private handleValidate = (
    formData: TrainingProgramFormData,
    errors: FormValidation<TrainingProgramFormData>,
    isOnSubmit?: boolean,
  ) => {
    if (
      formData.departments &&
      Object.values(groupBy(formData.departments, (d) => d.department)).some((d) => d.length > 1)
    ) {
      errors.departments?.addError("One or more Departments have been specified more than once.");
    }
  };

  private handleChange = (changeEvent: IChangeEvent<TrainingProgramFormData>) => {
    const { onChange } = this.props;

    let { formData } = changeEvent;

    formData = {
      ...formData,
      hasDepartments: !!formData.departments?.length,
    };

    onChange(formData);
  };

  private handleSubmit = (submitEvent: ISubmitEvent<TrainingProgramFormData>) => {
    const { onSubmit } = this.props;

    const { formData } = submitEvent;

    onSubmit(formData);
  };

  private handleCancel = () => {
    const { onCancel } = this.props;
    const { initialFormData } = this.state;

    onCancel(initialFormData);
  };
};

const getFormActions = (
  classes: ClassNameMap<"deleteFormAction">,
  formMode: "read" | "write",
  onDelete: () => void,
  onPreview: () => void,
) => {
  const previewButton = {
    type: "other",
    button: {
      variant: "secondary",
      label: "Preview",
      onClick: onPreview,
    },
  };

  let formActions = [];

  if (formMode === "read") {
    formActions = [
      {
        type: "edit",
        button: {
          variant: "primary",
          label: "Edit",
        },
      },
      previewButton,
      {
        type: "close",
        button: {
          variant: "secondary",
          label: "Back",
        },
      },
      {
        type: "other",
        button: {
          className: classes.deleteFormAction,
          variant: "secondary",
          label: "Delete",
          onClick: onDelete,
        },
      },
    ] as FormAction[];
  } else {
    formActions = [
      {
        type: "submit",
        button: {
          variant: "primary",
          label: "Save",
        },
      },
      previewButton,
      {
        type: "cancel",
        button: {
          variant: "secondary",
          label: "Cancel",
        },
      },
    ] as FormAction[];
  }

  return formActions;
};

export const TrainingProgramForm = withStyles(styles)(TrainingProgramFormComponent);
