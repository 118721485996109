/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VideoSummaryDto
 */
export interface VideoSummaryDto {
    /**
     * 
     * @type {string}
     * @memberof VideoSummaryDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof VideoSummaryDto
     */
    mediaCategoryId: string;
    /**
     * 
     * @type {string}
     * @memberof VideoSummaryDto
     */
    mediaCategoryName: string;
    /**
     * 
     * @type {string}
     * @memberof VideoSummaryDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof VideoSummaryDto
     */
    fileName: string;
    /**
     * 
     * @type {string}
     * @memberof VideoSummaryDto
     */
    fileExtension: string;
    /**
     * 
     * @type {number}
     * @memberof VideoSummaryDto
     */
    fileSizeBytes: number;
    /**
     * 
     * @type {number}
     * @memberof VideoSummaryDto
     */
    durationSeconds: number;
    /**
     * 
     * @type {number}
     * @memberof VideoSummaryDto
     */
    resolutionWidth: number;
    /**
     * 
     * @type {number}
     * @memberof VideoSummaryDto
     */
    resolutionHeight: number;
}

export function VideoSummaryDtoFromJSON(json: any): VideoSummaryDto {
    return {
        'id': json['id'],
        'mediaCategoryId': json['mediaCategoryId'],
        'mediaCategoryName': json['mediaCategoryName'],
        'name': json['name'],
        'fileName': json['fileName'],
        'fileExtension': json['fileExtension'],
        'fileSizeBytes': json['fileSizeBytes'],
        'durationSeconds': json['durationSeconds'],
        'resolutionWidth': json['resolutionWidth'],
        'resolutionHeight': json['resolutionHeight'],
    };
}

export function VideoSummaryDtoToJSON(value?: VideoSummaryDto | null): any {
    if (value == undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'mediaCategoryId': value.mediaCategoryId,
        'mediaCategoryName': value.mediaCategoryName,
        'name': value.name,
        'fileName': value.fileName,
        'fileExtension': value.fileExtension,
        'fileSizeBytes': value.fileSizeBytes,
        'durationSeconds': value.durationSeconds,
        'resolutionWidth': value.resolutionWidth,
        'resolutionHeight': value.resolutionHeight,
    };
}


