/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CourseSummaryDto,
    CourseSummaryDtoFromJSON,
    CourseSummaryDtoToJSON,
    DocumentSummaryDto,
    DocumentSummaryDtoFromJSON,
    DocumentSummaryDtoToJSON,
    QuizTemplateDto,
    QuizTemplateDtoFromJSON,
    QuizTemplateDtoToJSON,
    VideoSummaryDto,
    VideoSummaryDtoFromJSON,
    VideoSummaryDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface TrainingProgramComponentSummaryDto
 */
export interface TrainingProgramComponentSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof TrainingProgramComponentSummaryDto
     */
    number: number;
    /**
     * 
     * @type {CourseSummaryDto}
     * @memberof TrainingProgramComponentSummaryDto
     */
    course?: CourseSummaryDto;
    /**
     * 
     * @type {DocumentSummaryDto}
     * @memberof TrainingProgramComponentSummaryDto
     */
    document?: DocumentSummaryDto;
    /**
     * 
     * @type {VideoSummaryDto}
     * @memberof TrainingProgramComponentSummaryDto
     */
    video?: VideoSummaryDto;
    /**
     * 
     * @type {QuizTemplateDto}
     * @memberof TrainingProgramComponentSummaryDto
     */
    quiz?: QuizTemplateDto;
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramComponentSummaryDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof TrainingProgramComponentSummaryDto
     */
    durationMinutes: number;
}

export function TrainingProgramComponentSummaryDtoFromJSON(json: any): TrainingProgramComponentSummaryDto {
    return {
        'number': json['number'],
        'course': !exists(json, 'course') ? undefined : CourseSummaryDtoFromJSON(json['course']),
        'document': !exists(json, 'document') ? undefined : DocumentSummaryDtoFromJSON(json['document']),
        'video': !exists(json, 'video') ? undefined : VideoSummaryDtoFromJSON(json['video']),
        'quiz': !exists(json, 'quiz') ? undefined : QuizTemplateDtoFromJSON(json['quiz']),
        'name': json['name'],
        'durationMinutes': json['durationMinutes'],
    };
}

export function TrainingProgramComponentSummaryDtoToJSON(value?: TrainingProgramComponentSummaryDto | null): any {
    if (value == undefined) {
        return undefined;
    }
    return {
        'number': value.number,
        'course': CourseSummaryDtoToJSON(value.course),
        'document': DocumentSummaryDtoToJSON(value.document),
        'video': VideoSummaryDtoToJSON(value.video),
        'quiz': QuizTemplateDtoToJSON(value.quiz),
        'name': value.name,
        'durationMinutes': value.durationMinutes,
    };
}


