import {
  Form,
  FormAction,
  FormContext,
  FormSchema,
  getNewFormContext,
  handleFieldError,
  IChangeEvent,
  ISubmitEvent,
} from "@interface48/forms";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import React from "react";
import { QuizTemplateDto } from "../../../api";
import { QuizSessionState } from "../models";
import { getQuizSessionFormSchema } from "./utils";

const FORM_ID = "quizSessionForm";

const styles = (theme: Theme) =>
  createStyles({
    form: {
      // Full-size Question Label
      "& .field-object > .properties > .property > .form-group > label": {
        transform: "none",
        position: "relative",
        color: theme.palette.text.primary,
      },
    },
  });

interface QuizSessionFormProps extends WithStyles<typeof styles> {
  scrollContainerElementId?: string;
  className?: string;
  quiz: QuizTemplateDto;
  quizSessionState: QuizSessionState;
  isPassed: boolean;
  isPreviewMode?: boolean;
  onChange: (formData: QuizSessionState, isCompleted: boolean) => void;
  onCompletionChange: (isCompleted: boolean) => void;
  onSubmit: (formData: QuizSessionState) => void;
}

interface QuizSessionFormState {
  formContext: FormContext;
  initialFormData: QuizSessionState;
  formSchema: FormSchema;
  formActions: FormAction[];
  isCompleted: boolean;
}

export const QuizSessionFormComponent = class extends React.Component<QuizSessionFormProps, QuizSessionFormState> {
  constructor(props: QuizSessionFormProps) {
    super(props);

    const { quiz, quizSessionState, isPassed, isPreviewMode } = this.props;

    const isCompleted = this.isQuizCompleted(quizSessionState);

    // Note: initialFormData should only need to be cloned in the constructor and not in componentDidUpdate,
    // but if canceling out of this form does not restore the initial data, than check if this form
    // is not reconstructing itself on load of a new set of add train form data...
    this.state = {
      // Only restore initialFormData for existing Operations
      initialFormData: cloneDeep(quizSessionState),
      formSchema: getQuizSessionFormSchema(quiz),
      formContext: { ...getNewFormContext() },
      formActions: getFormActions(isCompleted, isPassed, isPreviewMode),
      isCompleted,
    };
  }

  public componentDidMount() {
    const { quizSessionState, onChange, onCompletionChange } = this.props;
    const { isCompleted } = this.state;

    if (!quizSessionState.beganAt) {
      quizSessionState.beganAt = moment().toISOString();
      onChange(quizSessionState, isCompleted);
    } else {
      onCompletionChange(isCompleted);
    }
  }

  public render() {
    const { scrollContainerElementId, className, quizSessionState, classes, children } = this.props;
    const { formContext, formSchema, formActions } = this.state;

    return (
      <Form<QuizSessionState>
        id={FORM_ID}
        className={classNames(classes.form, className)}
        schema={formSchema.jsonSchema}
        uiSchema={formSchema.uiSchema}
        formContext={formContext}
        formData={quizSessionState}
        formActions={formActions}
        onChange={this.handleChange}
        onSubmit={this.handleSubmit}
        onError={handleFieldError(FORM_ID, scrollContainerElementId)}
        formActionsAlignment={"left"}
      >
        {children}
      </Form>
    );
  }

  public componentDidUpdate(prevProps: QuizSessionFormProps, prevState: QuizSessionFormState) {
    const { quiz: prevQuiz, isPassed: prevIsPassed } = prevProps;
    const { quiz, isPassed, isPreviewMode } = this.props;
    const { isCompleted } = this.state;

    if (prevQuiz !== quiz || prevIsPassed !== isPassed) {
      const formSchema = getQuizSessionFormSchema(quiz);
      const formActions = getFormActions(isCompleted, isPassed, isPreviewMode);

      this.setState({ ...this.state, formSchema, formActions });
    }
  }

  private handleChange = (changeEvent: IChangeEvent<QuizSessionState>) => {
    const { isPassed, isPreviewMode, onChange } = this.props;
    const { isCompleted: prevIsCompleted } = this.state;

    const { formData } = changeEvent;

    const isCompleted = this.isQuizCompleted(formData);

    if (isCompleted && !formData.completedAt) {
      formData.completedAt = moment().toISOString();
    }

    onChange(formData, isCompleted);

    if (prevIsCompleted !== isCompleted) {
      const formActions = getFormActions(isCompleted, isPassed, isPreviewMode);

      this.setState({ ...this.state, formActions, isCompleted });
    }
  };

  private handleSubmit = (submitEvent: ISubmitEvent<QuizSessionState>) => {
    const { onSubmit } = this.props;

    const { formData } = submitEvent;

    onSubmit(formData);
  };

  private isQuizCompleted = (formData: QuizSessionState) => {
    const isCompleted = Object.keys(formData.quizQuestionAnswerValues)
      .map((key) => formData.quizQuestionAnswerValues[key])
      .every((value: number | number[] | undefined) => (Array.isArray(value) ? value.length > 0 : value !== undefined));

    return isCompleted;
  };
};

const getFormActions = (isCompleted: boolean, isPassed: boolean, isPreviewMode?: boolean): FormAction[] => {
  return isPreviewMode
    ? []
    : [
        {
          type: "submit",
          button: {
            disabled: !isPassed || !isCompleted,
            variant: "primary",
            label: "Next",
          },
        },
      ];
};

export const QuizSessionForm = withStyles(styles)(QuizSessionFormComponent);
