import Chip from "@material-ui/core/Chip";
import green from "@material-ui/core/colors/green";
import orange from "@material-ui/core/colors/orange";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import React from "react";
import { CourseResultDto, TrainingProgramSessionCourseResultDto } from "../../../api";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "flex-start",
      flexDirection: "column",
    },
    result: {
      display: "flex",
      alignItems: "flex-start",
      flexDirection: "row",
    },
    resultDescription: {
      paddingTop: theme.spacing(1),
    },
    chip: {
      margin: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
      marginLeft: 0,
      color: theme.palette.common.white,
    },
    chipPassed: {
      backgroundColor: green[500],
    },
    chipNotPassed: {
      backgroundColor: orange[500],
    },
  });

interface CourseResultSummaryProps extends WithStyles<typeof styles> {
  courseResult: CourseResultDto | TrainingProgramSessionCourseResultDto;
}

export const CourseResultSummaryComponent = (props: CourseResultSummaryProps) => {
  const { courseResult, classes } = props;
  const { courseName, isPassResult } = courseResult;

  let chipLabel: string;
  let chipStyleClassName: string;

  if (isPassResult) {
    chipLabel = "Passed";
    chipStyleClassName = classes.chipPassed;
  } else {
    chipLabel = "Not Passed";
    chipStyleClassName = classes.chipNotPassed;
  }

  return (
    <div className={classes.root}>
      <div className={classes.result}>
        <Chip label={chipLabel} className={classNames(classes.chip, chipStyleClassName)} />
        <div>
          <Typography className={classes.resultDescription}>{courseName}</Typography>
        </div>
      </div>
    </div>
  );
};

export const CourseResultSummary = withStyles(styles)(CourseResultSummaryComponent);
