import Grid from "@material-ui/core/Grid";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { AdminCard } from "../../components/administration";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(2),
    },
    title: {
      margin: `${theme.spacing(2)}px 0 ${theme.spacing(1)}px`,
    },
  });

interface ReportingIndexPageProps extends WithStyles<typeof styles> {}

export const ReportingIndexPageComponent = (props: ReportingIndexPageProps) => {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <Typography variant="h4" className={classes.title}>
        Reporting
      </Typography>
      <Grid item={true} xs={12}>
        <Grid container={true} spacing={2}>
          <Grid item={true} lg={3} md={4} sm={6} xs={12}>
            <AdminCard
              title="Training Scorecards"
              description="View Training Compliance by Department."
              url="/reporting/training-scorecards"
            />
          </Grid>
          <Grid item={true} lg={3} md={4} sm={6} xs={12}>
            <AdminCard
              title="Training Program Sessions"
              description="View Training Program Session results."
              url="/reporting/training-program-sessions"
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export const ReportingIndexPage = withStyles(styles)(ReportingIndexPageComponent);
