/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TrainingProgramComponentSummaryDto,
    TrainingProgramComponentSummaryDtoFromJSON,
    TrainingProgramComponentSummaryDtoToJSON,
    TrainingProgramDepartmentDto,
    TrainingProgramDepartmentDtoFromJSON,
    TrainingProgramDepartmentDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface TrainingProgramSummaryDto
 */
export interface TrainingProgramSummaryDto {
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramSummaryDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramSummaryDto
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramSummaryDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramSummaryDto
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof TrainingProgramSummaryDto
     */
    durationMinutes: number;
    /**
     * 
     * @type {number}
     * @memberof TrainingProgramSummaryDto
     */
    sortOrder?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TrainingProgramSummaryDto
     */
    majorVersion: number;
    /**
     * 
     * @type {number}
     * @memberof TrainingProgramSummaryDto
     */
    version: number;
    /**
     * 
     * @type {Array<TrainingProgramComponentSummaryDto>}
     * @memberof TrainingProgramSummaryDto
     */
    components: Array<TrainingProgramComponentSummaryDto>;
    /**
     * 
     * @type {Array<TrainingProgramDepartmentDto>}
     * @memberof TrainingProgramSummaryDto
     */
    departments: Array<TrainingProgramDepartmentDto>;
}

export function TrainingProgramSummaryDtoFromJSON(json: any): TrainingProgramSummaryDto {
    return {
        'id': json['id'],
        'slug': json['slug'],
        'name': json['name'],
        'description': json['description'],
        'durationMinutes': json['durationMinutes'],
        'sortOrder': !exists(json, 'sortOrder') ? undefined : json['sortOrder'],
        'majorVersion': json['majorVersion'],
        'version': json['version'],
        'components': (json['components'] as Array<any>).map(TrainingProgramComponentSummaryDtoFromJSON),
        'departments': (json['departments'] as Array<any>).map(TrainingProgramDepartmentDtoFromJSON),
    };
}

export function TrainingProgramSummaryDtoToJSON(value?: TrainingProgramSummaryDto | null): any {
    if (value == undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'slug': value.slug,
        'name': value.name,
        'description': value.description,
        'durationMinutes': value.durationMinutes,
        'sortOrder': value.sortOrder,
        'majorVersion': value.majorVersion,
        'version': value.version,
        'components': (value.components as Array<any>).map(TrainingProgramComponentSummaryDtoToJSON),
        'departments': (value.departments as Array<any>).map(TrainingProgramDepartmentDtoToJSON),
    };
}


