import { Dialog } from "@interface48/app";
import { Theme, WithStyles, createStyles, withStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { TrainingProgramSessionResultDto } from "../../../api";
import {
  TrainingProgramSessionRevisionForm,
  TrainingProgramSessionRevisionFormData,
  toTrainingProgramSessionRevisionFormData,
} from "../forms";

const styles = (theme: Theme) =>
  createStyles({
    form: {
      "& > div:last-child": {
        paddingBottom: theme.spacing(2),
      },
    },
  });

interface TrainingProgramSessionRevisionDialogProps extends WithStyles<typeof styles> {
  trainingProgramSession?: TrainingProgramSessionResultDto;
  isOpen: boolean;
  isActionPending: boolean;
  onCancel: () => void;
  onSubmit: (formData: TrainingProgramSessionRevisionFormData) => void;
}

export const TrainingProgramSessionRevisionDialogComponent = (props: TrainingProgramSessionRevisionDialogProps) => {
  const { trainingProgramSession, isOpen, isActionPending, classes, onSubmit, onCancel } = props;

  const [formData, setFormData] = useState<TrainingProgramSessionRevisionFormData | null>(
    trainingProgramSession ? toTrainingProgramSessionRevisionFormData(trainingProgramSession) : null,
  );

  useEffect(() => {
    if (trainingProgramSession) {
      setFormData(toTrainingProgramSessionRevisionFormData(trainingProgramSession));
    }
  }, [trainingProgramSession]);

  const handleChange = (formData: TrainingProgramSessionRevisionFormData) => {
    setFormData(formData);
  };

  return (
    <Dialog
      open={isOpen}
      title={`Revise Training Program Session ${formData?.number}`}
      onCloseClick={onCancel}
      onEscapeKeyDown={onCancel}
    >
      {formData && (
        <TrainingProgramSessionRevisionForm
          className={classes.form}
          formData={formData}
          isFormSubmitting={isActionPending}
          onChange={handleChange}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      )}
    </Dialog>
  );
};

export const TrainingProgramSessionRevisionDialog = withStyles(styles)(TrainingProgramSessionRevisionDialogComponent);
