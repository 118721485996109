/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    QueryResultsPagingConfiguration,
    QueryResultsPagingConfigurationFromJSON,
    QueryResultsPagingConfigurationToJSON,
    QueryResultsSortingConfiguration,
    QueryResultsSortingConfigurationFromJSON,
    QueryResultsSortingConfigurationToJSON,
    TrainingProgramSessionResultDto,
    TrainingProgramSessionResultDtoFromJSON,
    TrainingProgramSessionResultDtoToJSON,
    TrainingProgramSessionsQueryFilters,
    TrainingProgramSessionsQueryFiltersFromJSON,
    TrainingProgramSessionsQueryFiltersToJSON,
} from './';

/**
 * 
 * @export
 * @interface TrainingProgramSessionsQueryFiltersTrainingProgramSessionResultDtoQueryResultsDto
 */
export interface TrainingProgramSessionsQueryFiltersTrainingProgramSessionResultDtoQueryResultsDto {
    /**
     * 
     * @type {TrainingProgramSessionsQueryFilters}
     * @memberof TrainingProgramSessionsQueryFiltersTrainingProgramSessionResultDtoQueryResultsDto
     */
    filters: TrainingProgramSessionsQueryFilters;
    /**
     * 
     * @type {Array<TrainingProgramSessionResultDto>}
     * @memberof TrainingProgramSessionsQueryFiltersTrainingProgramSessionResultDtoQueryResultsDto
     */
    results: Array<TrainingProgramSessionResultDto>;
    /**
     * 
     * @type {number}
     * @memberof TrainingProgramSessionsQueryFiltersTrainingProgramSessionResultDtoQueryResultsDto
     */
    totalResultsCount: number;
    /**
     * 
     * @type {QueryResultsSortingConfiguration}
     * @memberof TrainingProgramSessionsQueryFiltersTrainingProgramSessionResultDtoQueryResultsDto
     */
    sorting: QueryResultsSortingConfiguration;
    /**
     * 
     * @type {QueryResultsPagingConfiguration}
     * @memberof TrainingProgramSessionsQueryFiltersTrainingProgramSessionResultDtoQueryResultsDto
     */
    paging: QueryResultsPagingConfiguration;
}

export function TrainingProgramSessionsQueryFiltersTrainingProgramSessionResultDtoQueryResultsDtoFromJSON(json: any): TrainingProgramSessionsQueryFiltersTrainingProgramSessionResultDtoQueryResultsDto {
    return {
        'filters': TrainingProgramSessionsQueryFiltersFromJSON(json['filters']),
        'results': (json['results'] as Array<any>).map(TrainingProgramSessionResultDtoFromJSON),
        'totalResultsCount': json['totalResultsCount'],
        'sorting': QueryResultsSortingConfigurationFromJSON(json['sorting']),
        'paging': QueryResultsPagingConfigurationFromJSON(json['paging']),
    };
}

export function TrainingProgramSessionsQueryFiltersTrainingProgramSessionResultDtoQueryResultsDtoToJSON(value?: TrainingProgramSessionsQueryFiltersTrainingProgramSessionResultDtoQueryResultsDto | null): any {
    if (value == undefined) {
        return undefined;
    }
    return {
        'filters': TrainingProgramSessionsQueryFiltersToJSON(value.filters),
        'results': (value.results as Array<any>).map(TrainingProgramSessionResultDtoToJSON),
        'totalResultsCount': value.totalResultsCount,
        'sorting': QueryResultsSortingConfigurationToJSON(value.sorting),
        'paging': QueryResultsPagingConfigurationToJSON(value.paging),
    };
}


