import {
  AppActions,
  AppBarMenuOptions,
  AppFrame,
  baseActionCreators,
  DrawerMenuOptions,
  getClientConfiguration,
  isUserInRole,
  isUserSignedIn,
  OidcState,
  ProtectedRoute,
  UserMenuOptions,
} from "@interface48/app";
import React from "react";
import * as ReactGA from "react-ga";
import "react-html5video/dist/styles.css";
import { connect } from "react-redux";
import { Route, RouteComponentProps, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { userApi } from "./api";
import "./App.css";
import logo from "./assets/logo.svg";
import { AuthorizationPolicy } from "./auth";
import {
  AdminIndexPage,
  CoursePage,
  CoursesIndexPage,
  DocumentPage,
  DocumentsIndexPage,
  QuizPage,
  QuizzesIndexPage,
  TrainingProgramPage,
  TrainingProgramsIndexPage,
  UserGroupPage,
  UserGroupsIndexPage,
  VideoPage,
  VideosIndexPage,
} from "./pages/administration";
import { HomePage } from "./pages/home";
import { ReportingIndexPage, TrainingProgramSessionsIndexPage, TrainingScorecardsPage } from "./pages/reporting";
import { TrainingResourceBrowserPage } from "./pages/resource-browser";
import { TrainingProgramSessionPage } from "./pages/training-program";
import { actionCreators, ApplicationState, TrainingProgramActions, UIActions } from "./store";

interface AppStateProps {
  oidcState: OidcState;
}

interface AppDispatchProps {
  appActions: AppActions;
  uiActions: UIActions;
  trainingProgramActions: TrainingProgramActions;
}

interface AppOwnProps extends RouteComponentProps<any> {
  enableAnalytics: boolean;
}

type AppProps = AppStateProps & AppDispatchProps & AppOwnProps;

export const AppComponent = class extends React.Component<AppProps> {
  public componentDidMount() {
    const { trainingProgramActions, enableAnalytics, location } = this.props;

    // Try restoring from session storage, if available
    trainingProgramActions.requestTrainingProgramSessionStates();

    if (enableAnalytics) {
      this.sendPageChange(location.pathname, location.search);
    }
  }

  public render() {
    const { uiActions, history, location, match } = this.props;

    const clientConfiguration = getClientConfiguration();
    const environmentNameSuffix =
      clientConfiguration.environmentName !== "production"
        ? ` (${clientConfiguration.environmentName[0].toUpperCase() + clientConfiguration.environmentName.slice(1)})`
        : "";
    const appName = "Training Portal" + environmentNameSuffix;
    const shortAppName = "Training" + environmentNameSuffix;

    const appBarMenuOptions: AppBarMenuOptions = {
      buttonOverride: {
        onClick: uiActions.toggleSidebarDisplay,
        enabledRules: {
          urlRegex: /^\/(training-program|resource-browser).*/,
        },
      },
    };

    const drawerMenuOptions: DrawerMenuOptions = {
      items: [
        {
          label: "Home",
          linkUrl: "/",
          faIconName: "home",
          activeLinkUrlRegex: /^\/(?!(administration|reporting)($|\/.*$))/,
        },
        {
          label: "Reporting",
          linkUrl: "/reporting",
          faIconName: "file-chart-pie",
          activeLinkUrlRegex: /^\/reporting/,
        },
      ],
      collapseOnRouteChange: true,
      enabledRules: {
        authorizationPolicies: [AuthorizationPolicy.Administrators, AuthorizationPolicy.TrainingProgramStakeholders],
      },
    };

    if (isUserSignedIn() && isUserInRole(AuthorizationPolicy.Administrators)) {
      drawerMenuOptions.items.push({
        label: "Administration",
        linkUrl: "/administration",
        faIconName: "cog",
        activeLinkUrlRegex: /^\/administration($|\/.*$)/,
      });
    }

    const userMenuOptions: UserMenuOptions = {
      items: [],
      showSignIn: true,
      showSignOut: true,
    };

    return (
      <AppFrame
        appName={appName}
        shortAppName={shortAppName}
        appLogo={{ src: logo, alt: "Neptune Bulk Terminals" }}
        appBarMenuOptions={appBarMenuOptions}
        drawerMenuOptions={drawerMenuOptions}
        userMenuOptions={userMenuOptions}
        history={history}
        location={location}
        match={match}
        userSignInHandler={userApi.signIn}
        onUserSignedInChange={this.handleUserSignedInChange}
      >
        <Route exact={true} path="/" component={HomePage} />
        <Route path="/training-program" component={TrainingProgramSessionPage} />
        <Route exact={true} path="/resource-browser" component={TrainingResourceBrowserPage} />
        <Route exact={true} path="/resource-browser/documents/:documentId" component={TrainingResourceBrowserPage} />
        <Route
          exact={true}
          path="/resource-browser/presentations/:presentationId"
          component={TrainingResourceBrowserPage}
        />
        <Route exact={true} path="/resource-browser/videos/:videoId" component={TrainingResourceBrowserPage} />
        <ProtectedRoute
          authorizationPolicies={[AuthorizationPolicy.TrainingProgramStakeholders]}
          exact={true}
          path="/reporting"
          component={ReportingIndexPage}
        />
        <ProtectedRoute
          authorizationPolicies={[AuthorizationPolicy.TrainingProgramStakeholders]}
          path="/reporting/training-scorecards"
          component={TrainingScorecardsPage}
        />
        <ProtectedRoute
          authorizationPolicies={[AuthorizationPolicy.TrainingProgramStakeholders]}
          path="/reporting/training-program-sessions"
          component={TrainingProgramSessionsIndexPage}
        />
        <ProtectedRoute
          authorizationPolicies={[AuthorizationPolicy.Administrators]}
          exact={true}
          path="/administration"
          component={AdminIndexPage}
        />
        <ProtectedRoute
          authorizationPolicies={[AuthorizationPolicy.Administrators]}
          exact={true}
          path="/administration/documents"
          component={DocumentsIndexPage}
        />
        <ProtectedRoute
          authorizationPolicies={[AuthorizationPolicy.Administrators]}
          exact={true}
          path="/administration/documents/:documentId"
          component={DocumentPage}
        />
        <ProtectedRoute
          authorizationPolicies={[AuthorizationPolicy.Administrators]}
          exact={true}
          path="/administration/videos"
          component={VideosIndexPage}
        />
        <ProtectedRoute
          authorizationPolicies={[AuthorizationPolicy.Administrators]}
          exact={true}
          path="/administration/videos/:videoId"
          component={VideoPage}
        />
        <ProtectedRoute
          authorizationPolicies={[AuthorizationPolicy.Administrators]}
          exact={true}
          path="/administration/quizzes"
          component={QuizzesIndexPage}
        />
        <ProtectedRoute
          authorizationPolicies={[AuthorizationPolicy.Administrators]}
          exact={true}
          path="/administration/quizzes/:quizId"
          component={QuizPage}
        />
        <ProtectedRoute
          authorizationPolicies={[AuthorizationPolicy.Administrators]}
          exact={true}
          path="/administration/courses"
          component={CoursesIndexPage}
        />
        <ProtectedRoute
          authorizationPolicies={[AuthorizationPolicy.Administrators]}
          exact={true}
          path="/administration/courses/:courseId"
          component={CoursePage}
        />
        <ProtectedRoute
          authorizationPolicies={[AuthorizationPolicy.Administrators]}
          exact={true}
          path="/administration/training-programs"
          component={TrainingProgramsIndexPage}
        />
        <ProtectedRoute
          authorizationPolicies={[AuthorizationPolicy.Administrators]}
          exact={true}
          path="/administration/training-programs/:trainingProgramId"
          component={TrainingProgramPage}
        />
        <ProtectedRoute
          authorizationPolicies={[AuthorizationPolicy.Administrators]}
          exact={true}
          path="/administration/user-groups"
          component={UserGroupsIndexPage}
        />
        <ProtectedRoute
          authorizationPolicies={[AuthorizationPolicy.Administrators]}
          exact={true}
          path="/administration/user-groups/:userGroupType(training-program-stakeholders)"
          component={UserGroupPage}
        />
      </AppFrame>
    );
  }

  public componentDidUpdate(prevProps: AppProps) {
    const { location: prevLocation } = prevProps;
    const { enableAnalytics, location } = this.props;

    if (enableAnalytics && (location.pathname !== prevLocation.pathname || location.search !== prevLocation.search)) {
      this.sendPageChange(location.pathname, location.search);
    }
  }

  private handleUserSignedInChange = (isUserSignedIn: boolean) => {
    const { trainingProgramActions } = this.props;

    if (isUserSignedIn) {
      // Try restoring from server storage, if available
      trainingProgramActions.requestTrainingProgramSessionStates();
    }
  };

  private sendPageChange(pathname: string, search: string = "") {
    ReactGA.set({ page: pathname });
    ReactGA.pageview(pathname + search);
  }
};

export const App = withRouter<any, any>(
  connect<AppStateProps, AppDispatchProps, AppOwnProps, ApplicationState>(
    (state) => ({
      oidcState: state.oidc,
    }),
    (dispatch) => ({
      appActions: bindActionCreators(baseActionCreators.app, dispatch),
      uiActions: bindActionCreators(actionCreators.ui, dispatch),
      trainingProgramActions: bindActionCreators(actionCreators.trainingProgram, dispatch),
    }),
  )(AppComponent),
);
