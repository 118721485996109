/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddCourseCommand
 */
export interface AddCourseCommand {
    /**
     * 
     * @type {string}
     * @memberof AddCourseCommand
     */
    fileUploadId: string;
    /**
     * 
     * @type {number}
     * @memberof AddCourseCommand
     */
    durationMinutes: number;
}

export function AddCourseCommandFromJSON(json: any): AddCourseCommand {
    return {
        'fileUploadId': json['fileUploadId'],
        'durationMinutes': json['durationMinutes'],
    };
}

export function AddCourseCommandToJSON(value?: AddCourseCommand | null): any {
    if (value == undefined) {
        return undefined;
    }
    return {
        'fileUploadId': value.fileUploadId,
        'durationMinutes': value.durationMinutes,
    };
}


