import { FormSchema } from "@interface48/forms";

export const getQuizFormSchema = (formMode: "read" | "write"): FormSchema => {
  const formSchema: FormSchema = {
    jsonSchema: {
      type: "object",
      required: ["name", "description", "durationMinutes", "minimumPassScorePercentage"],
      properties: {
        isExisting: {
          type: "boolean",
        },
        id: {
          type: "string",
          title: "Quiz ID",
        },
        name: {
          type: "string",
          title: "Name",
          maxLength: 75,
        },
        description: {
          type: "string",
          title: "Description",
          maxLength: 500,
        },
        durationMinutes: {
          type: "number",
          title: "Duration (Minutes)",
          minimum: 1,
          maximum: 32767,
        },
        minimumPassScorePercentage: {
          type: "number",
          title: "Minimum Pass Score Percentage",
          minimum: 1,
          maximum: 100,
        },
        questions: {
          type: "array",
          title: "Questions",
          minItems: 1,
          items: {
            type: "object",
            required: ["text", "answers"],
            properties: {
              text: {
                type: "string",
                title: "Question Text",
              },
              answers: {
                type: "array",
                title: "Answers",
                minItems: 2,
                items: {
                  type: "object",
                  required: ["text", "isCorrect"],
                  properties: {
                    text: {
                      type: "string",
                      title: "Answer Text",
                    },
                    isCorrect: {
                      type: "boolean",
                      title: "Correct?",
                      default: false,
                    },
                  },
                },
              },
            },
          },
          uniqueItems: true,
        },
      },
      dependencies: {
        isExisting: {
          oneOf: [
            {
              properties: {
                isExisting: { enum: [true] },
                created: {
                  title: "Created",
                  type: "object",
                  properties: {
                    at: {
                      type: "string",
                    },
                    personName: {
                      type: "string",
                    },
                    userName: {
                      type: "string",
                    },
                  },
                },
                lastModified: {
                  title: "Last Modified",
                  type: "object",
                  properties: {
                    at: {
                      type: "string",
                    },
                    personName: {
                      type: "string",
                    },
                    userName: {
                      type: "string",
                    },
                  },
                },
                version: {
                  type: "number",
                  title: "Version",
                },
              },
            },
            {
              properties: {
                isExisting: { enum: [false] },
              },
            },
          ],
        },
      },
    },
    uiSchema: {
      "ui:order": [
        "name",
        "description",
        "durationMinutes",
        "minimumPassScorePercentage",
        "questions",
        "created",
        "lastModified",
        "version",
        "*",
      ],
      isExisting: {
        "ui:widget": "hidden",
      },
      id: {
        "ui:widget": "hidden",
      },
      name: {
        "ui:widget": formMode === "read" ? "ReadOnlyWidget" : undefined,
      },
      description: {
        "ui:widget": formMode === "read" ? "ReadOnlyWidget" : "textarea",
      },
      durationMinutes: {
        "ui:widget": formMode === "read" ? "ReadOnlyWidget" : undefined,
      },
      minimumPassScorePercentage: {
        "ui:widget": formMode === "read" ? "ReadOnlyWidget" : undefined,
      },
      questions:
        formMode === "read"
          ? {
              "ui:field": "QuizQuestionsReadOnlyField",
            }
          : {
              items: {
                text: {
                  "ui:widget": "QuizQuestionTextWidget",
                },
                answers: {
                  items: {
                    text: {
                      "ui:widget": "QuizQuestionAnswerTextWidget",
                    },
                  },
                },
              },
            },
      created: {
        "ui:field": "AuditDetailsField",
      },
      lastModified: {
        "ui:field": "AuditDetailsField",
      },
      version: {
        "ui:widget": "ReadOnlyWidget",
      },
    },
  };

  return formSchema;
};
