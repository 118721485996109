/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetQuizResultQuery
 */
export interface GetQuizResultQuery {
    /**
     * 
     * @type {{ [key: string]: Array<number>; }}
     * @memberof GetQuizResultQuery
     */
    quizQuestionAnswerValues: { [key: string]: Array<number>; };
}

export function GetQuizResultQueryFromJSON(json: any): GetQuizResultQuery {
    return {
        'quizQuestionAnswerValues': json['quizQuestionAnswerValues'],
    };
}

export function GetQuizResultQueryToJSON(value?: GetQuizResultQuery | null): any {
    if (value == undefined) {
        return undefined;
    }
    return {
        'quizQuestionAnswerValues': value.quizQuestionAnswerValues,
    };
}


