export const compareName = (name1: string, name2: string) => {
  if (name1 > name2) {
    return 1;
  } else if (name1 < name2) {
    return -1;
  }
  return 0;
};

export const compareNumber = (number1: number, number2: number) => {
  if (number1 > number2) {
    return 1;
  } else if (number1 < number2) {
    return -1;
  }
  return 0;
};
