import { Table, TableBodyRowButton } from "@interface48/tables";
import Typography from "@material-ui/core/Typography";
import { Theme, WithStyles, createStyles, withStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { UserGroupDto, UserGroupMemberDto } from "../../../api";
import { getUserPermissionSlugFromType as getUserGroupSlugFromType } from "../utils";

const styles = (theme: Theme) =>
  createStyles({
    userGroupMembers: {
      display: "flex",
      padding: `${theme.spacing(0.5)}px 0`,
    },
  });

interface UserGroupsTableProps extends WithStyles<typeof styles> {
  results?: UserGroupDto[];
  width: number;
  height?: number;
  isLoading: boolean;
}

interface UserGroupsState {
  sortDirection: "asc" | "desc";
  sortPropertyName: string;
}

export const UserGroupsTableComponent = (props: UserGroupsTableProps) => {
  const { results = [], height, isLoading, classes } = props;
  const [tableState, setTableState] = useState<UserGroupsState>({
    sortDirection: "asc",
    sortPropertyName: "name",
  });

  const viewDetailsButtonRenderer = (id: string, row: UserGroupDto) => {
    const userGroupTypeSlug = getUserGroupSlugFromType(row.userGroupType);

    return (
      <TableBodyRowButton
        icon={["fas", "info"]}
        title="View Details"
        linkUrl={`/administration/user-groups/${userGroupTypeSlug}`}
      />
    );
  };

  const userGroupMembersRenderer = (userGroupMembers: UserGroupMemberDto[], row: UserGroupDto) => {
    return userGroupMembers.length > 0 ? (
      <div className={classes.userGroupMembers}>
        <Typography variant="body2">{userGroupMembers.map((ugm) => ugm.userPrincipalName).join(", ")}</Typography>
      </div>
    ) : (
      <span>(None)</span>
    );
  };

  const handleSortChange = (sortPropertyName: string, sortDirection: "asc" | "desc") => {
    setTableState({ sortDirection, sortPropertyName });
  };

  return (
    <Table
      size="small"
      columnSchemas={[
        {
          id: "id",
          width: 40,
          cellRenderer: viewDetailsButtonRenderer,
        },
        {
          id: "userGroupTypeName",
          label: "Name",
        },
        {
          id: "userGroupMembers",
          label: "User(s)",
          cellRenderer: userGroupMembersRenderer,
        },
        {
          id: "lastModifiedAt",
          label: "Last Modified",
          width: 140,
          format: { type: "date-time", options: { reference: "relative" } },
        },
      ]}
      data={results}
      dataSourceType="local"
      sorting={{
        sortDirection: tableState.sortDirection,
        sortPropertyName: tableState.sortPropertyName,
        onSortChange: handleSortChange,
      }}
      height={height}
      isLoading={isLoading}
    />
  );
};

export const UserGroupsTable = withStyles(styles)(UserGroupsTableComponent);
