/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    UserSignInResultDto,
    UserSignInResultDtoFromJSON,
    UserSignInResultDtoToJSON,
} from '../models';

export interface ApiUserFileUploadsLocalRelativeFilePathGetRequest {
    localRelativeFilePath: string;
}

export interface ApiUserSignInPostRequest {
    body: string;
}

/**
 * no description
 */
export class UserApi extends runtime.BaseAPI {

    /**
     */
    async apiUserFileUploadsLocalRelativeFilePathGetRaw(requestParameters: ApiUserFileUploadsLocalRelativeFilePathGetRequest): Promise<runtime.ApiResponse<File>> {
        if (requestParameters.localRelativeFilePath === null || requestParameters.localRelativeFilePath === undefined) {
            throw new runtime.RequiredError('localRelativeFilePath','Required parameter requestParameters.localRelativeFilePath was null or undefined when calling apiUserFileUploadsLocalRelativeFilePathGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["null"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/user/file-uploads/{localRelativeFilePath}`.replace(`{${"localRelativeFilePath"}}`, encodeURIComponent(String(requestParameters.localRelativeFilePath))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.FileApiResponse(response);
    }

   /**
    */
    async apiUserFileUploadsLocalRelativeFilePathGet(requestParameters: ApiUserFileUploadsLocalRelativeFilePathGetRequest): Promise<File> {
        const response = await this.apiUserFileUploadsLocalRelativeFilePathGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiUserSignInPostRaw(requestParameters: ApiUserSignInPostRequest): Promise<runtime.ApiResponse<UserSignInResultDto>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling apiUserSignInPost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["null"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/user/sign-in`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserSignInResultDtoFromJSON(jsonValue));
    }

   /**
    */
    async apiUserSignInPost(requestParameters: ApiUserSignInPostRequest): Promise<UserSignInResultDto> {
        const response = await this.apiUserSignInPostRaw(requestParameters);
        return await response.value();
    }

}
