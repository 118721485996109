import { FieldProps, ReadOnlyField } from "@interface48/forms";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import moment from "moment";
import React from "react";
import { VideoFileMetadataFormData } from "../models";

const styles = (theme: Theme) =>
  createStyles({
    displayField: {
      marginTop: theme.spacing(1.5),
      paddingBottom: 0,
    },
  });

interface VideoFileMetadataFieldProps extends FieldProps<VideoFileMetadataFormData>, WithStyles<typeof styles> {}

export const VideoFileMetadataFieldComponent = (props: VideoFileMetadataFieldProps) => {
  const { formData, classes } = props;

  return formData && formData.durationSeconds && formData.resolutionWidth && formData.resolutionHeight ? (
    <>
      <ReadOnlyField label={"Video Duration"} className={classes.displayField}>
        {moment.utc(moment.duration(formData.durationSeconds, "seconds").as("milliseconds")).format("HH:mm:ss")}
      </ReadOnlyField>
      <ReadOnlyField label={"Video Resolution"} className={classes.displayField}>
        {formData.resolutionWidth}x{formData.resolutionHeight}
      </ReadOnlyField>
    </>
  ) : null;
};

export const VideoFileMetadataField = withStyles(styles)(VideoFileMetadataFieldComponent);
