import { Dialog } from "@interface48/app";
import Button from "@material-ui/core/Button";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";
import React from "react";
import { CompleteTrainingProgramSessionCommand } from "../../../api";
import { CourseSessionState } from "../../courses";
import { QuizSessionState } from "../../quizzes";
import {
  TrainingProgramSession,
  TrainingProgramSessionCompletionFormData,
  TrainingProgramSessionViewer,
} from "../../training-program-sessions";

const styles = (theme: Theme) =>
  createStyles({
    dialog: {
      [theme.breakpoints.up("md")]: {
        minWidth: 1280,
      },
      "& .MuiDialogActions-root": {
        padding: theme.spacing(3),
      },
    },
  });

interface TrainingProgramPreviewDialogProps extends WithStyles<typeof styles> {
  trainingProgramSession?: TrainingProgramSession;
  title: string;
  availableWidth: number;
  availableHeight: number;
  isOpen: boolean;
  onStepChange: (stepNumber: number) => void;
  onProgressChange: (stepIndex: number, stepProgress: number) => void;
  onCourseOpen: () => void;
  onCourseSessionStateChange: (courseSessionState: CourseSessionState) => void;
  onQuizSessionStateChange: (quizSessionState: QuizSessionState, isQuizComplete?: boolean) => void;
  onCompletionFormChange: (trainingProgramSessionCompletionFormData: TrainingProgramSessionCompletionFormData) => void;
  onSubmit: (completeTrainingProgramSessionCommand: CompleteTrainingProgramSessionCommand) => void;
  onError: (errorMessage: string) => void;
  onClose: () => void;
}

export const TrainingProgramPreviewDialogComponent = (props: TrainingProgramPreviewDialogProps) => {
  const {
    trainingProgramSession,
    title,
    availableWidth,
    availableHeight,
    isOpen,
    classes,
    onStepChange,
    onProgressChange,
    onCourseOpen,
    onCourseSessionStateChange,
    onQuizSessionStateChange,
    onCompletionFormChange,
    onSubmit,
    onError,
    onClose,
  } = props;

  return (
    <Dialog
      className={classes.dialog}
      open={isOpen}
      title={title}
      actions={<Button onClick={onClose}>Close</Button>}
      onCloseClick={onClose}
      onEscapeKeyDown={onClose}
    >
      <TrainingProgramSessionViewer
        trainingProgramSession={trainingProgramSession}
        availableWidth={availableWidth}
        availableHeight={availableHeight}
        isPreviewMode={true}
        isLoading={false}
        onStepChange={onStepChange}
        onProgressChange={onProgressChange}
        onCourseSessionStart={onCourseOpen}
        onCourseSessionStateChange={onCourseSessionStateChange}
        onQuizSessionStateChange={onQuizSessionStateChange}
        onCompletionFormChange={onCompletionFormChange}
        onSubmit={onSubmit}
        onError={onError}
      />
    </Dialog>
  );
};

export const TrainingProgramPreviewDialog = withStyles(styles)(TrainingProgramPreviewDialogComponent);
