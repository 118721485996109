/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddDocumentCommand,
    AddDocumentCommandFromJSON,
    AddDocumentCommandToJSON,
    DocumentDto,
    DocumentDtoFromJSON,
    DocumentDtoToJSON,
    DocumentSummaryDto,
    DocumentSummaryDtoFromJSON,
    DocumentSummaryDtoToJSON,
    RemoveDocumentCommand,
    RemoveDocumentCommandFromJSON,
    RemoveDocumentCommandToJSON,
    UpdateDocumentCommand,
    UpdateDocumentCommandFromJSON,
    UpdateDocumentCommandToJSON,
} from '../models';

export interface ApiAdministrationDocumentsIdDeleteRequest {
    id: string;
    removeDocumentCommand: RemoveDocumentCommand;
}

export interface ApiAdministrationDocumentsIdGetRequest {
    id: string;
}

export interface ApiAdministrationDocumentsIdPutRequest {
    id: string;
    updateDocumentCommand: UpdateDocumentCommand;
}

export interface ApiAdministrationDocumentsPostRequest {
    addDocumentCommand: AddDocumentCommand;
}

export interface ApiDocumentsIdFileGetRequest {
    id: string;
    download?: boolean;
}

export interface ApiDocumentsIdGetRequest {
    id: string;
}

/**
 * no description
 */
export class DocumentsApi extends runtime.BaseAPI {

    /**
     */
    async apiAdministrationDocumentsGetRaw(): Promise<runtime.ApiResponse<Array<DocumentDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["Administrators"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/administration/documents`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DocumentDtoFromJSON));
    }

   /**
    */
    async apiAdministrationDocumentsGet(): Promise<Array<DocumentDto>> {
        const response = await this.apiAdministrationDocumentsGetRaw();
        return await response.value();
    }

    /**
     */
    async apiAdministrationDocumentsIdDeleteRaw(requestParameters: ApiAdministrationDocumentsIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAdministrationDocumentsIdDelete.');
        }

        if (requestParameters.removeDocumentCommand === null || requestParameters.removeDocumentCommand === undefined) {
            throw new runtime.RequiredError('removeDocumentCommand','Required parameter requestParameters.removeDocumentCommand was null or undefined when calling apiAdministrationDocumentsIdDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["Administrators"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/administration/documents/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveDocumentCommandToJSON(requestParameters.removeDocumentCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

   /**
    */
    async apiAdministrationDocumentsIdDelete(requestParameters: ApiAdministrationDocumentsIdDeleteRequest): Promise<void> {
        await this.apiAdministrationDocumentsIdDeleteRaw(requestParameters);
    }

    /**
     */
    async apiAdministrationDocumentsIdGetRaw(requestParameters: ApiAdministrationDocumentsIdGetRequest): Promise<runtime.ApiResponse<DocumentDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAdministrationDocumentsIdGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["Administrators"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/administration/documents/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentDtoFromJSON(jsonValue));
    }

   /**
    */
    async apiAdministrationDocumentsIdGet(requestParameters: ApiAdministrationDocumentsIdGetRequest): Promise<DocumentDto> {
        const response = await this.apiAdministrationDocumentsIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAdministrationDocumentsIdPutRaw(requestParameters: ApiAdministrationDocumentsIdPutRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAdministrationDocumentsIdPut.');
        }

        if (requestParameters.updateDocumentCommand === null || requestParameters.updateDocumentCommand === undefined) {
            throw new runtime.RequiredError('updateDocumentCommand','Required parameter requestParameters.updateDocumentCommand was null or undefined when calling apiAdministrationDocumentsIdPut.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["Administrators"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/administration/documents/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDocumentCommandToJSON(requestParameters.updateDocumentCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

   /**
    */
    async apiAdministrationDocumentsIdPut(requestParameters: ApiAdministrationDocumentsIdPutRequest): Promise<void> {
        await this.apiAdministrationDocumentsIdPutRaw(requestParameters);
    }

    /**
     */
    async apiAdministrationDocumentsPostRaw(requestParameters: ApiAdministrationDocumentsPostRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.addDocumentCommand === null || requestParameters.addDocumentCommand === undefined) {
            throw new runtime.RequiredError('addDocumentCommand','Required parameter requestParameters.addDocumentCommand was null or undefined when calling apiAdministrationDocumentsPost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["Administrators"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/administration/documents`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddDocumentCommandToJSON(requestParameters.addDocumentCommand),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

   /**
    */
    async apiAdministrationDocumentsPost(requestParameters: ApiAdministrationDocumentsPostRequest): Promise<string> {
        const response = await this.apiAdministrationDocumentsPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiDocumentsGetRaw(): Promise<runtime.ApiResponse<Array<DocumentSummaryDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/documents`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DocumentSummaryDtoFromJSON));
    }

   /**
    */
    async apiDocumentsGet(): Promise<Array<DocumentSummaryDto>> {
        const response = await this.apiDocumentsGetRaw();
        return await response.value();
    }

    /**
     */
    async apiDocumentsIdFileGetRaw(requestParameters: ApiDocumentsIdFileGetRequest): Promise<runtime.ApiResponse<File>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiDocumentsIdFileGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.download !== undefined) {
            queryParameters['download'] = requestParameters.download;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/documents/{id}/file`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.FileApiResponse(response);
    }

   /**
    */
    async apiDocumentsIdFileGet(requestParameters: ApiDocumentsIdFileGetRequest): Promise<File> {
        const response = await this.apiDocumentsIdFileGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiDocumentsIdGetRaw(requestParameters: ApiDocumentsIdGetRequest): Promise<runtime.ApiResponse<DocumentSummaryDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiDocumentsIdGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/documents/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentSummaryDtoFromJSON(jsonValue));
    }

   /**
    */
    async apiDocumentsIdGet(requestParameters: ApiDocumentsIdGetRequest): Promise<DocumentSummaryDto> {
        const response = await this.apiDocumentsIdGetRaw(requestParameters);
        return await response.value();
    }

}
