import { BaseInput, WidgetProps } from "@interface48/forms";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    number: {
      marginRight: theme.spacing(2),
    },
    questionText: {
      position: "relative",
      width: "100%",
    },
    "& .field-array .field-string label": {
      display: "none",
    },
  });

interface QuizQuestionTextWidgetProps extends WidgetProps<string | number>, WithStyles<typeof styles> {}

export const QuizQuestionTextWidgetComponent = (props: QuizQuestionTextWidgetProps) => {
  const { id, classes, placeholder, ...rest } = props;

  // Extract index position from the index-based id in the format root_questions_0_text (Note: additionally add 1 since
  // index is 0-based, but we want the display number to be 1-based)
  const questionNumberMatch = /_(\d+)_/.exec(id);
  const questionNumber = questionNumberMatch ? parseInt(questionNumberMatch[1], 10) + 1 : undefined;

  return (
    <div className={classes.root}>
      <Typography variant="body1" className={classes.number}>
        {questionNumber ? `${questionNumber}.` : ""}
      </Typography>
      <div className={classes.questionText}>
        <BaseInput id={id} placeholder="Question Text" multiline={true} {...rest} />
      </div>
    </div>
  );
};

export const QuizQuestionTextWidget = withStyles(styles)(QuizQuestionTextWidgetComponent);
