import { FormSchema } from "@interface48/forms";

export const getVideoFormSchema = (formMode: "read" | "write"): FormSchema => {
  const formSchema: FormSchema = {
    jsonSchema: {
      type: "object",
      required: ["mediaCategoryId", "name"],
      properties: {
        isExisting: {
          type: "boolean",
        },
        id: {
          type: "string",
          title: "Video ID",
        },
        mediaCategoryId: {
          type: "string",
          title: "Media Category",
        },
        mediaCategoryName: {
          type: "string",
          title: "Media Category",
        },
        name: {
          type: "string",
          title: "Name",
          maxLength: 75,
        },
      },
      dependencies: {
        isExisting: {
          oneOf: [
            {
              required: ["updateFile"],
              properties: {
                isExisting: { enum: [true] },
                existingFile: {
                  type: "object",
                  title: "File",
                  properties: {
                    id: {
                      type: "string",
                    },
                    fileName: {
                      type: "string",
                    },
                    fileExtension: {
                      type: "string",
                    },
                    fileSizeBytes: {
                      type: "number",
                    },
                  },
                },
                existingFileMetadata: {
                  type: "object",
                  title: "File Metadata",
                  properties: {
                    durationSeconds: {
                      type: "number",
                      title: "Duration",
                    },
                    resolutionWidth: {
                      type: "number",
                      title: "Width",
                    },
                    resolutionHeight: {
                      type: "number",
                      title: "Width",
                    },
                  },
                },
                updateFile: {
                  type: "boolean",
                  title: "Update File?",
                },
                created: {
                  title: "Created",
                  type: "object",
                  properties: {
                    at: {
                      type: "string",
                    },
                    personName: {
                      type: "string",
                    },
                    userName: {
                      type: "string",
                    },
                  },
                },
                lastModified: {
                  title: "Last Modified",
                  type: "object",
                  properties: {
                    at: {
                      type: "string",
                    },
                    personName: {
                      type: "string",
                    },
                    userName: {
                      type: "string",
                    },
                  },
                },
              },
              dependencies: {
                updateFile: {
                  oneOf: [
                    {
                      required: ["file", "fileMetadata"],
                      properties: {
                        updateFile: { enum: [true] },
                        file: {
                          type: "object",
                          title: "File",
                          required: ["fileUploadId", "fileName", "fileSizeBytes"],
                          properties: {
                            fileUploadId: {
                              type: "string",
                              title: "File Upload ID",
                            },
                            fileName: {
                              type: "string",
                              title: "File Name",
                            },
                            fileSizeBytes: {
                              type: "number",
                              title: "File Size",
                            },
                          },
                        },
                        fileMetadata: {
                          type: "object",
                          title: "File Metadata",
                          properties: {
                            durationSeconds: {
                              type: "number",
                              title: "Duration",
                            },
                            resolutionWidth: {
                              type: "number",
                              title: "Width",
                            },
                            resolutionHeight: {
                              type: "number",
                              title: "Width",
                            },
                          },
                        },
                      },
                    },
                  ],
                },
              },
            },
            {
              required: ["file"],
              properties: {
                isExisting: { enum: [false] },
                file: {
                  type: "object",
                  title: "File",
                  required: ["fileUploadId", "fileName", "fileSizeBytes"],
                  properties: {
                    fileUploadId: {
                      type: "string",
                      title: "File Upload ID",
                    },
                    fileName: {
                      type: "string",
                      title: "File Name",
                    },
                    fileSizeBytes: {
                      type: "number",
                      title: "File Size",
                    },
                  },
                },
                fileMetadata: {
                  type: "object",
                  title: "File Metadata",
                  properties: {
                    durationSeconds: {
                      type: "number",
                      title: "Duration",
                    },
                    resolutionWidth: {
                      type: "number",
                      title: "Width",
                    },
                    resolutionHeight: {
                      type: "number",
                      title: "Width",
                    },
                  },
                },
              },
            },
          ],
        },
      },
    },
    uiSchema: {
      "ui:order": [
        "mediaCategoryId",
        "mediaCategoryName",
        "name",
        "existingFile",
        "existingFileMetadata",
        "updateFile",
        "file",
        "fileMetadata",
        "created",
        "lastModified",
        "*",
      ],
      isExisting: {
        "ui:widget": "hidden",
      },
      id: {
        "ui:widget": "hidden",
      },
      mediaCategoryId: {
        "ui:widget": formMode === "read" ? "hidden" : "select",
        "ui:options": {
          remoteOptions: {
            url: `/api/administration/lists/media-categories`,
          },
        },
      },
      mediaCategoryName: {
        "ui:widget": formMode === "read" ? "ReadOnlyWidget" : "hidden",
      },
      name: {
        "ui:widget": formMode === "read" ? "ReadOnlyWidget" : undefined,
      },
      existingFile: {
        "ui:field": "MediaFileLinkField",
        "ui:options": {
          mediaCategory: "videos",
        },
      },
      existingFileMetadata: {
        "ui:field": "VideoFileMetadataField",
      },
      updateFile: {
        "ui:widget": formMode === "read" ? "hidden" : undefined,
      },
      file: {
        "ui:field": formMode === "read" ? "HiddenField" : "FileField",
        "ui:options": {
          uploadUrl: "/api/administration/videos/file-upload",
          acceptedMimeTypes: ["video/mp4"],
          maxSizeBytes: 1000000000, // 1 GB
        },
        "ui:help": "You may upload an MP4 file that is no more than 1 GB in size.",
      },
      fileMetadata: {
        "ui:field": "VideoFileMetadataField",
      },
      created: {
        "ui:field": "AuditDetailsField",
      },
      lastModified: {
        "ui:field": "AuditDetailsField",
      },
    },
  };

  return formSchema;
};
