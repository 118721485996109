import { FormSchema } from "@interface48/forms";
import { QuizTemplateDto } from "../../../../api";
import { sortQuizQuestionAnswersByNumber, sortQuizQuestionsByNumber } from "../../utils";

export const getQuizSessionFormSchema = (quiz: QuizTemplateDto): FormSchema => {
  const questionsJsonSchema =
    quiz.questions &&
    quiz.questions.sort(sortQuizQuestionsByNumber).reduce((questionsJsonSchemaMap, question) => {
      const sortedAnswers = question.possibleAnswers.sort(sortQuizQuestionAnswersByNumber);

      return {
        ...questionsJsonSchemaMap,
        [question.number]:
          question && question.hasSingleCorrectAnswer
            ? {
                type: "number",
                title: question.displayText,
                enum: sortedAnswers.map((possibleAnswer) => possibleAnswer.number),
                enumNames: sortedAnswers.map((possibleAnswer) => possibleAnswer.displayText),
              }
            : {
                type: "array",
                title: question.displayText,
                items: {
                  type: "number",
                  oneOf: sortedAnswers.map((possibleAnswer) => {
                    return { const: possibleAnswer.number, title: possibleAnswer.displayText };
                  }),
                },
                uniqueItems: true,
              },
      };
    }, {});

  const questionsUiSchema =
    quiz.questions &&
    quiz.questions.reduce((questionsUiSchemaMap, question) => {
      return {
        ...questionsUiSchemaMap,
        [question.number]: question.hasSingleCorrectAnswer
          ? { "ui:widget": "RadioWidget", "ui:options": { allowHTML: true } }
          : { "ui:widget": "CheckboxesWidget", "ui:options": { allowHTML: true } },
      };
    }, {});

  const formSchema: FormSchema = {
    jsonSchema: {
      type: "object",
      title: quiz.name,
      required: ["quizQuestionAnswerValues"],
      properties: {
        quizId: { type: "string" },
        quizVersion: { type: "string" },
        quizQuestionAnswerValues: {
          type: "object",
          title: "",
          required: quiz.questions?.map((question) => `${question.number}`),
          properties: { ...questionsJsonSchema },
        },
        beganAt: { type: "string" },
        completedAt: { type: "string" },
      },
    },
    uiSchema: {
      quizId: { "ui:widget": "hidden" },
      quizVersion: { "ui:widget": "hidden" },
      quizQuestionAnswerValues: { ...questionsUiSchema },
      beganAt: { "ui:widget": "hidden" },
      completedAt: { "ui:widget": "hidden" },
    },
  };

  return formSchema;
};
