import React from "react";
import { Presentation } from "../../../api";

interface PresentationViewerProps {
  presentation: Presentation;
  availableWidth: number;
}

export const PresentationViewerComponent = class extends React.Component<PresentationViewerProps> {
  public componentDidMount() {
    window.addEventListener("resize", this.forceReRender);
  }

  public render() {
    const { presentation } = this.props;

    const PresentationViewerHeight = window.innerHeight - 66;
    const PresentationViewerStyle = {
      height: PresentationViewerHeight + "px",
    };

    return (
      <div id={"presentation-container"} className={"presentation-viewer-container"} style={PresentationViewerStyle}>
        <iframe title="Presentation" src={presentation.localFileUrl} />
      </div>
    );
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.forceReRender);
  }

  private forceReRender = () => {
    this.forceUpdate();
  };
};

export const PresentationViewer = PresentationViewerComponent;
