import { genericFileApi } from "@interface48/api";
import { getApiConfiguration } from "@interface48/app";
import { formatFileSize } from "@interface48/formatting";
import { FieldProps, ReadOnlyField } from "@interface48/forms";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Link from "@material-ui/core/Link";
import { Theme, WithStyles, createStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { saveAs } from "file-saver";
import React, { useState } from "react";
import { MediaFile } from "../../../api";
import { DocumentFormContext } from "../../documents";
import { VideoFormContext } from "../../videos";

const styles = (theme: Theme) =>
  createStyles({
    fileDownloadLinkContainer: {
      position: "relative",
      display: "inline-block",
    },
    fileDownloadLink: {
      textAlign: "left",
    },
    fileDownloadLinkDisabled: {
      pointerEvents: "none",
      color: theme.palette.action.disabled,
    },
    fileAttributeChip: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      "&:first-child": {
        marginLeft: 0,
      },
    },
    fileDownloadProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  });

const ChipLabel = (props: { title: string; value?: string | number }) => (
  <span>
    <strong>{props.title} </strong>
    {props.value}
  </span>
);

interface MediaFileLinkFieldProps extends WithStyles<typeof styles>, FieldProps<MediaFile> {}

interface MediaFileLinkFieldOptions {
  mediaCategory: "documents" | "videos";
}

export const MediaFileLinkFieldComponent = (props: MediaFileLinkFieldProps) => {
  const { formData, schema, uiSchema, classes } = props;
  const { id, fileName, fileSizeBytes } = formData;

  const formContext = props.formContext as DocumentFormContext | VideoFormContext;

  const { mediaCategory } = uiSchema["ui:options"] as MediaFileLinkFieldOptions;

  const [isDownloadPending, setDownloadPending] = useState(false);

  let fileDownloadRelativeUri = `/api/${mediaCategory}/${id}/file?download=true`;

  const fileSize = fileSizeBytes ? formatFileSize(fileSizeBytes) : undefined;

  const handleFileDownload = async (event: React.MouseEvent) => {
    // Must stop click event propagation if Link being used within an element that itself is listening to click events
    // (e.g., Card)
    event.stopPropagation();

    setDownloadPending(true);

    try {
      const fileDownload = await genericFileApi.get(fileDownloadRelativeUri!, getApiConfiguration);

      saveAs(fileDownload);
    } catch (error) {
      formContext.onError((error as Error).message ? (error as Error).message : "An unknown error occurred.");
    }

    setDownloadPending(false);
  };

  return (
    <ReadOnlyField label={schema.title}>
      <div className={classes.fileDownloadLinkContainer}>
        <Link
          component="button"
          type="button"
          variant="body1"
          disabled={isDownloadPending}
          className={classNames(classes.fileDownloadLink, { [classes.fileDownloadLinkDisabled]: isDownloadPending })}
          onClick={handleFileDownload}
        >
          {fileName}
        </Link>
        {isDownloadPending && <CircularProgress size={24} className={classes.fileDownloadProgress} />}
      </div>
      <div>
        <Chip
          size="small"
          label={<ChipLabel title="Size" value={fileSize} />}
          classes={{ root: classes.fileAttributeChip }}
        />
      </div>
    </ReadOnlyField>
  );
};

export const MediaFileLinkField = withStyles(styles)(MediaFileLinkFieldComponent);
