/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddOrUpdateUpdateTrainingScorecardDepartmentCommand,
    AddOrUpdateUpdateTrainingScorecardDepartmentCommandFromJSON,
    AddOrUpdateUpdateTrainingScorecardDepartmentCommandToJSON,
} from './';

/**
 * 
 * @export
 * @interface UpdateTrainingScorecardCommand
 */
export interface UpdateTrainingScorecardCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateTrainingScorecardCommand
     */
    currentPeriodBeginAt: string;
    /**
     * 
     * @type {Array<AddOrUpdateUpdateTrainingScorecardDepartmentCommand>}
     * @memberof UpdateTrainingScorecardCommand
     */
    departments: Array<AddOrUpdateUpdateTrainingScorecardDepartmentCommand>;
}

export function UpdateTrainingScorecardCommandFromJSON(json: any): UpdateTrainingScorecardCommand {
    return {
        'currentPeriodBeginAt': json['currentPeriodBeginAt'],
        'departments': (json['departments'] as Array<any>).map(AddOrUpdateUpdateTrainingScorecardDepartmentCommandFromJSON),
    };
}

export function UpdateTrainingScorecardCommandToJSON(value?: UpdateTrainingScorecardCommand | null): any {
    if (value == undefined) {
        return undefined;
    }
    return {
        'currentPeriodBeginAt': value.currentPeriodBeginAt,
        'departments': (value.departments as Array<any>).map(AddOrUpdateUpdateTrainingScorecardDepartmentCommandToJSON),
    };
}


