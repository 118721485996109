/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TrainingScorecardDepartmentDto,
    TrainingScorecardDepartmentDtoFromJSON,
    TrainingScorecardDepartmentDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface TrainingScorecardDto
 */
export interface TrainingScorecardDto {
    /**
     * 
     * @type {string}
     * @memberof TrainingScorecardDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TrainingScorecardDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TrainingScorecardDto
     */
    currentPeriodBeginAt: string;
    /**
     * 
     * @type {Array<TrainingScorecardDepartmentDto>}
     * @memberof TrainingScorecardDto
     */
    departments: Array<TrainingScorecardDepartmentDto>;
}

export function TrainingScorecardDtoFromJSON(json: any): TrainingScorecardDto {
    return {
        'id': json['id'],
        'name': json['name'],
        'currentPeriodBeginAt': json['currentPeriodBeginAt'],
        'departments': (json['departments'] as Array<any>).map(TrainingScorecardDepartmentDtoFromJSON),
    };
}

export function TrainingScorecardDtoToJSON(value?: TrainingScorecardDto | null): any {
    if (value == undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'currentPeriodBeginAt': value.currentPeriodBeginAt,
        'departments': (value.departments as Array<any>).map(TrainingScorecardDepartmentDtoToJSON),
    };
}


