import moment from "moment";
import { CourseSummaryDto, TrainingProgramSessionCourseStateDto } from "../../../api";
import { CourseSessionState } from "../models";

export const toCourseSessionState = (
  course: CourseSummaryDto,
  courseState?: TrainingProgramSessionCourseStateDto,
): CourseSessionState => {
  let courseStateJson: string;
  let beganAt: string;
  let completedAt: string | undefined;

  // If a new Course Session...
  if (!courseState) {
    courseStateJson = JSON.stringify({});
    beganAt = moment().toISOString();
  }
  // Otherwise, restore an on-going Quiz Session...
  else {
    courseStateJson = courseState.courseStateJson;
    beganAt = courseState.beganAt;
    completedAt = courseState.completedAt ?? undefined;
  }

  const courseSessionState: CourseSessionState = {
    courseId: course.id,
    courseVersion: course.version,
    courseName: course.name,
    courseStateJson,
    beganAt,
    completedAt,
  };

  return courseSessionState;
};
