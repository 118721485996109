import { OptionItem } from "@interface48/api";
import groupBy from "lodash/groupBy";
import {
  TrainingProgramComponentDto,
  TrainingProgramDto,
  TrainingProgramSummaryDto,
  getPositionName,
} from "../../../api";
import { compareName, compareNumber } from "../../shared";

export const sortTrainingProgramsByName = (
  tp1: TrainingProgramSummaryDto | TrainingProgramDto,
  tp2: TrainingProgramSummaryDto | TrainingProgramDto,
) => compareName(tp1.name, tp2.name);

export const sortTrainingProgramsBySortNumberThenName = (
  tp1: TrainingProgramSummaryDto | TrainingProgramDto,
  tp2: TrainingProgramSummaryDto | TrainingProgramDto,
) => {
  // If both Training Programs have a Sort priority...
  if (tp1.sortOrder != null && tp2.sortOrder != null) {
    // If the Training Programs are sorted with the same priority, then sort by Name...
    if (tp1.sortOrder === tp2.sortOrder) {
      return compareName(tp1.name, tp2.name);
    }
    // Otherwise, sort the Training Programs by their priority...
    else {
      return compareNumber(tp1.sortOrder, tp2.sortOrder);
    }
  } else if (tp1.sortOrder == null && tp2.sortOrder == null) {
    return compareName(tp1.name, tp2.name);
  } else if (tp1.sortOrder && tp2.sortOrder == null) {
    return -1;
  } else {
    return 1;
  }
};

export const sortTrainingProgramComponentsByNumber = (
  c1: TrainingProgramComponentDto,
  c2: TrainingProgramComponentDto,
) => compareNumber(c1.number, c2.number);

export const getRemainingTrainingProgramDurationMinutes = (
  trainingProgramComponents: TrainingProgramComponentDto[],
  trainingProgramDurationMinutes: number,
  currentTrainingProgramComponentIndex: number,
  currentTrainingProgramComponentProgressMinutes?: number,
) => {
  const completedTrainingProgramDurationMinutes =
    trainingProgramComponents
      .sort(sortTrainingProgramComponentsByNumber)
      .filter((trainingProgramComponent, index) => index < currentTrainingProgramComponentIndex)
      .reduce((totalDurationMinutes, trainingProgramComponent) => {
        return totalDurationMinutes + trainingProgramComponent.durationMinutes;
      }, 0) + (currentTrainingProgramComponentProgressMinutes ?? 0);
  const remainingTrainingProgramDurationMinutes = Math.ceil(
    trainingProgramDurationMinutes - completedTrainingProgramDurationMinutes,
  );

  return remainingTrainingProgramDurationMinutes;
};

export const getTrainingProgramsDepartmentPositionOptionItemsMap = (trainingPrograms: TrainingProgramSummaryDto[]) => {
  const groupedTrainingProgramDepartments = Object.entries(
    groupBy(
      trainingPrograms.flatMap((tp) => tp.departments ?? []),
      (tpd) => tpd.department,
    ),
  );

  const departmentOptionItems: OptionItem<number>[] = groupedTrainingProgramDepartments.map((departmentGroup) => {
    const department = parseInt(departmentGroup[0], 10);
    const trainingProgramDepartments = departmentGroup[1];

    return { value: department, label: trainingProgramDepartments[0].departmentName };
  });

  const departmentPositionOptionItemsMap: {
    [department: string]: OptionItem<number>[];
  } = groupedTrainingProgramDepartments.reduce((map, departmentGroup) => {
    const department = parseInt(departmentGroup[0], 10);
    const trainingProgramDepartments = departmentGroup[1];

    const positionOptions: OptionItem<number>[] = trainingProgramDepartments
      .flatMap((trainingProgramDepartment) => trainingProgramDepartment.positions ?? [])
      .filter(
        (trainingProgramDepartmentPosition, index, self) =>
          self.findIndex((tpdp) => tpdp === trainingProgramDepartmentPosition) === index,
      )
      .map((trainingProgramDepartmentPosition) => ({
        value: trainingProgramDepartmentPosition,
        label: getPositionName(trainingProgramDepartmentPosition),
      }));

    return {
      ...map,
      [department]: positionOptions,
    };
  }, {});

  return { departmentOptionItems, departmentPositionOptionItemsMap };
};
