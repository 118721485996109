/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QuizResultDto
 */
export interface QuizResultDto {
    /**
     * 
     * @type {string}
     * @memberof QuizResultDto
     */
    quizName: string;
    /**
     * 
     * @type {number}
     * @memberof QuizResultDto
     */
    quizVersion: number;
    /**
     * 
     * @type {number}
     * @memberof QuizResultDto
     */
    minimumPassScorePercentage: number;
    /**
     * 
     * @type {number}
     * @memberof QuizResultDto
     */
    scorePercentage: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof QuizResultDto
     */
    incorrectQuestionNumbers: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof QuizResultDto
     */
    isPassResult: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof QuizResultDto
     */
    isObsoleteResult: boolean;
}

export function QuizResultDtoFromJSON(json: any): QuizResultDto {
    return {
        'quizName': json['quizName'],
        'quizVersion': json['quizVersion'],
        'minimumPassScorePercentage': json['minimumPassScorePercentage'],
        'scorePercentage': json['scorePercentage'],
        'incorrectQuestionNumbers': json['incorrectQuestionNumbers'],
        'isPassResult': json['isPassResult'],
        'isObsoleteResult': json['isObsoleteResult'],
    };
}

export function QuizResultDtoToJSON(value?: QuizResultDto | null): any {
    if (value == undefined) {
        return undefined;
    }
    return {
        'quizName': value.quizName,
        'quizVersion': value.quizVersion,
        'minimumPassScorePercentage': value.minimumPassScorePercentage,
        'scorePercentage': value.scorePercentage,
        'incorrectQuestionNumbers': value.incorrectQuestionNumbers,
        'isPassResult': value.isPassResult,
        'isObsoleteResult': value.isObsoleteResult,
    };
}


