/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateVideoCommand
 */
export interface UpdateVideoCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateVideoCommand
     */
    mediaCategoryId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateVideoCommand
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateVideoCommand
     */
    fileUploadId?: string | null;
}

export function UpdateVideoCommandFromJSON(json: any): UpdateVideoCommand {
    return {
        'mediaCategoryId': json['mediaCategoryId'],
        'name': json['name'],
        'fileUploadId': !exists(json, 'fileUploadId') ? undefined : json['fileUploadId'],
    };
}

export function UpdateVideoCommandToJSON(value?: UpdateVideoCommand | null): any {
    if (value == undefined) {
        return undefined;
    }
    return {
        'mediaCategoryId': value.mediaCategoryId,
        'name': value.name,
        'fileUploadId': value.fileUploadId,
    };
}


