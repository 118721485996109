import { getApiConfiguration } from "@interface48/app";
import {
  Form,
  FormAction,
  FormContext,
  FormSchema,
  FormValidation,
  IChangeEvent,
  ISubmitEvent,
  getNewFormContext,
  handleFieldError,
} from "@interface48/forms";
import { red } from "@material-ui/core/colors";
import { Theme, WithStyles, createStyles, withStyles } from "@material-ui/core/styles";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import Alert from "@material-ui/lab/Alert";
import classNames from "classnames";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import React from "react";
import { QuizQuestionsReadOnlyField } from "./fields";
import { QuizFormData } from "./models";
import { getQuizFormSchema } from "./utils";
import { QuizQuestionAnswerTextWidget, QuizQuestionTextWidget } from "./widgets";

const FORM_ID = "quizForm";

const styles = (theme: Theme) =>
  createStyles({
    form: {
      "& .field-object img": {
        display: "block",
        maxWidth: "100%",
        marginTop: theme.spacing(1),
      },
      "& .field-array h5": {
        marginTop: theme.spacing(2),
        marginBottom: 0,
        fontSize: "0.75rem",
        color: theme.palette.text.secondary,
      },
      "& .field-array .field-array h5": {
        marginTop: 0,
      },
      "& .field-array .field-string > label": {
        display: "none",
      },
      "& .field-array .field-string > div > div > div": {
        width: "100%",
      },
      "& .field-array .field-array": {
        marginTop: 8,
        padding: theme.spacing(2),
        width: `calc(100% - ${theme.spacing(4)}px)`,
        border: `solid 1px ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
      },
      "& .field-array .field-array .field-object .properties": {
        display: "flex",
        flexDirection: "row",
      },
      "& .field-array .field-array .field-object .properties .property": {
        flexGrow: 1,
        flexBasis: "auto",
      },
      // 'Correct?' field
      "& .field-array .field-array .field-object .properties > .property:last-child": {
        flexGrow: 0,
        flexBasis: 130,
      },
    },
    deleteFormAction: {
      marginLeft: "auto !important",
      color: red[500],
    },
  });

interface QuizFormProps extends WithStyles<typeof styles> {
  className: string;
  formMode: "read" | "write";
  formData: QuizFormData;
  isFormSubmitting: boolean;
  onEdit: () => void;
  onClose: () => void;
  onChange: (formData: QuizFormData) => void;
  onSubmit: (formData: QuizFormData) => void;
  onCancel: (initialFormData: QuizFormData) => void;
  onDelete: () => void;
}

interface QuizFormState {
  formSchema: FormSchema;
  formContext: FormContext;
  formActions: FormAction[];
  initialFormData: QuizFormData;
  showRevisionWarning: boolean;
}

export const QuizFormComponent = class extends React.Component<QuizFormProps, QuizFormState> {
  constructor(props: QuizFormProps) {
    super(props);

    const { formMode, formData, classes, onDelete } = this.props;

    const formSchema = getQuizFormSchema(formMode);
    const formActions = getFormActions(classes, formMode, onDelete);

    this.state = {
      formSchema,
      formContext: {
        ...getNewFormContext(),
        getApiConfiguration,
      },
      formActions,
      initialFormData: cloneDeep(formData),
      showRevisionWarning: false,
    };
  }

  public render() {
    const { className, formData, isFormSubmitting, onClose, onEdit, classes } = this.props;
    const { formSchema, formContext, formActions, showRevisionWarning } = this.state;

    return (
      <Form<QuizFormData>
        id={FORM_ID}
        className={classNames(classes.form, className)}
        schema={formSchema.jsonSchema}
        uiSchema={formSchema.uiSchema}
        formData={formData}
        formContext={formContext}
        formActions={formActions}
        widgets={{ QuizQuestionTextWidget, QuizQuestionAnswerTextWidget }}
        fields={{ QuizQuestionsReadOnlyField }}
        isFormSubmitting={isFormSubmitting}
        validate={this.handleValidate}
        onEdit={onEdit}
        onChange={this.handleChange}
        onSubmit={this.handleSubmit}
        onError={handleFieldError(FORM_ID)}
        onCancel={this.handleCancel}
        onClose={onClose}
      >
        {showRevisionWarning && (
          <Alert severity="warning">
            If you update the Quiz Questions/Answers, the Quiz Version will be incremented. As a result, any Trainees
            currently in the process of completing the existing Quiz will be forced to retake the Quiz based on the new
            Questions/Answers.
          </Alert>
        )}
      </Form>
    );
  }

  public componentDidUpdate(prevProps: QuizFormProps) {
    const { formMode: prevFormMode, formData: prevFormData } = prevProps;
    const { formMode, formData, onDelete, classes } = this.props;
    // If in read mode, the form data has now been loaded, and the user has permission to edit...
    if (prevFormMode !== formMode || (formMode === "read" && !prevFormData && formData)) {
      const formSchema = getQuizFormSchema(formMode);
      const formActions = getFormActions(classes, formMode, onDelete);

      this.setState({ ...this.state, formSchema, formActions });
    }
  }

  private handleValidate = (formData: QuizFormData, errors: FormValidation<QuizFormData>, isOnSubmit?: boolean) => {
    if (isOnSubmit) {
      const questionsMissingACorrectAnswer: number[] = [];

      formData.questions.forEach((question, questionIndex) => {
        if (question.answers) {
          if (question.answers.every((answer) => !answer.isCorrect)) {
            questionsMissingACorrectAnswer.push(questionIndex);
          }
        }
      });
      questionsMissingACorrectAnswer.forEach((questionIndex) => {
        errors.questions?.[questionIndex]?.answers?.addError("Question requires at least 1 Correct Answer.");
      });
    }
  };

  private handleChange = (changeEvent: IChangeEvent<QuizFormData>) => {
    const { onChange } = this.props;
    const { initialFormData, showRevisionWarning } = this.state;

    const { formData } = changeEvent;

    if (formData.isExisting) {
      const nextShowRevisionWarning = !isEqual(initialFormData.questions, formData.questions);

      if (showRevisionWarning !== nextShowRevisionWarning) {
        this.setState({ ...this.state, showRevisionWarning: nextShowRevisionWarning });
      }
    }

    onChange({
      ...formData,
      minimumPassScorePercentage: formData.minimumPassScorePercentage
        ? Math.round(formData.minimumPassScorePercentage)
        : undefined,
    });
  };

  private handleSubmit = (submitEvent: ISubmitEvent<QuizFormData>) => {
    const { onSubmit } = this.props;

    const { formData } = submitEvent;

    onSubmit(formData);
  };

  private handleCancel = () => {
    const { onCancel } = this.props;
    const { initialFormData } = this.state;

    onCancel(initialFormData);
  };
};

const getFormActions = (
  classes: ClassNameMap<"deleteFormAction">,
  formMode: "read" | "write",
  onDelete: () => void,
) => {
  if (formMode === "read") {
    const formActions = [
      {
        type: "edit",
        button: {
          variant: "primary",
          label: "Edit",
        },
      },
      {
        type: "close",
        button: {
          variant: "secondary",
          label: "Back",
        },
      },
      {
        type: "other",
        button: {
          className: classes.deleteFormAction,
          variant: "secondary",
          label: "Delete",
          onClick: onDelete,
        },
      },
    ] as FormAction[];

    return formActions;
  } else {
    return [
      {
        type: "submit",
        button: {
          variant: "primary",
          label: "Save",
        },
      },
      {
        type: "cancel",
        button: {
          variant: "secondary",
          label: "Cancel",
        },
      },
    ] as FormAction[];
  }
};

export const QuizForm = withStyles(styles)(QuizFormComponent);
