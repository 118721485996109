import { getApiError } from "@interface48/api";
import { AppActions, AppPageFrame, baseActionCreators } from "@interface48/app";
import { TableSearchField } from "@interface48/tables";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { Theme, WithStyles, WithTheme, createStyles, withStyles } from "@material-ui/core/styles";
import SaveAlt from "@material-ui/icons/SaveAlt";
import { saveAs } from "file-saver";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { bindActionCreators } from "redux";
import {
  ApiReportingTrainingProgramSessionsFileGetRequest,
  TrainingProgramSessionResultDto,
  TrainingProgramSessionsQueryFilters,
  reportingTrainingProgramSessionsApi,
} from "../../../api";
import {
  TrainingProgramSessionRevisionDialog,
  TrainingProgramSessionRevisionFormData,
  TrainingProgramSessionsFiltersPanel,
  TrainingProgramSessionsTable,
  toReviseTrainingProgramSessionCommand,
} from "../../../components/training-program-sessions";
import { ApplicationState, ReportingActions, ReportingState, actionCreators } from "../../../store";

const MinimumTableHeight = 200;

const styles = (theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(2),
    },
    filters: {
      paddingBottom: theme.spacing(2),
    },
    tableTopbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingBottom: theme.spacing(2),
    },
    searchField: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        width: "50%",
      },
      [theme.breakpoints.up("md")]: {
        width: "33%",
      },
      [theme.breakpoints.up("lg")]: {
        width: "20%",
      },
    },
    buttonWrapper: {
      position: "relative",
      display: "flex",
      alignItems: "center",
    },
    buttonLabel: {
      marginLeft: theme.spacing(1),
    },
    buttonProgress: {
      color: theme.palette.primary.main,
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  });

interface TrainingProgramSessionsIndexPageStateProps {
  reportingState: ReportingState;
}

interface TrainingProgramSessionsIndexPageDispatchProps {
  appActions: AppActions;
  reportingActions: ReportingActions;
}

interface TrainingProgramSessionsIndexPageOwnProps
  extends RouteComponentProps<any>,
    WithStyles<typeof styles>,
    WithTheme {}

type TrainingProgramSessionsIndexPageProps = TrainingProgramSessionsIndexPageStateProps &
  TrainingProgramSessionsIndexPageDispatchProps &
  TrainingProgramSessionsIndexPageOwnProps;

interface TrainingProgramSessionsIndexPageState {
  tableHeight: number;
  isFiltersOpen: boolean;
  isExportPending: boolean;
  trainingProgramSessionRevisionDialog: {
    isOpen: boolean;
    trainingProgramSession?: TrainingProgramSessionResultDto;
  };
}

export const TrainingProgramSessionsIndexPageComponent = class extends React.Component<
  TrainingProgramSessionsIndexPageProps,
  TrainingProgramSessionsIndexPageState
> {
  private resizeTimeoutId?: number;

  constructor(props: TrainingProgramSessionsIndexPageProps) {
    super(props);

    this.state = {
      tableHeight: MinimumTableHeight,
      isFiltersOpen: false,
      isExportPending: false,
      trainingProgramSessionRevisionDialog: {
        isOpen: false,
      },
    };
  }

  public componentDidMount() {
    const { reportingActions } = this.props;

    reportingActions.requestTrainingProgramSessionsQuery();

    window.addEventListener("resize", this.handleWindowResize);

    this.resizeTable();
  }

  public render() {
    const { reportingState, classes, theme } = this.props;
    const { isFiltersOpen, isExportPending, trainingProgramSessionRevisionDialog } = this.state;
    const { isOpen, trainingProgramSession } = trainingProgramSessionRevisionDialog;
    const { trainingProgramSessionsQuery, actionStatus } = reportingState;
    const { filters, results, sorting, paging } = trainingProgramSessionsQuery;

    const windowWidth = window.innerWidth;
    const isWindowMdOrLess = theme ? windowWidth <= theme.breakpoints.values.md : false;
    const tableHeight = !isWindowMdOrLess ? this.state.tableHeight : undefined;
    const isTableLoading =
      actionStatus.type === "REPORTING_TRAINING_PROGRAM_SESSIONS_QUERY_REQUEST" && actionStatus.pending;

    return (
      <AppPageFrame pageTitle="Training Program Sessions" fullWidth={true} onBackClick={this.handleBackClick}>
        <Grid container={true} className={classes.container}>
          <Grid id="filters" item={true} xs={12} className={classes.filters}>
            <TrainingProgramSessionsFiltersPanel
              id="filters-panel"
              filters={filters}
              isFiltersOpen={isFiltersOpen}
              onFiltersResized={this.resizeTable}
              onFiltersChange={this.handleFiltersChange}
              onFiltersOpenChange={this.handleFiltersOpenChange}
            />
          </Grid>
          <Grid id="table-topbar" item={true} xs={12} className={classes.tableTopbar}>
            <TableSearchField
              className={classes.searchField}
              search={filters.searchTerm}
              searchDebounceMilliseconds={500}
              onSearchChange={this.handleSearchChange}
            />
            <div className={classes.buttonWrapper}>
              <Button
                color="primary"
                variant="contained"
                disabled={results.length === 0 || isExportPending}
                onClick={this.handleExportClick}
              >
                <SaveAlt fontSize="small" />
                <span className={classes.buttonLabel}>Export</span>
              </Button>
              {isExportPending && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
          </Grid>
          <Grid item={true} xs={12}>
            <TrainingProgramSessionsTable
              results={results}
              sorting={{
                sortPropertyName: sorting.sortPropertyName,
                sortDirection: sorting.sortDirection,
                onSortChange: this.handleTableSortChange,
              }}
              paging={{
                pageIndex: paging.pageIndex,
                pageSize: paging.pageSize,
                totalCount: paging.totalCount,
                onPageIndexChange: this.handleTablePageIndexChange,
                onPageSizeChange: this.handleTablePageSizeChange,
              }}
              width={windowWidth}
              height={tableHeight}
              isLoading={isTableLoading}
              onReviseTrainingProgramSession={this.handleEditTrainingProgramSession}
            />
          </Grid>
        </Grid>
        <TrainingProgramSessionRevisionDialog
          trainingProgramSession={trainingProgramSession}
          isOpen={isOpen}
          isActionPending={actionStatus.pending}
          onCancel={this.handleTrainingProgramSessionRevisionDialogCancel}
          onSubmit={this.handleTrainingProgramSessionRevisionDialogSubmit}
        />
      </AppPageFrame>
    );
  }

  public componentDidUpdate(
    prevProps: TrainingProgramSessionsIndexPageProps,
    prevState: TrainingProgramSessionsIndexPageState,
  ) {
    const { reportingState: prevReportingState } = prevProps;
    const { reportingState, appActions, reportingActions } = this.props;

    const prevActionStatus = prevReportingState.actionStatus;
    const actionStatus = reportingState.actionStatus;

    // If some training program action just completed...
    if (prevActionStatus.pending && !actionStatus.pending) {
      // If an error occurred, display the message...
      if (actionStatus.error) {
        const errorMessage =
          actionStatus.error.message || "An unexpected error occurred upon attempting to process the last operation.";

        appActions.postSnackbarMessage(errorMessage, { variant: "error" });
      } else {
        // If we succesfully updated a Training Program Session...
        if (actionStatus.type === "REPORTING_TRAINING_PROGRAM_SESSION_REVISION_REQUEST") {
          this.setState({
            ...this.state,
            trainingProgramSessionRevisionDialog: { isOpen: false },
          });

          reportingActions.requestTrainingProgramSessionsQuery();
        }
      }
    }
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
  }

  private handleWindowResize = () => {
    if (this.resizeTimeoutId) {
      clearTimeout(this.resizeTimeoutId);
    }
    setTimeout(() => this.resizeTable(), 100);
  };

  private resizeTable = (callback?: () => void) => {
    const combinedHeight =
      (document.getElementById("app-frame_header") as HTMLElement).clientHeight +
      (document.getElementById("app-page-frame_header") as HTMLElement).clientHeight +
      (document.getElementById("filters") as HTMLElement).clientHeight +
      (document.getElementById("table-topbar") as HTMLElement).clientHeight +
      // 32px container padding + 2px table border
      34;

    let tableHeight = window.innerHeight - combinedHeight;
    tableHeight = Math.max(tableHeight, MinimumTableHeight);

    this.setState({ ...this.state, tableHeight }, () => {
      if (typeof callback === "function") {
        callback();
      }
    });
  };

  private handleFiltersOpenChange = (isFiltersOpen: boolean) => {
    this.setState({ ...this.state, isFiltersOpen });
  };

  private handleFiltersChange = (filters: TrainingProgramSessionsQueryFilters) => {
    const { reportingState, reportingActions } = this.props;
    const { searchTerm } = reportingState.trainingProgramSessionsQuery.filters;

    reportingActions.updateTrainingProgramSessionsQueryFilters({ ...filters, searchTerm }, true);
  };

  private handleSearchChange = (searchTerm?: string) => {
    const { reportingState, reportingActions } = this.props;
    const { trainingProgramSessionsQuery } = reportingState;
    const { filters } = trainingProgramSessionsQuery;

    reportingActions.updateTrainingProgramSessionsQueryFilters({ ...filters, searchTerm }, true);
  };

  private handleTableSortChange = (sortPropertyName: string, sortDirection: "asc" | "desc") => {
    const { reportingActions } = this.props;

    reportingActions.updateTrainingProgramSessionsQueryResultsSorting({ sortPropertyName, sortDirection });
  };

  private handleTablePageIndexChange = (pageIndex: number) => {
    const { reportingState, reportingActions } = this.props;
    const { trainingProgramSessionsQuery } = reportingState;
    const { paging } = trainingProgramSessionsQuery;

    reportingActions.updateTrainingProgramSessionsQueryResultsPaging({ pageIndex, pageSize: paging.pageSize });
  };

  private handleTablePageSizeChange = (pageSize: number) => {
    const { reportingActions } = this.props;

    reportingActions.updateTrainingProgramSessionsQueryResultsPaging({ pageIndex: 0, pageSize });
  };

  private handleBackClick = () => {
    this.props.history.push("/reporting");
  };

  private handleEditTrainingProgramSession = (trainingProgramSession: TrainingProgramSessionResultDto) => () => {
    this.setState({
      ...this.state,
      trainingProgramSessionRevisionDialog: { isOpen: true, trainingProgramSession },
    });
  };

  private handleTrainingProgramSessionRevisionDialogCancel = () => {
    this.setState({
      ...this.state,
      trainingProgramSessionRevisionDialog: { isOpen: false, trainingProgramSession: undefined },
    });
  };

  private handleTrainingProgramSessionRevisionDialogSubmit = async (
    formData: TrainingProgramSessionRevisionFormData,
  ) => {
    const { reportingActions } = this.props;
    const { trainingProgramSessionRevisionDialog } = this.state;
    const { trainingProgramSession } = trainingProgramSessionRevisionDialog;

    if (!trainingProgramSession) {
      throw new Error("trainingProgramSession undefined.");
    }

    const reviseTrainingProgramSessionCommand = toReviseTrainingProgramSessionCommand(formData);

    reportingActions.requestTrainingProgramSessionRevision(
      trainingProgramSession.id,
      reviseTrainingProgramSessionCommand,
    );
  };

  private handleExportClick = async () => {
    const { reportingState, appActions } = this.props;
    const { trainingProgramSessionsQuery } = reportingState;
    const { filters, sorting } = trainingProgramSessionsQuery;

    const request: ApiReportingTrainingProgramSessionsFileGetRequest = {
      filtersCompanyNames: filters.companyNames ?? undefined,
      filtersTraineeNames: filters.traineeNames ?? undefined,
      filtersTrainingProgramIds: filters.trainingProgramIds ?? undefined,
      filtersDepartments: filters.departments ?? undefined,
      filtersPositions: filters.positions ?? undefined,
      filtersCompletedAnytime: filters.completedAnytime,
      filtersCompletedAtBegin: filters.completedAtBegin ?? undefined,
      filtersCompletedAtEnd: filters.completedAtEnd ?? undefined,
      filtersSearchTerm: filters.searchTerm ?? undefined,
      sortingSortPropertyName: sorting.sortPropertyName,
      sortingSortDirection: sorting.sortDirection,
      format: "xlsx",
    };

    this.setState({ ...this.state, isExportPending: true });

    try {
      var file = await reportingTrainingProgramSessionsApi.getTrainingProgramSessionsFile(request);

      saveAs(file);
    } catch (errorResponse) {
      const error = await getApiError(errorResponse);

      appActions.postSnackbarMessage(error.message, { variant: "error" });
    }

    this.setState({ ...this.state, isExportPending: false });
  };
};

export const TrainingProgramSessionsIndexPage = withStyles(styles, { withTheme: true })(
  connect<
    TrainingProgramSessionsIndexPageStateProps,
    TrainingProgramSessionsIndexPageDispatchProps,
    TrainingProgramSessionsIndexPageOwnProps,
    ApplicationState
  >(
    (state) => {
      return {
        reportingState: state.reporting,
      };
    },
    (dispatch) => {
      return {
        appActions: bindActionCreators(baseActionCreators.app, dispatch),
        reportingActions: bindActionCreators(actionCreators.reporting, dispatch),
      };
    },
  )(TrainingProgramSessionsIndexPageComponent),
);
