import moment from "moment";
import {
  GetQuizResultQuery,
  QuizQuestionTemplateDto,
  QuizTemplateDto,
  TrainingProgramSessionQuizStateDto,
} from "../../../api";
import { QuizSessionState } from "../models";

export const toQuizSessionState = (
  quiz: QuizTemplateDto,
  quizState?: TrainingProgramSessionQuizStateDto,
): QuizSessionState => {
  let quizQuestionAnswerValues: { [questionNumber: string]: number | number[] | undefined } = {};
  let beganAt: string;
  let completedAt: string | undefined;

  if (!quiz.questions) {
    throw Error("Quiz Questions undefined.");
  }

  // If a new Quiz Session...
  if (!quizState) {
    quiz.questions.forEach((question) => {
      quizQuestionAnswerValues[`${question.number}`] = question.hasSingleCorrectAnswer ? undefined : [];
    });

    beganAt = moment().toISOString();
  }
  // Otherwise, restore an on-going Quiz Session...
  else {
    quiz.questions.forEach((question: QuizQuestionTemplateDto) => {
      const questionAnswerValuesKey = `${question.number}`;

      const questionAnswerValue = quizState.quizQuestionAnswerValues[questionAnswerValuesKey];

      if (!questionAnswerValue) {
        quizQuestionAnswerValues[questionAnswerValuesKey] = question.hasSingleCorrectAnswer ? undefined : [];
      } else {
        const valueArray = questionAnswerValue;
        const value = question.hasSingleCorrectAnswer ? valueArray[0] || undefined : valueArray;

        quizQuestionAnswerValues[questionAnswerValuesKey] = value;
      }
    });

    beganAt = quizState.beganAt;
    completedAt = quizState.completedAt ?? undefined;
  }

  const quizSessionState: QuizSessionState = {
    quizId: quiz.id,
    quizVersion: quiz.version,
    quizName: quiz.name,
    quizQuestionAnswerValues,
    beganAt,
    completedAt,
  };

  return quizSessionState;
};

export const toQuestionAnswerValuesForCommand = (questionAnswerValuesFormData: {
  [questionNumber: string]: number | number[] | undefined;
}) => {
  const questionAnswerValuesForCommand: { [questionNumber: string]: number[] } = {};

  Object.keys(questionAnswerValuesFormData).forEach((questionAnswerValueKey) => {
    const value = questionAnswerValuesFormData[questionAnswerValueKey];

    if (value) {
      const valueArray = Array.isArray(value) ? value : [value];

      if (valueArray) {
        questionAnswerValuesForCommand[questionAnswerValueKey] = valueArray;
      }
    }
  });

  return questionAnswerValuesForCommand;
};

export const toGetQuizResultQuery = (quizSessionState: QuizSessionState): GetQuizResultQuery => ({
  quizQuestionAnswerValues: toQuestionAnswerValuesForCommand(quizSessionState.quizQuestionAnswerValues),
});
