import { TrainingProgramSession, toTrainingProgramSessionCompletionFormData } from "..";
import { TrainingProgramComponentSummaryDto, TrainingProgramDepartmentDto } from "../../../api";
import { toCourseSessionState } from "../../courses";
import { toQuizSessionState } from "../../quizzes";
import { TrainingProgramFormData, getRemainingTrainingProgramDurationMinutes } from "../../training-programs";

export const toTrainingProgramSession = (
  trainingProgramFormData: TrainingProgramFormData,
  trainingProgramComponents: TrainingProgramComponentSummaryDto[],
  trainingProgramDepartments: TrainingProgramDepartmentDto[],
): TrainingProgramSession => {
  const { id, name, description, version, majorVersion, durationMinutes } = trainingProgramFormData;

  if (id == null || name == null || description == null || version == null || majorVersion == null) {
    throw Error("Training Program must be specified.");
  }

  const trainingProgramDurationMinutes = durationMinutes ?? 0;

  return {
    trainingProgramId: id,
    trainingProgramName: name,
    trainingProgramDescription: description,
    trainingProgramDepartments,
    trainingProgramComponents,
    trainingProgramDurationMinutes,
    trainingProgramVersion: version,
    trainingProgramMajorVersion: majorVersion,
    currentTrainingProgramComponentIndex: 0,
    maximumTrainingProgramComponentIndex: 0,
    remainingTrainingProgramDurationMinutes: trainingProgramComponents
      ? getRemainingTrainingProgramDurationMinutes(trainingProgramComponents, trainingProgramDurationMinutes, 0)
      : 0,
    courses: trainingProgramComponents
      ? trainingProgramComponents.filter((c) => c.course != null).map((c) => toCourseSessionState(c.course!))
      : [],
    quizzes: trainingProgramComponents
      ? trainingProgramComponents.filter((c) => c.quiz != null).map((c) => toQuizSessionState(c.quiz!))
      : [],
    completionFormData: toTrainingProgramSessionCompletionFormData(trainingProgramDepartments),
  };
};
