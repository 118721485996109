/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TrainingProgramSessionCompletionResultDto
 */
export interface TrainingProgramSessionCompletionResultDto {
    /**
     * 
     * @type {number}
     * @memberof TrainingProgramSessionCompletionResultDto
     */
    number: number;
    /**
     * 
     * @type {boolean}
     * @memberof TrainingProgramSessionCompletionResultDto
     */
    sentConfirmationEmail: boolean;
}

export function TrainingProgramSessionCompletionResultDtoFromJSON(json: any): TrainingProgramSessionCompletionResultDto {
    return {
        'number': json['number'],
        'sentConfirmationEmail': json['sentConfirmationEmail'],
    };
}

export function TrainingProgramSessionCompletionResultDtoToJSON(value?: TrainingProgramSessionCompletionResultDto | null): any {
    if (value == undefined) {
        return undefined;
    }
    return {
        'number': value.number,
        'sentConfirmationEmail': value.sentConfirmationEmail,
    };
}


