/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    TrainingScorecardDto,
    TrainingScorecardDtoFromJSON,
    TrainingScorecardDtoToJSON,
    UpdateTrainingScorecardCommand,
    UpdateTrainingScorecardCommandFromJSON,
    UpdateTrainingScorecardCommandToJSON,
} from '../models';

export interface ApiReportingTrainingScorecardsIdFileGetRequest {
    id: string;
}

export interface ApiReportingTrainingScorecardsIdPostRequest {
    id: string;
    updateTrainingScorecardCommand: UpdateTrainingScorecardCommand;
}

/**
 * no description
 */
export class TrainingScorecardsApi extends runtime.BaseAPI {

    /**
     */
    async apiReportingTrainingScorecardsGetRaw(): Promise<runtime.ApiResponse<Array<TrainingScorecardDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["TrainingProgramStakeholders"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/reporting/training-scorecards`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TrainingScorecardDtoFromJSON));
    }

   /**
    */
    async apiReportingTrainingScorecardsGet(): Promise<Array<TrainingScorecardDto>> {
        const response = await this.apiReportingTrainingScorecardsGetRaw();
        return await response.value();
    }

    /**
     */
    async apiReportingTrainingScorecardsIdFileGetRaw(requestParameters: ApiReportingTrainingScorecardsIdFileGetRequest): Promise<runtime.ApiResponse<File>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiReportingTrainingScorecardsIdFileGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["TrainingProgramStakeholders"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/reporting/training-scorecards/{id}/file`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.FileApiResponse(response);
    }

   /**
    */
    async apiReportingTrainingScorecardsIdFileGet(requestParameters: ApiReportingTrainingScorecardsIdFileGetRequest): Promise<File> {
        const response = await this.apiReportingTrainingScorecardsIdFileGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiReportingTrainingScorecardsIdPostRaw(requestParameters: ApiReportingTrainingScorecardsIdPostRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiReportingTrainingScorecardsIdPost.');
        }

        if (requestParameters.updateTrainingScorecardCommand === null || requestParameters.updateTrainingScorecardCommand === undefined) {
            throw new runtime.RequiredError('updateTrainingScorecardCommand','Required parameter requestParameters.updateTrainingScorecardCommand was null or undefined when calling apiReportingTrainingScorecardsIdPost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["TrainingProgramStakeholders", "null"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/reporting/training-scorecards/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateTrainingScorecardCommandToJSON(requestParameters.updateTrainingScorecardCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

   /**
    */
    async apiReportingTrainingScorecardsIdPost(requestParameters: ApiReportingTrainingScorecardsIdPostRequest): Promise<void> {
        await this.apiReportingTrainingScorecardsIdPostRaw(requestParameters);
    }

}
