import { AppActions, AppPageFrame, baseActionCreators, Loader, OidcState } from "@interface48/app";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";
import cloneDeep from "lodash/cloneDeep";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { bindActionCreators } from "redux";
import {
  getUserGroupTypeFromSlug,
  toUpdateUserGroupCommand,
  toUserGroupFormData,
  UserGroupForm,
  UserGroupFormData,
} from "../../../components/user-groups";
import { actionCreators, AdministrationActions, AdministrationState, ApplicationState } from "../../../store";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
    },
    form: {
      backgroundColor: theme.palette.background.default,
    },
  });

interface UserGroupPageMatchParamsProps {
  userGroupType: string;
}

interface UserGroupPageStateProps {
  oidcState: OidcState;
  administrationState: AdministrationState;
}

interface UserGroupPageDispatchProps {
  appActions: AppActions;
  administrationActions: AdministrationActions;
}

interface UserGroupPageOwnProps extends RouteComponentProps<UserGroupPageMatchParamsProps>, WithStyles<typeof styles> {}

type UserGroupPageProps = UserGroupPageStateProps & UserGroupPageDispatchProps & UserGroupPageOwnProps;

interface UserGroupPageState {
  formMode: "read" | "write";
  formData: UserGroupFormData;
  initialFormData: UserGroupFormData;
}

export const UserGroupPageComponent = class extends React.Component<UserGroupPageProps, UserGroupPageState> {
  constructor(props: UserGroupPageProps) {
    super(props);

    const formMode = "read";
    const formData = toUserGroupFormData();

    this.state = {
      formMode,
      formData,
      initialFormData: cloneDeep(formData),
    };
  }

  public componentDidMount() {
    const { administrationActions, match } = this.props;

    const userGroupType = getUserGroupTypeFromSlug(match.params.userGroupType);

    if (userGroupType) {
      administrationActions.requestUserGroup(userGroupType);
    }
  }

  public render() {
    const { administrationState, classes, match } = this.props;
    const { userGroup, actionStatus } = administrationState;
    const { formMode, formData } = this.state;

    const formKey = `form_${formMode}`;
    const userGroupType = getUserGroupTypeFromSlug(match.params.userGroupType);
    const isUserGroupLoading =
      !userGroup && actionStatus.type === "ADMINISTRATION_USER_GROUP_REQUEST" && actionStatus.pending;
    const isUserGroupSaving = actionStatus.type === "ADMINISTRATION_USER_GROUP_UPDATE_REQUEST" && actionStatus.pending;
    const isUserGroupFormLoading = userGroupType && isUserGroupLoading;

    return (
      <AppPageFrame
        pageTitle={
          isUserGroupFormLoading
            ? "Loading User Group"
            : `${formMode === "write" ? "Edit " : ""}User Group - ${userGroup && userGroup.userGroupTypeName}`
        }
        onBackClick={this.handleBackClick}
      >
        <div className={classes.container}>
          {isUserGroupFormLoading ? (
            <Loader loadingText={"Loading User Group"} />
          ) : (
            <UserGroupForm
              key={formKey}
              className={classes.form}
              formMode={formMode}
              formData={formData}
              onEdit={this.handleEdit}
              onChange={this.handleChange}
              onSubmit={this.handleSubmit}
              onCancel={this.handleCancel}
              onClose={this.handleClose}
              isFormSubmitting={isUserGroupSaving}
            />
          )}
        </div>
      </AppPageFrame>
    );
  }

  public componentDidUpdate(prevProps: UserGroupPageProps, prevState: UserGroupPageState) {
    const { administrationState: prevAdministrationState } = prevProps;
    const { formMode: prevFormMode } = prevState;
    const { administrationState, appActions, administrationActions, match } = this.props;
    const { userGroup } = administrationState;
    const { formMode } = this.state;

    const userGroupType = getUserGroupTypeFromSlug(match.params.userGroupType);

    const prevActionStatus = prevAdministrationState.actionStatus;
    const actionStatus = administrationState.actionStatus;

    // If some UserGroup action just completed...
    if (prevActionStatus.pending && !actionStatus.pending) {
      // If an error occurred, display the message...
      if (actionStatus.error) {
        const errorMessage =
          actionStatus.error.message || "An unexpected error occurred upon attempting to process  the last operation.";

        appActions.postSnackbarMessage(errorMessage, { variant: "error" });
      }
      // Otherwise, the operation completed successfully...
      else if (userGroup) {
        // If we fetched the Details for this UserGroup...
        if (actionStatus.type === "ADMINISTRATION_USER_GROUP_REQUEST" && userGroup) {
          const formData = toUserGroupFormData(userGroup);

          this.setState({ ...this.state, formMode: "read", formData });
        }
        // Otherwise if we added or updated a UserGroup, fetch the latest Details...
        else if (actionStatus.type === "ADMINISTRATION_USER_GROUP_UPDATE_REQUEST" && userGroup) {
          administrationActions.requestUserGroup(userGroupType);
        }
      }
    }

    // If the Form Mode just changed, scroll to the top of the page...
    if (prevFormMode !== formMode) {
      const appFrame = document.getElementById("app-frame");
      if (appFrame) {
        appFrame.scrollTop = 0;
      }
    }
  }

  private handleBackClick = () => {
    this.props.history.goBack();
  };

  private handleClose = () => {
    this.props.history.goBack();
  };

  private handleEdit = () => {
    this.setState({ ...this.state, formMode: "write" });
  };

  private handleCancel = (initialFormData: UserGroupFormData) => {
    this.setState({ ...this.state, formMode: "read", formData: initialFormData });
  };

  private handleChange = (formData: UserGroupFormData) => {
    this.setState({ ...this.state, formData });
  };

  private handleSubmit = (formData: UserGroupFormData) => {
    const { userGroupType } = formData;
    const { administrationActions } = this.props;

    const addOrUpdateUserGroupCommand = toUpdateUserGroupCommand(formData);

    administrationActions.requestUserGroupUpdate(userGroupType!, addOrUpdateUserGroupCommand);
  };
};

export const UserGroupPage = withStyles(styles)(
  connect<UserGroupPageStateProps, UserGroupPageDispatchProps, UserGroupPageOwnProps, ApplicationState>(
    (state) => ({
      oidcState: state.oidc,
      administrationState: state.administration,
    }),
    (dispatch) => ({
      appActions: bindActionCreators(baseActionCreators.app, dispatch),
      administrationActions: bindActionCreators(actionCreators.administration, dispatch),
    }),
  )(UserGroupPageComponent),
);
