import { formatFileSize } from "@interface48/formatting";
import { toAuditDetailsFormData } from "@interface48/forms";
import { AddCourseCommand, CourseDto, UpdateCourseCommand } from "../../../../api";
import { CourseFormData } from "../models";

export const toCourseFormData = (course?: CourseDto): CourseFormData => {
  if (course) {
    const isExisting = true;

    return {
      isExisting,
      id: course.id,
      name: course.name,
      updateFile: false,
      file: {},
      isFileValid: false,
      durationMinutes: course.durationMinutes,
      directorySize: formatFileSize(course.fileSizeBytes),
      created: toAuditDetailsFormData(course.createdAt, course.createdByUserName),
      lastModified: toAuditDetailsFormData(course.lastModifiedAt, course.lastModifiedByUserName),
      version: course.version,
    };
  } else {
    const isExisting = false;

    return {
      isExisting,
      file: {},
      isFileValid: false,
      updateFile: false,
      created: {},
      lastModified: {},
    };
  }
};

export const toAddCourseCommand = (formData: CourseFormData): AddCourseCommand => {
  const { file, durationMinutes } = formData;

  if (!file?.fileUploadId) {
    throw new Error("File is required.");
  }

  if (!durationMinutes) {
    throw new Error("Duration is required.");
  }

  const addCourseCommand: AddCourseCommand = {
    fileUploadId: file.fileUploadId,
    durationMinutes,
  };

  return addCourseCommand;
};

export const toUpdateCourseCommand = (formData: CourseFormData): UpdateCourseCommand => {
  const { file, durationMinutes } = formData;

  if (!durationMinutes) {
    throw new Error("Duration is required.");
  }

  const updateCourseCommand: UpdateCourseCommand = {
    fileUploadId: file?.fileUploadId,
    durationMinutes,
  };

  return updateCourseCommand;
};
