import { OptionItem, getApiError } from "@interface48/api";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Theme, WithStyles, createStyles, withStyles } from "@material-ui/core/styles";
import React from "react";
import {
  CompleteTrainingProgramSessionCommand,
  CourseResultDto,
  QuizResultDto,
  coursesApi,
  getPositionName,
  quizzesApi,
} from "../../../api";
import { toGetQuizResultQuery } from "../../quizzes";
import {
  TrainingProgramSessionCompletionForm,
  TrainingProgramSessionCompletionFormData,
  toCompleteTrainingProgramSessionCommand,
} from "../forms";
import { TrainingProgramSession } from "../models";
import { TrainingProgramSessionResultSummary } from "../results";

const styles = (theme: Theme) =>
  createStyles({
    resultsSummaryContainer: {
      marginTop: theme.spacing(2),
    },
    resultsSummary: {
      borderRadius: theme.shape.borderRadius,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
    },
    formContainer: {
      position: "relative",
      overflowY: "scroll",
    },
    form: {
      maxWidth: 900,
      margin: "0 auto",
      padding: theme.spacing(2),
      "& .field-object h5": {
        marginTop: 0,
      },
    },
    formSubmitProgress: {
      position: "absolute",
      width: "100%",
    },
  });

interface TrainingProgramSessionCompletionViewerProps extends WithStyles<typeof styles> {
  availableHeight?: number;
  trainingProgramSession: TrainingProgramSession;
  isPreviewMode: boolean;
  isUserSignedIn: boolean;
  isTrainingProgramSessionSubmitting: boolean;
  onChange: (formData: TrainingProgramSessionCompletionFormData) => void;
  onSubmit: (completeTrainingProgramSessionCommand: CompleteTrainingProgramSessionCommand) => void;
  onError: (errorMessage: string) => void;
}

interface TrainingProgramSessionCompletionViewerState {
  courseResults?: CourseResultDto[];
  quizResults?: QuizResultDto[];
  departmentOptionItems: Array<OptionItem<number>>;
  departmentPositionOptionItemsMap: { [department: string]: Array<OptionItem<number>> };
}

export const TrainingProgramSessionCompletionViewerComponent = class extends React.Component<
  TrainingProgramSessionCompletionViewerProps,
  TrainingProgramSessionCompletionViewerState
> {
  constructor(props: TrainingProgramSessionCompletionViewerProps) {
    super(props);

    const { trainingProgramSession } = this.props;

    const departmentOptionItems =
      trainingProgramSession.trainingProgramDepartments.map((department) => ({
        value: department.department,
        label: department.departmentName,
      })) ?? [];

    const departmentPositionOptionItemsMap = trainingProgramSession.trainingProgramDepartments.reduce(
      (map, department) => ({
        ...map,
        [department.department]: department.positions.map((position) => ({
          value: position,
          label: getPositionName(position),
        })),
      }),
      {},
    );

    this.state = {
      departmentOptionItems,
      departmentPositionOptionItemsMap,
    };
  }

  public async componentDidMount() {
    const { isPreviewMode } = this.props;

    if (!isPreviewMode) {
      await this.getTrainingProgramSessionResults();
    }
  }

  public render() {
    const { availableHeight, trainingProgramSession, isUserSignedIn, isTrainingProgramSessionSubmitting, classes } =
      this.props;

    const { courseResults, quizResults, departmentOptionItems, departmentPositionOptionItemsMap } = this.state;

    const isFormSubmittable =
      !!courseResults &&
      (courseResults.length === 0 || courseResults.every((cr) => cr.isPassResult)) &&
      !!quizResults &&
      (quizResults.length === 0 || quizResults.every((qr) => qr.isPassResult));

    return (
      <div id="form-container" className={classes.formContainer} style={{ height: availableHeight }}>
        {isTrainingProgramSessionSubmitting && (
          <LinearProgress color="primary" className={classes.formSubmitProgress} />
        )}
        <TrainingProgramSessionCompletionForm
          scrollContainerElementId={"form-container"}
          className={classes.form}
          trainingProgramName={trainingProgramSession.trainingProgramName}
          formData={trainingProgramSession.completionFormData}
          isUserSignedIn={isUserSignedIn}
          isFormSubmittable={isFormSubmittable}
          isFormSubmitting={isTrainingProgramSessionSubmitting}
          departmentOptionItems={departmentOptionItems}
          departmentPositionOptionItemsMap={departmentPositionOptionItemsMap}
          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
        >
          {!!quizResults && !!courseResults && !!(quizResults.length || courseResults.length) && (
            <div className={classes.resultsSummaryContainer}>
              <Typography variant="h5">Results Summary</Typography>
              <Paper className={classes.resultsSummary}>
                <TrainingProgramSessionResultSummary
                  quizzes={quizResults}
                  courses={courseResults}
                  showIncorrectQuestionNumbersMessage={true}
                />
              </Paper>
            </div>
          )}
        </TrainingProgramSessionCompletionForm>
      </div>
    );
  }

  private getTrainingProgramSessionResults = async () => {
    const { trainingProgramSession, onError } = this.props;

    try {
      const courseResults: CourseResultDto[] = [];

      await trainingProgramSession.courses.reduce(async (promiseChain, course) => {
        await promiseChain;

        const courseResult = await coursesApi.getCourseResult(course.courseId, course.courseVersion);

        courseResults.push(courseResult);
      }, Promise.resolve());

      const quizResults: QuizResultDto[] = [];

      await trainingProgramSession.quizzes.reduce(async (promiseChain, quiz) => {
        await promiseChain;

        const quizResult = await quizzesApi.getQuizResult(quiz.quizId, quiz.quizVersion, toGetQuizResultQuery(quiz));

        quizResults.push(quizResult);
      }, Promise.resolve());

      this.setState({ ...this.state, courseResults, quizResults });
    } catch (errorResponse) {
      const error = await getApiError(errorResponse);

      // Log the actual error message
      console.error(error.message);

      // Display a friendly error messsage
      onError("Failed to retrieve results.");
    }
  };

  private handleChange = (trainingCompletionFormData: TrainingProgramSessionCompletionFormData) => {
    const { onChange } = this.props;

    onChange(trainingCompletionFormData);
  };

  private handleSubmit = (trainingCompletionFormData: TrainingProgramSessionCompletionFormData) => {
    const { trainingProgramSession, onSubmit } = this.props;

    if (!trainingProgramSession) {
      throw Error("No Training Program Session in progress.");
    }

    const completeTrainingProgramSessionCommand = toCompleteTrainingProgramSessionCommand(
      trainingProgramSession,
      trainingCompletionFormData,
    );

    onSubmit(completeTrainingProgramSessionCommand);
  };
};

export const TrainingProgramSessionCompletionViewer = withStyles(styles)(
  TrainingProgramSessionCompletionViewerComponent,
);
