import { getApiConfiguration } from "@interface48/app";
import {
  Form,
  FormAction,
  FormContext,
  FormSchema,
  getNewFormContext,
  handleFieldError,
  IChangeEvent,
  ISubmitEvent,
} from "@interface48/forms";
import Paper from "@material-ui/core/Paper";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import cloneDeep from "lodash/cloneDeep";
import React from "react";
import { UserGroupFormData } from "./models";
import { getUserGroupFormSchema } from "./utils";

const FORM_ID = "userGroupForm";

const styles = (theme: Theme) =>
  createStyles({
    form: {
      "& > .field-object > .field-array": {
        marginTop: 0,
      },
      "& .field-array .field-object": {
        display: "flex",
        flexDirection: "row",
      },
      "& .field-array .field-object .field-array": {
        marginLeft: theme.spacing(2),
      },
    },
    formRead: {
      "& .field-array .field-object > div": {
        flex: 1,
      },
      "& .field-array .field-object > div:last-child": {
        flex: 3,
        marginLeft: theme.spacing(2),
      },
    },
    message: {
      padding: theme.spacing(2),
    },
    messageTitle: {
      marginBottom: theme.spacing(1),
    },
  });

interface UserGroupFormProps extends WithStyles<typeof styles> {
  className: string;
  formMode: "read" | "write";
  formData: UserGroupFormData;
  isFormSubmitting: boolean;
  onEdit: () => void;
  onClose: () => void;
  onChange: (formData: UserGroupFormData) => void;
  onSubmit: (formData: UserGroupFormData) => void;
  onCancel: (initialFormData: UserGroupFormData) => void;
}

interface UserGroupFormState {
  formSchema: FormSchema;
  formContext: FormContext;
  formActions: FormAction[];
  initialFormData: UserGroupFormData;
}

export const UserGroupFormComponent = class extends React.Component<UserGroupFormProps, UserGroupFormState> {
  constructor(props: UserGroupFormProps) {
    super(props);

    const { formMode, formData } = this.props;

    const formSchema = getUserGroupFormSchema(formMode, formData);
    const formActions = getFormActions(formMode);

    this.state = {
      initialFormData: cloneDeep(formData),
      formSchema,
      formContext: {
        ...getNewFormContext(),
        getApiConfiguration,
      },
      formActions,
    };
  }

  public render() {
    const { className, formMode, formData, isFormSubmitting, onClose, onEdit, classes } = this.props;
    const { formSchema, formContext, formActions } = this.state;

    return (
      <Form<UserGroupFormData>
        id={FORM_ID}
        className={classNames(classes.form, formMode === "read" ? classes.formRead : undefined, className)}
        schema={formSchema.jsonSchema}
        uiSchema={formSchema.uiSchema}
        formData={formData}
        formContext={formContext}
        formActions={formActions}
        isFormSubmitting={isFormSubmitting}
        onEdit={onEdit}
        onChange={this.handleChange}
        onSubmit={this.handleSubmit}
        onError={handleFieldError(FORM_ID)}
        onCancel={this.handleCancel}
        onClose={onClose}
      >
        {formMode === "read" && (formData.userPrincipalNames ?? []).length === 0 ? (
          <Paper className={classes.message}>
            <Typography variant="h6" component="h3" className={classes.messageTitle}>
              No Members
            </Typography>
            <Typography component="p">
              {formData.userGroupTypeName} User Group has no Members. Select 'Edit' below to add some.
            </Typography>
          </Paper>
        ) : null}
      </Form>
    );
  }

  public componentDidUpdate(prevProps: UserGroupFormProps) {
    const { formMode: prevFormMode, formData: prevFormData } = prevProps;
    const { formMode, formData } = this.props;
    // If in read mode, the form data has now been loaded, and the user has permission to edit...
    if (!(prevFormMode === formMode && prevFormData === formData)) {
      const formSchema = getUserGroupFormSchema(formMode, formData);

      const formActions = getFormActions(formMode);

      this.setState({ ...this.state, formSchema, formActions });
    }
  }

  private handleChange = (changeEvent: IChangeEvent<UserGroupFormData>) => {
    const { onChange } = this.props;

    onChange(changeEvent.formData);
  };

  private handleSubmit = (submitEvent: ISubmitEvent<UserGroupFormData>) => {
    const { onSubmit } = this.props;

    onSubmit(submitEvent.formData);
  };

  private handleCancel = () => {
    const { onCancel } = this.props;
    const { initialFormData } = this.state;

    onCancel(initialFormData);
  };
};

const getFormActions = (formMode: "read" | "write") => {
  if (formMode === "read") {
    const formActions = [
      {
        type: "edit",
        button: {
          variant: "primary",
          label: "Edit",
        },
      },
      {
        type: "close",
        button: {
          variant: "secondary",
          label: "Back",
        },
      },
    ] as FormAction[];

    return formActions;
  } else {
    return [
      {
        type: "submit",
        button: {
          variant: "primary",
          label: "Save",
        },
      },
      {
        type: "cancel",
        button: {
          variant: "secondary",
          label: "Cancel",
        },
      },
    ] as FormAction[];
  }
};

export const UserGroupForm = withStyles(styles)(UserGroupFormComponent);
