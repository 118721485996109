/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddOrUpdateUpdateTrainingScorecardDepartmentPositionCommand,
    AddOrUpdateUpdateTrainingScorecardDepartmentPositionCommandFromJSON,
    AddOrUpdateUpdateTrainingScorecardDepartmentPositionCommandToJSON,
} from './';

/**
 * 
 * @export
 * @interface AddOrUpdateUpdateTrainingScorecardDepartmentCommand
 */
export interface AddOrUpdateUpdateTrainingScorecardDepartmentCommand {
    /**
     * 
     * @type {number}
     * @memberof AddOrUpdateUpdateTrainingScorecardDepartmentCommand
     */
    department: number;
    /**
     * 
     * @type {Array<AddOrUpdateUpdateTrainingScorecardDepartmentPositionCommand>}
     * @memberof AddOrUpdateUpdateTrainingScorecardDepartmentCommand
     */
    positions: Array<AddOrUpdateUpdateTrainingScorecardDepartmentPositionCommand>;
}

export function AddOrUpdateUpdateTrainingScorecardDepartmentCommandFromJSON(json: any): AddOrUpdateUpdateTrainingScorecardDepartmentCommand {
    return {
        'department': json['department'],
        'positions': (json['positions'] as Array<any>).map(AddOrUpdateUpdateTrainingScorecardDepartmentPositionCommandFromJSON),
    };
}

export function AddOrUpdateUpdateTrainingScorecardDepartmentCommandToJSON(value?: AddOrUpdateUpdateTrainingScorecardDepartmentCommand | null): any {
    if (value == undefined) {
        return undefined;
    }
    return {
        'department': value.department,
        'positions': (value.positions as Array<any>).map(AddOrUpdateUpdateTrainingScorecardDepartmentPositionCommandToJSON),
    };
}


