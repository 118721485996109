import { AuditDetailsFormData, FileFormData } from "@interface48/forms";
import { MediaFile } from "../../../../api";

export enum DocumentFormDataSchemaVariant {
  New = 1,
  NewRemote,
  Existing,
  ExistingRemote,
}

export interface DocumentFormData {
  isExisting: boolean;
  schemaVariant: DocumentFormDataSchemaVariant;
  id?: string;
  mediaCategoryId?: string;
  mediaCategoryName?: string;
  name?: string;
  existingFile?: MediaFile;
  isRemoteFile: boolean;
  remoteFileUrl?: string;
  updateFile: boolean;
  file?: FileFormData;
  created: AuditDetailsFormData;
  lastModified: AuditDetailsFormData;
}
