/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TrainingProgramComponentDto,
    TrainingProgramComponentDtoFromJSON,
    TrainingProgramComponentDtoToJSON,
    TrainingProgramDepartmentDto,
    TrainingProgramDepartmentDtoFromJSON,
    TrainingProgramDepartmentDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface TrainingProgramDto
 */
export interface TrainingProgramDto {
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramDto
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramDto
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof TrainingProgramDto
     */
    durationMinutes: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof TrainingProgramDto
     */
    emailRecipients?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramDto
     */
    trainingScorecardId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramDto
     */
    trainingScorecardName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TrainingProgramDto
     */
    sortOrder?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TrainingProgramDto
     */
    isEnabled: boolean;
    /**
     * 
     * @type {number}
     * @memberof TrainingProgramDto
     */
    majorVersion: number;
    /**
     * 
     * @type {number}
     * @memberof TrainingProgramDto
     */
    version: number;
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramDto
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramDto
     */
    createdByUserName: string;
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramDto
     */
    lastModifiedAt: string;
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramDto
     */
    lastModifiedByUserName: string;
    /**
     * 
     * @type {Array<TrainingProgramComponentDto>}
     * @memberof TrainingProgramDto
     */
    components: Array<TrainingProgramComponentDto>;
    /**
     * 
     * @type {Array<TrainingProgramDepartmentDto>}
     * @memberof TrainingProgramDto
     */
    departments: Array<TrainingProgramDepartmentDto>;
}

export function TrainingProgramDtoFromJSON(json: any): TrainingProgramDto {
    return {
        'id': json['id'],
        'slug': json['slug'],
        'name': json['name'],
        'description': json['description'],
        'durationMinutes': json['durationMinutes'],
        'emailRecipients': !exists(json, 'emailRecipients') ? undefined : json['emailRecipients'],
        'trainingScorecardId': !exists(json, 'trainingScorecardId') ? undefined : json['trainingScorecardId'],
        'trainingScorecardName': !exists(json, 'trainingScorecardName') ? undefined : json['trainingScorecardName'],
        'sortOrder': !exists(json, 'sortOrder') ? undefined : json['sortOrder'],
        'isEnabled': json['isEnabled'],
        'majorVersion': json['majorVersion'],
        'version': json['version'],
        'createdAt': json['createdAt'],
        'createdByUserName': json['createdByUserName'],
        'lastModifiedAt': json['lastModifiedAt'],
        'lastModifiedByUserName': json['lastModifiedByUserName'],
        'components': (json['components'] as Array<any>).map(TrainingProgramComponentDtoFromJSON),
        'departments': (json['departments'] as Array<any>).map(TrainingProgramDepartmentDtoFromJSON),
    };
}

export function TrainingProgramDtoToJSON(value?: TrainingProgramDto | null): any {
    if (value == undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'slug': value.slug,
        'name': value.name,
        'description': value.description,
        'durationMinutes': value.durationMinutes,
        'emailRecipients': value.emailRecipients,
        'trainingScorecardId': value.trainingScorecardId,
        'trainingScorecardName': value.trainingScorecardName,
        'sortOrder': value.sortOrder,
        'isEnabled': value.isEnabled,
        'majorVersion': value.majorVersion,
        'version': value.version,
        'createdAt': value.createdAt,
        'createdByUserName': value.createdByUserName,
        'lastModifiedAt': value.lastModifiedAt,
        'lastModifiedByUserName': value.lastModifiedByUserName,
        'components': (value.components as Array<any>).map(TrainingProgramComponentDtoToJSON),
        'departments': (value.departments as Array<any>).map(TrainingProgramDepartmentDtoToJSON),
    };
}


