import AppBar from "@material-ui/core/AppBar";
import Drawer from "@material-ui/core/Drawer";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { History, Location } from "history";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators, ApplicationState, UIActions, UIState } from "../../store";

enum SidebarTab {
  TrainingProgram,
  ResourceBrowser,
}

const styles = (theme: Theme) =>
  createStyles({
    drawerPaper: {
      top: 56,
      width: Math.min(375, window.innerWidth),
      boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
      borderRight: 0,
      [theme.breakpoints.up("sm")]: {
        top: 64,
      },
    },
    tabsIndicator: {
      backgroundColor: theme.palette.common.white,
    },
  });

interface SidebarStateProps {
  uiState: UIState;
}

interface SidebarDispatchProps {
  uiActions: UIActions;
}

interface SidebarOwnProps extends WithStyles<typeof styles> {
  history: History;
  children: React.ReactNode;
}

type SidebarProps = SidebarStateProps & SidebarDispatchProps & SidebarOwnProps;

interface SidebarState {
  width: number;
}

export const SidebarComponent = class extends React.Component<SidebarProps, SidebarState> {
  constructor(props: SidebarProps) {
    super(props);

    const width = this.getSidebarWidth();

    this.state = {
      width,
    };
  }

  public componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  public render() {
    const { uiState, children, history, classes } = this.props;
    const { sidebar } = uiState;

    const tabValue = this.getCurrentTab(history.location);

    return (
      <Drawer variant={"persistent"} classes={{ paper: classes.drawerPaper }} open={sidebar.open}>
        <AppBar position="static">
          <Tabs
            variant="fullWidth"
            classes={{ indicator: classes.tabsIndicator }}
            value={tabValue}
            onChange={this.handleTabChange}
          >
            <Tab label="Training Program" />
            <Tab label="Resource Browser" />
          </Tabs>
        </AppBar>
        {children}
      </Drawer>
    );
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  private handleResize = () => {
    const { uiState, uiActions } = this.props;
    const { sidebar } = uiState;
    const { updateSidebarDisplay } = uiActions;
    const width = this.getSidebarWidth();

    this.setState({ ...this.state, width }, () => {
      const sidebarOpen = (window.innerWidth - 960) / 2 >= width;

      if (sidebarOpen !== sidebar.open) {
        updateSidebarDisplay(sidebarOpen);
      }
    });
  };

  private handleTabChange = (event: any, tab: SidebarTab) => {
    const { history } = this.props;

    if (tab === SidebarTab.TrainingProgram) {
      history.push("/training-program");
    } else {
      history.push("/resource-browser");
    }
  };

  private getCurrentTab = (location: Location<any>) => {
    return location.pathname.toLowerCase().indexOf("/training-program") !== -1
      ? SidebarTab.TrainingProgram
      : SidebarTab.ResourceBrowser;
  };

  private getSidebarWidth = () => {
    return Math.min(375, window.innerWidth);
  };
};

export const Sidebar = withStyles(styles)(
  connect<SidebarStateProps, SidebarDispatchProps, SidebarOwnProps, ApplicationState>(
    (state) => ({
      uiState: state.ui,
    }),
    (dispatch) => ({
      uiActions: bindActionCreators(actionCreators.ui, dispatch),
    }),
  )(SidebarComponent),
);
