/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QuizQuestionAnswerDto
 */
export interface QuizQuestionAnswerDto {
    /**
     * 
     * @type {number}
     * @memberof QuizQuestionAnswerDto
     */
    number: number;
    /**
     * 
     * @type {string}
     * @memberof QuizQuestionAnswerDto
     */
    text: string;
    /**
     * 
     * @type {boolean}
     * @memberof QuizQuestionAnswerDto
     */
    isCorrect: boolean;
}

export function QuizQuestionAnswerDtoFromJSON(json: any): QuizQuestionAnswerDto {
    return {
        'number': json['number'],
        'text': json['text'],
        'isCorrect': json['isCorrect'],
    };
}

export function QuizQuestionAnswerDtoToJSON(value?: QuizQuestionAnswerDto | null): any {
    if (value == undefined) {
        return undefined;
    }
    return {
        'number': value.number,
        'text': value.text,
        'isCorrect': value.isCorrect,
    };
}


