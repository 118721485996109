/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddOrUpdateQuizQuestionAnswerCommand,
    AddOrUpdateQuizQuestionAnswerCommandFromJSON,
    AddOrUpdateQuizQuestionAnswerCommandToJSON,
} from './';

/**
 * 
 * @export
 * @interface AddOrUpdateQuizQuestionCommand
 */
export interface AddOrUpdateQuizQuestionCommand {
    /**
     * 
     * @type {string}
     * @memberof AddOrUpdateQuizQuestionCommand
     */
    text: string;
    /**
     * 
     * @type {Array<AddOrUpdateQuizQuestionAnswerCommand>}
     * @memberof AddOrUpdateQuizQuestionCommand
     */
    answers?: Array<AddOrUpdateQuizQuestionAnswerCommand> | null;
}

export function AddOrUpdateQuizQuestionCommandFromJSON(json: any): AddOrUpdateQuizQuestionCommand {
    return {
        'text': json['text'],
        'answers': !exists(json, 'answers') ? undefined : (json['answers'] as Array<any>).map(AddOrUpdateQuizQuestionAnswerCommandFromJSON),
    };
}

export function AddOrUpdateQuizQuestionCommandToJSON(value?: AddOrUpdateQuizQuestionCommand | null): any {
    if (value == undefined) {
        return undefined;
    }
    return {
        'text': value.text,
        'answers': value.answers === undefined ? undefined : (value.answers as Array<any>).map(AddOrUpdateQuizQuestionAnswerCommandToJSON),
    };
}


