import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";

const styles = (theme: Theme) =>
  createStyles({
    mediaSelectorFilter: {
      display: "flex",
      flexWrap: "wrap",
      padding: theme.spacing(1),
      borderBottom: `solid 1px ${theme.palette.divider}`,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 150,
    },
  });

interface TrainingProgramSessionCompletionMessageProps extends WithStyles<typeof styles> {
  trainingProgramName: string;
  trainingProgramSessionNumber: number;
  sentTrainingProgramSessionConfirmationEmail?: boolean;
  className?: string;
}

export const TrainingProgramSessionCompletionMessageComponent = (
  props: TrainingProgramSessionCompletionMessageProps,
) => {
  const { trainingProgramName, trainingProgramSessionNumber, sentTrainingProgramSessionConfirmationEmail, className } =
    props;

  return (
    <div className={className}>
      <Typography variant="h5" style={{ marginBottom: "16px" }}>
        Training Complete!
      </Typography>
      <Typography variant="body1" gutterBottom={true}>
        Thank you for completing the <strong>{trainingProgramName}</strong> Training Program. For your reference, you
        completed <strong>Training Program Session #{trainingProgramSessionNumber}.</strong>
      </Typography>
      {sentTrainingProgramSessionConfirmationEmail && (
        <Typography variant="body1" gutterBottom={true}>
          You should receive an email within the next few minutes confirming your completion of the{" "}
          {trainingProgramName} Training Program.
        </Typography>
      )}
    </div>
  );
};

export const TrainingProgramSessionCompletionMessage = withStyles(styles)(
  TrainingProgramSessionCompletionMessageComponent,
);
