import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";
import { duration as transitionDuration } from "@material-ui/core/styles/transitions";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import React from "react";
import { TrainingProgramSessionCompletionViewer } from "..";
import {
  CompleteTrainingProgramSessionCommand,
  CourseSummaryDto,
  Document,
  QuizTemplateDto,
  Video,
} from "../../../api";
import { CourseSessionState, CourseViewer, toCourseSessionState } from "../../courses";
import { DocumentViewer } from "../../documents";
import { QuizSessionState, QuizViewer, toQuizSessionState } from "../../quizzes";
import { VideoViewer } from "../../videos";
import { TrainingProgramSessionCompletionFormData } from "../forms";
import { TrainingProgramSessionCompletionMessage } from "../messages";
import { TrainingProgramSession } from "../models";
import { TrainingProgramSessionViewerStepper } from "./TrainingProgramSessionViewerStepper";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: 0,
      transition: theme.transitions.create(["padding"], {
        easing: theme.transitions.easing.sharp,
        duration: transitionDuration.enteringScreen,
      }),
    },
    rootPreviewMode: {
      border: `solid 1px ${theme.palette.divider}`,
    },
    rootWithMediaSelectorOpen: {
      paddingLeft: Math.min(375, window.innerWidth),
      transition: theme.transitions.create(["padding"], {
        easing: theme.transitions.easing.sharp,
        duration: transitionDuration.leavingScreen,
      }),
      [theme.breakpoints.up("sm")]: {
        paddingLeft: 375,
      },
    },
    loader: {
      margin: `${theme.spacing(2)}px auto`,
      padding: `0 ${theme.spacing(2)}px`,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& .MuiCircularProgress-root": {
        marginRight: theme.spacing(1),
      },
    },
    messageContainer: {
      maxWidth: 900,
      margin: `${theme.spacing(2)}px auto`,
      padding: `0 ${theme.spacing(2)}px`,
    },
    message: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.secondary.main,
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
    },
    messageIcon: {
      paddingRight: theme.spacing(1),
      color: theme.palette.common.white,
    },
    messageBody: {
      color: theme.palette.common.white,
    },
    quizzesResultsSummaryContainer: {
      marginTop: theme.spacing(2),
    },
    quizzesResultsSummary: {
      borderRadius: theme.shape.borderRadius,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
    },
    formContainer: {
      position: "relative",
      overflowY: "scroll",
    },
    form: {
      maxWidth: 900,
      margin: "0 auto",
      padding: theme.spacing(2),
      "& .field-object h5": {
        marginTop: 0,
      },
    },
    formSubmitProgress: {
      position: "absolute",
      width: "100%",
    },
  });

interface TrainingProgramSessionViewerProps extends WithStyles<typeof styles> {
  trainingProgramSession?: TrainingProgramSession;
  availableWidth: number;
  availableHeight: number;
  sidebarOpen?: boolean;
  isPreviewMode?: boolean;
  isUserSignedIn?: boolean;
  isLoading?: boolean;
  isTrainingProgramSessionSubmitting?: boolean;
  onReload?: () => void;
  onStepChange: (stepIndex: number) => void;
  onProgressChange: (stepIndex: number, stepProgress: number) => void;
  onCourseSessionStart: () => void;
  onCourseSessionStateChange: (courseSessionState: CourseSessionState, isCourseComplete?: boolean) => void;
  onQuizSessionStateChange: (quizSessionState: QuizSessionState, isQuizComplete?: boolean) => void;
  onCompletionFormChange: (trainingProgramSessionCompletionFormData: TrainingProgramSessionCompletionFormData) => void;
  onSubmit: (completeTrainingProgramSessionCommand: CompleteTrainingProgramSessionCommand) => void;
  onError: (errorMessage: string) => void;
}

interface TrainingProgramSessionViewerState {
  trainingProgramComponent?: {
    course?: CourseSummaryDto;
    document?: Document;
    video?: Video;
    quiz?: QuizTemplateDto;
    completion?: boolean;
  };
  isNextStepDisabled: boolean;
}

export const TrainingProgramSessionViewerComponent = class extends React.Component<
  TrainingProgramSessionViewerProps,
  TrainingProgramSessionViewerState
> {
  constructor(props: TrainingProgramSessionViewerProps) {
    super(props);

    this.state = {
      isNextStepDisabled: true,
    };
  }

  public componentDidMount() {
    this.updateTrainingProgramComponentState();
  }

  public render() {
    const {
      trainingProgramSession,
      isUserSignedIn,
      isTrainingProgramSessionSubmitting,
      availableWidth,
      availableHeight,
      sidebarOpen,
      isLoading,
      isPreviewMode,
      classes,
      onStepChange,
      onCompletionFormChange,
      onSubmit,
      onError,
    } = this.props;
    const { trainingProgramComponent, isNextStepDisabled } = this.state;

    return (
      <div
        className={classNames(
          classes.root,
          sidebarOpen && classes.rootWithMediaSelectorOpen,
          isPreviewMode && classes.rootPreviewMode,
        )}
      >
        {trainingProgramSession && (trainingProgramSession.beganAt || isPreviewMode) ? (
          <>
            <TrainingProgramSessionViewerStepper
              stepIndex={trainingProgramSession.currentTrainingProgramComponentIndex}
              stepItems={trainingProgramSession.trainingProgramComponents.map((component) => ({
                type: component.document
                  ? "document"
                  : component.video
                  ? "video"
                  : component.quiz
                  ? "quiz"
                  : "completion",
                description: component.name,
              }))}
              isPreviousStepDisabled={!!trainingProgramSession.trainingProgramSessionNumber}
              isNextStepDisabled={
                !!trainingProgramSession.trainingProgramSessionNumber || (!isPreviewMode && isNextStepDisabled)
              }
              onStepChange={onStepChange}
            />
            {trainingProgramSession.trainingProgramSessionNumber ? (
              <TrainingProgramSessionCompletionMessage
                className={classes.form}
                trainingProgramName={trainingProgramSession.trainingProgramName}
                trainingProgramSessionNumber={trainingProgramSession.trainingProgramSessionNumber}
                sentTrainingProgramSessionConfirmationEmail={
                  trainingProgramSession.sentTrainingProgramSessionConfirmationEmail
                }
              />
            ) : trainingProgramComponent ? (
              trainingProgramComponent.course ? (
                <CourseViewer
                  key={trainingProgramComponent.course.id}
                  course={trainingProgramComponent.course}
                  courseSessionState={
                    trainingProgramSession.courses.find((c) => c.courseId === trainingProgramComponent.course!.id) ??
                    toCourseSessionState(trainingProgramComponent.course)
                  }
                  onChange={this.handleCourseStateChange}
                  availableHeight={availableHeight}
                />
              ) : trainingProgramComponent.document ? (
                <DocumentViewer
                  key={trainingProgramComponent.document.id}
                  document={trainingProgramComponent.document}
                  availableWidth={availableWidth}
                  availableHeight={availableHeight}
                />
              ) : trainingProgramComponent.video ? (
                <VideoViewer
                  key={trainingProgramComponent.video.id}
                  video={trainingProgramComponent.video}
                  availableWidth={availableWidth}
                  availableHeight={availableHeight}
                  initialPlaybackTime={
                    isPreviewMode
                      ? undefined
                      : trainingProgramSession.currentTrainingProgramComponentIndex >
                        trainingProgramSession.maximumTrainingProgramComponentIndex
                      ? 0
                      : trainingProgramSession.currentTrainingProgramComponentIndex <
                        trainingProgramSession.maximumTrainingProgramComponentIndex
                      ? undefined
                      : trainingProgramSession.maximumTrainingProgramComponentProgress ?? 0
                  }
                  onProgressChange={this.handleVideoProgressChange}
                  onCompletionChange={this.handleVideoCompletionChange}
                />
              ) : trainingProgramComponent.quiz ? (
                <QuizViewer
                  key={`${trainingProgramComponent.quiz.id}-${trainingProgramComponent.quiz.version}`}
                  availableHeight={availableHeight}
                  quiz={trainingProgramComponent.quiz}
                  quizSessionState={
                    trainingProgramSession.quizzes.find((q) => q.quizId === trainingProgramComponent.quiz!.id) ??
                    toQuizSessionState(trainingProgramComponent.quiz)
                  }
                  isPreviewMode={isPreviewMode}
                  onChange={this.handleQuizStateChange}
                  onResultChange={this.handleQuizResultChange}
                  onSubmit={this.handleQuizSubmit}
                />
              ) : trainingProgramComponent.completion ? (
                <TrainingProgramSessionCompletionViewer
                  availableHeight={availableHeight}
                  trainingProgramSession={trainingProgramSession}
                  isPreviewMode={!!isPreviewMode}
                  isUserSignedIn={!!isUserSignedIn}
                  isTrainingProgramSessionSubmitting={!!isTrainingProgramSessionSubmitting}
                  onChange={onCompletionFormChange}
                  onSubmit={onSubmit}
                  onError={onError}
                />
              ) : null
            ) : null}
          </>
        ) : isLoading ? (
          <div className={classes.loader}>
            <CircularProgress size={24} />
            <Typography variant="body2">Training Program Session Loading...</Typography>
          </div>
        ) : !isPreviewMode ? (
          <div className={classes.messageContainer}>
            <Paper className={classes.message}>
              <div className={classes.messageIcon}>
                <FontAwesomeIcon icon={["fas", "info-circle"]} />
              </div>
              <Typography variant="body1" className={classes.messageBody}>
                Select a Training program from the 'Program' drop-down list at left, then select 'Start Training
                Program'.
              </Typography>
            </Paper>
          </div>
        ) : null}
      </div>
    );
  }

  public componentDidUpdate(
    prevProps: TrainingProgramSessionViewerProps,
    prevState: TrainingProgramSessionViewerState,
  ) {
    const { trainingProgramSession: prevTrainingProgramSession } = prevProps;
    const { trainingProgramSession } = this.props;

    if (trainingProgramSession) {
      const prevTrainingProgramBeganAt = prevTrainingProgramSession?.beganAt;
      const prevTrainingProgramComponentIndex = prevTrainingProgramSession?.currentTrainingProgramComponentIndex;
      const prevTrainingProgramComponentCourseVersion =
        prevTrainingProgramComponentIndex != null &&
        prevTrainingProgramSession?.trainingProgramComponents[prevTrainingProgramComponentIndex]
          ? prevTrainingProgramSession.trainingProgramComponents[prevTrainingProgramComponentIndex].course?.version
          : undefined;
      const prevTrainingProgramComponentQuizVersion =
        prevTrainingProgramComponentIndex != null &&
        prevTrainingProgramSession?.trainingProgramComponents[prevTrainingProgramComponentIndex]
          ? prevTrainingProgramSession.trainingProgramComponents[prevTrainingProgramComponentIndex].quiz?.version
          : undefined;

      const trainingProgramBeganAt = trainingProgramSession.beganAt;
      const trainingProgramComponentIndex = trainingProgramSession.currentTrainingProgramComponentIndex;
      const trainingProgramComponentCourseVersion =
        trainingProgramComponentIndex != null &&
        trainingProgramSession.trainingProgramComponents[trainingProgramComponentIndex]
          ? trainingProgramSession.trainingProgramComponents[trainingProgramComponentIndex].course?.version
          : undefined;
      const trainingProgramComponentQuizVersion =
        trainingProgramComponentIndex != null &&
        trainingProgramSession.trainingProgramComponents[trainingProgramComponentIndex]
          ? trainingProgramSession.trainingProgramComponents[trainingProgramComponentIndex].quiz?.version
          : undefined;

      // If the Training Program has been started, or the current Training Program Component has changed...
      if (
        prevTrainingProgramBeganAt !== trainingProgramBeganAt ||
        prevTrainingProgramComponentIndex !== trainingProgramComponentIndex ||
        prevTrainingProgramComponentCourseVersion !== trainingProgramComponentCourseVersion ||
        prevTrainingProgramComponentQuizVersion !== trainingProgramComponentQuizVersion
      ) {
        this.updateTrainingProgramComponentState();
      }
    }
  }

  private updateTrainingProgramComponentState = () => {
    const { trainingProgramSession, onCourseSessionStart } = this.props;

    if (trainingProgramSession) {
      const trainingProgramComponentIndex = trainingProgramSession.currentTrainingProgramComponentIndex;

      const trainingProgramComponent = trainingProgramSession.trainingProgramComponents[trainingProgramComponentIndex];

      if (trainingProgramComponent.course) {
        this.setState({
          ...this.state,
          trainingProgramComponent: { course: trainingProgramComponent.course },
          isNextStepDisabled: true,
        });

        // If the Training Program has been started, hide the sidebar...
        if (trainingProgramSession.beganAt) {
          onCourseSessionStart();
        }
      }
      // Otherwise, if this is a Document, convert it to a Media-derived Document then set as the active Component...
      else if (trainingProgramComponent.document) {
        const document: Document = {
          type: "document",
          ...trainingProgramComponent.document,
        };

        this.setState({ ...this.state, trainingProgramComponent: { document }, isNextStepDisabled: false });
      }
      // Otherwise, if this is a Video, convert it to a Media-derived Video then set as the active Component...
      else if (trainingProgramComponent.video) {
        const video: Video = {
          type: "video",
          ...trainingProgramComponent.video,
        };

        this.setState({ ...this.state, trainingProgramComponent: { video }, isNextStepDisabled: true });
      }
      // Otherwise, if this is a Quiz, set initial Quiz Form Data if necessary...
      else if (trainingProgramComponent.quiz) {
        const quiz = trainingProgramComponent.quiz;
        // Load the Quiz with the 'Next Step' button disabled, to ensure the user cannot move to the next step until
        // we have confirmed the Quiz is completed
        this.setState({ ...this.state, trainingProgramComponent: { quiz }, isNextStepDisabled: true });
      }
      // Otherwise, if this is the last Component, assume it is the 'Completion Form'...
      else if (trainingProgramComponentIndex === trainingProgramSession.trainingProgramComponents.length - 1) {
        this.setState({ ...this.state, trainingProgramComponent: { completion: true }, isNextStepDisabled: false });
      }
      // Otherwise, this is unexpectedly an empty Component...
      else {
        throw Error("There is unexpectedly nothing to show for this Training Program Component.");
      }
    }
  };

  private handleCourseStateChange = (courseSessionState: CourseSessionState, isCourseComplete?: boolean) => {
    const { onCourseSessionStateChange } = this.props;
    const { isNextStepDisabled } = this.state;

    onCourseSessionStateChange(courseSessionState, isCourseComplete);

    if (isCourseComplete && isNextStepDisabled) {
      this.setState({ ...this.state, isNextStepDisabled: false });
    }
  };

  private handleVideoProgressChange = (progressSeconds: number) => {
    const { trainingProgramSession, onProgressChange } = this.props;

    if (!trainingProgramSession) {
      throw Error("Training Program Session not loaded.");
    }

    onProgressChange(trainingProgramSession.currentTrainingProgramComponentIndex, progressSeconds);
  };

  private handleVideoCompletionChange = (isCompleted: boolean) => {
    const { isNextStepDisabled } = this.state;

    const nextIsNextStepDisabled = !isCompleted;

    if (isNextStepDisabled !== nextIsNextStepDisabled) {
      this.setState({ ...this.state, isNextStepDisabled: nextIsNextStepDisabled });
    }
  };

  private handleQuizStateChange = (quizSessionState: QuizSessionState, isQuizComplete?: boolean) => {
    const { onQuizSessionStateChange } = this.props;

    onQuizSessionStateChange(quizSessionState, isQuizComplete);
  };

  private handleQuizResultChange = (resultOutcome?: "obsolete" | "passed") => {
    const { onReload } = this.props;
    const { isNextStepDisabled } = this.state;

    if (resultOutcome === "obsolete") {
      if (onReload) {
        onReload();
      }
    } else {
      const nextIsNextStepDisabled = resultOutcome !== "passed";

      if (isNextStepDisabled !== nextIsNextStepDisabled) {
        this.setState({ ...this.state, isNextStepDisabled: nextIsNextStepDisabled });
      }
    }
  };

  private handleQuizSubmit = (quizSessionState: QuizSessionState) => {
    const { trainingProgramSession, onStepChange } = this.props;

    if (!trainingProgramSession) {
      throw Error("No Training Program Session in progress.");
    }

    const nextStepIndex = trainingProgramSession.currentTrainingProgramComponentIndex + 1;

    if (nextStepIndex > trainingProgramSession.trainingProgramComponents.length - 1) {
      throw Error("There are unexpectedly no Steps following the Quiz.");
    }

    if (onStepChange) {
      onStepChange(nextStepIndex);
    }
  };
};

export const TrainingProgramSessionViewer = withStyles(styles)(TrainingProgramSessionViewerComponent);
