import { AppActions, AppPageFrame, baseActionCreators, Loader, OidcState } from "@interface48/app";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";
import cloneDeep from "lodash/cloneDeep";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  QuizForm,
  QuizFormData,
  toAddQuizCommand,
  toQuizFormData,
  toUpdateQuizCommand,
} from "../../../components/quizzes";
import { EntityDeleteDialog } from "../../../components/shared";
import { actionCreators, AdministrationActions, AdministrationState, ApplicationState } from "../../../store";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
    },
    form: {
      backgroundColor: theme.palette.background.default,
    },
  });

interface QuizPageMatchParamsProps {
  quizId: string;
}

interface QuizPageStateProps {
  oidcState: OidcState;
  administrationState: AdministrationState;
}

interface QuizPageDispatchProps {
  appActions: AppActions;
  administrationActions: AdministrationActions;
}

interface QuizPageOwnProps extends RouteComponentProps<QuizPageMatchParamsProps>, WithStyles<typeof styles> {}

type QuizPageProps = QuizPageStateProps & QuizPageDispatchProps & QuizPageOwnProps;

interface QuizPageState {
  formMode: "read" | "write";
  formData: QuizFormData;
  initialFormData: QuizFormData;
  isDeleteConfirmationModalOpen: boolean;
}

export const QuizPageComponent = class extends React.Component<QuizPageProps, QuizPageState> {
  constructor(props: QuizPageProps) {
    super(props);

    const formMode = this.getQuizId() ? "read" : "write";
    const formData = toQuizFormData();

    this.state = {
      formMode,
      formData,
      initialFormData: cloneDeep(formData),
      isDeleteConfirmationModalOpen: false,
    };
  }

  public componentDidMount() {
    const { administrationActions } = this.props;

    const quizId = this.getQuizId();

    if (quizId) {
      administrationActions.requestQuiz(quizId);
    }
  }

  public render() {
    const { administrationState, classes } = this.props;
    const { quiz, actionStatus } = administrationState;
    const { formMode, formData, isDeleteConfirmationModalOpen } = this.state;

    const formKey = `form_${formMode}`;
    const quizId = this.getQuizId();
    const isQuizLoading = !quiz && actionStatus.type === "ADMINISTRATION_QUIZ_REQUEST" && actionStatus.pending;
    const isQuizSaving =
      (actionStatus.type === "ADMINISTRATION_QUIZ_ADD_REQUEST" ||
        actionStatus.type === "ADMINISTRATION_QUIZ_UPDATE_REQUEST") &&
      actionStatus.pending;
    const isQuizFormLoading = quizId && (isQuizLoading || !formData.id);

    return (
      <AppPageFrame
        pageTitle={
          !quizId
            ? "Add Quiz"
            : isQuizFormLoading
            ? "Loading Quiz..."
            : `${formMode === "write" ? "Edit " : ""}Quiz - ${quiz!.name}`
        }
        onBackClick={this.handleBackClick}
      >
        <div className={classes.container}>
          {isQuizFormLoading ? (
            <Loader loadingText={"Loading Quiz"} />
          ) : (
            <>
              <QuizForm
                key={formKey}
                className={classes.form}
                formMode={formMode}
                formData={formData}
                onEdit={this.handleEdit}
                onChange={this.handleChange}
                onSubmit={this.handleSubmit}
                onCancel={this.handleCancel}
                onClose={this.handleClose}
                onDelete={this.handleDelete}
                isFormSubmitting={isQuizSaving}
              />
              {quizId && formData.name && (
                <EntityDeleteDialog
                  entityId={quizId}
                  entityName={formData.name}
                  isOpen={isDeleteConfirmationModalOpen}
                  isActionPending={actionStatus.pending}
                  onCancel={this.handleDeleteCancel}
                  onDelete={this.handleDeleteConfirm}
                />
              )}
            </>
          )}
        </div>
      </AppPageFrame>
    );
  }

  public componentDidUpdate(prevProps: QuizPageProps, prevState: QuizPageState) {
    const { administrationState: prevAdministrationState } = prevProps;
    const { formMode: prevFormMode } = prevState;
    const { administrationState, appActions, administrationActions, history } = this.props;
    const { quiz } = administrationState;
    const { formMode } = this.state;

    const quizId = this.getQuizId();

    const prevActionStatus = prevAdministrationState.actionStatus;
    const actionStatus = administrationState.actionStatus;

    // If some Quiz action just completed...
    if (prevActionStatus.pending && !actionStatus.pending) {
      // If an error occurred, display the message...
      if (actionStatus.error) {
        const errorMessage =
          actionStatus.error.message || "An unexpected error occurred upon attempting to process the last operation.";

        appActions.postSnackbarMessage(errorMessage, { variant: "error" });
      }
      // Otherwise, the operation completed successfully...
      else if (quiz) {
        // If we fetched the Details for this Quiz...
        if (actionStatus.type === "ADMINISTRATION_QUIZ_REQUEST" && quiz) {
          const formData = toQuizFormData(quiz);

          this.setState({ ...this.state, formMode: "read", formData });
          // If we loaded the details for a Quiz that was just added via /add, replace the URL with it's
          // ID-specific one...
          if (!quizId) {
            history.replace(`/administration/quizzes/${formData.id}`);
          }
        }
        // Otherwise if we added or updated a Quiz, fetch the latest Details...
        else if (
          (actionStatus.type === "ADMINISTRATION_QUIZ_ADD_REQUEST" ||
            actionStatus.type === "ADMINISTRATION_QUIZ_UPDATE_REQUEST") &&
          quiz
        ) {
          administrationActions.requestQuiz(quiz.id);
        }
        // Otherwise, if we removed a Quiz...
        else if (actionStatus.type === "ADMINISTRATION_QUIZ_DELETE_REQUEST") {
          history.push("/administration/quizzes");
        }
      }
    }

    // If the Form Mode just changed, scroll to the top of the page...
    if (prevFormMode !== formMode) {
      const appFrame = document.getElementById("app-frame");
      if (appFrame) {
        appFrame.scrollTop = 0;
      }
    }
  }

  private handleBackClick = () => {
    this.props.history.goBack();
  };

  private handleClose = () => {
    this.props.history.goBack();
  };

  private handleEdit = () => {
    this.setState({ ...this.state, formMode: "write" });
  };

  private handleCancel = (initialFormData: QuizFormData) => {
    const { history } = this.props;

    if (!this.getQuizId()) {
      history.push("/administration/quizzes");
    } else {
      this.setState({ ...this.state, formMode: "read", formData: initialFormData });
    }
  };

  private handleChange = (formData: QuizFormData) => {
    this.setState({ ...this.state, formData });
  };

  private handleSubmit = (formData: QuizFormData) => {
    const { administrationActions } = this.props;

    // If adding a new Quiz...
    if (!this.getQuizId()) {
      const addQuizCommand = toAddQuizCommand(formData);

      administrationActions.requestQuizAdd(addQuizCommand);
    }
    // Otherwise, if updating a Quiz...
    else {
      if (!formData.id) {
        throw Error("Quiz Id is undefined upon request to update.");
      }

      const updateQuizCommand = toUpdateQuizCommand(formData);

      administrationActions.requestQuizUpdate(formData.id, updateQuizCommand);
    }
  };

  private handleDelete = () => {
    this.setState({ ...this.state, isDeleteConfirmationModalOpen: true });
  };

  private handleDeleteCancel = () => {
    this.setState({ ...this.state, isDeleteConfirmationModalOpen: false });
  };

  private handleDeleteConfirm = (quizId: string) => {
    const { administrationActions } = this.props;

    administrationActions.requestQuizDelete(quizId);
  };

  private getQuizId = (): string | undefined => {
    const { match } = this.props;
    const { params } = match;
    const { quizId } = params;

    if (quizId.toLowerCase() === "add") {
      return undefined;
    }

    return quizId;
  };
};

export const QuizPage = withStyles(styles)(
  connect<QuizPageStateProps, QuizPageDispatchProps, QuizPageOwnProps, ApplicationState>(
    (state) => ({
      oidcState: state.oidc,
      administrationState: state.administration,
    }),
    (dispatch) => ({
      appActions: bindActionCreators(baseActionCreators.app, dispatch),
      administrationActions: bindActionCreators(actionCreators.administration, dispatch),
    }),
  )(QuizPageComponent),
);
