/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetTrainingProgramComponentSummariesQuery
 */
export interface GetTrainingProgramComponentSummariesQuery {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetTrainingProgramComponentSummariesQuery
     */
    componentIds: Array<string>;
}

export function GetTrainingProgramComponentSummariesQueryFromJSON(json: any): GetTrainingProgramComponentSummariesQuery {
    return {
        'componentIds': json['componentIds'],
    };
}

export function GetTrainingProgramComponentSummariesQueryToJSON(value?: GetTrainingProgramComponentSummariesQuery | null): any {
    if (value == undefined) {
        return undefined;
    }
    return {
        'componentIds': value.componentIds,
    };
}


