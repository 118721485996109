import { toAuditDetailsFormData } from "@interface48/forms";
import {
  AddOrUpdateQuizQuestionAnswerCommand,
  AddOrUpdateQuizQuestionCommand,
  AddQuizCommand,
  QuizDto,
  QuizQuestionAnswerDto,
  QuizQuestionDto,
  UpdateQuizCommand,
} from "../../../../api";
import { sortQuizQuestionAnswersByNumber, sortQuizQuestionsByNumber } from "../../utils";
import { QuizFormData, QuizQuestionAnswerFormData, QuizQuestionFormData } from "../models";

export const toQuizFormData = (quiz?: QuizDto): QuizFormData => {
  if (quiz) {
    const isExisting = true;

    return {
      isExisting,
      id: quiz.id,
      name: quiz.name,
      description: quiz.description,
      durationMinutes: quiz.durationMinutes,
      minimumPassScorePercentage: quiz.minimumPassScorePercentage,
      version: quiz.version,
      questions: quiz.questions
        ? quiz.questions.sort(sortQuizQuestionsByNumber).map((question) => toQuizQuestionFormData(question))
        : [],
      created: toAuditDetailsFormData(quiz.createdAt, quiz.createdByUserName),
      lastModified: toAuditDetailsFormData(quiz.lastModifiedAt, quiz.lastModifiedByUserName),
    };
  } else {
    const isExisting = false;

    return {
      isExisting,
      // Default with at least 1 question and 2 answers
      questions: [toQuizQuestionFormData()],
      created: {},
      lastModified: {},
    };
  }
};
const toQuizQuestionFormData = (quizQuestion?: QuizQuestionDto): QuizQuestionFormData => {
  if (quizQuestion) {
    return {
      text: quizQuestion.text,
      answers: quizQuestion.answers
        ? quizQuestion.answers
            .sort(sortQuizQuestionAnswersByNumber)
            .map((answer) => toQuizQuestionAnswerFormData(answer))
        : [],
    };
  } else {
    // Default with at least 1 question and 2 answers
    return {
      text: undefined,
      answers: [toQuizQuestionAnswerFormData(), toQuizQuestionAnswerFormData()],
    };
  }
};

const toQuizQuestionAnswerFormData = (quizQuestionAnswer?: QuizQuestionAnswerDto): QuizQuestionAnswerFormData => {
  if (quizQuestionAnswer) {
    return {
      text: quizQuestionAnswer.text,
      isCorrect: quizQuestionAnswer.isCorrect,
    };
  } else {
    return {
      text: undefined,
      isCorrect: false,
    };
  }
};

export const toAddQuizCommand = (formData: QuizFormData): AddQuizCommand => {
  const { name, description, durationMinutes, minimumPassScorePercentage, questions } = formData;

  const addQuizCommand = {
    name: name!,
    description: description!,
    durationMinutes: durationMinutes!,
    minimumPassScorePercentage: minimumPassScorePercentage!,
    questions: toAddOrUpdateQuizQuestionCommand(questions),
  };

  return addQuizCommand;
};

export const toUpdateQuizCommand = (formData: QuizFormData): UpdateQuizCommand => {
  const { name, description, durationMinutes, minimumPassScorePercentage, questions } = formData;

  const updateQuizCommand = {
    name: name!,
    description: description!,
    durationMinutes: durationMinutes!,
    minimumPassScorePercentage: minimumPassScorePercentage!,
    questions: toAddOrUpdateQuizQuestionCommand(questions),
  };

  return updateQuizCommand;
};

const toAddOrUpdateQuizQuestionCommand = (
  quizQuestionFormData: QuizQuestionFormData[],
): AddOrUpdateQuizQuestionCommand[] => {
  const addOrUpdateQuizQuestionsCommands = quizQuestionFormData.map((question) => {
    const addOrUpdateQuizQuestionsCommand = {
      text: question.text!,
      answers:
        question.answers &&
        question.answers.map((answer) => {
          const addOrUpdateQuizQuestionAnswerCommand = toAddOrUpdateQuizQuestionAnswerCommand(answer);
          return addOrUpdateQuizQuestionAnswerCommand;
        }),
    };

    return addOrUpdateQuizQuestionsCommand;
  });

  return addOrUpdateQuizQuestionsCommands;
};

const toAddOrUpdateQuizQuestionAnswerCommand = (
  formData: QuizQuestionAnswerFormData,
): AddOrUpdateQuizQuestionAnswerCommand => {
  const addOrUpdateQuizQuestionAnswerCommand = {
    text: formData.text!,
    isCorrect: formData.isCorrect!,
  };
  return addOrUpdateQuizQuestionAnswerCommand;
};
