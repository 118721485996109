import { Table } from "@interface48/tables";
import orderBy from "lodash/orderBy";
import React, { useMemo } from "react";
import { TrainingScorecardDto } from "../../../api";

interface TrainingScorecardResult {
  departmentName: string;
  positionName?: string;
  targetCompletionCount: number;
  completionCount: number;
  completionPercentage: number;
}

interface TrainingScorecardTableProps {
  trainingScorecard: TrainingScorecardDto;
  isLoading: boolean;
}

const TrainingScorecardTableComponent = (props: TrainingScorecardTableProps) => {
  const { trainingScorecard, isLoading } = props;

  const trainingScorecardResults = useMemo(
    () =>
      orderBy(trainingScorecard.departments, (d) => d.departmentName).flatMap((department) =>
        orderBy(department.positions, (p) => p.positionName).map(
          (position): TrainingScorecardResult => ({
            departmentName: department.departmentName,
            positionName: position.positionName ?? (department.positions.length > 1 ? "(Other)" : undefined),
            targetCompletionCount: position.targetCompletionCount,
            completionCount: position.completionCount,
            completionPercentage: position.completionPercentage,
          }),
        ),
      ),
    [trainingScorecard],
  );

  return (
    <Table
      columnSchemas={[
        { id: "departmentName", label: "Department" },
        { id: "positionName", label: "Position" },
        {
          id: "completionCount",
          label: "Completion Count",
          align: "right",
          cellRenderer: (completionCount: number, row: TrainingScorecardResult) =>
            `${completionCount} (of ${row.targetCompletionCount})`,
        },
        { id: "completionPercentage", label: "% Completed", align: "right" },
      ]}
      data={trainingScorecardResults}
      dataSourceType="local"
      isLoading={isLoading}
    />
  );
};

export const TrainingScorecardTable = TrainingScorecardTableComponent;
