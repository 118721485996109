/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateUserGroupCommand
 */
export interface UpdateUserGroupCommand {
    /**
     * 
     * @type {number}
     * @memberof UpdateUserGroupCommand
     */
    userGroupType: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateUserGroupCommand
     */
    userPrincipalNames: Array<string>;
}

export function UpdateUserGroupCommandFromJSON(json: any): UpdateUserGroupCommand {
    return {
        'userGroupType': json['userGroupType'],
        'userPrincipalNames': json['userPrincipalNames'],
    };
}

export function UpdateUserGroupCommandToJSON(value?: UpdateUserGroupCommand | null): any {
    if (value == undefined) {
        return undefined;
    }
    return {
        'userGroupType': value.userGroupType,
        'userPrincipalNames': value.userPrincipalNames,
    };
}


