import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import React from "react";
import { StepItem } from "../../shared";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
      overflowY: "scroll",
      height: "100%",
    },
    stepperRoot: {
      padding: theme.spacing(2),
      backgroundColor: "transparent",
    },
    stepLabel: {
      display: "flex",
      alignItems: "center",
      fontSize: theme.typography.body1.fontSize,
    },
    stepLabelActive: {
      fontWeight: "bold",
    },
    stepLabelName: {
      padding: theme.spacing(1),
    },
  });

interface TrainingProgramSyllabusStepperProps extends WithStyles<typeof styles> {
  className?: string;
  stepItems: StepItem[];
  stepIndex?: number;
}

export const TrainingProgramSyllabusStepperComponent = (props: TrainingProgramSyllabusStepperProps) => {
  const { className, stepItems, stepIndex, classes } = props;

  return (
    <div className={classNames(classes.root, className)}>
      <Stepper classes={{ root: classes.stepperRoot }} activeStep={stepIndex ?? -1} orientation="vertical">
        {stepItems.map((step, index) => {
          const stepDescription = step.description;
          return (
            <Step key={`step-${index}`}>
              <StepLabel classes={{ label: classes.stepLabel, active: classes.stepLabelActive }}>
                <div className={classes.stepLabelName}>{stepDescription}</div>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
};

export const TrainingProgramSyllabusStepper = withStyles(styles)(TrainingProgramSyllabusStepperComponent);
