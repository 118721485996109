import forEach from "lodash/forEach";
import groupBy from "lodash/groupBy";
import {
  AddOrUpdateUpdateTrainingScorecardDepartmentCommand,
  AddOrUpdateUpdateTrainingScorecardDepartmentPositionCommand,
  TrainingScorecardDto,
  UpdateTrainingScorecardCommand,
} from "../../../../api";
import { TrainingScorecardFormData } from "../models";

export const toTrainingScorecardFormData = (trainingScorecard: TrainingScorecardDto): TrainingScorecardFormData => {
  return {
    id: trainingScorecard.id,
    name: trainingScorecard.name,
    currentPeriodBeginAt: trainingScorecard.currentPeriodBeginAt,
    targets: trainingScorecard.departments.flatMap((department) =>
      department.positions.map((position) => ({
        department: department.department,
        position: position.position,
        targetCompletionCount: position.targetCompletionCount,
      })),
    ),
  };
};

export const toUpdateTrainingScorecardCommand = (
  formData: TrainingScorecardFormData,
): UpdateTrainingScorecardCommand => {
  const { currentPeriodBeginAt, targets } = formData;

  if (currentPeriodBeginAt == null || targets == null) {
    throw new Error("One or more required fields undefined.");
  }

  const departmentCommands: AddOrUpdateUpdateTrainingScorecardDepartmentCommand[] = [];

  forEach(
    groupBy(targets, (target) => target.department),
    (departmentTargets, departmentKey) => {
      const department = parseInt(departmentKey, 10);

      departmentCommands.push({
        department,
        positions: departmentTargets.map(
          (departmentTarget): AddOrUpdateUpdateTrainingScorecardDepartmentPositionCommand => {
            const { department, position, targetCompletionCount } = departmentTarget;

            if (department == null || targetCompletionCount == null) {
              throw new Error("One or more required fields undefined.");
            }

            return {
              position,
              targetCompletionCount: targetCompletionCount,
            };
          },
        ),
      });
    },
  );

  return {
    currentPeriodBeginAt,
    departments: departmentCommands,
  };
};
