import { ApiError, getApiError } from "@interface48/api";
import { ActionStatus, AppThunkAction, getUserFullName, isUserAuthorized, isUserSignedIn } from "@interface48/app";
import moment from "moment";
import { Reducer } from "redux";
import {
  AddOrUpdateTrainingProgramSessionCourseCommand,
  AddOrUpdateTrainingProgramSessionQuizCommand,
  AddOrUpdateTrainingProgramSessionStateCommand,
  CompleteTrainingProgramSessionCommand,
  Department,
  Position,
  TrainingProgramComponentSummaryDto,
  TrainingProgramSessionCompletionResultDto,
  TrainingProgramSessionStateDto,
  TrainingProgramSummaryDto,
  toTrainingProgramSessionStateDto,
  trainingProgramSessionsApi,
  trainingProgramsApi,
} from "../api";
import { CourseSessionState, toCourseSessionState } from "../components/courses";
import { QuizSessionState, toQuestionAnswerValuesForCommand, toQuizSessionState } from "../components/quizzes";
import {
  TrainingProgramSession,
  TrainingProgramSessionCompletionFormData,
  toTrainingProgramSessionCompletionFormData,
} from "../components/training-program-sessions";
import {
  getRemainingTrainingProgramDurationMinutes,
  sortTrainingProgramComponentsByNumber,
} from "../components/training-programs";
import { ApplicationState } from "./ApplicationState";
import { SidebarDisplayUpdateAction } from "./UI";

const SessionStorageKeys = {
  TrainingProgramSessionStates: "tpss",
};

const getSessionTrainingProgramSessionStates = () => {
  const trainingProgramSessionStatesJson = localStorage.getItem(SessionStorageKeys.TrainingProgramSessionStates);

  return trainingProgramSessionStatesJson
    ? (JSON.parse(trainingProgramSessionStatesJson) as TrainingProgramSessionStateDto[])
    : undefined;
};

const setSessionTrainingProgramSessionStates = (
  trainingProgramSessionStates: TrainingProgramSessionStateDto[] | undefined,
) => {
  if (trainingProgramSessionStates) {
    localStorage.setItem(SessionStorageKeys.TrainingProgramSessionStates, JSON.stringify(trainingProgramSessionStates));
  } else {
    localStorage.removeItem(SessionStorageKeys.TrainingProgramSessionStates);
  }
};

type TrainingProgramActionType =
  | "TRAINING_PROGRAMS_REQUEST"
  | "TRAINING_PROGRAM_SESSION_STATES_REQUEST"
  | "TRAINING_PROGRAM_SESSION_STATE_REQUEST"
  | "TRAINING_PROGRAM_SESSION_STATE_ADD_OR_UPDATE_REQUEST"
  | "TRAINING_PROGRAM_SESSION_STATE_REMOVE_REQUEST"
  | "TRAINING_PROGRAM_SESSION_COMPLETION_REQUEST";

export interface TrainingProgramState {
  trainingPrograms?: TrainingProgramSummaryDto[];
  trainingProgramFilters: { department?: number; position?: number };
  trainingProgramSession?: TrainingProgramSession;
  trainingProgramSessionStates?: TrainingProgramSessionStateDto[];
  actionStatus: ActionStatus<TrainingProgramActionType>;
}

interface TrainingProgramsRequestAction {
  type: "TRAINING_PROGRAMS_REQUEST";
}

interface TrainingProgramsRequestSuccessAction {
  type: "TRAINING_PROGRAMS_REQUEST_SUCCESS";
  trainingPrograms: TrainingProgramSummaryDto[];
}

interface TrainingProgramsRequestFailureAction {
  type: "TRAINING_PROGRAMS_REQUEST_FAILURE";
  error: ApiError;
}

interface TrainingProgramsFiltersUpdateRequestAction {
  type: "TRAINING_PROGRAMS_FILTERS_UPDATE";
  trainingProgramFilters: { department?: number; position?: number };
}

interface TrainingProgramSessionStatesRequestAction {
  type: "TRAINING_PROGRAM_SESSION_STATES_REQUEST";
}

interface TrainingProgramSessionStatesRequestSuccessAction {
  type: "TRAINING_PROGRAM_SESSION_STATES_REQUEST_SUCCESS";
  trainingProgramSessionStates: TrainingProgramSessionStateDto[];
}

interface TrainingProgramSessionStatesRequestFailureAction {
  type: "TRAINING_PROGRAM_SESSION_STATES_REQUEST_FAILURE";
  error: ApiError;
}

interface TrainingProgramSessionStateRequestAction {
  type: "TRAINING_PROGRAM_SESSION_STATE_REQUEST";
}

interface TrainingProgramSessionStateRequestSuccessAction {
  type: "TRAINING_PROGRAM_SESSION_STATE_REQUEST_SUCCESS";
  trainingProgramSession: TrainingProgramSession;
}

interface TrainingProgramSessionStateRequestFailureAction {
  type: "TRAINING_PROGRAM_SESSION_STATE_REQUEST_FAILURE";
  error: ApiError;
}

interface TrainingProgramSessionStateAddOrUpdateRequestAction {
  type: "TRAINING_PROGRAM_SESSION_STATE_ADD_OR_UPDATE_REQUEST";
  trainingProgramSession: TrainingProgramSession;
  trainingProgramSessionStates: TrainingProgramSessionStateDto[];
}

interface TrainingProgramSessionStateAddOrUpdateRequestSuccessAction {
  type: "TRAINING_PROGRAM_SESSION_STATE_ADD_OR_UPDATE_REQUEST_SUCCESS";
}

interface TrainingProgramSessionStateAddOrUpdateRequestFailureAction {
  type: "TRAINING_PROGRAM_SESSION_STATE_ADD_OR_UPDATE_REQUEST_FAILURE";
  error: ApiError;
}

interface TrainingProgramSessionStateRemoveRequestAction {
  type: "TRAINING_PROGRAM_SESSION_STATE_REMOVE_REQUEST";
  trainingProgramSessionStates: TrainingProgramSessionStateDto[];
}

interface TrainingProgramSessionStateRemoveRequestSuccessAction {
  type: "TRAINING_PROGRAM_SESSION_STATE_REMOVE_REQUEST_SUCCESS";
}

interface TrainingProgramSessionStateRemoveRequestFailureAction {
  type: "TRAINING_PROGRAM_SESSION_STATE_REMOVE_REQUEST_FAILURE";
  error: ApiError;
}

interface TrainingProgramSessionStateUpdateAction {
  type: "TRAINING_PROGRAM_SESSION_COMPLETION_FORM_UPDATE_ACTION";
  trainingProgramSessionCompletionFormData: TrainingProgramSessionCompletionFormData;
}

interface TrainingProgramSessionCompletionRequestAction {
  type: "TRAINING_PROGRAM_SESSION_COMPLETION_REQUEST";
}

interface TrainingProgramSessionCompletionRequestSuccessAction {
  type: "TRAINING_PROGRAM_SESSION_COMPLETION_REQUEST_SUCCESS";
  trainingProgramSession: TrainingProgramSession;
  trainingProgramSessionStates: TrainingProgramSessionStateDto[];
}

interface TrainingProgramSessionCompletionRequestFailureAction {
  type: "TRAINING_PROGRAM_SESSION_COMPLETION_REQUEST_FAILURE";
  error: ApiError;
}

interface TrainingProgramSessionRemoveAction {
  type: "TRAINING_PROGRAM_SESSION_REMOVE_ACTION";
}

interface TrainingProgramSessionStateRemoveAction {
  type: "TRAINING_PROGRAM_SESSION_STATE_REMOVE_ACTION";
  trainingProgramId: string;
}

type KnownAction =
  | TrainingProgramsRequestAction
  | TrainingProgramsRequestSuccessAction
  | TrainingProgramsRequestFailureAction
  | TrainingProgramsFiltersUpdateRequestAction
  | TrainingProgramSessionStatesRequestAction
  | TrainingProgramSessionStatesRequestSuccessAction
  | TrainingProgramSessionStatesRequestFailureAction
  | TrainingProgramSessionStateRequestAction
  | TrainingProgramSessionStateRequestSuccessAction
  | TrainingProgramSessionStateRequestFailureAction
  | TrainingProgramSessionStateAddOrUpdateRequestAction
  | TrainingProgramSessionStateAddOrUpdateRequestSuccessAction
  | TrainingProgramSessionStateAddOrUpdateRequestFailureAction
  | TrainingProgramSessionStateRemoveRequestAction
  | TrainingProgramSessionStateRemoveRequestSuccessAction
  | TrainingProgramSessionStateRemoveRequestFailureAction
  | TrainingProgramSessionStateUpdateAction
  | TrainingProgramSessionCompletionRequestAction
  | TrainingProgramSessionCompletionRequestSuccessAction
  | TrainingProgramSessionCompletionRequestFailureAction
  | TrainingProgramSessionRemoveAction
  | TrainingProgramSessionStateRemoveAction
  | SidebarDisplayUpdateAction;

export const actionCreators = {
  requestTrainingPrograms: (): AppThunkAction<KnownAction, ApplicationState> => async (dispatch) => {
    dispatch({ type: "TRAINING_PROGRAMS_REQUEST" });

    try {
      const trainingPrograms = await trainingProgramsApi.getTrainingPrograms();

      dispatch({
        type: "TRAINING_PROGRAMS_REQUEST_SUCCESS",
        trainingPrograms,
      });
    } catch (errorResponse) {
      const error = await getApiError(errorResponse);

      dispatch({ type: "TRAINING_PROGRAMS_REQUEST_FAILURE", error });
    }
  },

  updateTrainingProgramsFilters: (trainingProgramFilters: { department?: Department; position?: Position }) => ({
    type: "TRAINING_PROGRAMS_FILTERS_UPDATE",
    trainingProgramFilters,
  }),

  requestTrainingProgramSessionStates: (): AppThunkAction<KnownAction, ApplicationState> => async (dispatch) => {
    requestTrainingProgramSessionStates(dispatch);
  },

  requestTrainingProgramSessionState:
    (trainingProgramSlug: string): AppThunkAction<KnownAction, ApplicationState> =>
    async (dispatch, getState) => {
      dispatch({ type: "TRAINING_PROGRAM_SESSION_STATE_REQUEST" });

      try {
        const trainingProgram = await trainingProgramsApi.getTrainingProgramBySlug(trainingProgramSlug);

        if (!trainingProgram.components) {
          throw Error("Training Program has no Components.");
        }

        const trainingProgramComponents = trainingProgram.components.sort(sortTrainingProgramComponentsByNumber);

        trainingProgramComponents.push({
          name: "Completion",
          number: trainingProgram.components.length,
          durationMinutes: 1,
        });

        let beganAt: string | undefined;
        let courses: CourseSessionState[] = [];
        let quizzes: QuizSessionState[] = [];
        let currentTrainingProgramComponentIndex = 0;
        let maximumTrainingProgramComponentIndex = 0;
        let maximumTrainingProgramComponentProgress: number | undefined;
        let trainingProgramVersion = trainingProgram.version;
        let trainingProgramMajorVersion = trainingProgram.majorVersion;
        let completionFormData = toTrainingProgramSessionCompletionFormData(trainingProgram.departments);

        // If user is authorized and signed in, pre-fill the completion form
        if (isUserSignedIn()) {
          const user = getState().oidc.user;

          if (!user) {
            throw Error("User is unexpectedly not loaded.");
          }

          completionFormData = {
            ...completionFormData,
            userName: getUserFullName(user),
            companyName: "Neptune",
            userEmailAddress: user.profile.email || undefined,
          };
        }

        const trainingProgramSessionStates = getState().trainingProgram.trainingProgramSessionStates;

        // Resume any applicable existing Training Program Session...
        if (trainingProgramSessionStates) {
          const trainingProgramSessionState = trainingProgramSessionStates.find(
            (tpss) =>
              tpss.trainingProgramId === trainingProgram.id &&
              (tpss.trainingProgramMajorVersion || trainingProgram.majorVersion) === trainingProgram.majorVersion &&
              !tpss.trainingProgramCompletedAt,
          );

          if (trainingProgramSessionState) {
            if (trainingProgramSessionState.trainingProgramStepNumber == null) {
              throw Error("Training Program Session State Step Number undefined.");
            }
            beganAt = trainingProgramSessionState.trainingProgramBeganAt;
            // Ensure we do not record a Step Number that is greater than the number of available Components (i.e., in the
            // event the number of Components was reduced below the Step Number the Trainee was last on)
            currentTrainingProgramComponentIndex =
              Math.min(trainingProgramSessionState.trainingProgramStepNumber, trainingProgram.components.length) - 1;
            // Ensure we do not record a Step Number that is greater than the number of available Components (i.e., in the
            // event the number of Components was reduced below the Step Number the Trainee was last on)
            maximumTrainingProgramComponentIndex =
              Math.min(
                trainingProgramSessionState.maximumTrainingProgramStepNumber ??
                  trainingProgramSessionState.trainingProgramStepNumber,
                trainingProgram.components.length,
              ) - 1;
            maximumTrainingProgramComponentProgress =
              trainingProgramSessionState.maximumTrainingProgramStepProgress ?? undefined;

            const trainingProgramCourseIds = trainingProgram
              .components!.filter((c) => !!c.course)
              .map((c) => c.course!.id);

            courses = trainingProgramSessionState.courses
              .filter((courseState) => trainingProgramCourseIds.includes(courseState.courseId))
              .map((courseState) => {
                const courseComponent: TrainingProgramComponentSummaryDto | undefined = trainingProgram
                  .components!.filter((c) => !!c.course)
                  .find((c) => c.course!.id === courseState.courseId);

                if (!courseComponent?.course) {
                  throw Error("Course was not found.");
                }

                const courseComponentIndex = courseComponent.number - 1;

                let course: CourseSessionState = toCourseSessionState(courseComponent.course, courseState);

                // If the Course has not yet been completed or the Course has been completed but has since been revised,
                // set current Training Program position to the affected Course...
                if (!courseState.completedAt || courseState.courseVersion !== courseComponent.course.version) {
                  currentTrainingProgramComponentIndex = Math.min(
                    courseComponentIndex,
                    currentTrainingProgramComponentIndex,
                  );

                  if (courseState.courseVersion !== courseComponent.course.version) {
                    course = {
                      ...course,
                      courseVersion: courseComponent.course.version,
                      courseStateJson: JSON.stringify({}),
                    };
                  }
                }

                return course;
              });

            const trainingProgramQuizIds = trainingProgram.components!.filter((c) => !!c.quiz).map((c) => c.quiz!.id);

            quizzes = trainingProgramSessionState.quizzes
              .filter((quizState) => trainingProgramQuizIds.indexOf(quizState.quizId) !== -1)
              .map((quizState) => {
                const quizComponent: TrainingProgramComponentSummaryDto | undefined = trainingProgram
                  .components!.filter((c) => !!c.quiz)
                  .find((q) => q.quiz!.id === quizState.quizId);

                if (!quizComponent) {
                  throw Error("Quiz Form Schema was not found.");
                }

                const quizTemplate = quizComponent.quiz;

                if (!quizTemplate) {
                  throw Error("Quiz Template not found.");
                }

                const quizComponentIndex = quizComponent.number - 1;

                // If the Quiz has not yet been completed or the Quiz has been completed but has since been revised,
                // set current Training Program position to the affected Quiz...
                if (!quizState.completedAt || quizState.quizVersion !== quizTemplate.version) {
                  currentTrainingProgramComponentIndex = Math.min(
                    quizComponentIndex,
                    currentTrainingProgramComponentIndex,
                  );
                }

                return toQuizSessionState(quizTemplate, quizState);
              });
          }
        }

        const trainingProgramSession: TrainingProgramSession = {
          trainingProgramId: trainingProgram.id,
          trainingProgramName: trainingProgram.name,
          trainingProgramDescription: trainingProgram.description,
          trainingProgramDepartments: trainingProgram.departments,
          trainingProgramComponents: trainingProgram.components.sort(sortTrainingProgramComponentsByNumber),
          trainingProgramDurationMinutes: trainingProgram.durationMinutes,
          trainingProgramVersion,
          trainingProgramMajorVersion,
          currentTrainingProgramComponentIndex,
          maximumTrainingProgramComponentIndex,
          maximumTrainingProgramComponentProgress,
          remainingTrainingProgramDurationMinutes: getRemainingTrainingProgramDurationMinutes(
            trainingProgram.components,
            trainingProgram.durationMinutes,
            maximumTrainingProgramComponentIndex,
            maximumTrainingProgramComponentProgress != null
              ? Math.round(maximumTrainingProgramComponentProgress / 60.0)
              : undefined,
          ),
          beganAt,
          quizzes,
          courses,
          completionFormData,
        };

        dispatch({
          type: "TRAINING_PROGRAM_SESSION_STATE_REQUEST_SUCCESS",
          trainingProgramSession,
        });
      } catch (errorResponse) {
        const error = await getApiError(errorResponse);

        dispatch({ type: "TRAINING_PROGRAM_SESSION_STATE_REQUEST_FAILURE", error });
      }
    },

  startTrainingProgramSession:
    (trainingProgramVersion: number): AppThunkAction<KnownAction, ApplicationState> =>
    async (dispatch, getState) => {
      const { trainingProgramSession } = getState().trainingProgram;

      if (!trainingProgramSession) {
        throw Error("Training Program Session not loaded.");
      }

      const trainingProgramBeganAt = moment().toISOString();

      const addedTrainingProgramSession: TrainingProgramSession = {
        ...trainingProgramSession,
        beganAt: trainingProgramBeganAt,
        trainingProgramVersion,
        currentTrainingProgramComponentIndex: 0,
        remainingTrainingProgramDurationMinutes: getRemainingTrainingProgramDurationMinutes(
          trainingProgramSession.trainingProgramComponents,
          trainingProgramSession.trainingProgramDurationMinutes,
          0,
        ),
        courses: [],
        quizzes: [],
      };

      const addOrUpdateTrainingProgramSessionStateCommand: AddOrUpdateTrainingProgramSessionStateCommand | undefined =
        isUserAuthorized()
          ? {
              trainingProgramVersion,
              stepNumber: 1,
              trainingProgramBeganAt,
            }
          : undefined;

      requestTrainingProgramSessionAddOrUpdate(
        addedTrainingProgramSession,
        addOrUpdateTrainingProgramSessionStateCommand,
        dispatch,
        getState,
      );

      const mediaSelectorWidth = Math.min(375, window.innerWidth);
      const closeMediaSelector = (window.innerWidth - 960) / 2 < mediaSelectorWidth;

      // Automatically collapse the sidebar if on mobile
      dispatch({ type: "SIDEBAR_DISPLAY_UPDATE", open: !closeMediaSelector });
    },

  restartTrainingProgramSession:
    (trainingProgramSlug: string): AppThunkAction<KnownAction, ApplicationState> =>
    async (dispatch, getState) => {
      const { trainingPrograms, trainingProgramSessionStates } = getState().trainingProgram;

      const trainingProgram = trainingPrograms?.find((tp) => tp.slug === trainingProgramSlug);

      if (!trainingProgram) {
        throw Error("Training Program not found.");
      }

      const trainingProgramSession = trainingProgramSessionStates?.find(
        (tpss) => tpss.trainingProgramId === trainingProgram.id,
      );

      if (!trainingProgramSession) {
        throw Error("Training Program Session not loaded.");
      }

      const addOrUpdateTrainingProgramSessionStateCommand = isUserAuthorized()
        ? ({
            stepNumber: -1, // Step Number of -1 indicates Removal
            trainingProgramId: trainingProgramSession.trainingProgramId,
            trainingProgramVersion: trainingProgramSession.trainingProgramVersion,
          } as AddOrUpdateTrainingProgramSessionStateCommand)
        : undefined;

      let nextTrainingProgramSessionStates: TrainingProgramSessionStateDto[];

      if (trainingProgramSessionStates) {
        const trainingProgramSessionStateIndex = trainingProgramSessionStates.findIndex(
          (tpss) =>
            tpss.trainingProgramId === trainingProgramSession.trainingProgramId && !tpss.trainingProgramCompletedAt,
        );

        nextTrainingProgramSessionStates = [
          ...trainingProgramSessionStates.slice(0, trainingProgramSessionStateIndex),
          ...trainingProgramSessionStates.slice(trainingProgramSessionStateIndex + 1),
        ];
      } else {
        nextTrainingProgramSessionStates = [];
      }

      dispatch({
        type: "TRAINING_PROGRAM_SESSION_STATE_REMOVE_REQUEST",
        trainingProgramSessionStates: nextTrainingProgramSessionStates,
      });

      try {
        if (addOrUpdateTrainingProgramSessionStateCommand) {
          await trainingProgramSessionsApi.addOrUpdateTrainingProgramSessionState(
            trainingProgramSession.trainingProgramId,
            addOrUpdateTrainingProgramSessionStateCommand as AddOrUpdateTrainingProgramSessionStateCommand,
          );
        } else {
          setSessionTrainingProgramSessionStates(nextTrainingProgramSessionStates);
        }

        dispatch({
          type: "TRAINING_PROGRAM_SESSION_STATE_REMOVE_REQUEST_SUCCESS",
        });
      } catch (errorResponse) {
        const error = await getApiError(errorResponse);

        dispatch({ type: "TRAINING_PROGRAM_SESSION_STATE_REMOVE_REQUEST_FAILURE", error });
      }
    },

  updateTrainingProgramSessionStep:
    (stepIndex: number, stepProgressSeconds?: number): AppThunkAction<KnownAction, ApplicationState> =>
    async (dispatch, getState) => {
      const { trainingProgramSession } = getState().trainingProgram;

      if (!trainingProgramSession) {
        throw Error("Training Program Session not loaded.");
      }

      const maximumStepIndex =
        stepIndex > trainingProgramSession.maximumTrainingProgramComponentIndex
          ? stepIndex
          : trainingProgramSession.maximumTrainingProgramComponentIndex;

      const maximumStepProgressSeconds =
        stepIndex === maximumStepIndex
          ? stepProgressSeconds === undefined
            ? trainingProgramSession.maximumTrainingProgramComponentProgress
            : stepProgressSeconds
          : trainingProgramSession.maximumTrainingProgramComponentProgress;

      const remainingTrainingProgramDurationMinutes = getRemainingTrainingProgramDurationMinutes(
        trainingProgramSession.trainingProgramComponents,
        trainingProgramSession.trainingProgramDurationMinutes,
        maximumStepIndex,
        maximumStepProgressSeconds != null ? Math.round(maximumStepProgressSeconds / 60.0) : undefined,
      );

      const updatedTrainingProgramSession: TrainingProgramSession = {
        ...trainingProgramSession,
        currentTrainingProgramComponentIndex: stepIndex,
        maximumTrainingProgramComponentIndex: maximumStepIndex,
        maximumTrainingProgramComponentProgress: maximumStepProgressSeconds,
        remainingTrainingProgramDurationMinutes: remainingTrainingProgramDurationMinutes,
      };

      const addOrUpdateTrainingProgramSessionStateCommand = isUserSignedIn()
        ? ({
            stepNumber: stepIndex + 1,
            stepProgress: stepProgressSeconds,
            trainingProgramBeganAt: trainingProgramSession.beganAt!,
            trainingProgramId: trainingProgramSession.trainingProgramId,
            trainingProgramVersion: trainingProgramSession.trainingProgramVersion,
          } as AddOrUpdateTrainingProgramSessionStateCommand)
        : undefined;

      requestTrainingProgramSessionAddOrUpdate(
        updatedTrainingProgramSession,
        addOrUpdateTrainingProgramSessionStateCommand,
        dispatch,
        getState,
      );
    },

  addOrUpdateTrainingProgramSessionCourse:
    (courseSessionState: CourseSessionState): AppThunkAction<KnownAction, ApplicationState> =>
    async (dispatch, getState) => {
      const { trainingProgramSession } = getState().trainingProgram;

      if (!trainingProgramSession) {
        throw Error("Training Program Session not loaded.");
      }

      const nextTrainingProgramSessionCourses = trainingProgramSession.courses.find(
        (course) => course.courseId === courseSessionState.courseId,
      )
        ? trainingProgramSession.courses.map((course) =>
            course.courseId === courseSessionState.courseId ? courseSessionState : course,
          )
        : [...trainingProgramSession.courses, courseSessionState];

      const updatedTrainingProgramSession: TrainingProgramSession = {
        ...trainingProgramSession,
        courses: nextTrainingProgramSessionCourses,
      };

      const addOrUpdateTrainingProgramSessionCourseCommand: AddOrUpdateTrainingProgramSessionCourseCommand | undefined =
        isUserSignedIn()
          ? {
              courseId: courseSessionState.courseId,
              courseVersion: courseSessionState.courseVersion,
              courseStateJson: courseSessionState.courseStateJson,
              beganAt: courseSessionState.beganAt,
              completedAt: courseSessionState.completedAt,
            }
          : undefined;

      requestTrainingProgramSessionAddOrUpdate(
        updatedTrainingProgramSession,
        addOrUpdateTrainingProgramSessionCourseCommand,
        dispatch,
        getState,
      );
    },

  addOrUpdateTrainingProgramSessionQuiz:
    (quizSessionState: QuizSessionState): AppThunkAction<KnownAction, ApplicationState> =>
    async (dispatch, getState) => {
      const { trainingProgramSession } = getState().trainingProgram;

      if (!trainingProgramSession) {
        throw Error("Training Program Session not loaded.");
      }

      const nextTrainingProgramSessionQuizzes = trainingProgramSession.quizzes.find(
        (quiz) => quiz.quizId === quizSessionState.quizId,
      )
        ? trainingProgramSession.quizzes.map((quiz) =>
            quiz.quizId === quizSessionState.quizId ? quizSessionState : quiz,
          )
        : [...trainingProgramSession.quizzes, quizSessionState];

      const updatedTrainingProgramSession: TrainingProgramSession = {
        ...trainingProgramSession,
        quizzes: nextTrainingProgramSessionQuizzes,
      };

      const addOrUpdateTrainingProgramSessionQuizCommand: AddOrUpdateTrainingProgramSessionQuizCommand | undefined =
        isUserSignedIn()
          ? {
              quizId: quizSessionState.quizId,
              quizVersion: quizSessionState.quizVersion,
              quizQuestionAnswerValues: toQuestionAnswerValuesForCommand(quizSessionState.quizQuestionAnswerValues),
              beganAt: quizSessionState.beganAt,
              completedAt: quizSessionState.completedAt,
            }
          : undefined;

      requestTrainingProgramSessionAddOrUpdate(
        updatedTrainingProgramSession,
        addOrUpdateTrainingProgramSessionQuizCommand,
        dispatch,
        getState,
      );
    },

  updateTrainingProgramSessionCompletionForm:
    (
      trainingProgramSessionCompletionFormData: TrainingProgramSessionCompletionFormData,
    ): AppThunkAction<KnownAction, ApplicationState> =>
    async (dispatch, getState) => {
      dispatch({
        type: "TRAINING_PROGRAM_SESSION_COMPLETION_FORM_UPDATE_ACTION",
        trainingProgramSessionCompletionFormData,
      });
    },

  requestTrainingProgramSessionCompletion:
    (
      completeTrainingProgramSessionCommand: CompleteTrainingProgramSessionCommand,
    ): AppThunkAction<KnownAction, ApplicationState> =>
    async (dispatch, getState) => {
      dispatch({ type: "TRAINING_PROGRAM_SESSION_COMPLETION_REQUEST" });

      const { trainingProgramSession, trainingProgramSessionStates } = getState().trainingProgram;

      if (trainingProgramSession == null) {
        throw Error("Training Program Session not loaded.");
      }

      if (trainingProgramSessionStates == null) {
        throw Error("Training Program Session States not loaded");
      }

      try {
        let trainingProgramSessionCompletionResult: TrainingProgramSessionCompletionResultDto | undefined;

        if (isUserSignedIn()) {
          trainingProgramSessionCompletionResult =
            await trainingProgramSessionsApi.completeAuthenticatedTrainingProgramSessionResult(
              trainingProgramSession.trainingProgramId,
              completeTrainingProgramSessionCommand,
            );
        } else {
          trainingProgramSessionCompletionResult =
            await trainingProgramSessionsApi.completeTrainingProgramSessionResult(
              trainingProgramSession.trainingProgramId,
              completeTrainingProgramSessionCommand,
            );
        }

        const trainingProgramSessionNumber = trainingProgramSessionCompletionResult.number;
        const sentTrainingProgramSessionConfirmationEmail =
          trainingProgramSessionCompletionResult.sentConfirmationEmail != null
            ? trainingProgramSessionCompletionResult.sentConfirmationEmail
            : undefined;

        const completedTrainingProgramSession = {
          ...trainingProgramSession,
          trainingProgramSessionNumber: trainingProgramSessionNumber,
          sentTrainingProgramSessionConfirmationEmail: sentTrainingProgramSessionConfirmationEmail,
        };

        const trainingProgramSessionStateIndex = trainingProgramSessionStates.findIndex(
          (tpss) => tpss.trainingProgramId === trainingProgramSession.trainingProgramId,
        );

        const nextTrainingProgramSessionStates =
          trainingProgramSessionStateIndex !== -1
            ? [
                ...trainingProgramSessionStates.slice(0, trainingProgramSessionStateIndex),
                ...trainingProgramSessionStates.slice(trainingProgramSessionStateIndex + 1),
              ]
            : trainingProgramSessionStates;

        if (!isUserSignedIn()) {
          setSessionTrainingProgramSessionStates(nextTrainingProgramSessionStates);
        }

        dispatch({
          type: "TRAINING_PROGRAM_SESSION_COMPLETION_REQUEST_SUCCESS",
          trainingProgramSession: completedTrainingProgramSession,
          trainingProgramSessionStates: nextTrainingProgramSessionStates,
        });

        requestTrainingProgramSessionStates(dispatch);
      } catch (errorResponse) {
        const error = await getApiError(errorResponse);

        dispatch({ type: "TRAINING_PROGRAM_SESSION_COMPLETION_REQUEST_FAILURE", error });
      }
    },

  removeTrainingProgramSession: (): KnownAction => ({
    type: "TRAINING_PROGRAM_SESSION_REMOVE_ACTION",
  }),
};

const requestTrainingProgramSessionStates = async (dispatch: (action: KnownAction) => void) => {
  dispatch({ type: "TRAINING_PROGRAM_SESSION_STATES_REQUEST" });

  try {
    let trainingProgramSessionStates: TrainingProgramSessionStateDto[];

    if (isUserSignedIn()) {
      trainingProgramSessionStates = await trainingProgramSessionsApi.getTrainingProgramSessionStates();
      // Now that we have server-based state, we can abandon our session-based state
      setSessionTrainingProgramSessionStates(undefined);
    } else {
      trainingProgramSessionStates = getSessionTrainingProgramSessionStates() ?? [];
    }

    dispatch({
      type: "TRAINING_PROGRAM_SESSION_STATES_REQUEST_SUCCESS",
      trainingProgramSessionStates,
    });
  } catch (errorResponse) {
    const error = await getApiError(errorResponse);

    dispatch({ type: "TRAINING_PROGRAM_SESSION_STATES_REQUEST_FAILURE", error });
  }
};

const requestTrainingProgramSessionAddOrUpdate = async (
  trainingProgramSession: TrainingProgramSession,
  addOrUpdateTrainingProgramSessionCommand:
    | AddOrUpdateTrainingProgramSessionStateCommand
    | AddOrUpdateTrainingProgramSessionQuizCommand
    | AddOrUpdateTrainingProgramSessionCourseCommand
    | undefined,
  dispatch: (action: KnownAction) => void,
  getState: () => ApplicationState,
) => {
  const trainingProgramSessionStates = getState().trainingProgram.trainingProgramSessionStates;

  const nextTrainingProgramSessionState = toTrainingProgramSessionStateDto(trainingProgramSession);

  const nextTrainingProgramSessionStates = trainingProgramSessionStates
    ? trainingProgramSessionStates.find(
        (tpss) =>
          tpss.trainingProgramId === trainingProgramSession.trainingProgramId && !tpss.trainingProgramCompletedAt,
      )
      ? // IF the Training Program Session State already exists, replace it with the new one
        trainingProgramSessionStates.map((tpss) =>
          tpss.trainingProgramId === trainingProgramSession.trainingProgramId && !tpss.trainingProgramCompletedAt
            ? nextTrainingProgramSessionState
            : tpss,
        )
      : // else add the new one
        [...trainingProgramSessionStates, nextTrainingProgramSessionState]
    : [nextTrainingProgramSessionState];

  dispatch({
    type: "TRAINING_PROGRAM_SESSION_STATE_ADD_OR_UPDATE_REQUEST",
    trainingProgramSession,
    trainingProgramSessionStates: nextTrainingProgramSessionStates,
  });

  try {
    if (addOrUpdateTrainingProgramSessionCommand) {
      if ((addOrUpdateTrainingProgramSessionCommand as AddOrUpdateTrainingProgramSessionStateCommand).stepNumber) {
        await trainingProgramSessionsApi.addOrUpdateTrainingProgramSessionState(
          trainingProgramSession.trainingProgramId,
          addOrUpdateTrainingProgramSessionCommand as AddOrUpdateTrainingProgramSessionStateCommand,
        );
      } else if (
        (addOrUpdateTrainingProgramSessionCommand as AddOrUpdateTrainingProgramSessionCourseCommand).courseId
      ) {
        await trainingProgramSessionsApi.addOrUpdateTrainingProgramSessionCourse(
          trainingProgramSession.trainingProgramId,
          addOrUpdateTrainingProgramSessionCommand as AddOrUpdateTrainingProgramSessionCourseCommand,
        );
      } else if ((addOrUpdateTrainingProgramSessionCommand as AddOrUpdateTrainingProgramSessionQuizCommand).quizId) {
        await trainingProgramSessionsApi.addOrUpdateTrainingProgramSessionQuiz(
          trainingProgramSession.trainingProgramId,
          addOrUpdateTrainingProgramSessionCommand as AddOrUpdateTrainingProgramSessionQuizCommand,
        );
      } else {
        throw Error("Attempted to update Training Program Session State using an unsupported command.");
      }
    } else {
      setSessionTrainingProgramSessionStates(nextTrainingProgramSessionStates);
    }

    dispatch({
      type: "TRAINING_PROGRAM_SESSION_STATE_ADD_OR_UPDATE_REQUEST_SUCCESS",
    });
  } catch (errorResponse) {
    const error = await getApiError(errorResponse);

    dispatch({ type: "TRAINING_PROGRAM_SESSION_STATE_ADD_OR_UPDATE_REQUEST_FAILURE", error });
  }
};

export const initialState: TrainingProgramState = {
  trainingProgramFilters: {},
  actionStatus: {
    pending: false,
  },
};

export const reducer: Reducer<TrainingProgramState, KnownAction> = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case "TRAINING_PROGRAMS_REQUEST":
      state = {
        ...state,
        actionStatus: {
          type: action.type,
          pending: true,
        },
      };
      break;

    case "TRAINING_PROGRAMS_REQUEST_SUCCESS":
      state = {
        ...state,
        trainingPrograms: action.trainingPrograms,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
        },
      };
      break;

    case "TRAINING_PROGRAMS_REQUEST_FAILURE":
      state = {
        ...state,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
          error: action.error,
        },
      };
      break;

    case "TRAINING_PROGRAMS_FILTERS_UPDATE":
      state = {
        ...state,
        trainingProgramFilters: action.trainingProgramFilters,
      };
      break;

    case "TRAINING_PROGRAM_SESSION_STATES_REQUEST":
      state = {
        ...state,
        actionStatus: {
          type: action.type,
          pending: true,
        },
      };
      break;

    case "TRAINING_PROGRAM_SESSION_STATES_REQUEST_SUCCESS":
      state = {
        ...state,
        trainingProgramSessionStates: action.trainingProgramSessionStates,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
        },
      };
      break;

    case "TRAINING_PROGRAM_SESSION_STATES_REQUEST_FAILURE":
      state = {
        ...state,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
          error: action.error,
        },
      };
      break;

    case "TRAINING_PROGRAM_SESSION_STATE_REQUEST":
      state = {
        ...state,
        actionStatus: {
          type: action.type,
          pending: true,
        },
      };
      break;

    case "TRAINING_PROGRAM_SESSION_STATE_REQUEST_SUCCESS":
      state = {
        ...state,
        trainingProgramSession: action.trainingProgramSession,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
        },
      };
      break;

    case "TRAINING_PROGRAM_SESSION_STATE_REQUEST_FAILURE":
      state = {
        ...state,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
          error: action.error,
        },
      };
      break;

    case "TRAINING_PROGRAM_SESSION_STATE_ADD_OR_UPDATE_REQUEST":
      state = {
        ...state,
        trainingProgramSession: action.trainingProgramSession,
        trainingProgramSessionStates: action.trainingProgramSessionStates,
        actionStatus: {
          type: action.type,
          pending: true,
        },
      };
      break;

    case "TRAINING_PROGRAM_SESSION_STATE_ADD_OR_UPDATE_REQUEST_SUCCESS":
      state = {
        ...state,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
        },
      };
      break;

    case "TRAINING_PROGRAM_SESSION_STATE_ADD_OR_UPDATE_REQUEST_FAILURE":
      state = {
        ...state,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
          error: action.error,
        },
      };
      break;

    case "TRAINING_PROGRAM_SESSION_STATE_REMOVE_REQUEST":
      state = {
        ...state,
        trainingProgramSessionStates: action.trainingProgramSessionStates,
        actionStatus: {
          type: action.type,
          pending: true,
        },
      };
      break;

    case "TRAINING_PROGRAM_SESSION_STATE_REMOVE_REQUEST_SUCCESS":
      state = {
        ...state,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
        },
      };
      break;

    case "TRAINING_PROGRAM_SESSION_STATE_REMOVE_REQUEST_FAILURE":
      state = {
        ...state,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
          error: action.error,
        },
      };
      break;

    case "TRAINING_PROGRAM_SESSION_COMPLETION_FORM_UPDATE_ACTION":
      state = {
        ...state,
        trainingProgramSession: {
          ...state.trainingProgramSession!,
          completionFormData: action.trainingProgramSessionCompletionFormData,
        },
      };
      break;

    case "TRAINING_PROGRAM_SESSION_COMPLETION_REQUEST":
      state = {
        ...state,
        actionStatus: {
          type: action.type,
          pending: true,
        },
      };
      break;

    case "TRAINING_PROGRAM_SESSION_COMPLETION_REQUEST_SUCCESS":
      state = {
        ...state,
        trainingProgramSession: action.trainingProgramSession,
        trainingProgramSessionStates: action.trainingProgramSessionStates,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
        },
      };
      break;

    case "TRAINING_PROGRAM_SESSION_COMPLETION_REQUEST_FAILURE":
      state = {
        ...state,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
          error: action.error,
        },
      };
      break;

    case "TRAINING_PROGRAM_SESSION_REMOVE_ACTION":
      state = {
        ...state,
        trainingProgramSession: undefined,
      };
      break;

    default:
  }

  return state;
};
