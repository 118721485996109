import { ApiError, getApiError } from "@interface48/api";
import { ActionStatus, AppThunkAction } from "@interface48/app";
import { push } from "connected-react-router";
import { Reducer } from "redux";
import { Document, documentsApi, Media, mediaCategoriesApi, Presentation, Video, videosApi } from "../api";
import { ApplicationState } from "./ApplicationState";
import { SidebarDisplayUpdateAction } from "./UI";

export interface MediaState {
  mediaSelector: {
    mediaType: "all" | "document" | "video";
    mediaCategory: string;
    mediaSearchFilter: string;
  };
  mediaCategoryNames?: string[];
  media?: Media[];
  actionStatus: ActionStatus<"LOAD_MEDIA_REQUEST" | "MEDIA_CATEGORIES_REQUEST">;
}

interface ChangeMediaTypeAction {
  type: "CHANGE_MEDIA_TYPE";
  mediaType: "all" | "document" | "video";
}

interface ChangeMediaCategoryAction {
  type: "CHANGE_MEDIA_CATEGORY";
  category: string;
}

interface ChangeMediaSearchFilterAction {
  type: "CHANGE_MEDIA_SEARCH_FILTER";
  searchFilter: string;
}

interface LoadMediaAction {
  type: "LOAD_MEDIA_REQUEST";
}

interface LoadMediaSuccessAction {
  type: "LOAD_MEDIA_SUCCESS";
  media: Array<Document | Video>;
}

interface LoadMediaFailureAction {
  type: "LOAD_MEDIA_FAILURE";
  error: ApiError;
}

interface MediaCategoryNamesRequestAction {
  type: "MEDIA_CATEGORIES_REQUEST";
}

interface MediaCategoryNamesRequestSuccessAction {
  type: "MEDIA_CATEGORIES_REQUEST_SUCCESS";
  mediaCategoryNames: string[];
}

interface MediaCategoryNamesRequestFailureAction {
  type: "MEDIA_CATEGORIES_REQUEST_FAILURE";
  error: ApiError;
}

type KnownAction =
  | ChangeMediaTypeAction
  | ChangeMediaCategoryAction
  | ChangeMediaSearchFilterAction
  | LoadMediaAction
  | LoadMediaAction
  | LoadMediaSuccessAction
  | LoadMediaFailureAction
  | MediaCategoryNamesRequestAction
  | MediaCategoryNamesRequestSuccessAction
  | MediaCategoryNamesRequestFailureAction
  | SidebarDisplayUpdateAction;

export const actionCreators = {
  onMediaTypeChange: (mediaType: "all" | "document" | "video" | "presentation") => ({
    type: "CHANGE_MEDIA_TYPE",
    mediaType,
  }),

  onMediaCategoryChange: (category: string) => ({ type: "CHANGE_MEDIA_CATEGORY", category }),

  onMediaSearchFilterChange: (searchFilter: string) => ({ type: "CHANGE_MEDIA_SEARCH_FILTER", searchFilter }),

  onSelectedMediaItemChange:
    (mediaItem: Document | Presentation | Video): AppThunkAction<KnownAction, ApplicationState> =>
    (dispatch, getState) => {
      const mediaSelectorWidth = Math.min(375, window.innerWidth);
      const closeMediaSelector = (window.innerWidth - 960) / 2 < mediaSelectorWidth;

      // If a document was selected...
      if (mediaItem.type === "document") {
        dispatch(push(`/resource-browser/documents/${mediaItem.id}`) as any);
      }
      // Otherwise, if a presentation was selected...
      else if (mediaItem.type === "presentation") {
        dispatch(push(`/resource-browser/presentations/${mediaItem.id}`) as any);
      }
      // Otherwise, if a video was selected...
      else if (mediaItem.type === "video") {
        dispatch(push(`/resource-browser/videos/${mediaItem.id}`) as any);
      }
      // Otherwise, just go to the homepage...
      else {
        dispatch(push("/resource-browser") as any);
      }
      // Automatically collapse the sidebar if on mobile
      dispatch({ type: "SIDEBAR_DISPLAY_UPDATE", open: !closeMediaSelector });
    },

  loadMedia: (): AppThunkAction<KnownAction, ApplicationState> => async (dispatch, getState) => {
    dispatch({ type: "LOAD_MEDIA_REQUEST" });
    try {
      const fetchedDocuments = await documentsApi.getDocuments();

      const documents = fetchedDocuments.map((document) => {
        return { type: "document", ...document } as Document;
      });

      const fetchedVideos = await videosApi.getVideos();

      const videos = fetchedVideos.map((video) => {
        return { type: "video", ...video } as Video;
      });

      const visitorOrientationDocument = [
        {
          id: "visitor-orientation-pdf",
          type: "document",
          mediaCategoryName: "Visitor Orientation",
          name: "NBT Visitor Orientation",
          remoteFileUrl: `${document.location.origin}/documents/visitor-orientation/NBT Visitor Orientation.pdf`,
        },
      ] as Document[];

      const visitorOrientationPresentation = [
        {
          id: "visitor-orientation-presentation",
          mediaCategoryName: "Visitor Orientation",
          localFileUrl: `${document.location.origin}/presentations/visitor-orientation/presentation_html5.html`,
          name: "NBT Visitor Orientation",
          type: "presentation",
        },
      ] as Presentation[];

      const media: Media[] = documents
        .concat(videos)
        .concat(visitorOrientationDocument)
        .concat(visitorOrientationPresentation);

      dispatch({ type: "LOAD_MEDIA_SUCCESS", media });
    } catch (errorResponse) {
      const error = await getApiError(errorResponse);

      dispatch({ type: "LOAD_MEDIA_FAILURE", error });
    }
  },

  requestMediaCategoryNames: (): AppThunkAction<KnownAction, ApplicationState> => async (dispatch, getState) => {
    dispatch({ type: "MEDIA_CATEGORIES_REQUEST" });
    try {
      const mediaCategoryNames = await mediaCategoriesApi.getMediaCategoryNames();

      dispatch({
        type: "MEDIA_CATEGORIES_REQUEST_SUCCESS",
        mediaCategoryNames,
      });
    } catch (errorResponse) {
      const error = await getApiError(errorResponse);

      dispatch({ type: "MEDIA_CATEGORIES_REQUEST_FAILURE", error });
    }
  },
};

export const initialState: MediaState = {
  mediaSelector: {
    mediaType: "all",
    mediaCategory: "all",
    mediaSearchFilter: "",
  },
  actionStatus: {
    pending: false,
  },
};

export const reducer: Reducer<MediaState, KnownAction> = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case "CHANGE_MEDIA_TYPE":
      state = {
        ...state,
        mediaSelector: {
          ...state.mediaSelector,
          mediaType: action.mediaType,
        },
      };
      break;

    case "CHANGE_MEDIA_CATEGORY":
      state = {
        ...state,
        mediaSelector: {
          ...state.mediaSelector,
          mediaCategory: action.category,
        },
      };
      break;

    case "CHANGE_MEDIA_SEARCH_FILTER":
      state = {
        ...state,
        mediaSelector: {
          ...state.mediaSelector,
          mediaSearchFilter: action.searchFilter,
        },
      };
      break;

    case "LOAD_MEDIA_REQUEST":
      state = {
        ...state,
        actionStatus: {
          type: action.type,
          pending: true,
        },
      };
      break;

    case "LOAD_MEDIA_SUCCESS":
      state = {
        ...state,
        media: action.media,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
        },
      };
      break;

    case "LOAD_MEDIA_FAILURE":
      state = {
        ...state,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
          error: action.error,
        },
      };
      break;
    case "MEDIA_CATEGORIES_REQUEST":
      state = {
        ...state,
        actionStatus: {
          type: action.type,
          pending: true,
        },
      };
      break;

    case "MEDIA_CATEGORIES_REQUEST_SUCCESS":
      state = {
        ...state,
        mediaCategoryNames: action.mediaCategoryNames,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
        },
      };
      break;

    case "MEDIA_CATEGORIES_REQUEST_FAILURE":
      state = {
        ...state,
        actionStatus: {
          ...state.actionStatus,
          pending: false,
          error: action.error,
        },
      };
      break;

    default:
  }

  return state;
};
