import { ReadOnlyField } from "@interface48/forms";
import Button from "@material-ui/core/Button";
import MobileStepper from "@material-ui/core/MobileStepper";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import AssignmentIcon from "@material-ui/icons/Assignment";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HelpIcon from "@material-ui/icons/Help";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import MovieIcon from "@material-ui/icons/Movie";
import NoteIcon from "@material-ui/icons/Note";
import classNames from "classnames";
import React from "react";
import { StepItem } from "../../shared";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      zIndex: 1,
      boxShadow: theme.shadows[2],
      backgroundColor: theme.palette.background.paper,
    },
    mobileStepperRoot: {
      minHeight: "48px",
      padding: `0 ${theme.spacing(1)}px`,
      borderBottom: `solid 1px ${theme.palette.divider}`,
      backgroundColor: theme.palette.background.paper,
    },
    stepDetails: {
      display: "flex",
      alignItems: "center",
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px 0`,
      // HACK: Set Step Number container width to 100px
      "& > div:first-child": {
        width: 100,
      },
    },
    stepDetailsField: {
      display: "flex",
      alignItems: "center",
    },
    stepNumber: {
      marginTop: 12,
      marginRight: theme.spacing(2),
    },
    currentStepNumber: {
      marginRight: 4,
      width: 28,
      height: 28,
      borderRadius: 14,
      lineHeight: "24px",
      textAlign: "center",
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    stepTypeIcon: {
      marginRight: 4,
      display: "flex",
    },
    stepDescription: {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    tooltip: {
      cursor: "help",
    },
  });

interface TrainingProgramSessionViewerStepperProps extends WithStyles<typeof styles> {
  stepItems: StepItem[];
  stepIndex: number;
  isPreviousStepDisabled: boolean;
  isNextStepDisabled: boolean;
  onStepChange: (stepIndex: number) => void;
}

interface TrainingProgramSessionViewerStepperState {}

export const TrainingProgramSessionViewerStepperComponent = class extends React.Component<
  TrainingProgramSessionViewerStepperProps,
  TrainingProgramSessionViewerStepperState
> {
  constructor(props: TrainingProgramSessionViewerStepperProps) {
    super(props);

    this.state = {};
  }

  public render() {
    const { stepItems, stepIndex, isPreviousStepDisabled, isNextStepDisabled, classes } = this.props;

    const stepItem = stepItems[stepIndex];

    const backButton = (
      <Button
        variant="contained"
        size="small"
        color="primary"
        onClick={this.handleBackOperation}
        disabled={isPreviousStepDisabled || stepIndex === 0}
      >
        <KeyboardArrowLeft />
        Back&nbsp;&nbsp;
      </Button>
    );

    const nextButton = (
      <Button
        variant="contained"
        size="small"
        color="primary"
        onClick={this.handleNextOperation}
        disabled={isNextStepDisabled || stepIndex === stepItems.length - 1}
      >
        &nbsp;&nbsp;Next
        <KeyboardArrowRight />
      </Button>
    );

    return (
      <div id="training-program-stepper" className={classes.root}>
        <MobileStepper
          variant="progress"
          position="static"
          classes={{ root: classes.mobileStepperRoot }}
          steps={stepItems.length}
          activeStep={stepIndex}
          backButton={backButton}
          nextButton={
            isNextStepDisabled ? (
              <Tooltip
                title="You must complete the current step before you can advance to the next step."
                aria-label="Next Step Disabled"
                className={classes.tooltip}
              >
                <div>{nextButton}</div>
              </Tooltip>
            ) : (
              nextButton
            )
          }
        />
        <div className={classes.stepDetails}>
          <ReadOnlyField
            label="Step"
            margin="none"
            className={classNames(classes.stepDetailsField, classes.stepNumber)}
          >
            <span className={classes.currentStepNumber}>{stepIndex + 1}</span>
            <span>of {stepItems.length}</span>
          </ReadOnlyField>
          <ReadOnlyField label="Step Description" margin="none" className={classes.stepDetailsField}>
            <div className={classes.stepTypeIcon}>
              {stepItem.type === "document" ? (
                <NoteIcon />
              ) : stepItem.type === "video" ? (
                <MovieIcon />
              ) : stepItem.type === "quiz" ? (
                <AssignmentIcon />
              ) : stepItem.type === "completion" ? (
                <CheckCircleIcon />
              ) : (
                <HelpIcon />
              )}
            </div>
            <div className={classes.stepDescription}>{stepItem.description}</div>
          </ReadOnlyField>
        </div>
      </div>
    );
  }

  private handleNextOperation = () => {
    const { stepItems, stepIndex, onStepChange } = this.props;
    // If there are still more operations to animate, advance to the next operation, but ensure we break-out of
    // playing mode if we were in it (i.e. since the user has manually interrupted playback)...
    if (stepIndex < stepItems.length - 1) {
      onStepChange(stepIndex + 1);
    }
  };

  private handleBackOperation = () => {
    const { stepIndex, onStepChange } = this.props;
    // If we are not yet all the way back to the first step, go back to the previous operation, but ensure we break-out
    // of playing mode if we were in it (i.e. since the user has manually interrupted playback)...
    if (onStepChange && stepIndex > 0) {
      onStepChange(stepIndex - 1);
    }
  };
};

export const TrainingProgramSessionViewerStepper = withStyles(styles)(TrainingProgramSessionViewerStepperComponent);
