import Chip from "@material-ui/core/Chip";
import green from "@material-ui/core/colors/green";
import orange from "@material-ui/core/colors/orange";
import red from "@material-ui/core/colors/red";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import React from "react";
import { QuizResultDto, TrainingProgramSessionQuizResultDto } from "../../../api";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "flex-start",
      flexDirection: "column",
    },
    result: {
      display: "flex",
      alignItems: "flex-start",
      flexDirection: "row",
    },
    resultDescription: {
      paddingTop: theme.spacing(1),
    },
    resultSubDescription: {
      marginTop: theme.spacing(1),
      color: theme.palette.text.secondary,
    },
    chip: {
      margin: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
      marginLeft: 0,
      color: theme.palette.common.white,
    },
    chipObsolete: {
      backgroundColor: orange[500],
    },
    chipPassed: {
      backgroundColor: green[500],
    },
    chipFailed: {
      backgroundColor: red[500],
    },
  });

interface QuizResultSummaryProps extends WithStyles<typeof styles> {
  quizResult: QuizResultDto | TrainingProgramSessionQuizResultDto;
  showIncorrectQuestionNumbersMessage?: boolean;
}

export const QuizResultSummaryComponent = (props: QuizResultSummaryProps) => {
  const { quizResult, showIncorrectQuestionNumbersMessage, classes } = props;
  const { quizName, scorePercentage, minimumPassScorePercentage, incorrectQuestionNumbers, isPassResult } = quizResult;

  const isObsoleteResult =
    (quizResult as QuizResultDto).isObsoleteResult != null ? (quizResult as QuizResultDto).isObsoleteResult : false;

  let chipLabel: string;
  let chipStyleClassName: string;

  if (isObsoleteResult) {
    chipLabel = "Obsolete";
    chipStyleClassName = classes.chipObsolete;
  } else if (isPassResult) {
    chipLabel = "Passed";
    chipStyleClassName = classes.chipPassed;
  } else {
    chipLabel = "Failed";
    chipStyleClassName = classes.chipFailed;
  }

  const incorrectQuestionNumbersMessage =
    showIncorrectQuestionNumbersMessage && incorrectQuestionNumbers && incorrectQuestionNumbers.length
      ? `Incorrectly Answered Question(s): ${incorrectQuestionNumbers.join(", ")}.`
      : undefined;

  return (
    <div className={classes.root}>
      <div className={classes.result}>
        <Chip label={chipLabel} className={classNames(classes.chip, chipStyleClassName)} />
        <div>
          <Typography component="div" className={classes.resultDescription}>
            <Typography>{quizName} </Typography>
            {isObsoleteResult ? (
              <Typography variant="caption">
                You must re-take this Quiz, as it has changed since you completed it.
              </Typography>
            ) : (
              <Typography variant="caption">
                {Math.round(scorePercentage)}% (Required Minimum {minimumPassScorePercentage}%)
              </Typography>
            )}
          </Typography>
          {!isObsoleteResult && !isPassResult && incorrectQuestionNumbersMessage ? (
            <Typography component="div" className={classes.resultSubDescription}>
              <Typography>{incorrectQuestionNumbersMessage}</Typography>
              <Typography>
                Please review your Answers to these Questions&mdash;you must achieve an overall score that exceeds the
                required minimum before you may continue with the rest of the Training Program.
              </Typography>
            </Typography>
          ) : undefined}
        </div>
      </div>
    </div>
  );
};

export const QuizResultSummary = withStyles(styles)(QuizResultSummaryComponent);
