/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddOrUpdateTrainingProgramDepartmentCommand
 */
export interface AddOrUpdateTrainingProgramDepartmentCommand {
    /**
     * 
     * @type {number}
     * @memberof AddOrUpdateTrainingProgramDepartmentCommand
     */
    department: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof AddOrUpdateTrainingProgramDepartmentCommand
     */
    positions?: Array<number> | null;
}

export function AddOrUpdateTrainingProgramDepartmentCommandFromJSON(json: any): AddOrUpdateTrainingProgramDepartmentCommand {
    return {
        'department': json['department'],
        'positions': !exists(json, 'positions') ? undefined : json['positions'],
    };
}

export function AddOrUpdateTrainingProgramDepartmentCommandToJSON(value?: AddOrUpdateTrainingProgramDepartmentCommand | null): any {
    if (value == undefined) {
        return undefined;
    }
    return {
        'department': value.department,
        'positions': value.positions,
    };
}


