import { AppPageFrame } from "@interface48/app";
import Button from "@material-ui/core/Button";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { DocumentsTable } from "../../../components/documents";
import { actionCreators, AdministrationActions, AdministrationState, ApplicationState } from "../../../store";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      margin: theme.spacing(2),
    },
    button: {
      marginLeft: "auto",
      marginBottom: theme.spacing(2),
    },
  });

interface DocumentsIndexPageStateProps {
  administrationState: AdministrationState;
}

interface DocumentsIndexPageDispatchProps {
  administrationActions: AdministrationActions;
}

interface DocumentsIndexPageOwnProps extends RouteComponentProps<any>, WithStyles<typeof styles> {}

type DocumentsIndexPageProps = DocumentsIndexPageStateProps &
  DocumentsIndexPageDispatchProps &
  DocumentsIndexPageOwnProps;

const DocumentsIndexPageComponent = class extends React.Component<DocumentsIndexPageProps> {
  public componentDidMount() {
    const { administrationActions } = this.props;

    administrationActions.requestDocuments();
  }

  public render() {
    const { administrationState, classes } = this.props;
    const { documents, actionStatus } = administrationState;

    const windowWidth = window.innerWidth;
    const isTableLoading =
      actionStatus && actionStatus.type === "ADMINISTRATION_DOCUMENTS_REQUEST" && actionStatus.pending;

    return (
      <AppPageFrame pageTitle="Documents" fullWidth={true} onBackClick={this.handleBackClick}>
        <div className={classes.root}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            component={Link}
            to="/administration/documents/add"
          >
            Add Document
          </Button>
          <DocumentsTable results={documents ?? []} width={windowWidth} isLoading={isTableLoading} />
        </div>
      </AppPageFrame>
    );
  }

  private handleBackClick = () => {
    this.props.history.push("/administration");
  };
};

export const DocumentsIndexPage = withStyles(styles)(
  connect<DocumentsIndexPageStateProps, DocumentsIndexPageDispatchProps, DocumentsIndexPageOwnProps, ApplicationState>(
    (state) => ({
      administrationState: state.administration,
    }),
    (dispatch) => ({
      administrationActions: bindActionCreators(actionCreators.administration, dispatch),
    }),
  )(DocumentsIndexPageComponent),
);
