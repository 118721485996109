import { Table, TableBodyRowButton } from "@interface48/tables";
import React, { useState } from "react";
import { TrainingProgramDto } from "../../../api";

interface TrainingProgramsTableProps {
  results?: TrainingProgramDto[];
  width: number;
  height?: number;
  isLoading: boolean;
}

interface TrainingProgramsTableState {
  sortDirection: "asc" | "desc";
  sortPropertyName: string;
}

export const TrainingProgramsTableComponent = (props: TrainingProgramsTableProps) => {
  const { results = [], width, height, isLoading } = props;
  const [tableState, setTableState] = useState<TrainingProgramsTableState>({
    sortDirection: "asc",
    sortPropertyName: "name",
  });

  const viewDetailsButtonRenderer = (id: string, row: TrainingProgramDto) => (
    <TableBodyRowButton
      icon={["fas", "info"]}
      title="View Details"
      linkUrl={`/administration/training-programs/${row.id}`}
    />
  );

  const emailRecipientsRenderer = (emailRecipients: string[] | undefined, row: TrainingProgramDto) =>
    emailRecipients ? emailRecipients.join(", ") : "";

  const handleSortChange = (sortPropertyName: string, sortDirection: "asc" | "desc") => {
    setTableState({ sortDirection, sortPropertyName });
  };

  return (
    <Table
      columnSchemas={[
        {
          id: "id",
          width: 40,
          cellRenderer: viewDetailsButtonRenderer,
        },
        {
          id: "name",
          label: "Name",
        },
        { id: "description", label: "Description", hidden: width < 600 },
        { id: "durationMinutes", label: "Duration Minutes", hidden: width < 1000 },
        {
          id: "emailRecipients",
          label: "Email Recipients",
          cellRenderer: emailRecipientsRenderer,
          hidden: width < 1400,
        },
        {
          id: "lastModifiedAt",
          label: "Last Modified",
          width: 140,
          format: { type: "date-time", options: { reference: "relative" } },
        },
      ]}
      data={results}
      dataSourceType="local"
      sorting={{
        sortDirection: tableState.sortDirection,
        sortPropertyName: tableState.sortPropertyName,
        onSortChange: handleSortChange,
      }}
      height={height}
      isLoading={isLoading}
    />
  );
};

export const TrainingProgramsTable = TrainingProgramsTableComponent;
