/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QueryResultsPagingConfiguration
 */
export interface QueryResultsPagingConfiguration {
    /**
     * 
     * @type {number}
     * @memberof QueryResultsPagingConfiguration
     */
    pageIndex: number;
    /**
     * 
     * @type {number}
     * @memberof QueryResultsPagingConfiguration
     */
    pageSize: number;
}

export function QueryResultsPagingConfigurationFromJSON(json: any): QueryResultsPagingConfiguration {
    return {
        'pageIndex': json['pageIndex'],
        'pageSize': json['pageSize'],
    };
}

export function QueryResultsPagingConfigurationToJSON(value?: QueryResultsPagingConfiguration | null): any {
    if (value == undefined) {
        return undefined;
    }
    return {
        'pageIndex': value.pageIndex,
        'pageSize': value.pageSize,
    };
}


