import { FieldProps } from "@interface48/forms";
import Chip from "@material-ui/core/Chip";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";

const styles = (theme: Theme) =>
  createStyles({
    label: {
      color: theme.palette.text.secondary,
      fontSize: "75%",
    },
    emptyValue: {
      marginTop: theme.spacing(1),
    },
    department: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    departmentNumber: {
      marginRight: theme.spacing(2),
    },
    positions: {
      paddingTop: theme.spacing(0.5),
      "& .MuiChip-root": {
        marginRight: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
      },
    },
  });

interface TrainingProgramDepartmentsReadOnlyFieldOptions {
  emptyValue?: string;
}

interface TrainingProgramDepartmentsReadOnlyFieldProps
  extends FieldProps<Array<{ departmentName: string; positionNames: string[] }>>,
    WithStyles<typeof styles> {}

export const TrainingProgramDepartmentsReadOnlyFieldComponent = (
  props: TrainingProgramDepartmentsReadOnlyFieldProps,
) => {
  const { schema, uiSchema, formData, classes } = props;

  const { title } = schema;

  const emptyValue =
    ((uiSchema["ui:options"] ?? {}) as TrainingProgramDepartmentsReadOnlyFieldOptions).emptyValue ?? "(None)";

  return (
    <>
      <Typography className={classes.label}>{title}</Typography>
      {formData.length === 0 ? (
        <Typography variant="body1" color="textPrimary" className={classes.emptyValue}>
          {emptyValue}
        </Typography>
      ) : (
        formData.map(({ departmentName, positionNames }, departmentIndex) => {
          const departmentNumber = departmentIndex + 1;

          return (
            <div key={departmentIndex} className={classes.department}>
              <div className={classes.departmentNumber}>{`${departmentNumber}.`}</div>
              <div>
                {departmentName}
                <div className={classes.positions}>
                  {positionNames.sort().map((positionName, positionIndex) => (
                    <Chip key={positionIndex} size="small" label={positionName} />
                  ))}
                </div>
              </div>
            </div>
          );
        })
      )}
    </>
  );
};

export const TrainingProgramDepartmentsReadOnlyField = withStyles(styles)(
  TrainingProgramDepartmentsReadOnlyFieldComponent,
);
