import { OptionItem } from "@interface48/api";
import { Select, SelectValue } from "@interface48/form-controls";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import React from "react";

interface SelectFieldProps<T = string | number> {
  id: string;
  className?: string;
  label: string;
  value?: T;
  fullWidth?: boolean;
  disabled?: boolean;
  options: Array<OptionItem<T>>;
  onChange: (value: T | null | undefined) => void;
}

export const SelectField = <T extends string | number>(props: SelectFieldProps<T>) => {
  const { id, className, label, value, fullWidth = true, disabled = false, options, onChange } = props;

  const handleChange = (selectValue: SelectValue<T>) => {
    onChange(selectValue as T | null | undefined);
  };

  return (
    <FormControl fullWidth={fullWidth} className={className}>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <Select id={id} disabled={disabled} value={value} required={true} onChange={handleChange} options={options} />
    </FormControl>
  );
};
