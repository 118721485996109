import { getDateTimeFormat } from "@interface48/formatting";
import { Table, TableBodyRowButton, TablePagingProps, TableSortingProps } from "@interface48/tables";
import React from "react";
import { TrainingProgramSessionResultDto } from "../../../api";
import { TrainingProgramSessionResultSummary } from "../results";
import { TrainingProgramDetails, TrainingProgramSessionUserDetails } from "../shared";

interface TrainingProgramSessionsTableProps {
  results?: TrainingProgramSessionResultDto[];
  sorting: TableSortingProps;
  paging: TablePagingProps;
  width: number;
  height?: number;
  isLoading: boolean;
  onReviseTrainingProgramSession: (trainingProgramSession: TrainingProgramSessionResultDto) => () => void;
}

export const TrainingProgramSessionsTableComponent = (props: TrainingProgramSessionsTableProps) => {
  const { results = [], sorting, paging, width, height, isLoading, onReviseTrainingProgramSession } = props;

  const editDetailsButtonRenderer = (id: string, row: TrainingProgramSessionResultDto) => (
    <TableBodyRowButton
      icon={["fas", "edit"]}
      title="Revise Department/Position"
      onClick={onReviseTrainingProgramSession(row)}
    />
  );

  const trainingProgramRenderer = (trainingProgramName: string, row: TrainingProgramSessionResultDto) => {
    return <TrainingProgramDetails name={trainingProgramName} majorVersion={row.trainingProgramMajorVersion} />;
  };

  const trainingProgramSessionResultsRenderer = (results: any, row: TrainingProgramSessionResultDto) => {
    return <TrainingProgramSessionResultSummary courses={row.courses} quizzes={row.quizzes} />;
  };

  const trainingProgramSessionUserDetailsRenderer = (userName: string, row: TrainingProgramSessionResultDto) => {
    return (
      <TrainingProgramSessionUserDetails
        userName={userName}
        companyName={row.companyName}
        idBadgeType={row.idBadgeType}
        idBadgeNumber={row.idBadgeNumber}
        userEmailAddress={row.userEmailAddress}
        userWorkNumber={row.userWorkNumber}
      />
    );
  };

  const currentDateTimeFormat = getDateTimeFormat();
  const dateTimeFormatTemplate = `${currentDateTimeFormat.date} HH:mm:ss`;

  return (
    <Table
      columnSchemas={[
        {
          id: "id",
          width: 40,
          cellRenderer: editDetailsButtonRenderer,
        },
        {
          id: "number",
          label: "Number",
          width: 40,
        },
        {
          id: "userName",
          label: "Trainee",
          width: 325,
          cellRenderer: trainingProgramSessionUserDetailsRenderer,
        },
        {
          id: "supervisorName",
          label: "Supervisor",
          width: 325,
        },
        {
          id: "departmentName",
          label: "Department",
          width: 140,
        },
        {
          id: "positionName",
          label: "Position",
          width: 140,
        },
        {
          id: "trainingProgramName",
          label: "Training Program",
          width: 325,
          cellRenderer: trainingProgramRenderer,
        },
        {
          id: "results",
          label: "Result(s)",
          hidden: width < 1200,
          cellRenderer: trainingProgramSessionResultsRenderer,
        },
        {
          id: "trainingProgramBeganAt",
          label: "Began At",
          width: 140,
          format: { type: "date-time", options: { template: dateTimeFormatTemplate } },
          hidden: width < 1400,
        },
        {
          id: "trainingProgramCompletedAt",
          label: "Completed At",
          width: 140,
          format: { type: "date-time", options: { template: dateTimeFormatTemplate } },
          hidden: width < 1200,
        },
      ]}
      data={results}
      dataSourceType="remote"
      sorting={sorting}
      paging={paging}
      height={height}
      isLoading={isLoading}
    />
  );
};

export const TrainingProgramSessionsTable = TrainingProgramSessionsTableComponent;
