/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RemoveVideoCommand
 */
export interface RemoveVideoCommand {
    /**
     * 
     * @type {boolean}
     * @memberof RemoveVideoCommand
     */
    permanentlyDeleteFile: boolean;
}

export function RemoveVideoCommandFromJSON(json: any): RemoveVideoCommand {
    return {
        'permanentlyDeleteFile': json['permanentlyDeleteFile'],
    };
}

export function RemoveVideoCommandToJSON(value?: RemoveVideoCommand | null): any {
    if (value == undefined) {
        return undefined;
    }
    return {
        'permanentlyDeleteFile': value.permanentlyDeleteFile,
    };
}


