import { Typography } from "@material-ui/core";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import React from "react";
import {
  CourseResultDto,
  QuizResultDto,
  TrainingProgramSessionCourseResultDto,
  TrainingProgramSessionQuizResultDto,
} from "../../../api";
import { CourseResultSummary } from "../../courses";
import { QuizResultSummary } from "../../quizzes";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "flex-start",
      flexDirection: "column",
      "& $label:first-child": {
        marginTop: 0,
      },
    },
    label: {
      margin: `${theme.spacing(2)}px 0 ${theme.spacing(1)}px`,
      color: theme.palette.text.secondary,
      fontSize: "75%",
    },
  });

interface TrainingProgramSessionResultSummaryProps extends WithStyles<typeof styles> {
  courses: CourseResultDto[] | TrainingProgramSessionCourseResultDto[];
  quizzes: QuizResultDto[] | TrainingProgramSessionQuizResultDto[];
  showIncorrectQuestionNumbersMessage?: boolean;
}

export const TrainingProgramSessionResultSummaryComponent = (props: TrainingProgramSessionResultSummaryProps) => {
  const { quizzes, courses, showIncorrectQuestionNumbersMessage, classes } = props;

  return (
    <div className={classes.root}>
      {courses.length > 0 && (
        <>
          <Typography className={classes.label}>Courses</Typography>
          {courses.map((courseResult: CourseResultDto | TrainingProgramSessionCourseResultDto, index: number) => (
            <CourseResultSummary key={index} courseResult={courseResult} />
          ))}
        </>
      )}
      {quizzes.length > 0 && (
        <>
          <Typography className={classes.label}>Quizzes</Typography>
          {quizzes.map((quizResult: QuizResultDto | TrainingProgramSessionQuizResultDto, index: number) => (
            <QuizResultSummary
              key={index}
              quizResult={quizResult}
              showIncorrectQuestionNumbersMessage={showIncorrectQuestionNumbersMessage}
            />
          ))}
        </>
      )}
    </div>
  );
};

export const TrainingProgramSessionResultSummary = withStyles(styles)(TrainingProgramSessionResultSummaryComponent);
