import Grid from "@material-ui/core/Grid";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { AdminCard } from "../../components/administration";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(2),
    },
    title: {
      margin: `${theme.spacing(2)}px 0 ${theme.spacing(1)}px`,
    },
  });

interface AdminIndexPageProps extends WithStyles<typeof styles> {}

export const AdminIndexPageComponent = (props: AdminIndexPageProps) => {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <Typography variant="h4" className={classes.title}>
        Manage
      </Typography>
      <Grid container={true} spacing={2}>
        <Grid item={true} lg={3} md={4} sm={6} xs={12}>
          <AdminCard title="Courses" description="Add, edit, and remove Courses." url="/administration/courses" />
        </Grid>
        <Grid item={true} lg={3} md={4} sm={6} xs={12}>
          <AdminCard title="Documents" description="Add, edit, and remove Documents." url="/administration/documents" />
        </Grid>
        <Grid item={true} lg={3} md={4} sm={6} xs={12}>
          <AdminCard title="Videos" description="Add, edit, and remove Videos." url="/administration/videos" />
        </Grid>
        <Grid item={true} lg={3} md={4} sm={6} xs={12}>
          <AdminCard title="Quizzes" description="Add, edit, and remove Quizzes." url="/administration/quizzes" />
        </Grid>
        <Grid item={true} lg={3} md={4} sm={6} xs={12}>
          <AdminCard
            title="Training Programs"
            description="Add, edit, and remove Training Programs."
            url="/administration/training-programs"
          />
        </Grid>
        <Grid item={true} lg={3} md={4} sm={6} xs={12}>
          <AdminCard title="User Groups" description="Edit User Groups." url="/administration/user-groups" />
        </Grid>
      </Grid>
    </div>
  );
};

export const AdminIndexPage = withStyles(styles)(AdminIndexPageComponent);
