/* eslint-disable */
/**
 * Neptune TP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TrainingProgramSessionCourseStateDto
 */
export interface TrainingProgramSessionCourseStateDto {
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramSessionCourseStateDto
     */
    courseId: string;
    /**
     * 
     * @type {number}
     * @memberof TrainingProgramSessionCourseStateDto
     */
    courseVersion: number;
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramSessionCourseStateDto
     */
    courseStateJson: string;
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramSessionCourseStateDto
     */
    beganAt: string;
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramSessionCourseStateDto
     */
    completedAt?: string | null;
}

export function TrainingProgramSessionCourseStateDtoFromJSON(json: any): TrainingProgramSessionCourseStateDto {
    return {
        'courseId': json['courseId'],
        'courseVersion': json['courseVersion'],
        'courseStateJson': json['courseStateJson'],
        'beganAt': json['beganAt'],
        'completedAt': !exists(json, 'completedAt') ? undefined : json['completedAt'],
    };
}

export function TrainingProgramSessionCourseStateDtoToJSON(value?: TrainingProgramSessionCourseStateDto | null): any {
    if (value == undefined) {
        return undefined;
    }
    return {
        'courseId': value.courseId,
        'courseVersion': value.courseVersion,
        'courseStateJson': value.courseStateJson,
        'beganAt': value.beganAt,
        'completedAt': value.completedAt,
    };
}


