import { AppActions, AppPageFrame, Loader, baseActionCreators } from "@interface48/app";
import { Theme, WithStyles, createStyles, withStyles } from "@material-ui/core/styles";
import cloneDeep from "lodash/cloneDeep";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { bindActionCreators } from "redux";
import { EntityDeleteDialog } from "../../../components/shared";
import {
  VideoForm,
  VideoFormData,
  toAddVideoCommand,
  toUpdateVideoCommand,
  toVideoFormData,
} from "../../../components/videos";
import { AdministrationActions, AdministrationState, ApplicationState, actionCreators } from "../../../store";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
    },
    form: {
      backgroundColor: theme.palette.background.default,
    },
  });

interface VideoPageMatchParamsProps {
  videoId: string;
}

interface VideoPageStateProps {
  administrationState: AdministrationState;
}

interface VideoPageDispatchProps {
  appActions: AppActions;
  administrationActions: AdministrationActions;
}

interface VideoPageOwnProps extends RouteComponentProps<VideoPageMatchParamsProps>, WithStyles<typeof styles> {}

type VideoPageProps = VideoPageStateProps & VideoPageDispatchProps & VideoPageOwnProps;

interface VideoPageState {
  formMode: "read" | "write";
  formData: VideoFormData;
  initialFormData: VideoFormData;
  isDeleteConfirmationModalOpen: boolean;
}

export const VideoPageComponent = class extends React.Component<VideoPageProps, VideoPageState> {
  constructor(props: VideoPageProps) {
    super(props);

    const formMode = this.getVideoId() ? "read" : "write";
    const formData = toVideoFormData();

    this.state = {
      formMode,
      formData,
      initialFormData: cloneDeep(formData),
      isDeleteConfirmationModalOpen: false,
    };
  }

  public componentDidMount() {
    const { administrationActions } = this.props;

    const videoId = this.getVideoId();

    if (videoId) {
      administrationActions.requestVideo(videoId);
    }
  }

  public render() {
    const { administrationState, classes } = this.props;
    const { video, actionStatus } = administrationState;
    const { formMode, formData, isDeleteConfirmationModalOpen } = this.state;

    const formKey = `form_${formMode}`;
    const videoId = this.getVideoId();
    const isVideoLoading = !video && actionStatus.type === "ADMINISTRATION_VIDEO_REQUEST" && actionStatus.pending;
    const isVideoSaving =
      (actionStatus.type === "ADMINISTRATION_VIDEO_ADD_REQUEST" ||
        actionStatus.type === "ADMINISTRATION_VIDEO_UPDATE_REQUEST") &&
      actionStatus.pending;
    const isVideoFormLoading = videoId && (isVideoLoading || !formData.id);

    return (
      <AppPageFrame
        pageTitle={
          !videoId
            ? "Add Video"
            : isVideoFormLoading
            ? "Loading Video..."
            : `${formMode === "write" ? "Edit " : ""}Video - ${video!.name}`
        }
        onBackClick={this.handleBackClick}
      >
        <div className={classes.container}>
          {isVideoFormLoading ? (
            <Loader loadingText={"Loading Video"} />
          ) : (
            <>
              <VideoForm
                key={formKey}
                className={classes.form}
                formMode={formMode}
                formData={formData}
                isFormSubmitting={isVideoSaving}
                onEdit={this.handleEdit}
                onChange={this.handleChange}
                onSubmit={this.handleSubmit}
                onCancel={this.handleCancel}
                onClose={this.handleClose}
                onDelete={this.handleDelete}
                onError={this.handleError}
              />
              {videoId && formData.name && (
                // !formData.deleted.at &&
                <EntityDeleteDialog
                  entityId={videoId}
                  entityName={formData.name}
                  entityDeleteOption={"Delete the Video permanently?"}
                  isOpen={isDeleteConfirmationModalOpen}
                  isActionPending={actionStatus.pending}
                  onCancel={this.handleDeleteCancel}
                  onDelete={this.handleDeleteConfirm}
                />
              )}
            </>
          )}
        </div>
      </AppPageFrame>
    );
  }

  public componentDidUpdate(prevProps: VideoPageProps, prevState: VideoPageState) {
    const { administrationState: prevAdministrationState } = prevProps;
    const { formMode: prevFormMode } = prevState;
    const { administrationState, appActions, administrationActions, history } = this.props;
    const { video } = administrationState;
    const { formMode } = this.state;

    const videoId = this.getVideoId();

    const prevActionStatus = prevAdministrationState.actionStatus;
    const actionStatus = administrationState.actionStatus;

    // If some Video action just completed...
    if (prevActionStatus.pending && !actionStatus.pending) {
      // If an error occurred, display the message...
      if (actionStatus.error) {
        const errorMessage =
          actionStatus.error.message || "An unexpected error occurred upon attempting to process  the last operation.";

        appActions.postSnackbarMessage(errorMessage, { variant: "error" });
      }
      // Otherwise, the operation completed successfully...
      else if (video) {
        // If we fetched the Details for this Video...
        if (actionStatus.type === "ADMINISTRATION_VIDEO_REQUEST") {
          const formData = toVideoFormData(video);

          this.setState({ ...this.state, formMode: "read", formData });
          // If we loaded the details for a Video that was just added via /add, replace the URL with it's
          // ID-specific one...
          if (!videoId) {
            history.replace(`/administration/videos/${formData.id}`);
          }
        }
        // Otherwise if we added or updated a Video, fetch the latest Details...
        else if (
          actionStatus.type === "ADMINISTRATION_VIDEO_ADD_REQUEST" ||
          actionStatus.type === "ADMINISTRATION_VIDEO_UPDATE_REQUEST"
        ) {
          administrationActions.requestVideo(video.id);
        }
        // Otherwise, if we removed a Video...
        else if (actionStatus.type === "ADMINISTRATION_VIDEO_DELETE_REQUEST") {
          history.push("/administration/videos");
        }
      }
    }

    // If the Form Mode just changed, scroll to the top of the page...
    if (prevFormMode !== formMode) {
      const appFrame = document.getElementById("app-frame");
      if (appFrame) {
        appFrame.scrollTop = 0;
      }
    }
  }

  private handleBackClick = () => {
    this.props.history.goBack();
  };

  private handleClose = () => {
    this.props.history.goBack();
  };

  private handleEdit = () => {
    this.setState({ ...this.state, formMode: "write" });
  };

  private handleCancel = (initialFormData: VideoFormData) => {
    const { history } = this.props;

    if (!this.getVideoId()) {
      history.push("/administration/videos");
    } else {
      this.setState({ ...this.state, formMode: "read", formData: initialFormData });
    }
  };

  private handleChange = (formData: VideoFormData) => {
    this.setState({ ...this.state, formData });
  };

  private handleSubmit = (formData: VideoFormData) => {
    const { administrationActions } = this.props;

    // If adding a new Video...
    if (!this.getVideoId()) {
      const addVideoCommand = toAddVideoCommand(formData);

      administrationActions.requestVideoAdd(addVideoCommand);
    }
    // Otherwise, if updating a Video...
    else {
      if (!formData.id) {
        throw Error("Video Id is undefined upon request to update.");
      }

      const updateVideoCommand = toUpdateVideoCommand(formData);
      administrationActions.requestVideoUpdate(formData.id, updateVideoCommand);
    }
  };

  private handleDelete = () => {
    this.setState({ ...this.state, isDeleteConfirmationModalOpen: true });
  };

  private handleDeleteCancel = () => {
    this.setState({ ...this.state, isDeleteConfirmationModalOpen: false });
  };

  private handleDeleteConfirm = (videoId: string, permanentlyDeleteFile?: boolean) => {
    const { administrationActions } = this.props;

    administrationActions.requestVideoDelete(videoId, permanentlyDeleteFile ?? false);
  };

  private handleError = (errorMessage: string) => {
    const { appActions } = this.props;

    appActions.postSnackbarMessage(errorMessage, { variant: "error" });
  };

  private getVideoId = (): string | undefined => {
    const { match } = this.props;
    const { params } = match;
    const { videoId } = params;

    if (videoId.toLowerCase() === "add") {
      return undefined;
    }

    return videoId;
  };
};

export const VideoPage = withStyles(styles)(
  connect<VideoPageStateProps, VideoPageDispatchProps, VideoPageOwnProps, ApplicationState>(
    (state) => ({
      administrationState: state.administration,
    }),
    (dispatch) => ({
      appActions: bindActionCreators(baseActionCreators.app, dispatch),
      administrationActions: bindActionCreators(actionCreators.administration, dispatch),
    }),
  )(VideoPageComponent),
);
