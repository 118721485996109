import { Dialog } from "@interface48/app";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import { red } from "@material-ui/core/colors";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";

const styles = (theme: Theme) =>
  createStyles({
    dialog: {
      [theme.breakpoints.up("sm")]: {
        minWidth: 480,
      },
    },
    deleteOption: {
      marginTop: theme.spacing(2),
    },
    deleteOptionLabel: {
      fontSize: theme.typography.body1.fontSize,
    },
    dialogActions: {
      padding: "8px 12px",
      "& button": {
        marginLeft: theme.spacing(1),
      },
    },
    deleteAction: {
      color: theme.palette.getContrastText(red[500]),
      backgroundColor: red[500],
      "&:hover": {
        backgroundColor: red[700],
      },
    },
  });

interface EntityDeleteDialogProps extends WithStyles<typeof styles> {
  entityId: string;
  entityName: string;
  entityDeleteOption?: string;
  isOpen: boolean;
  isActionPending: boolean;
  onCancel: () => void;
  onDelete: (entityId: string, isEntityDeleteOptionSelected?: boolean) => void;
}

export const EntityDeleteDialogComponent = (props: EntityDeleteDialogProps) => {
  const { entityId, entityName, entityDeleteOption, isOpen, isActionPending, onCancel, onDelete, classes } = props;
  const [isDeleteOptionSelected, setIsDeleteOptionSelected] = useState<boolean>(false);

  const handleSetIsDeleteOptionSelectedChanged = () => {
    setIsDeleteOptionSelected(!isDeleteOptionSelected);
  };

  const handleDelete = () => {
    onDelete(entityId, entityDeleteOption ? isDeleteOptionSelected : undefined);
  };

  return (
    <Dialog
      // className={classes.dialog}
      open={isOpen}
      title={`Delete ${entityName}`}
      actions={
        <div className={classes.dialogActions}>
          <Button disabled={isActionPending} onClick={onCancel}>
            Cancel
          </Button>
          <Button
            variant="contained"
            className={classes.deleteAction}
            disabled={isActionPending}
            onClick={handleDelete}
          >
            Delete
          </Button>
        </div>
      }
      onCloseClick={onCancel}
      onEscapeKeyDown={onCancel}
    >
      <Typography variant="body1">Are you sure you want to delete '{entityName}'?</Typography>
      {entityDeleteOption && (
        <FormControlLabel
          control={<Checkbox checked={isDeleteOptionSelected} onChange={handleSetIsDeleteOptionSelectedChanged} />}
          label={entityDeleteOption}
          classes={{ root: classes.deleteOption, label: classes.deleteOptionLabel }}
        />
      )}
    </Dialog>
  );
};

export const EntityDeleteDialog = withStyles(styles)(EntityDeleteDialogComponent);
