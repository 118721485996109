import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import React from "react";
import { DefaultPlayer as VideoPlayer } from "react-html5video";
import { Video } from "../../../api";
import { getDurationDescription } from "../../media/utils";

const ProgressResolutionSeconds = 10;

interface VideoViewerProps {
  video: Video;
  availableWidth: number;
  availableHeight: number;
  initialPlaybackTime?: number;
  onProgressChange?: (playbackTime: number) => void;
  onCompletionChange?: (isCompleted: boolean) => void;
}

interface VideoViewerState {
  playbackTime: number;
  lastReportedPlaybackTime: number;
  playbackTimeIntervalId: number;
  skipAheadAlertTimeoutId?: number;
}

export const VideoViewerComponent = class extends React.Component<VideoViewerProps, VideoViewerState> {
  private videoPlayerRef = React.createRef<VideoPlayer>();

  constructor(props: VideoViewerProps) {
    super(props);

    const { initialPlaybackTime } = this.props;

    const playbackTime = initialPlaybackTime ?? 0;

    this.state = {
      playbackTime,
      lastReportedPlaybackTime: playbackTime,
      playbackTimeIntervalId: 0,
    };
  }

  public componentDidMount() {
    const { initialPlaybackTime, onCompletionChange } = this.props;

    // If we're tracking Video Playthrough and we have to play through this video...
    if (initialPlaybackTime != null) {
      const playbackTimeIntervalId = window.setInterval(this.setPlaybackTime, 1000);

      this.setState({ playbackTimeIntervalId });
    }
    // Otherwise, if we're tracking Video Playthrough and we got it, then it must be the case this video has been
    // played through to completion...
    else if (onCompletionChange) {
      onCompletionChange(true);
    }
  }

  public render() {
    const { video, availableHeight, initialPlaybackTime } = this.props;
    const { skipAheadAlertTimeoutId } = this.state;

    const videoCardStyle = {
      width: video.resolutionWidth + "px",
      borderRadius: 0,
    };
    let cardActions = null;
    if (video.chapters && video.chapters.length) {
      const chapterLinks = [];
      for (let i = 0; i < video.chapters.length; i++) {
        chapterLinks.push(<Button key={"fb_" + i}>{video.chapters[i]}</Button>);
      }
      cardActions = <CardActions>{chapterLinks}</CardActions>;
    }
    let documents = null;
    if (video.documents && video.documents.length) {
      const documentLinks = [];
      for (let i = 0; i < video.documents.length; i++) {
        documentLinks.push(
          <Button
            variant="contained"
            key={"lb_" + i}
            href={`/static/media/videos/${video.id}/documents/` + video.documents[i]}
          >
            {video.documents[i]}
          </Button>,
        );
      }
      documents = (
        <div style={{ padding: "0 16px 16px 16px", position: "relative" }}>
          <span
            style={{
              fontSize: "18px",
              color: "rgba(0, 0, 0, 0.87)",
              display: "block",
              lineHeight: "36px",
              borderBottom: "solid 1px #dddddd",
            }}
          >
            Documents
          </span>
          <CardActions style={{ paddingLeft: "0", paddingBottom: "0" }}>{documentLinks}</CardActions>
        </div>
      );
    }
    return (
      <div className="video-viewer-container" style={{ height: availableHeight - 2, overflowY: "scroll" }}>
        <Card className="video-player" style={videoCardStyle}>
          <CardMedia>
            <VideoPlayer
              ref={this.videoPlayerRef}
              controls={["PlayPause", "Seek", "Time", "Volume", "Fullscreen"]}
              poster={`/api/videos/${video.id}/poster-file`}
              onSeeking={initialPlaybackTime != null ? this.handleSeeking : undefined}
              onEnded={initialPlaybackTime != null ? this.handleEnded : undefined}
            >
              <source src={`/api/videos/${video.id}/file`} type="video/mp4" />
            </VideoPlayer>
            {skipAheadAlertTimeoutId ? (
              <Alert severity="info" square={true}>
                You may not skip ahead until you have played the video through to completion.
              </Alert>
            ) : null}
          </CardMedia>
          <CardContent style={{ paddingBottom: "16px" }}>
            <Typography variant="h5" component="h2" gutterBottom={true}>
              {video.name}
            </Typography>
            <Typography variant="subtitle1" gutterBottom={true}>
              {getDurationDescription(video.durationSeconds)}
            </Typography>
            {cardActions}
            {documents}
          </CardContent>
        </Card>
      </div>
    );
  }

  public componentDidUpdate(prevProps: VideoViewerProps, prevState: VideoViewerState) {
    const { video: prevVideo } = prevProps;
    const { video, initialPlaybackTime, onProgressChange, onCompletionChange } = this.props;
    const { playbackTime, lastReportedPlaybackTime, playbackTimeIntervalId } = this.state;

    const prevVideoId = prevVideo?.id ?? 0;
    const videoId = video?.id ?? 0;

    const videoPlayer = this.videoPlayerRef.current;

    if (videoPlayer) {
      // If we just loaded a new video...
      if (prevVideoId !== videoId) {
        if (playbackTimeIntervalId) {
          window.clearInterval(playbackTimeIntervalId);
        }

        videoPlayer.videoEl.load();

        // If we're tracking Video Playthrough and we have to play through this video...
        if (initialPlaybackTime != null) {
          const playbackTimeIntervalId = window.setInterval(this.setPlaybackTime, 1000);

          const nextPlaybackTime = initialPlaybackTime ?? 0;

          videoPlayer.videoEl.currentTime = nextPlaybackTime;

          this.setState({
            playbackTime: nextPlaybackTime,
            lastReportedPlaybackTime: nextPlaybackTime,
            playbackTimeIntervalId,
          });
        }
        // Otherwise, if we're tracking Video Playthrough, indicate that we've played this video through to completion...
        else if (onCompletionChange) {
          onCompletionChange(true);
        }
      }
      // Otherwise, if we're tracking progress, and we've hit a "progress reporting" checkpoint...
      else if (onProgressChange && playbackTime - lastReportedPlaybackTime > ProgressResolutionSeconds) {
        onProgressChange(playbackTime);

        this.setState({ ...this.state, lastReportedPlaybackTime: playbackTime });
      }
    }
  }

  public componentWillUnmount() {
    const { playbackTimeIntervalId } = this.state;

    if (playbackTimeIntervalId) {
      window.clearInterval(playbackTimeIntervalId);
    }
  }

  private setPlaybackTime = () => {
    const { playbackTime } = this.state;

    const videoPlayer = this.videoPlayerRef.current;

    if (videoPlayer && !videoPlayer.videoEl.seeking) {
      const nextPlaybackTime = videoPlayer.videoEl.currentTime;

      if (nextPlaybackTime > playbackTime) {
        this.setState({ ...this.state, playbackTime: nextPlaybackTime });
      }
    }
  };

  private handleSeeking = () => {
    const { playbackTime, playbackTimeIntervalId, skipAheadAlertTimeoutId } = this.state;

    const videoPlayer = this.videoPlayerRef.current;

    if (videoPlayer && playbackTimeIntervalId > 0 && videoPlayer.videoEl.currentTime > playbackTime) {
      videoPlayer.videoEl.currentTime = playbackTime;

      if (skipAheadAlertTimeoutId) {
        window.clearTimeout(skipAheadAlertTimeoutId);
      }

      const nextSkipAheadAlertTimeoutId = window.setTimeout(() => {
        this.setState({ ...this.state, skipAheadAlertTimeoutId: undefined });
      }, 5000);

      this.setState({ ...this.state, skipAheadAlertTimeoutId: nextSkipAheadAlertTimeoutId });
    }
  };

  private handleEnded = () => {
    const { onCompletionChange } = this.props;
    const { playbackTimeIntervalId } = this.state;

    if (playbackTimeIntervalId > 0) {
      window.clearInterval(playbackTimeIntervalId);

      this.setState({ ...this.state, playbackTimeIntervalId: 0 });

      if (onCompletionChange) {
        onCompletionChange(true);
      }
    }
  };
};

export const VideoViewer = VideoViewerComponent;
